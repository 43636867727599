<template>
    <div v-if="alerts && alerts.length > 0" class="inset-x-0 px-4 mt-8 mx-auto bg-white rounded max-w-xl w-full">
        <div class="p-5 text-center text-red-500">{{ alerts[0]?.msg }}</div>
    </div>
    <div class="px-4 mt-8 mx-auto bg-white rounded-lg max-w-xl w-full">
        <div class="v-bbgray p-5 text-center">
            <span class="mx-auto flex-cc profile_img w-16 h-16 bg-[#42b6f8] font-white before:w-16 before:h-16">
                <svg class="w-7 h-7 fill-white"><use xlink:href="@/assets/i.svg#nav-masters"></use></svg>
            </span>

            <h1 class="uppercase">Вход</h1>
        </div>
        <div class="p-5">
            <form id="login-form">
                <div class="mb-4">
                    <input type="text" class="text-teal-600 w-full border px-5 h-9 text-sm rounded" v-model="username" placeholder="Логин / Email" />
                </div>
                <div class="mb-4">
                    <input
                        type="password"
                        class="text-teal-600 w-full border px-5 h-9 text-sm rounded"
                        v-model.trim="password"
                        placeholder="Пароль"
                    />
                </div>

                <div
                    class="mx-auto block v-btn group bg-green-500 hover:bg-green-400 rounded-[4px] duration-300 transition-colors ease-out px-5 py-3 h-9"
                >
                    <div
                        class="text-sm font-latobold leading-none uppercase text-white flex items-center"
                        @click="Login({ username: username, password: password })"
                    >
                        Войти
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "MainPage",

    data() {
        return {
            username: null,
            password: null,
        };
    },

    computed: {
        ...mapGetters(["alerts"]),
    },

    methods: {
        ...mapActions(["Login"]),
    },
};
</script>
