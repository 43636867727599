<template>
    <div
        class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
        @click="click"
        >
        <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
            <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto transition-all duration-300">
                <use :xlink:href="require('@/assets/i.svg') + '#' + ico"></use>
            </svg>
            <h2 class="text-2xl z-2 relative mt-1 text-white transition-all duration-300">
                {{ showNum(stage) }}
            </h2>
            <svg
                class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 transition-all duration-300"
                :class="back"
            >
                <use xlink:href="@/assets/i.svg#bg-romb"></use>
            </svg>
        </div>
        <div
            class="duration-300 left-0 w-full opacity-100 text-status-20 font-latobold"
        >
            <p class="label-shit">{{ label }}</p>
            <p
                class="sign-shit"
                :class="showSign(stage - plane)"
            >{{ showNum(stage - plane) }} <span>дн.</span></p>
            <p class="plan-shit" >ПЛАН: {{ showNum(plane) }} <span>дн.</span></p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ReportDiffRomb',
    props: {
        stage: Number,
        plane: Number,
        label: String,
        back: String,
        ico: String,
        click: Function,
    },
    methods: {
        showNum(n){
            return Math.abs(+n || 0).toLocaleString(Intl.NumberFormat, { 
                maximumFractionDigits: 1,
             });
        },
        showSign(n){
            return (Math.abs( +n || 0) < 0.05)
                ? 'null-diff'
                : +n > 0
                ? 'positive-diff'
                : 'negative-diff';
        }
    }
}
</script>

<style scoped>
.label-shit {
    color: #262D29;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 10px;
}
.sign-shit {
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
}
.sign-shit span {
    font-size: 10px; 
}
.plan-shit {
    margin-top: 25px;
    color: #ABABAB;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.plan-shit span {
    font-size: 8px; 
}
</style>