<template>
  <div
    class="w-full bg-white v-bgray2 my-4 pt-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <a
      class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
      @click="setSalaryOrders([]), $emit('close')"
    >
      <span class="text-white mr-2">Закрыть</span>
      <svg class="fill-white w-4 h-4">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </a>
    <div>
      <select v-if="false">
        <option value="1">Назначены</option>
        <option value="2">В работе</option>
        <option value="3">Сделано</option>
        <option value="4">Одобрено</option>
        <option value="5"></option>
        <option value="6"></option>
        <option value="7"></option>
        <option value="8"></option>
      </select>
    </div>
    <div
      class="grid grid-cols-11 max-2xl:grid-cols-workersalary_m max-m:hidden v-bbgray p-4 uppercase items-center"
    >
      <div class="font-latobold text-gray-400 text-[11px]">Статус</div>
      <div class="font-latobold text-gray-400 text-[11px]">№ Заказа</div>
      <div class="font-latobold text-gray-400 text-[11px]">Дата</div>
      <div
        v-if="role == 'Master'"
        class="font-latobold text-gray-400 text-[11px] col-span-2"
      >
        Клиент
      </div>
      <div v-else class="font-latobold text-gray-400 text-[11px] col-span-2">
        ИНЖЕНЕР
      </div>
      <div class="font-latobold text-gray-400 text-[11px] text-center">ЗП</div>
    </div>
    <div
      v-for="order in salaryOrders"
      :key="order"
      class="grid grid-cols-11 max-2xl:grid-cols-5 px-4 py-2 gap-2 v-bbgray bg-teal-light odd:bg-white items-center transition duration-300 cursor-pointer hover:shadow-v1"
    >
      <div class="w-5 h-5"><LineIco :status="parseInt(order.status)" /></div>

      <a
        class="text-green-500 hover:text-green-400 cursor-pointer"
        @click="editOrderId(order.id)"
        >{{ order.iid }}
      </a>

      <div v-if="order.date" class="flex items-center max-m:col-span-2">
        <svg class="fill-status-6 min-w-[1rem] w-4 h-4 mr-2">
          <use xlink:href="@/assets/i.svg#nav-date-line"></use>
        </svg>
        <div>{{ moment(order.date).format("DD.MM.YYYY") }}</div>
      </div>

      <template v-if="['Logist', 'Studio', 'Hosd'].includes(role)">
        <div class="flex col-span-2 max-m:col-span-5">
          <div
            class="min-w-[1.5rem] rounded-full mr-4 overflow-hidden bg-status-8"
          >
            <IcoPic :pic="order.wpicture" :name="order.wname" />
          </div>
          <div class="flex flex-col justify-center overflow-hidden">
            <span class="text-[10px] text-gray-400 leading-none">ИНЖЕНЕР</span>
            <span class="text-black overflow-hidden"
              >{{ order.wsecondname }} {{ order.wname[0] }}.
              {{ order.wmiddlename[0] }}.
            </span>
          </div>
        </div>
      </template>

      <template v-else>
        <div
          v-if="order.client"
          class="text-black mr-4 flex w-full h-full items-center col-span-2 max-m:col-span-5"
        >
          <span
            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
            :class="'bg-status-' + order.status"
          >
            {{ order.client.slice(0, 1) }}
          </span>
          <div class="flex flex-col justify-center overflow-hidden pr-4">
            <span
              class="text-base font-semibold break-all max-h-10 leading-5"
              >{{ order.client }}</span
            >
          </div>
        </div>
      </template>

      <template v-if="order.salary">
        <div class="text-center">
          <div
            class="text-gray-400 text-[9px] invisible h-0 max-m:visible max-m:h-5"
          >
            ЗП
          </div>
          <template v-for="sal in order.salary" :key="sal">
            <template v-if="parseInt(sal?.type) == 1">
              <template v-if="parseInt(sal?.status) == 7">
                <template v-if="order.r_id">
                  <span class="text-status-7 text-base font-latobold"
                    >РЕКЛАМАЦИЯ</span
                  >
                </template>
              </template>
              <template v-else>
                <span class="text-status-1 text-base font-latobold">{{
                  sal.sum
                }}</span
                ><br />
              </template>
            </template>
          </template>
        </div>

        <div class="text-right">
          <div
            class="text-gray-400 text-[9px] invisible h-0 max-m:visible max-m:h-5"
          >
            ИТОГ
          </div>
          <span class="text-base font-latobold">{{ order.total }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import LineIco from "../components/ui/LineIco.vue";

export default {
  name: "SalaryOrder",

  emits: ["close"],

  components: { LineIco },

  data() {
    return {
      moment: moment,
      loader: false,
    };
  },

  computed: {
    ...mapGetters(["s", "role", "salaryOrders"]),
  },

  methods: {
    ...mapActions(["setSalaryOrders", "editOrderId"]),
  },
  mounted() {
    console.log("ВИДЖЕТ!!!");
  },
};
</script>
