<template>
  <template v-if="car">
    <a
      class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
      @click="$emit('close')"
    >
      <span class="text-white mr-2">Закрыть</span>
      <svg class="fill-white w-4 h-4">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </a>

    <div class="h-screen overflow-y-auto">
      <div class="p-5 v-bbgray">
        <div class="flex items-center">
          <span
            class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg uppercase"
          >
            {{ car.mark?.slice(0, 1) }}
          </span>
          <div class="flex">
            <span
              class="text-base font-latobold mr-2"
              :class="car.year ? '' : 'text-red-500'"
              >{{ car.mark }}</span
            >
            <span class="v-gray font-latoblod-serif">
              <span
                class="w-auto rounded-sm v-tsnum px-2 py-1 text-base"
                :class="
                  car.year ? 'text-slate-600' : 'text-red-500 border-rose-300'
                "
                >{{ car.num }}</span
              >
            </span>
          </div>
        </div>
      </div>

      <div class="flex h-full max-l:flex-col">
        <div class="flex-1">
          <div
            v-if="['Manager', 'Logist', 'Studio', 'Hosd'].includes(role)"
            class="p-2 v-bbgray flex"
          >
            <div class="w-1/2 px-4 my-2 relative group" v-if="car.client_id">
              <div class="flex">
                <div
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                >
                  <span class="w-6 h-6 flex-cc">
                    <IcoPic :pic="null" :name="car.client" />
                  </span>
                </div>
                <div class="flex flex-col justify-center overflow-hidden pr-8">
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >Клиент</span
                  >
                  <span class="font-latobold text-base">{{ car.client }}</span>
                </div>
                <div
                  @click="removeClient"
                  class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                >
                  <a class="group cursor-pointer">
                    <svg
                      class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use></svg
                  ></a>
                </div>
              </div>
            </div>
            <div class="w-1/2 px-4 my-2 relative group" v-else>
              <div class="flex items-center w-full">
                <p class="text-red-500 leading-none w-full max-w-[100px] mr-6">
                  Выберите клиента
                </p>
                <div class="relative w-full">
                  <input
                    type="text"
                    v-model.trim="text_client"
                    class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray border-rose-300 rounded-sm text-sm text-teal-700"
                    @input="findClient()"
                    @click="findClient()"
                  />
                  <div
                    v-if="clientList && clientList.length > 0"
                    class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                  >
                    <div
                      v-for="cl in clientList"
                      :key="cl.id"
                      @click="setOtherClient(cl)"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>{{ cl.secondname }} {{ cl.name }}</span>
                      <span class="text-xs">{{ cl.position }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/2 px-4 my-2 relative group">
              <template v-if="car.company_id != null">
                <div class="flex">
                  <div
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                  >
                    <span class="w-6 h-6 flex-cc">
                      <IcoPic :pic="null" :name="car.company" />
                    </span>
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >Компания</span
                    >
                    <span class="font-latobold text-base">{{
                      car.company
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="['Manager', 'Studio', 'Hosd'].includes(role)"
                  class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                >
                  <a class="group cursor-pointer" @click="setCarCompany()">
                    <svg
                      class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                </div>
              </template>
              <template v-else-if="role != 'Client'">
                <div class="flex items-center w-full">
                  <p
                    class="text-red-500 leading-none w-full max-w-[100px] mr-6"
                  >
                    Выберите компанию
                  </p>
                  <div class="relative w-full">
                    <input
                      type="text"
                      v-model.trim="text_company"
                      class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray border-rose-300 rounded-sm text-sm text-teal-700"
                      @input="findCompany()"
                      @click="findCompany()"
                      @focus="findCompany()"
                    />
                    <div
                      v-if="companyList && companyList.length > 0"
                      class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                    >
                      <div
                        v-for="co in companyList"
                        :key="co"
                        @click="setCarCompany(co), (text_company = '')"
                        class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                      >
                        <span>{{ co.secondname }} {{ co.name }}</span>
                        <span class="text-xs">{{ co.position }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div
                v-else
                class="w-full h-full flex justify-center items-center"
              >
                <p class="text-rose-500 text-sm">Компания не выбрана!</p>
              </div>
            </div>
          </div>

          <div class="p-2 v-bbgray flex flex-wrap">
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Марка</label>
              <input
                type="text"
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="car.mark"
                @change="saveCar(car)"
              />
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Гос номер</label>
              <input
                type="text"
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="car.num"
                @change="saveCar(car)"
              />
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label :class="!car?.year ? 'text-red-500' : 'text-gray-400'"
                >Год выпуска</label
              >
              <input
                type="text"
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                :class="!car?.year ? 'border-rose-300' : ''"
                v-model="car.year"
                @change="saveCar(car)"
              />
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Vin</label>
              <input
                type="text"
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="car.win"
                @change="saveCar(car)"
              />
            </div>

            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Категория</label>
              <select
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="car.category"
                @change="saveCar(car)"
              >
                <option disabled value="">Выбрать</option>
                <option :value="'M1'">M1</option>
                <option :value="'M2'">M2</option>
                <option :value="'M3'">M3</option>
                <option :value="'N1'">N1</option>
                <option :value="'N2'">N2</option>
                <option :value="'N3'">N3</option>
              </select>
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Модель</label>
              <input
                type="text"
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="car.model"
                @change="saveCar(car)"
              />
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Цвет</label>
              <input
                type="text"
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="car.color"
                @change="saveCar(car)"
              />
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Паспорт ТС (№ СТС)</label>
              <input
                type="text"
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="car.sts"
                @change="saveCar(car)"
              />
            </div>
            <div class="w-full flex flex-col px-4 my-2">
              <label class="text-gray-400">Документ о регистрации</label>
              <input
                type="text"
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="car.doc"
                @change="saveCar(car)"
                placeholder="ПТС (для Российской Федерации)"
              />
            </div>
          </div>

          <div class="p-2 v-bbgray w-full mt-4">
            <h2 class="font-latobold text-base px-4">
              Мониторинг
              <span v-if="parseInt(car.monitoring) == 2">(подключен)</span>
              <span v-else>(отключен)</span>
            </h2>
            <template
              v-if="
                ['Logist', 'Studio', 'Hosd'].includes(role) &&
                parseInt(car.monitoring) != 2
              "
            >
              <div class="flex flex-wrap">
                <div class="w-1/5 px-4 my-2">
                  <label>
                    <span class="text-gray-400">Имя виалон</span>
                    <input
                      class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                      type="text"
                      v-model="car.vialon"
                      @change="saveCar(car)"
                    />
                  </label>
                </div>
                <div class="w-1/5 flex flex-col px-4 my-2">
                  <label class="text-gray-400">№ сим карты</label>
                  <input
                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    type="text"
                    v-model="car.sim"
                    @change="saveCar(car)"
                  />
                </div>
                <div class="w-1/5 flex flex-col px-4 my-2">
                  <label class="text-gray-400">Оператор</label>
                  <select
                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    v-model="car.operator"
                    @change="saveCar(car)"
                  >
                    <option value="">Выбрать</option>
                    <option :value="'М2М'">М2М</option>
                    <option :value="'БИЛАЙН'">БИЛАЙН</option>
                    <option :value="'МТС'">МТС</option>
                    <option :value="'МЕГАФОН'">МЕГАФОН</option>
                  </select>
                </div>
                <div class="w-1/5 flex flex-col px-4 my-2">
                  <label>Тариф</label>
                  <select
                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    v-model="car.tariff"
                  >
                    <option value="0" disabled>Выбрать</option>
                    <option :value="1">месяц</option>
                    <option :value="2">месяц+</option>
                    <option :value="3">год</option>
                    <option :value="4">год+</option>
                  </select>
                </div>
                <div class="w-1/5 flex flex-col px-4 my-2">
                  <label class="text-gray-400">Начало</label>
                  <input
                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                    type="date"
                    v-model="car.start"
                  />
                </div>
              </div>

              <template v-if="parseInt(car.monitoring) != 2">
                <div class="flex justify-end">
                  <!--  <div class="w-1/4 flex flex-col px-4 my-2">
                                    <label class="text-gray-400">Окончание</label>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="date"
                                        v-model="car.end"
                                    />
                                </div> -->

                  <span
                    class="v-btn v-btn_text mr-4 flex items-center bg-green-500 hover:bg-green-400"
                    @click="
                      onMonitoring({
                        car_id: car.id,
                        vialon: car.vialon,
                        tariff: car.tariff,
                        operator: car.operator,
                        sim: car.sim,
                        start: car.start,
                      })
                    "
                  >
                    <svg
                      class="fill-white w-16px h-16px mr-3 duration-100 transition ease-linear"
                    >
                      <use xlink:href="@/assets/i.svg#ico-ok"></use>
                    </svg>
                    Подключить
                  </span>
                </div>
              </template>
            </template>

            <div class="px-4 my-4">
              <div
                class="grid grid-cols-15 gap-2 p-2 bg-teal-light odd:bg-white border-t border-gray-200"
              >
                <div class=""></div>
                <div class="col-span-2">
                  <label class="text-gray-400">Начало</label>
                </div>
                <div class="col-span-2">
                  <label class="text-gray-400">Виалон</label>
                </div>
                <div class="col-span-2">
                  <label class="text-gray-400">Тариф</label>
                </div>
                <div class="col-span-2">
                  <label class="text-gray-400">Номер</label>
                </div>
                <div class="col-span-2">
                  <label class="text-gray-400">Оператор</label>
                </div>
                <div class="col-span-3">
                  <label class="text-gray-400">Статус</label>
                </div>
              </div>

              <template v-for="monitor in car.monitorings" :key="monitor">
                <div
                  class="grid grid-cols-16 gap-2 p-2 bg-teal-light odd:bg-white group relative"
                  :class="{ '!bg-green-200': monitor.status == 2 }"
                >
                  <svg
                    v-if="monitor.status == 2"
                    class="w-4 h-4 mt-1 fill-status-6"
                  >
                    <use xlink:href="@/assets/i.svg#status-6"></use>
                  </svg>
                  <svg v-else class="w-4 h-4 mt-1 fill-status-20">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>

                  <div class="col-span-2">
                    {{ moment(monitor.start).format("DD.MM.YYYY") }}
                  </div>

                  <div class="col-span-2">{{ car.vialon }}</div>

                  <div class="col-span-2">
                    <span v-if="monitor.tariff">{{ trf[monitor.tariff] }}</span>
                    <template v-else>
                      <template
                        v-if="
                          ['Logist', 'Finance', 'Studio', 'Hosd'].includes(role)
                        "
                      >
                        <select
                          class="bg-slate-50 h-7 p-1 w-full v-bgray rounded-sm text-sm text-teal-700"
                          v-model="car.tariff"
                          @change="
                            setClientTariff({
                              monitor_id: monitor.id,
                              id: car.client_id,
                              tariff: car.tariff,
                            })
                          "
                        >
                          <option value="0" disabled>Выбрать</option>
                          <option :value="1">месяц</option>
                          <option :value="2">месяц+</option>
                          <option :value="3">год</option>
                          <option :value="4">год+</option>
                        </select>
                      </template>
                      <span v-else>тариф не указан</span>
                    </template>
                  </div>

                  <div class="col-span-2">
                    <span v-if="monitor.sim">{{ monitor.sim }}</span>
                    <template v-else>
                      <!-- <template v-if="['Finance', 'Studio' , 'Hosd'].includes(role)">
                                                <input
                                                    class="bg-slate-50 h-7 p-1 w-full v-bgray rounded-sm text-sm text-teal-700"
                                                    type="text"
                                                    v-model="monitor.sim"
                                                    @change="setSim({ id: monitor.id, sim: monitor.sim })"
                                                />
                                            </template> -->
                      <span>номер не указан</span>
                    </template>
                  </div>

                  <div class="col-span-2">
                    <span v-if="monitor.operator">{{ monitor.operator }}</span>
                    <template v-else><span>оператор не указан</span> </template>
                  </div>

                  <div class="flex col-span-3">
                    <template
                      v-if="
                        monitor.status == 2 &&
                        ['Logist', 'Finance', 'Studio', 'Hosd'].includes(role)
                      "
                    >
                      <span
                        class="v-btn v-btn_text !text-[11px] px-3 h-6 mr-2 flex items-center bg-red-500 hover:bg-red-400"
                        @click="
                          offMonitoring({
                            car_id: monitor.car_id,
                            id: monitor.id,
                            cancel_id: 1,
                          })
                        "
                      >
                        Неуплата
                      </span>
                      <span
                        class="v-btn v-btn_text !text-[11px] px-3 h-6 flex items-center bg-red-500 hover:bg-red-400"
                        @click="
                          offMonitoring({
                            car_id: monitor.car_id,
                            id: monitor.id,
                            cancel_id: 2,
                          })
                        "
                      >
                        ушел
                      </span>
                    </template>
                    <template v-else>
                      <span v-if="monitor.cancel_id == 1"
                        >Отключен: неуплата</span
                      >
                      <span v-if="monitor.cancel_id == 2"
                        >Отключен: сам ушел</span
                      >
                    </template>
                  </div>
                  <template
                    v-if="
                      ['Logist', 'Studio', 'Hosd', 'Finance'].includes(role)
                    "
                  >
                    <div
                      class="flex absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150"
                    >
                      <a
                        class="cursor-pointer ml-4"
                        @click="delMonitor(monitor.id)"
                      >
                        <svg
                          class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                        >
                          <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                      </a>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>

          <div class="p-2 v-bbgray flex flex-wrap">
            <div class="w-1/4 flex flex-col px-4 my-2 self-center">
              <sup>{{ car?.warranty_at ? car?.warranty_at : "-" }}</sup>
              <input
                class="mychbox"
                type="checkbox"
                id="warranty"
                v-model="car.warranty"
                :class="{ checked: car.warranty }"
                @change="saveCar(car)"
              />
              <label class="text-gray-400" for="warranty">Гарантийное ТС</label>
            </div>

            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Состояние ТС</label>
              <select
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="car.state"
                @change="saveCar(car)"
              >
                <option value="">Выбрать</option>
                <option :class="{ selected: car.state == 1 }" value="1">
                  Работает
                </option>
                <option :class="{ selected: car.state == 2 }" value="2">
                  В ремонте
                </option>
                <option :class="{ selected: car.state == 3 }" value="3">
                  Неисправна
                </option>
                <option :class="{ selected: car.state == 4 }" value="4">
                  Конструктивные особенности
                </option>
              </select>
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Макс. скорость</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                name="maxspeed"
                v-model="car.maxspeed"
                @change="saveCar(car)"
              />
            </div>
          </div>

          <div class="p-2 v-bbgray flex flex-wrap">
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Вид топлива</label>
              <select
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                name="fuel"
                v-model="car.fuel"
                @change="saveCar(car)"
              >
                <option value="">Выбрать</option>
                <option :class="{ selected: car.fuel == 1 }" value="1">
                  Газ
                </option>
                <option :class="{ selected: car.fuel == 2 }" value="2">
                  Бензин
                </option>
                <option :class="{ selected: car.fuel == 3 }" value="3">
                  ДТ
                </option>
              </select>
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Кол-во баков</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                name="tank"
                v-model="car.tank"
                @change="saveCar(car)"
              />
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2">
              <label class="text-gray-400">Объем бака л</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                name="val"
                v-model="car.val"
                @change="saveCar(car)"
              />
            </div>
            <div class="w-1/4 flex flex-col px-4 my-2 self-center">
              <input
                class="mychbox"
                type="checkbox"
                id="shape"
                v-model="car.shape"
                @change="saveCar(car)"
              />
              <label class="text-gray-400" for="shape"
                >Нестандартная форма баков</label
              >
            </div>
          </div>

          <div class="p-2 v-bbgray flex flex-wrap">
            <div class="w-1/3 flex flex-col px-4 my-2">
              <input
                class="mychbox"
                type="checkbox"
                id="flow"
                v-model="car.flow"
                @change="saveCar(car)"
              />
              <label class="text-gray-400" for="flow"
                >Наличие "перетекайки"</label
              >
            </div>
            <div class="w-2/3 flex flex-col px-4 my-2">
              <input
                class="mychbox"
                type="checkbox"
                id="block"
                v-model="car.block"
                @change="saveCar(car)"
              />
              <label class="text-gray-400" for="block"
                >Есть блокировка "перетекайки"</label
              >
            </div>
          </div>

          <div class="p-2 v-bbgray flex flex-wrap">
            <div class="w-1/3 flex flex-col px-4 my-2">
              <sup>
                {{ car.garbage_at ? car.garbage_at : "-" }}
              </sup>
              <input
                class="mychbox"
                type="checkbox"
                id="garbage"
                v-model="car.garbage"
                @change="saveCar(car)"
              />
              <label class="text-gray-400" for="garbage"
                >Надо чистить бак</label
              >
            </div>
            <div class="w-1/3 flex flex-col px-4 my-2">
              <sup>
                {{ car.demounting_at ? car.demounting_at : "-" }}
              </sup>
              <input
                class="mychbox"
                type="checkbox"
                id="demounting"
                v-model="car.demounting"
                @change="saveCar(car)"
              />
              <label class="text-gray-400" for="demounting"
                >Нужен демонтаж баков</label
              >
            </div>
          </div>

          <div class="p-2 v-bbgray pb-20">
            <div class="w-full px-4 my-2">
              <h2 class="font-latobold text-base mb-2">Уведомления</h2>
              <div class="flex flex-wrap">
                <div class="w-1/4 flex flex-col my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="notice_speed"
                    v-model="car.notice_speed"
                    @change="saveCar(car)"
                  />
                  <label class="text-gray-400" for="notice_speed"
                    >Скорость</label
                  >
                </div>
                <div class="w-1/4 flex flex-col my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="notice_hours"
                    v-model="car.notice_hours"
                    @change="saveCar(car)"
                  />
                  <label class="text-gray-400" for="notice_hours"
                    >График работы</label
                  >
                </div>
                <div class="w-1/4 flex flex-col my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="notice_zone"
                    v-model="car.notice_zone"
                    @change="saveCar(car)"
                  />
                  <label class="text-gray-400" for="notice_zone">Геозона</label>
                </div>
                <div class="w-1/4 flex flex-col my-2">
                  <input
                    class="mychbox"
                    type="checkbox"
                    id="notice_fuel"
                    v-model="car.notice_fuel"
                    @change="saveCar(car)"
                  />
                  <label class="text-gray-400" for="notice_fuel"
                    >Слив топлива</label
                  >
                </div>
              </div>
              <div class="w-full flex flex-col my-2">
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  placeholder="Другое"
                  v-model="car.notice_other"
                  @change="saveCar(car)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="max-w-[300px] w-full p-5">
          <div class="mt20 mb20 bb">
            <div class="flex flex-wrap">
              <template v-for="i in car.images" :key="i">
                <div class="relative m-2 w-16">
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: car })"
                  >
                    <svg
                      class="bg-white rounded-full w-4 h-4 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <IcoImg :img="i.img" :status="i.status" />
                </div>
              </template>
            </div>
          </div>

          <div class="mb20">
            <div class="py-1">
              <UploadImg
                :item="car"
                :id="parseInt(car.id)"
                type="car"
                :status="2"
                name="СТС лицо: jpg, png"
              />
            </div>
            <div class="py-1">
              <UploadImg
                :item="car"
                :id="parseInt(car.id)"
                type="car"
                :status="12"
                name="СТС оборот: jpg, png"
              />
            </div>
            <div class="py-1">
              <UploadImg
                :item="car"
                :id="parseInt(car.id)"
                type="car"
                :status="3"
                name="ПТС лицо: jpg, png"
              />
            </div>
            <div class="py-1">
              <UploadImg
                :item="car"
                :id="parseInt(car.id)"
                type="car"
                :status="13"
                name="ПТС оборот: jpg, png"
              />
            </div>
            <div class="py-1">
              <UploadImg
                :item="car"
                :id="parseInt(car.id)"
                type="car"
                :status="14"
                name="Бак близко: jpg, png"
              />
            </div>
            <div class="py-1">
              <UploadImg
                :item="car"
                :id="parseInt(car.id)"
                type="car"
                :status="15"
                name="Бак издалека: jpg, png"
              />
            </div>
            <div class="py-1">
              <h3 class="my-2 font-latobold text-base">Дополнительно</h3>
              <UploadImg
                :item="car"
                :id="parseInt(car.id)"
                type="car"
                :status="4"
                name="СТРАХОВКА ОСАГО: jpg, png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <div v-else class="absolute top-32 left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import Datepicker from "@vuepic/vue-datepicker";
import UploadImg from "../ui/UploadImg.vue";
import moment from "moment";
import API from "@/services/api";
export default {
  name: "CarEdit",

  components: { UploadImg },

  emits: ["close"],

  data() {
    return {
      moment: moment,
      text_company: "",
      text_client: "",
      companyList: [],
      clientList: [],
      loadForm: true,
      trf: {
        1: "месяц",
        2: "месяц+",
        3: "год",
        4: "год+",
      },
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "car",
      "company",
      "ClientCompanies",
    ]),
  },

  methods: {
    ...mapActions([
      "saveCar",
      "onMonitoring",
      "offMonitoring",
      "delMonitor",
      "delImg",
      "setCarClient",
      "setCarCompany",
      "setClientTariff",
      "setSim",
      "getClients",
      "getClientCompanies",
      "setClientCompanies",
      "setClient",
    ]),

    // КОМПАНИЯ
    findCompany() {
      if (this.text_company != "") {
        this.companyList = this.$store.state.companies.filter((item) => {
          let name = null;
          let full = null;
          if (item.name != null) {
            name =
              item.name
                .toLowerCase()
                .indexOf(this.text_company.toLowerCase()) !== -1;
          }
          if (item.full != null) {
            full =
              item.full
                .toLowerCase()
                .indexOf(this.text_company.toLowerCase()) !== -1;
          }
          if (name || full) {
            return true;
          }
          return false;
        });
      } else {
        this.companyList = this.$store.state.companies;
      }
    },
    findClient() {
      if (this.text_client != "") {
        this.clientList = (this.$store.state.client.clients ?? [])
          .filter((item) => {
            let name = null;
            let full = null;
            if (item.name != null) {
              name =
                item.name
                  .toLowerCase()
                  .indexOf(this.text_client.toLowerCase()) !== -1;
            }
            if (item.full != null) {
              full =
                item.full
                  .toLowerCase()
                  .indexOf(this.text_client.toLowerCase()) !== -1;
            }
            if (name || full) {
              return true;
            }
            return false;
          })
          .slice(0, 20);
      } else {
        this.clientList = (this.$store.state.client.clients ?? []).slice(0, 20);
      }
    },
    async removeClient() {
      await this.setCarClient({ car: { id: this.car.id }, client: { id: 0 } });
      this.text_client = "";
      this.findClient();
      this.companyList = [];
    },
    async setOtherClient(client) {
      this.companyList = [];
      await this.setCarClient({ car: { id: this.car.id }, client });
      this.text_client = "";
      await this.loadClientCompanies(client.id);
    },
    async loadClientCompanies(client_id) {
      if (client_id) {
        let res = await API.GET("client/companies-by-client-id", {
          id: client_id,
        });
        console.log(res.data);
        this.$store.state.companies = res.data.companies;
      } else {
        this.$store.state.companies = [];
      }
      this.findCompany();
    },

    // bool() {
    //     this.car.monitoring = Boolean(this.car.monitoring);
    //     this.car.warranty = Boolean(this.car.warranty);
    //     this.car.shape = Boolean(this.car.shape);
    //     this.car.flow = Boolean(this.car.flow);
    //     this.car.block = Boolean(this.car.block);
    //     this.car.garbage = Boolean(this.car.garbage);
    //     this.car.demounting = Boolean(this.car.demounting);
    //     this.car.notice_speed = Boolean(this.car.notice_speed);
    //     this.car.notice_hours = Boolean(this.car.notice_hours);
    //     this.car.notice_zone = Boolean(this.car.notice_zone);
    //     this.car.notice_fuel = Boolean(this.car.notice_fuel);
    // },
  },
  async mounted() {
    this.getClients();
    await this.loadClientCompanies(this.car?.client_id);
    window.DEBUG_CAR_EDIT = this;
  },
};
</script>
