<template>
    <div class="noti-right hidden">
        <div class="history">
            <div v-for="h in OrderHistory" :key="h" class="item">
                <div class="preview mini">
                    <div class="flx avatardel bbu" v-if="h.type == 777">
                        <svg><use xlink:href="@/assets/i.svg#ico-edit"></use></svg>
                    </div>
                    <!-- {% if h.old == 'code' %}Код заказа{% endif %}
                        {% if h.old == 'codebill' %}Код счета{% endif %}
                        {% if h.old == 'address' %}Адрес{% endif %} -->

                    <div class="flx" v-if="h.type == 1">
                        <div class="avatardel bbu">
                            <svg><use xlink:href="@/assets/i.svg#status-1"></use></svg>
                        </div>
                        Создан
                    </div>
                    <div class="flx" v-if="h.type == 41">
                        <div class="avatardel bgn">
                            <svg><use xlink:href="@/assets/i.svg#zp-1"></use></svg>
                        </div>
                        Счет
                    </div>
                    <div class="flx" v-if="h.type == 43">
                        <div class="avatardel bgn">
                            <svg><use xlink:href="@/assets/i.svg#zp-1"></use></svg>
                        </div>
                        Тип оплаты
                    </div>
                    <div class="flx" v-if="h.type == 44">
                        <div class="avatardel bgn">
                            <svg><use xlink:href="@/assets/i.svg#zp-1"></use></svg>
                        </div>
                        Статус оплаты
                    </div>
                    <div class="flx" v-if="h.type == 8">
                        <div class="avatardel">
                            <svg><use xlink:href="@/assets/i.svg#status-4"></use></svg>
                        </div>
                        Мастер
                    </div>
                    <div class="flx" v-if="h.type == 9">
                        <div class="avatardel">
                            <svg><use xlink:href="@/assets/i.svg#nav-clients"></use></svg>
                        </div>
                        Клиент
                    </div>
                    <div class="flx" v-if="h.type == 10">
                        <div class="avatardel">
                            <svg><use xlink:href="@/assets/i.svg#nav-clients"></use></svg>
                        </div>
                        Контакт
                    </div>
                    <div class="flx" v-if="h.type == 15">
                        <div class="avatardel">
                            <svg><use xlink:href="@/assets/i.svg#nav-calendar"></use></svg>
                        </div>
                        Дата
                    </div>
                    <div class="flx" v-if="h.type == 28">
                        <div class="avatardel brd">
                            <svg><use xlink:href="@/assets/i.svg#nav-calendar"></use></svg>
                        </div>
                        Дата удалена
                    </div>
                    <div class="flx" v-if="h.type == 18">
                        <div class="avatardel">
                            <svg><use xlink:href="@/assets/i.svg#sms"></use></svg>
                        </div>
                        Смс
                    </div>
                    <div class="flx" v-if="h.type == 21">
                        <div class="avatardel bbu">
                            <svg><use xlink:href="@/assets/i.svg#nav-service"></use></svg>
                        </div>
                        Заказ-наряд
                    </div>
                    <div class="flx" v-if="h.type == 29">
                        <div class="avatardel brd">
                            <svg><use xlink:href="@/assets/i.svg#nav-service"></use></svg>
                        </div>
                        Заказ-наряд
                    </div>
                    <div class="flx" v-if="h.type == 31">
                        <div class="avatardel bbu">
                            <svg><use xlink:href="@/assets/i.svg#nav-service"></use></svg>
                        </div>
                        Услуга
                    </div>
                    <div class="flx" v-if="h.type == 32">
                        <div class="avatardel bgn">
                            <svg><use xlink:href="@/assets/i.svg#pay-1"></use></svg>
                        </div>
                        Цена
                    </div>
                    <div class="flx" v-if="h.type == 39">
                        <div class="avatardel brd">
                            <svg><use xlink:href="@/assets/i.svg#nav-service"></use></svg>
                        </div>
                        Услуга удалена
                    </div>
                    <div class="flx" v-if="h.type == 61">
                        <div class="avatardel bgn">
                            <svg><use xlink:href="@/assets/i.svg#ico-comment"></use></svg>
                        </div>
                        Комментарий
                    </div>
                    <div class="flx" v-if="h.type == 69">
                        <div class="avatardel brd">
                            <svg><use xlink:href="@/assets/i.svg#ico-comment"></use></svg>
                        </div>
                        Коммент удален
                    </div>

                    <div class="flx" v-if="h.type == 91">
                        <div class="avatardel brd">
                            <svg><use xlink:href="@/assets/i.svg#status-11"></use></svg>
                        </div>
                        Оплата
                    </div>
                    <div class="flx" v-if="h.type == 92">
                        <div class="avatardel brd">
                            <svg><use xlink:href="@/assets/i.svg#status-11"></use></svg>
                        </div>
                        Назначение
                    </div>
                    <div class="flx" v-if="h.type == 93">
                        <div class="avatardel brd">
                            <svg><use xlink:href="@/assets/i.svg#status-11"></use></svg>
                        </div>
                        Начало {{ h.new }}м
                    </div>
                    <div class="flx" v-if="h.type == 51">
                        <div class="avatardel bbu">
                            <svg><use xlink:href="@/assets/i.svg#ico-phone"></use></svg>
                        </div>
                        Задача
                    </div>
                    <div class="flx" v-if="h.type == 52">
                        <div class="avatardel bgd">
                            <svg><use xlink:href="@/assets/i.svg#ico-phone"></use></svg>
                        </div>
                        Завершена
                    </div>
                    <div class="flx" v-if="h.type == 59">
                        <div class="avatardel brd">
                            <svg><use xlink:href="@/assets/i.svg#ico-phone"></use></svg>
                        </div>
                        Задача удален
                    </div>

                    <template v-if="h.type == 20">
                        <div class="flx" v-if="h.new == 3">
                            <div class="avatardel bbd">
                                <svg><use xlink:href="@/assets/i.svg#status-3"></use></svg>
                            </div>
                            Назначен
                        </div>

                        <div class="flx" v-if="h.new == 4">
                            <div class="avatardel bor">
                                <svg><use xlink:href="@/assets/i.svg#status-4"></use></svg>
                            </div>
                            Начат
                        </div>

                        <div class="flx" v-if="h.new == 5">
                            <div class="avatardel">
                                <svg><use xlink:href="@/assets/i.svg#status-5"></use></svg>
                            </div>
                            Сделан
                        </div>

                        <div class="flx" v-if="h.new == 6">
                            <div class="avatardel bgd">
                                <svg><use xlink:href="@/assets/i.svg#status-6"></use></svg>
                            </div>
                            Завершен
                        </div>

                        <div class="flx" v-if="h.new == 8">
                            <div class="avatardel bgd">
                                <svg><use xlink:href="@/assets/i.svg#status-8"></use></svg>
                            </div>
                            Проверен
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "OrderHistory",

    computed: {
        ...mapGetters(["OrderHistory"]),
    },
    mounted() {},
};
</script>
