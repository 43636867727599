<template>
    <div class="my-4">
        <div class="flex justify-between mb-4">
            <div></div>

            <button class="v-btn bg-green-500 hover:bg-green-400 ml-auto mt-2">
                <svg class="fill-white w-16px h-16px mr-3 rotate-45">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <span class="v-btn_text">Создать</span>
            </button>
        </div>

        <div class="w-full bg-white v-bgray2 overflow-hidden rounded-xl relative overflow-x-auto">
            <div class="grid grid-cols-[4rem_1fr_12rem_12rem_2rem] items-center h-10 v-bbgray px-4 uppercase min-w-full">
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">№</div>
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]" sort="client" k="s_client">Название</div>
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center" sort="client" k="s_client">Инженеров</div>
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center" sort="create" k="s_create">Заказов</div>
            </div>
            <div>
                <div v-for="item in FilialList" :key="item" class="grid grid-cols-[4rem_1fr_12rem_12rem_2rem] v-bbgray p-4 items-center transition duration-300 hover:shadow-v1 bg-teal-light odd:bg-white cursor-pointer group relative status-{{ item.status }}" id="{{ item.parent.studio.iid }}">
                    <div class="cell id flx">
                        <svg class="w-5 h-5">
                            <use xlink:href="@/assets/i.svg#nav-branches-line"></use>
                        </svg>
                    </div>
                    <div class="">
                        <a class="flex items-center">
                            <span v-if="item.parent.studio?.picture" class="min-w-[44px] w-11 h-11 rounded-full overflow-hidden bg-green-500 mr-4">
                                <img class="w-full h-full object-cover" :src="'https://api.tahocrm.ru/uploads/users/' + item.parent.studio?.picture" />
                            </span>
                            <span v-else class="w-11 h-11 rounded-full overflow-hidden flex justify-center items-center uppercase text-lg text-white bg-green-500 mr-4">
                                {{ item.parent.studio?.name.slice(0, 1) }}
                            </span>
                            <span class="font-latobold text-base">{{ item.parent.studio?.name }}</span>
                        </a>
                    </div>
                    <div class="flex justify-center items-center">
                        <svg class="w-5 h-5 mr-4">
                            <use xlink:href="@/assets/i.svg#nav-masters"></use>
                        </svg>
                        <span class="text-base font-latobold">{{ item.parent.masters }}</span>
                    </div>
                    <div class="flex justify-center items-center">
                        <svg class="w-5 h-5 mr-4">
                            <use xlink:href="@/assets/i.svg#nav-flow"></use>
                        </svg>
                        <span class="text-base font-latobold">{{ item.parent.orders }}</span>
                    </div>
                    <svg class="w-5 h-5 absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <use xlink:href="@/assets/i.svg#ico-next-one"></use>
                    </svg>
                </div>

                <div v-for="item in item.child" :key="item" class="line hvr status-{{ item.status }}" id="{{ item.studio.iid }}">
                    <div class="cell number flx pl15">
                        <svg class="w-5 h-5 rotate-90">
                            <use xlink:href="@/assets/i.svg#ico-arrow"></use>
                        </svg>
                    </div>
                    <div class="w-11 h-11">
                        <a class="w-11 h-11">
                            <span v-if="item.studio.picture" class="w-11 h-11 rounded-full overflow-hidden bg-green-500">
                                <img class="w-full h-full object-cover" :src="'https://api.tahocrm.ru/uploads/users/' + item.studio.picture" />
                            </span>
                            <span v-else class="w-11 h-11 rounded-full overflow-hidden flex justify-center items-center uppercase text-lg text-white bg-green-500">
                                {{ item.studio.name.slice(0, 1) }}
                            </span>
                            {{ item.studio.name }}
                        </a>
                    </div>
                    <div class="cell flx masters">
                        <svg class="w-5 h-5">
                            <use xlink:href="@/assets/i.svg#nav-masters"></use>
                        </svg>
                        <span>{{ item.masters }}</span>
                    </div>
                    <div class="cell orders">
                        <svg class="w-5 h-5">
                            <use xlink:href="@/assets/i.svg#nav-flow"></use>
                        </svg>
                        <span>{{ item.orders }}</span>
                    </div>
                    <svg class="arrow"><use xlink:href="@/assets/i.svg#ico-next-one"></use></svg>
                </div>
            </div>
        </div>
        <div id="popup" class="cox100 col50"></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "PageFilial",

    data() {
        return {
            data: [],
            item: {},
        };
    },
    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "FilialList"]),
    },
    methods: {
        ...mapActions(["getFilials"]),
    },

    mounted() {
        this.getFilials();
    },
};
</script>
