<template>
    <h1 class="text-3xl font-latobold text-center py-8 uppercase">Контроль</h1>

    <div class="flex justify-between flex-wrap max-m:flex-col max-m:items-center mb-8">
        <div class="v-btn bg-green-500 hover:bg-green-400 v-btn_text right-4" @click="reKK()">Перепроверить КК</div>
    </div>

    <div class="flex justify-between flex-wrap max-m:flex-col max-m:items-center mb-8">
        <div
            class="bg-white p-5 flex-1 uppercase mr-8 rounded-lg group hover:shadow-md transition-shadow duration-150 max-m:w-72 max-m:mx-0 max-m:mb-4"
            @click="show = 'bonus'"
            type="div"
        >
            <a class="flex items-center">
                <svg class="w-4 h-4 mr-2 group-hover:fill-green-400 fill-green-500">
                    <use xlink:href="@/assets/i.svg#nav-bill"></use>
                </svg>
                <h2 class="group-hover:text-green-400 text-green-500 transition-colors duration-150 text-xl font-latobold">Бонусы</h2>
                <svg
                    class="absolute top-1/2 -translate-y-1/2 right-4 w-3 h-3 fill-green-500 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
                >
                    <use xlink:href="@/assets/i.svg#ico-next-one"></use>
                </svg>
            </a>
        </div>

        <div
            class="bg-white p-5 flex-1 uppercase mr-8 rounded-lg group hover:shadow-md transition-shadow duration-150 max-m:w-72 max-m:mx-0 max-m:mb-4"
            @click="show = 'logist'"
            type="div"
        >
            <a class="flex items-center">
                <svg class="w-4 h-4 mr-2 group-hover:fill-green-400 fill-green-500">
                    <use xlink:href="@/assets/i.svg#status-3"></use>
                </svg>
                <h2 class="group-hover:text-green-400 text-green-500 transition-colors duration-150 text-xl font-latobold uppercase">Логист</h2>
                <svg
                    class="absolute top-1/2 -translate-y-1/2 right-4 w-3 h-3 fill-green-500 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
                >
                    <use xlink:href="@/assets/i.svg#ico-next-one"></use>
                </svg>
            </a>
        </div>

        <div
            class="bg-white p-5 flex-1 uppercase rounded-lg group hover:shadow-md transition-shadow duration-150 max-m:w-72 max-m:mx-0 max-m:mb-8"
            @click="show = 'task'"
            type="div"
        >
            <a class="flex items-center">
                <svg class="w-4 h-4 mr-2 group-hover:fill-green-400 fill-green-500">
                    <use xlink:href="@/assets/i.svg#ico-timer"></use>
                </svg>
                <h2 class="group-hover:text-green-400 text-green-500 transition-colors duration-150 text-xl font-latobold uppercase">Задачи</h2>
                <svg
                    class="absolute top-1/2 -translate-y-1/2 right-4 w-3 h-3 fill-green-500 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
                >
                    <use xlink:href="@/assets/i.svg#ico-next-one"></use>
                </svg>
            </a>
        </div>
    </div>

    <CheckBonus />
    <!-- <CheckLogist /> -->
    <!-- <CheckTask /> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CheckBonus from "../components/check/CheckBonus";
// import CheckLogist from "../components/check/CheckLogist";
// import CheckTask from "../components/check/CheckTask";

export default {
    name: "PageCheck",
    components: {
        CheckBonus,
        // CheckLogist,
        // CheckTask,
    },

    data() {
        return {
            show: "",
        };
    },
    computed: {
        ...mapGetters(["s", "profile", "role", "roles"]),
    },
    methods: {
        ...mapActions(["reKK"]),
    },

    mounted() {},
};
</script>
