<template>
  <div
    v-if="s.popup == 'view' && car && car.id"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-max overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <CarView @close="close" />
  </div>
  <div
    v-if="s.popup == 'edit' && car && car.id"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-max overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <CarEdit @close="close" />
  </div>
  <div
    v-if="(s.popup == 'view' || s.popup == 'edit') && car && car.id"
    @click="close"
    id="overlay"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
  ></div>

  <template v-if="ClientCars">
    <div class="bg-white rounded-lg">
      <div class="p-5 flex justify-between items-center v-bbgray">
        <h3 class="text-base font-latobold">
          Транспорт <span id="countcar">{{ ClientCars.length }}</span> из
          {{ client.ts }}
        </h3>
        <a
          class="v-btn bg-green-500 hover:bg-green-400 ml-auto mt-2"
          @click="Add"
        >
          <svg class="fill-white w-16px h-16px mr-3 rotate-45">
            <use xlink:href="@/assets/i.svg#ico-no"></use>
          </svg>
          <span class="v-btn_text">Создать ТС</span>
        </a>
      </div>
      <div class="grid grid-cols-8 px-5 py-2 v-bbgray">
        <p class="text-gray-400 uppercase text-[11px] col-span-2">МАРКА</p>
        <p class="text-gray-400 uppercase text-[11px] col-span-2">КОМПАНИЯ</p>
        <p class="text-gray-400 uppercase text-[11px] text-center">ТАХОГРАФ</p>
        <p class="text-gray-400 uppercase text-[11px] text-center">МОНИТОР.</p>
        <p class="text-gray-400 uppercase text-[11px] text-center">ПРОПУСК</p>
      </div>

      <div
        v-for="item in ClientCars"
        :key="item"
        class="px-5 py-2 grid grid-cols-8 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative group"
      >
        <div
          class="flex items-center col-span-2"
          @click="showCar(item), (this.s.popup = 'view')"
        >
          <span
            class="rounded-full h-11 min-w-[2.75rem] w-11 flex items-center justify-center mr-4 text-lg text-white uppercase"
            :class="['bg-green-500', 'bg-red-500'][+!item.year]"
          >
            {{ item.mark?.slice(0, 1) }}
          </span>
          <div class="flex flex-col">
            <span
              class="text-gray-400 uppercase font-latobold mb-1 text-[11px]"
              >{{ item.mark }}</span
            >
            <div class="v-gray font-latoblod-serif">
              <span
                class="text-slate-600 w-auto rounded-sm v-tsnum px-2 py-1 text-base"
                >{{ item.num }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-span-2">{{ item.company }}</div>
        <div class="text-center">{{ item.sell1 }}</div>
        <div class="text-center">{{ item.sell2 }}</div>
        <div class="text-center">{{ item.sell3 }}</div>
        <template
          v-if="
            profile.uid == client.manager_id ||
            ['Assistant', 'Studio', 'Hosd', 'Manager'].includes(role)
          "
        >
          <div
            class="flex absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150"
          >
            <a
              class="cursor-pointer"
              @click="editCar(item), (this.s.popup = 'edit')"
            >
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-green-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-edit"></use>
              </svg>
            </a>
            <a class="cursor-pointer ml-4" @click="delCar(item)">
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </a>
          </div>
        </template>
      </div>
      <div
        v-if="ClientCarsRemoved?.length"
        class="text-base font-latobold text-center"
      >
        Удалены
      </div>
      <div
        v-for="item in ClientCarsRemoved"
        :key="item"
        class="px-5 py-2 grid grid-cols-8 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative group"
      >
        <div
          class="flex items-center col-span-2"
          @click="showCar(item), (this.s.popup = 'view')"
        >
          <span
            class="rounded-full h-11 min-w-[2.75rem] w-11 bg-gray-400 flex items-center justify-center mr-4 text-lg text-white uppercase"
          >
            {{ item.mark?.slice(0, 1) }}
          </span>
          <div class="flex flex-col">
            <span
              class="text-gray-400 uppercase font-latobold mb-1 text-[11px]"
              >{{ item.mark }}</span
            >
            <div class="v-gray font-latoblod-serif">
              <span
                class="text-slate-600 w-auto rounded-sm v-tsnum px-2 py-1 text-base"
                >{{ item.num }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-span-2">{{ item.company }}</div>
        <div class="text-center">{{ item.sell1 }}</div>
        <div class="text-center">{{ item.sell2 }}</div>
        <div class="text-center">{{ item.sell3 }}</div>
        <template
          v-if="
            profile.uid == client.manager_id ||
            ['Assistant', 'Studio', 'Hosd', 'Manager'].includes(role)
          "
        >
          <div
            class="flex absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150"
          >
            <a
              class="cursor-pointer"
              @click="editCar(item), (this.s.popup = 'edit')"
            >
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-green-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-edit"></use>
              </svg>
            </a>
            <a class="cursor-pointer ml-4" @click="delCar(item)">
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </a>
          </div>
        </template>
      </div>
      <!--<pre v-html="JSON.stringify(ClientCarsRemoved, null, '\t')"/>-->
    </div>
  </template>

  <div v-else class="flex justify-center p-5">
    <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
  </div>
</template>

<script>
// import API from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import CarView from "../components/client/CarView";
import CarEdit from "../components/client/CarEdit";

export default {
  name: "ClientCar",

  components: { CarView, CarEdit },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "ClientCars",
      "ClientCarsRemoved",
      "car",
    ]),
  },

  methods: {
    ...mapActions([
      "Tab",
      "addCar",
      "showCar",
      "editCar",
      "delCar",
      "setCar",
      "setSettings",
      "getClientTabsCars",
    ]),

    Add() {
      this.addCar();
      this.s.popup = "edit";
      this.setSettings(this.s);
    },

    close() {
      this.s.popup = false;
      this.setCar(null);
      localStorage.removeItem("car");
      this.setSettings(this.s);
    },
  },

  async mounted() {
    //alert("Грузим");
    window.DEBUG_CLIENT_CAR = this;
    // await API.GET('client/companies-by-client-id', {id: this.client.id}).then((res)=>{
    //     this.$store.state.companies = res.data.companies;
    // });
    await this.getClientTabsCars();
  },
};
</script>
