<template>
    <div class="relative flex items-center overflow-hidden" :class="sizes">
        <svg v-if="obj" class="z-2 relative w-1/2 h-1/2 fill-white mx-auto">
            <template v-if="obj == 'task'">
                <use v-if="status == '1'" xlink:href="@/assets/i.svg#ico-edit"></use>
                <use v-if="status == '2'" xlink:href="@/assets/i.svg#status-1"></use>
            </template>
            <template v-else>
                <use v-if="status == '1'" xlink:href="@/assets/i.svg#status-1"></use>
                <use v-if="status == '2'" xlink:href="@/assets/i.svg#status-2"></use>
            </template>
            <use v-if="status == '3'" xlink:href="@/assets/i.svg#status-3"></use>
            <use v-if="status == '5'" xlink:href="@/assets/i.svg#status-5"></use>
            <use v-if="status == '6'" xlink:href="@/assets/i.svg#status-6"></use>
            <use v-if="status == '8'" xlink:href="@/assets/i.svg#status-8"></use>
        </svg>
        <svg class="absolute top-0 left-0 w-full h-full" :class="bg">
            <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
    </div>
</template>

<script>
export default {
    name: "IcoRomb",
    props: {
        obj: String,
        status: String,
        sizes: String,
    },

    computed: {
        bg() {
            let bg = "fill-status-1";

            if (this.status == 1) {
                bg = "fill-status-1";
            }
            if (this.status == 2) {
                bg = "fill-status-2";
            }
            if (this.status == 5) {
                bg = "fill-status-5";
            }
            if (this.status == 6) {
                bg = "fill-status-6";
            }
            if (this.status == 7) {
                bg = "fill-status-7";
            }
            if (this.status == 10) {
                bg = "fill-status-10";
            }
            if (this.status == 20) {
                bg = "fill-status-20";
            }

            if (this.obj == "task") {
                if (this.status == 1) {
                    bg = "fill-status-20";
                }
                if (this.status == 2) {
                    bg = "fill-status-1";
                }
            }

            return bg;
        },
    },
};
</script>
