<template>
    <div class="flex w-full pt-6 max-l:flex-col">
        <div class="w-full v-bgray rounded-lg mb-8 bg-white !h-full max-l:relative max-l:w-full" :class="{ 'w-1/2 sticky top-4': s.popup }">
            <div class="flex justify-around items-center v-bbgray">
                <div class="p-4 text-gray-400 uppercase text-sm leading-none cursor-pointer rounded font-latobold anim-calendar relative hover:text-green-500 transition-colors duration-150" @click="pickMonth(prevDate)">
                    {{ Month(prevDate) }} <sup>'{{ YY(prevDate) }}</sup>
                </div>
                <div class="p-4 text-green-500 uppercase text-sm leading-none cursor-pointer rounded font-latobold relative anim-calendar-state">
                    {{ Month(s.month) }} <sup>'{{ YY(s.month) }}</sup>
                </div>
                <div class="p-4 text-gray-400 uppercase text-sm leading-none cursor-pointer rounded font-latobold anim-calendar relative hover:text-green-500 transition-colors duration-150" @click="pickMonth(nextDate)">
                    {{ Month(nextDate) }} <sup>'{{ YY(nextDate) }}</sup>
                </div>
            </div>

            <div class="calendar monthcal cal2">
                <div class="flex items-center justify-between">
                    <div class="text-gray-400 mr-1 text-[11px] v-bbgray text-right flex-1 pr-4 py-1">ПН</div>
                    <div class="text-gray-400 mr-1 text-[11px] v-bbgray text-right flex-1 pr-4 py-1">ВТ</div>
                    <div class="text-gray-400 mr-1 text-[11px] v-bbgray text-right flex-1 pr-4 py-1">СР</div>
                    <div class="text-gray-400 mr-1 text-[11px] v-bbgray text-right flex-1 pr-4 py-1">ЧТ</div>
                    <div class="text-gray-400 mr-1 text-[11px] v-bbgray text-right flex-1 pr-4 py-1">ПТ</div>
                    <div class="text-rose-500 mr-1 text-[11px] v-bbgray text-right flex-1 pr-4 py-1">СБ</div>
                    <div class="text-rose-500 text-[11px] v-bbgray text-right flex-1 pr-4 py-1">ВС</div>
                </div>
                <div id="calendar-view" class="grid grid-cols-7 grid-rows-5 pb-1">
                    <div v-for="cal in Calendar" :key="cal" class="h-28 v-border-white relative calendar-weekends-class" @click="popCalView(cal.date)">
                        <div class="px-2 py-1 w-full text-black text-right bg-sky-50 calendar-weekends" :class="{ '!bg-green-100 !text-green-500': cal.date == today }">
                            <span v-if="cal.date" class="text-sm mt-2">{{ cal.date.slice(8, 10) }}</span>
                        </div>

                        <template v-if="cal.date">
                            <template v-if="[1, 2].includes(parseInt(s.calendar))">
                                <span v-if="cal.count?.length > 0" class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-4xl text-black">{{ cal.count.length }}</span>
                                <span v-else class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-4xl text-sky-100">0</span>

                                <template v-if="cal.sum">
                                    <span v-if="cal.sum > 0" class="absolute left-1/2 -translate-x-1/2 bottom-4 text-sm text-black">{{ cal.sum }} ₽</span>
                                    <span v-else class="absolute left-1/2 -translate-x-1/2 bottom-4 text-sm text-sky-100">0 ₽</span>
                                </template>
                            </template>

                            <template v-if="s.calendar == 4">
                                <span v-if="cal.count > 0" class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-4xl text-black">{{ cal.count }}</span>
                                <span v-else class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-4xl text-sky-100">0</span>

                                <template v-if="cal.sum">
                                    <span v-if="cal.sum > 0" class="absolute w-full text-center bottom-4 text-xs text-black">{{ cal.sum }} ₽</span>
                                    <span v-else class="absolute w-full text-center bottom-4 text-xs text-sky-100">0 ₽</span>
                                </template>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div id="dates" class="bg-white ml-4 rounded-lg h-max max-l:w-40 max-l:ml-0" :class="{ hidden: s.popup }">
            <div v-for="d in CalDates" :key="d" class="cursor-pointer hover:shadow-md transition-all duration-300 hover:bg-green-100 hover:text-green-600 w-40">
                <div class="flex v-bbgray p-2 items-center w-full" @click="pickMonth(d.date)">
                    <div class="">
                        {{ Month(d.date).slice(0, 3) }}&nbsp;<sup>'{{ YY(d.date) }}</sup>
                    </div>
                    <div v-if="d.count && [1, 2].includes(parseInt(s.calendar))" class="ml-auto font-latobold text-sm">{{ d.count }}</div>
                    <div v-if="d.sum" class="ml-auto font-latobold text-sm">{{ d.sum }}</div>
                </div>
            </div>
        </div>
        <template v-if="s.popup">
            <CalendarDay @closePopup="closePopup" />
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import CalendarDay from "../components/CalendarDay.vue";

export default {
    name: "CalendarView",

    components: { CalendarDay },

    data() {
        return {
            moment: moment,
            year: "",
            mnths: ["", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
            nbrday: ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        };
    },
    computed: {
        ...mapGetters(["s", "roles", "profile", "role", "Calendar", "CalDates", "selectWorkers"]),
        today() {
            return moment().format("YYYY-MM-DD");
        },
        prevDate() {
            return moment(this.s.month).subtract(1, "M").startOf("month").format("YYYY-MM-DD");
        },
        nextDate() {
            return moment(this.s.month).add(1, "M").startOf("month").format("YYYY-MM-DD");
        },
    },
    methods: {
        ...mapActions(["getCalDay", "pickMonth"]),

        popCalView(day) {
            this.getCalDay(day);
            this.s.popup = true;
        },

        Month(m) {
            return this.mnths[moment(m).format("M")];
        },

        YY(y) {
            return moment(y).format("YY");
        },

        Year(date) {
            console.log("date: ", date);
            this.year = moment(date).format("YYYY");
        },

        closePopup() {
            this.s.popup = false;
        },
    },

    mounted() {},
};
</script>