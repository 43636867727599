<template>
  <div class="bg-white rounded-lg">
    <div class="p-5 flex justify-between items-center v-bbgray">
      <h3 class="text-base font-latobold">
        Касания {{ ClientTouches?.length }}
      </h3>
      <!-- || (role == 'Manager' && ClientAccessIds.includes(profile.uid))"> -->
      <template v-if="['Manager', 'Studio', 'Hosd', 'Check'].includes(role)">
        <a
          class="v-btn bg-green-500 hover:bg-green-400 ml-auto mt-2"
          @click="NewTouch()"
        >
          <svg class="fill-white w-16px h-16px mr-3 rotate-45">
            <use xlink:href="@/assets/i.svg#ico-no"></use>
          </svg>
          <span class="v-btn_text">Новое касание</span>
        </a>
      </template>
    </div>

    <div class="grid grid-cols-14 h-10 v-bbgray px-4 uppercase items-center">
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
      >
        №
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
      >
        СОЗДАНА
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-4"
      >
        Клиент
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        НАПРАВЛЕНИЕ
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        ДЕТАЛИ
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        МЕНЕДЖЕР
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        СОЗДАТЕЛЬ
      </div>
    </div>

    <div
      v-for="item in ClientTouches"
      :key="item"
      @click="showTouch(item)"
      class="px-5 py-2 grid grid-cols-14 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative group"
    >
      <div class="flex w-fit text-left">
        <!-- <template v-if="item.violate || item.status==8">
                <svg class="w-5 h-5 mr-2"><use xlink:href="@/assets/i.svg#status-11"></use></svg>
            </template>
            <template v-else>
                <div class="w-5 h-5 mr-2"><LineIco :status="parseInt(item.status)" /></div>
            </template> -->
        #{{ item.id }}
      </div>

      <div class="items-center">
        <div class="flex items-center mr-2">
          <svg class="fill-gray-400 w-2 h-2 mr-1 rotate-45">
            <use xlink:href="@/assets/i.svg#ico-no"></use>
          </svg>
          <div class="text-gray-400 text-xs">
            {{ moment(item.created_at).format("DD.MM.YYYY") }}
          </div>
        </div>
      </div>

      <div class="text-black flex w-full h-full items-center col-span-4">
        <span
          class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
          :class="{
            'bg-status-1': item.status == 1,
            'bg-status-6': item.status == 2,
            'bg-status-9': item.status == 3,
            'bg-status-grey': item.del == 1,
          }"
        >
          {{ item.cname?.slice(0, 1) }}
        </span>
        <div class="flex flex-col justify-center overflow-hidden pr-4">
          <span class="text-base font-semibold break-all max-h-10 leading-5">{{
            item.cname
          }}</span>
        </div>
      </div>

      <div
        class="flex flex-col text-black text-xs tex-left max-h-16 col-span-2"
      >
        <span>{{ direction[item.direction] }}</span>
        <span>{{ type[item.type] }}</span>
      </div>

      <div
        class="flex flex-col text-black text-xs tex-left max-h-16 col-span-2"
      >
        <span>{{ channel[item.channel] }}</span>
        <span>{{ detail[item.detail] }}</span>
      </div>

      <template v-if="!['Manager', 'Logist'].includes(role)">
        <div
          v-if="item.mname"
          class="font-latoblod-serif font-[16px] text-v-gray relative flex justify-start items-center col-span-2 max-m:col-span-2"
        >
          <div
            class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
            :class="'bg-status-' + item.status"
          >
            <IcoPic :pic="item.mpicture" :name="item.mname" />
          </div>
          <div class="flex flex-col justify-center overflow-hidden">
            <span class="text-[10px] text-gray-400 leading-none uppercase"
              >Менеджер</span
            >
            <span class="text-black overflow-hidden">{{ item.mname }}</span>
          </div>
        </div>
        <div
          v-else
          class="flex justify-start items-center col-span-2 max-m:col-span-2"
        >
          <span
            class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2"
          >
            <svg class="w-4 h-4 fill-white">
              <use xlink:href="@/assets/i.svg#ico-avatar"></use>
            </svg>
          </span>
        </div>
        <div
          v-if="item.wname"
          class="font-latoblod-serif font-[16px] text-v-gray relative flex justify-start items-center col-span-2 max-m:col-span-2"
        >
          <div
            class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
            :class="'bg-status-' + item.status"
          >
            <IcoPic :pic="item.wpicture" :name="item.wname" />
          </div>
          <div class="flex flex-col justify-center overflow-hidden">
            <span class="text-[10px] text-gray-400 leading-none uppercase"
              >Создатель</span
            >
            <span class="text-black overflow-hidden">{{ item.wname }}</span>
          </div>
        </div>
        <div
          v-else
          class="flex justify-start items-center col-span-2 max-m:col-span-2"
        >
          <span
            class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2"
          >
            <svg class="w-4 h-4 fill-white">
              <use xlink:href="@/assets/i.svg#ico-avatar"></use>
            </svg>
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import API from "../services/api";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
// import InvoiceAdd from "../components/client/InvoiceAdd";
//import InvoiceEdit from "../components/client/InvoiceAdd";
//import InvoiceView from "../components/client/InvoiceAdd";

export default {
  name: "ClientTouches",
  // components: {
  //     InvoiceAdd
  // },
  data() {
    return {
      direction: { 1: "ВХОДЯЩИЙ", 2: "ИСХОДЯЩИЙ" },
      type: { 1: "ПЕРВИЧНЫЙ", 2: "ВТОРИЧНЫЙ" },
      detail: {
        101: 'Задача "Как дела"',
        102: 'Задача "Пролонгация"',
        103: "Контроль качества",
        104: "Агент-сотрудник",
      },
      channel: {
        1: "Интернет",
        2: "Рекомендация",
        3: "Агент",
        4: "Обзвон новой базы",
        15: "Обзвон новой базы",
        21: "Вторичная клиентская база",
        22: "Рассылка",
        23: "Агент-сотрудник",
        31: "Вторичная клиентская база",
      },
      moment: moment,
      modalMode: "",
      inputModal: {},
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "ClientTouches",
      "ClientOrders",
      "ClientContacts",
      "ClientCompanies",
    ]),
  },

  methods: {
    ...mapActions(["Tab", "getClientTabsTouches", "addTouch", "showTouch"]),
    NewTouch() {
      if (this.client.manager_id == null) {
        alert("Нельзя создать касание, если у клиента нет менеджера!");
      } else {
        let formData = new FormData();
        formData.append("client_id", this.client.id);
        this.addTouch(formData);
      }
    },
    // async Add() {

    //     let formData = new FormData();
    //     formData.append("1", this.inputModal.num);
    //     formData.append("2", this.client.id);
    //     formData.append("3", this.inputModal.companny);
    //     formData.append("4", this.inputModal.payment_type);
    //     formData.append("5", this.inputModal.date);
    //     formData.append("6", this.inputModal.amount);
    //     formData.append("7", '');
    //     this.inputModal.orders.forEach((val, pos)=>formData.append(`42[${pos}]`, val));
    //     console.log(await API.POST("invoice/add-invoice", formData));
    // },
  },
  async mounted() {
    await this.getClientTabsTouches();
  },
};
</script>
