<template>
    <div class="px-4 flex flex-wrap my-8">
        <div class="w-full flex items-center max-xl:flex-wrap max-xl:gap-y-2" :class="{ active: get?.clear == 1 }">
            <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center">
                <span class="w-full text-lg leading-none text-teal-700">
                    {{ total }}
                </span>
            </h2>

            <div class="h-9 w-1/4 mr-15px min-w-[180px] relative group">
                <input placeholder="С" class="pl-8 w-full px-3 py-8px text-sm bg-slate-50 h-full" name="srt" :value="get?.srt" />
                <svg class="fill-gray-400 absolute top-1/2 -translate-y-1/2 left-2 h-4 w-4 group-focus-within:fill-green-500">
                    <use xlink:href="@/assets/i.svg#nav-date"></use>
                </svg>
            </div>

            <div class="h-9 w-1/4 mr-15px min-w-[180px] relative group">
                <input placeholder="По" class="pl-8 w-full px-3 py-8px text-sm bg-slate-50 h-full" name="end" :value="get?.end" />
                <svg class="fill-gray-400 absolute top-1/2 -translate-y-1/2 left-2 h-4 w-4 group-focus-within:fill-green-500">
                    <use xlink:href="@/assets/i.svg#nav-date"></use>
                </svg>
            </div>

            <button class="v-btn bg-gray-500 hover:bg-gray-400 ml-0">
                <span class="v-btn_text">Сброс</span>
            </button>
        </div>
    </div>

    <div class="cox100">
        <div class="block list pb15">
            <div id="list" page="1">
                <!-- {{ this.render('/ajax/list/logist.twig', {'list' : list})|raw }} -->
                <div v-for="item in list" :key="item" class="cont flx flx-sa tac pt15 pb15">
                    <a v-if="item.profile" class="col-4 w100 preview" :href="'/worker/view/' + item.profile.uid">
                        <span v-if="item.profile.picture" class="avatar"
                            ><img :src="'https://api.tahocrm.ru/uploads/users/' + item.profile.picture" alt=""
                        /></span>
                        <span v-else class="avatar">{{ item.profile.secondname.slice(0, 1) }}</span>
                        {{ item.profile.secondname }} {{ item.profile.name }}
                    </a>

                    <div class="col w40 preview db">
                        {{ formatdate("d.m.Y", item.date) }}
                        <sup>Дата</sup>
                    </div>

                    <div class="col w40 preview db">
                        <a :href="'/order/view/' + item.order.iid" class="cbld">{{ item.order.iid }}</a>
                        <sup>Заказ</sup>
                    </div>

                    <div class="col w50 preview db">
                        <template v-if="item.type == 92">
                            <template v-if="item.count > 1440">
                                <span class="cred"
                                    >{{ (item.count / 1440).toFixed(0) }} д. {{ ((item.count % 1440) / 60).toFixed(0) }} ч.
                                    {{ item.count % 60 }} м.</span
                                >
                                <br />
                            </template>
                            <template v-else>
                                <span class="cred">{{ ((item.count % 1440) / 60).toFixed(0) }} ч. {{ item.count % 60 }} м.</span>
                                <br />
                            </template>

                            <sup>Нарушено назначение</sup>
                        </template>
                        <template v-if="item.type == 95">
                            <template v-if="item.count > 1440">
                                <span class="cred"
                                    >{{ (item.count / 1440).toFixed(0) }} д. {{ ((item.count % 1440) / 60).toFixed(0) }} ч.
                                    {{ item.count % 60 }} м.</span
                                ><br />
                            </template>
                            <template v-else>
                                <span class="cred">{{ ((item.count % 1440) / 60).toFixed(0) }} ч. {{ item.count % 60 }} м.</span><br />
                            </template>
                            <sup>Нарушено завершение</sup>
                        </template>
                    </div>
                    <div class="col w100 preview db">
                        <span>{{ vio.info }}</span>
                        <sup>Объяснение</sup>
                    </div>
                </div>
            </div>
            <div id="showmore" max="{{ total }}"></div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "CheckLogist",

    data() {
        return {};
    },
    computed: {
        ...mapState(["userProfileState", "profile"]),
    },
    methods: {
        ...mapActions(["setUserProfileState", "getProfile", "setProfile", "changeSave", "changeCommonInfo", "changePass", "requestChangePass"]),
    },

    mounted() {
        if (!this.profile) {
            this.getProfile();
        }
        console.log(this.profile);
    },
};
</script>
