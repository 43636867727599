<template>
    <a
        class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
        @click="$emit('close')"
    >
        <span class="text-white mr-2">Сохранить и закрыть</span>
        <svg class="fill-white w-4 h-4">
            <use xlink:href="@/assets/i.svg#ico-no"></use>
        </svg>
    </a>
    <div v-if="qcheck" class="h-screen overflow-y-auto pb-10">
        <h2 class="text-center mt-5 font-latobold text-lg">Контроль качества</h2>
        <h3 class="text-center mt-5 font-latobold text-lg">
            ЗАКАЗ № {{ order.iid }} от {{ order.create_at }} <br />
            КЛИЕНТ {{ order.client.name }} <br />{{ order.client?.phone }}
        </h3>

        <div class="w-full p-10 v-bbgray">
            <label class="w-full">1. Устраивает ли Вас качество предоставления услуг ООО «ТАХОБАН»?</label>
            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f5 = 'Нет'">Нет</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f5 = 'Да'">Да</div>
                <input class="w-full bg-white px-2 v-bgray" type="text" v-model="qcheck.f5" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f5 }}</h3>
            </div>

            <label class="w-full"> 2. При обращении в ООО «ТАХОБАН», была ли Вам дана исчерпывающая информация по интересующему Вас вопросу? </label>
            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f6 = 'Нет'">Нет</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f6 = 'Да'">Да</div>
                <input class="w-full bg-white px-2 v-bgray" type="text" v-model="qcheck.f6" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f6 }}</h3>
            </div>

            <label class="w-full">3. При обращении в ООО «ТАХОБАН», возникали замечания к сотрудникам ООО «ТАХОБАН»?</label>
            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f7 = 'Нет'">Нет</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f7 = 'Да'">Да</div>
                <input class="w-full bg-white px-2 v-bgray" type="text" v-model="qcheck.f7" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f7 }}</h3>
            </div>

            <label class="w-full">
                4. Оцените, пожалуйста, по 10-ти бальной шкале уровень обслуживания и компетентность сотрудников ООО «ТАХОБАН», где 1 – самый низкий,
                а 10- самый высокий уровень оценки.</label
            >
            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f1 = '1'">1</div>
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f1 = '2'">2</div>
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f1 = '3'">3</div>
                <div class="v-btn bg-orange-500 v-btn_text mr-2" @click="qcheck.f1 = '4'">4</div>
                <div class="v-btn bg-amber-500 v-btn_text mr-2" @click="qcheck.f1 = '5'">5</div>
                <div class="v-btn bg-yellow-500 v-btn_text mr-2" @click="qcheck.f1 = '6'">6</div>
                <div class="v-btn bg-lime-500 v-btn_text mr-2" @click="qcheck.f1 = '7'">7</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f1 = '8'">8</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f1 = '9'">9</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f1 = '10'">10</div>
                <input class="w-full bg-white px-2 v-bgray" type="text" v-model="qcheck.f1" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f1 }}</h3>
            </div>

            <label class="w-full">
                5. Оцените, пожалуйста, по 10-ти бальной шкале скорость реакции на запросы по телефону/ по электронной почте, где 1 – самый низкий, а
                10- самый высокий уровень оценки.</label
            >
            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f2 = '1'">1</div>
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f2 = '2'">2</div>
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f2 = '3'">3</div>
                <div class="v-btn bg-orange-500 v-btn_text mr-2" @click="qcheck.f2 = '4'">4</div>
                <div class="v-btn bg-amber-500 v-btn_text mr-2" @click="qcheck.f2 = '5'">5</div>
                <div class="v-btn bg-yellow-500 v-btn_text mr-2" @click="qcheck.f2 = '6'">6</div>
                <div class="v-btn bg-lime-500 v-btn_text mr-2" @click="qcheck.f2 = '7'">7</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f2 = '8'">8</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f2 = '9'">9</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f2 = '10'">10</div>
                <input class="w-full bg-white px-2 v-bgray" type="text" v-model="qcheck.f2" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f2 }}</h3>
            </div>

            <label class="w-full">
                6. Оцените по 10-ти бальной шкале насколько услуги компании, по Вашему мнению, соответствуют заявленному уровню качества.</label
            >
            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f3 = '1'">1</div>
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f3 = '2'">2</div>
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f3 = '3'">3</div>
                <div class="v-btn bg-orange-500 v-btn_text mr-2" @click="qcheck.f3 = '4'">4</div>
                <div class="v-btn bg-amber-500 v-btn_text mr-2" @click="qcheck.f3 = '5'">5</div>
                <div class="v-btn bg-yellow-500 v-btn_text mr-2" @click="qcheck.f3 = '6'">6</div>
                <div class="v-btn bg-lime-500 v-btn_text mr-2" @click="qcheck.f3 = '7'">7</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f3 = '8'">8</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f3 = '9'">9</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f3 = '10'">10</div>
                <input class="w-full bg-white px-2 v-bgray" type="text" v-model="qcheck.f3" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f3 }}</h3>
            </div>

            <label class="w-full">
                7. Оцените по 10-ти бальной шкале оперативность реагирования на претензии или рекламации (если они возникали), где 1 – самый низкий, а
                10- самый высокий уровень оценки.</label
            >
            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f4 = '1'">1</div>
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f4 = '2'">2</div>
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f4 = '3'">3</div>
                <div class="v-btn bg-orange-500 v-btn_text mr-2" @click="qcheck.f4 = '4'">4</div>
                <div class="v-btn bg-amber-500 v-btn_text mr-2" @click="qcheck.f4 = '5'">5</div>
                <div class="v-btn bg-yellow-500 v-btn_text mr-2" @click="qcheck.f4 = '6'">6</div>
                <div class="v-btn bg-lime-500 v-btn_text mr-2" @click="qcheck.f4 = '7'">7</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f4 = '8'">8</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f4 = '9'">9</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f4 = '10'">10</div>
                <input class="w-full bg-white px-2 v-bgray" type="text" v-model="qcheck.f4" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f4 }}</h3>
            </div>

            <label class="w-full"> 8. Достаточно ли времени для решения Ваших вопросов уделяют Вам сотрудники ООО «ТАХОБАН»?</label>
            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f8 = 'Нет'">Нет</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f8 = 'Да'">Да</div>
                <input class="w-full bg-white px-2 v-bgray" type="text" v-model="qcheck.f8" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f8 }}</h3>
            </div>

            <label class="w-full"> 9. Рекомендовали бы Вы ООО «ТАХОБАН» своим партнерам, заказчикам или друзьям? Да, уже. Возможно. Нет</label>
            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <div class="v-btn bg-red-500 v-btn_text mr-2" @click="qcheck.f9 = 'Нет'">Нет</div>
                <div class="v-btn bg-amber-500 v-btn_text mr-2" @click="qcheck.f9 = 'Возможно'">Возможно</div>
                <div class="v-btn bg-green-500 v-btn_text mr-2" @click="qcheck.f9 = 'Да'">Да</div>
                <input class="w-full bg-white px-2 v-bgray" type="text" v-model="qcheck.f9" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f9 }}</h3>
            </div>

            <label class="w-full"> 10. Что именно мы могли бы улучшить/изменить, чтобы повысить качество обслуживания? </label>

            <div v-if="qcheck.status == 1" class="flex mb-10 mt-2">
                <input class="w-full bg-white px-2 h-9 v-bgray" type="text" v-model="qcheck.f10" />
            </div>
            <div v-else class="flex mb-10 mt-2">
                <h3 class="text-lg">{{ qcheck.f10 }}</h3>
            </div>

            <input
                v-if="qcheck.status == 1"
                class="w-full bg-white px-2 h-9 v-bgray"
                type="text"
                placeholder="Комментарий менеджера"
                v-model="qcheck.comment"
            />
            <h3 v-else>{{ qcheck.comment }}</h3>

            <div
                v-if="qcheck.status == 1"
                class="v-btn my-10 m-auto bg-emerald-600 hover:bg-emerald-500"
                @click="saveQcheck(qcheck), (s.popup = false)"
            >
                <span class="v-btn_text flex items-center">
                    <svg class="fill-white w-4 h-4 mr-3"><use xlink:href="@/assets/i.svg#status-8"></use></svg>
                    <span>ЗАВЕРШИТЬ</span>
                </span>
            </div>
        </div>
    </div>

    <div v-else class="loader opacity1 mt30">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "OrderQcheck",

    emits: ["close"],

    computed: {
        ...mapGetters(["s", "profile", "role", "order", "qcheck"]),
    },

    methods: {
        ...mapActions(["saveQcheck"]),
    },

    mounted() {},
};
</script>
