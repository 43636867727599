<template>
    <div class="bg-white rounded-md">
        <div class="p-4 relative v-bbgray">
            <!-- <h2 class="text-center font-bold text-lg uppercase">
                <span>{{ Header }}</span>
            </h2> -->
            <button
                class="absolute v-btn bg-green-500 hover:bg-green-400 v-btn_text top-1/2 -translate-y-1/2 right-4"
                @click="this.changeShowPage('quality')"
            >
                Назад
            </button>
        </div>
        <div>
            <div
                class="py-4 flex justify-around w-full max-m:justify-between max-s:flex-wrap v-bbgray odd:bg-slate-50"
                v-for="item in statQualityOrders"
                :key="item.id"
                @click="editOrderId(item.id)"
            >
                <div class="flex flex-col items-center justify-center px-8">
                    <p class="text-lg font-bold text-status-5">{{ item.iid }}</p>
                    <span class="uppercase text-gray-400 text-xs">Заказ</span>
                </div>

                <div class="flex items-center justify-center px-8">
                    <div class="w-11 h-11 rounded-full bg-green-500 mr-2">
                        <IcoPic :pic="null" :name="item.client" />
                    </div>

                    <div class="flex flex-col justify-center overflow-hidden">
                        <span class="text-base font-semibold w-56">{{ item.client }}</span>
                    </div>
                </div>

                <div class="flex flex-col items-center justify-center px-8">
                    <p class="text-lg font-bold">{{ item.date }}</p>
                    <span class="uppercase text-gray-400 text-xs">Дата</span>
                </div>

                <div class="flex flex-col items-center justify-center px-8">
                    <p class="text-lg font-bold">
                        <span class="text-gray-600">{{ item.dates[0].start.slice(0, 5) }}</span>
                        <span> - </span>
                        <span class="text-gray-800">{{ item.dates[0].end.slice(0, 5) }}</span>
                    </p>
                    <span class="uppercase text-gray-400 text-xs">Время</span>
                </div>

                <div class="flex flex-col items-center justify-center px-8">
                    <p class="text-lg font-bold">
                        {{ item.qcheck }}
                    </p>
                    <span class="uppercase text-gray-400 text-xs">Оценка</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "StatisticQualityItem",

    computed: {
        ...mapGetters(["statisticShow", "statQualityOrders"]),

        // Header() {
        //     if (this.statQualityOrders.category == "1" && this.statQualityOrders.score == "normal") return "Тахографы с рейтингом > 7";
        //     if (this.statQualityOrders.category == "2" && this.statQualityOrders.score == "normal") return "Мониторинг с рейтингом > 7";
        //     if (this.statQualityOrders.category == "3" && this.statQualityOrders.score == "normal") return "Отопители с рейтингом > 7";
        //     if (this.statQualityOrders.category == "1" && this.statQualityOrders.score == "bad") return "Тахографы с рейтингом < 7";
        //     if (this.statQualityOrders.category == "2" && this.statQualityOrders.score == "bad") return "Мониторинг с рейтингом < 7";
        //     if (this.statQualityOrders.category == "3" && this.statQualityOrders.score == "bad") return "Отопители с рейтингом < 7";
        //     if (this.statQualityOrders.category == "all" && this.statQualityOrders.score == "normal") return "ИТОГО с рейтингом > 7";
        //     if (this.statQualityOrders.category == "all" && this.statQualityOrders.score == "bad") return "ИТОГО с рейтингом < 7";
        //     return " ";
        // },
    },
    methods: {
        ...mapMutations(["changeShowPage"]),

        ...mapActions(["editOrderId", "getStatQualityOrders"]),
    },
    mounted() {
        this.getStatQualityOrders();
    },
};
</script>

<style lang="scss" scoped></style>
