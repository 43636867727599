import AlertMsg from "@/components/AlertMsg";
import CalendarEdit from "@/components/CalendarEdit";
import ClientClaim from "@/components/ClientClaim";
import ClientActive from "@/components/ClientActive";
import ClientDoc from "@/components/ClientDoc";
import ClientCompany from "@/components/ClientCompany";
import ClientContact from "@/components/ClientContact";
import ClientInfo from "@/components/ClientInfo";
import ClientOrder from "@/components/ClientOrder";
import ClientTask from "@/components/ClientTask";
import ClientCar from "@/components/ClientCar";
import ClientManager from "@/components/ClientManager";
import CheckLogist from "@/components/check/CheckLogist";
import CheckBonus from "@/components/check/CheckBonus";
import CheckTask from "@/components/check/CheckTask";
import SalaryOrder from "@/components/SalaryOrder";
import StatisticMain from "@/components/statistic/StatisticMain.vue";
import StatisticOrders from "@/components/statistic/StatisticOrders.vue";
import StatisticServices from "@/components/statistic/StatisticServices.vue";
import StatisticSalaries from "@/components/statistic/StatisticSalaries.vue";
import StatisticLoading from "@/components/statistic/StatisticLoading.vue";
import StatisticQuality from "@/components/statistic/StatisticQuality.vue";
import StatisticQualityItem from "@/components/statistic/StatisticQualityItem.vue";
import StatisticStandards from "@/components/statistic/StatisticStandards.vue";
import StatisticViolations from "@/components/statistic/StatisticViolations.vue";
import TaskEdit from "@/components/TaskEdit";

import NavBar from "@/components/NavBar";
import IconLoader from "@/components/IconLoader";
import IcoStatus from "@/components/ui/IcoStatus";
import IcoPic from "@/components/ui/IcoPic";
import IcoPicSmall from "@/components/ui/IcoPicSmall";
import IcoImg from "@/components/ui/IcoImg";
import UploadImg from "@/components/ui/UploadImg";

export default [
    AlertMsg,
    CalendarEdit,
    ClientClaim,
    ClientActive,
    ClientDoc,
    ClientCompany,
    ClientContact,
    ClientInfo,
    ClientOrder,
    ClientTask,
    ClientCar,
    ClientManager,
    CheckLogist,
    CheckBonus,
    CheckTask,
    SalaryOrder,
    StatisticMain,
    StatisticOrders,
    StatisticServices,
    StatisticSalaries,
    StatisticLoading,
    StatisticQuality,
    StatisticQualityItem,
    StatisticStandards,
    StatisticViolations,
    TaskEdit,
    NavBar,
    IconLoader,
    IcoStatus,
    IcoPic,
    IcoPicSmall,
    IcoImg,
    UploadImg,
];
