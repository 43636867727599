<template>
  <!-- ВЕРХНЯЯ ПАНЕЛЬ -->
  <div v-if="showFact" class="flex-wrap-report my-5">
    <!-- <input class="date-report" type="date" v-model="dateFrom"/>
                  <input class="date-report" type="date" /> -->
    <p class="todayDate bg-white py-2 px-8 rounded-sm">
      {{ profile.secondname }} {{ profile.name }}
    </p>
    <div class="flex ml-auto">
      <select
        style="min-width: 240px"
        class="h-9 px-3 ml-auto bg-slate-50 mr-4"
        v-model="lead_dir"
      >
        <option value="0" disabled>Новые \ Повторные</option>
        <option value="all">Все</option>
        <option value="new">Новые</option>
        <option value="second">Повторные</option>
      </select>
      <select
        style="min-width: 240px"
        class="h-9 px-3 ml-auto bg-slate-50 mr-4"
        v-model="inc_dir"
      >
        <option value="0" disabled>Входящие \ исходящие</option>

        <option value="all">Все</option>
        <option value="incoming">Входящие</option>
        <option value="outgoing">Исходящие</option>
      </select>

      <select
        style="min-width: 240px"
        class="h-9 px-3 ml-auto bg-slate-50 mr-4"
        v-model="business"
      >
        <option value="0" disabled>Направление</option>
        <option value="all">Все</option>
        <option value="taho">Тахографы</option>
        <option value="monitor">Мониторинг</option>
        <option value="card">Карты</option>
        <option value="propusk">Пропуска</option>
        <option value="teach">Обучение</option>
        <option value="driver">Водители</option>
      </select>

      <button @click="changeDirection()" class="button-form-report">
        Сформировать
      </button>
    </div>
  </div>

  <div
    v-if="showFact"
    class="flex flex-wrap-report justify-between items-center"
  >
    <div
      class="flex bg-white w-fit py-3 px-8 relative rounded-[8px] items-center"
    >
      <p class="todayDateLabel">сегодня</p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '01'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} января
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '02'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} февраля
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '03'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} марта
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '04'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} апреля
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '05'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} мая
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '06'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} июня
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '07'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} июля
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '08'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} августа
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '09'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} сентября
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '10'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} октября
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '11'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} ноября
      </p>
      <p
        v-if="report_plan.date_data.current_date.substr(5, 2) == '12'"
        class="todayDate mr-12 ml-16"
      >
        {{ report_plan.date_data.current_date.substr(8, 2) }} декабря
      </p>
      <p class="workDays">
        <span>{{ report_plan.date_data.passed_working_days }}</span> раб. дн из
        <span>{{ report_plan.date_data.count_working_days }}</span>
      </p>
    </div>
    <!-- 
    <button @click="openThisRPS()" class="oh-frick-go-back">
      Вернуться на отчет продаж
    </button> -->
  </div>

  <div v-if="showFact" class="flex mt-4">
    <div
      class="bg-white flex flex-col w-1/3 pt-8 pb-6 mr-4 mt-4 rounded-[8px] relative justify-center items-center"
    >
      <div class="flex flex-row justify-center items-center">
        <div class="">
          <div class="relative">
            <div class="line100"></div>

            <div
              :class="{
                yellow:
                  Math.round(
                    (report_plan.plan_fact_data.current_margin_fact /
                      report_plan.plan_fact_data.current_margin_plan) *
                      100
                  ) < 100 &&
                  Math.round(
                    (report_plan.plan_fact_data.current_margin_fact /
                      report_plan.plan_fact_data.current_margin_plan) *
                      100
                  ) >= 90,
                red:
                  Math.round(
                    (report_plan.plan_fact_data.current_margin_fact /
                      report_plan.plan_fact_data.current_margin_plan) *
                      100
                  ) < 90,
                green:
                  Math.round(
                    (report_plan.plan_fact_data.current_margin_fact /
                      report_plan.plan_fact_data.current_margin_plan) *
                      100
                  ) >= 100,
              }"
              class=""
              role="progressbar"
              :aria-valuenow="
                Math.round(
                  (report_plan.plan_fact_data.current_margin_fact /
                    report_plan.plan_fact_data.current_margin_plan) *
                    100
                )
              "
              aria-valuemin="0"
              aria-valuemax="100"
              :style="
                '--value:' +
                Math.round(
                  (report_plan.plan_fact_data.current_margin_fact /
                    report_plan.plan_fact_data.current_margin_plan) *
                    100
                )
              "
            ></div>
          </div>
        </div>

        <div class="">
          <p class="numberToday pt-6">
            {{ addSpace(report_plan.plan_fact_data.current_margin_fact) }}
          </p>
          <p class="numberLabelToday pt-2">маржа: факт на сегодня</p>

          <p class="planText pt-6">
            план на сегодня:
            <span>{{
              addSpace(report_plan.plan_fact_data.current_margin_plan)
            }}</span>
          </p>
          <p class="planText pt-2">
            остаток плана на сегодня:
            <span>{{
              addSpace(
                report_plan.plan_fact_data.current_margin_plan -
                  report_plan.plan_fact_data.current_margin_fact
              )
            }}</span>
          </p>
          <p></p>
        </div>
      </div>
      <div class="flex flex-row justify-center items-center my-8">
        <div class="">
          <div class="relative">
            <div class="line100"></div>

            <div
              :class="{
                yellow:
                  Math.round(
                    (report_plan.plan_fact_data.current_income_fact /
                      report_plan.plan_fact_data.current_income_plan) *
                      100
                  ) < 100 &&
                  Math.round(
                    (report_plan.plan_fact_data.current_income_fact /
                      report_plan.plan_fact_data.current_income_plan) *
                      100
                  ) >= 90,
                red:
                  Math.round(
                    (report_plan.plan_fact_data.current_income_fact /
                      report_plan.plan_fact_data.current_income_plan) *
                      100
                  ) < 90,
                green:
                  Math.round(
                    (report_plan.plan_fact_data.current_income_fact /
                      report_plan.plan_fact_data.current_income_plan) *
                      100
                  ) >= 100,
              }"
              class=""
              role="progressbar"
              :aria-valuenow="
                Math.round(
                  (report_plan.plan_fact_data.current_income_fact /
                    report_plan.plan_fact_data.current_income_plan) *
                    100
                )
              "
              aria-valuemin="0"
              aria-valuemax="100"
              :style="
                '--value:' +
                Math.round(
                  (report_plan.plan_fact_data.current_income_fact /
                    report_plan.plan_fact_data.current_income_plan) *
                    100
                )
              "
            ></div>
          </div>
        </div>

        <div class="">
          <p class="numberToday pt-6">
            {{ addSpace(report_plan.plan_fact_data.current_income_fact) }}
          </p>
          <p class="numberLabelToday pt-2">доход: факт на сегодня</p>

          <p class="planText pt-6">
            план на сегодня:
            <span>{{
              addSpace(report_plan.plan_fact_data.current_income_plan)
            }}</span>
          </p>
          <p class="planText pt-2">
            остаток плана на сегодня:
            <span>
              {{
                addSpace(
                  report_plan.plan_fact_data.current_income_plan -
                    report_plan.plan_fact_data.current_income_fact
                )
              }}
            </span>
          </p>
          <p></p>
        </div>
      </div>
      <div class="flex flex-row justify-center items-center">
        <div class="">
          <div class="relative">
            <div class="line100"></div>

            <div
              :class="{
                yellow:
                  (report_plan.plan_fact_data.current_promise_fact /
                    report_plan.plan_fact_data.current_all_margin) *
                    100 <
                    100 &&
                  (report_plan.plan_fact_data.current_promise_fact /
                    report_plan.plan_fact_data.current_all_margin) *
                    100 >=
                    90,
                red:
                  (report_plan.plan_fact_data.current_promise_fact /
                    report_plan.plan_fact_data.current_all_margin) *
                    100 <
                  90,
                green:
                  (report_plan.plan_fact_data.current_promise_fact /
                    report_plan.plan_fact_data.current_all_margin) *
                    100 >=
                  100,
              }"
              class=""
              role="progressbar"
              :aria-valuenow="
                (report_plan.plan_fact_data.current_promise_fact /
                  report_plan.plan_fact_data.current_all_margin) *
                100
              "
              aria-valuemin="0"
              aria-valuemax="100"
              :style="
                '--value:' +
                (report_plan.plan_fact_data.current_promise_fact /
                  report_plan.plan_fact_data.current_all_margin) *
                  100
              "
            ></div>
          </div>
        </div>

        <div class="">
          <p class="numberToday pt-6">
            {{ addSpace(report_plan.plan_fact_data.current_promise_fact) }}
          </p>
          <p class="numberLabelToday pt-2">маржа, ожидаем оплат</p>

          <p class="planText pt-6">
            план на месяц:
            <span>{{
              addSpace(report_plan.plan_fact_data.current_all_margin)
            }}</span>
          </p>
          <p class="planText pt-2">
            остаток плана на месяц:
            <span>
              {{
                addSpace(
                  report_plan.plan_fact_data.current_all_margin -
                    report_plan.plan_fact_data.current_promise_fact
                )
              }}
            </span>
          </p>
          <p></p>
        </div>
      </div>
    </div>

    <div
      class="bg-white flex flex-col w-1/3 pt-8 rounded-[8px] pb-6 mt-4 mr-4 relative text-center"
    >
      <h2 class="title-MPRS mb-4">ВОРОНКА ПО СОЗДАННЫМ ЛИДАМ</h2>

      <!-- КАСАНИЯ -->
      <div class="voronka-row mb-2">
        <div class="voronka-top-row flex">
          <p class="number-vtr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_count_touch }}
          </p>
          <p class="text-vtr">касания</p>
          <div class="progress-wrap-vtr">
            <div
              role="progressopen"
              :style="
                '--valueOD:' + report_plan.plan_fact_data.conv_count_touch
              "
              class="progress-bar-vtr contacts-color-bg min-w-[5px]"
            ></div>
            <p class="ml-4 text-right">
              {{ report_plan.plan_fact_data.conv_count_touch }}%
            </p>
          </div>
        </div>
        <div class="voronka-bottom-row flex">
          <p class="number-vbr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_fail_touch }}
          </p>
          <p class="ext-vbr">отказы с касаний</p>
          <div class="progress-wrap-vbr">
            <div
              role="progressopen"
              :style="'--valueOD:' + report_plan.plan_fact_data.conv_fail_touch"
              class="progress-bar-vbr min-w-[5px]"
              :class="{
                '!bg-green-400':
                  report_plan.plan_fact_data.conv_fail_touch -
                    report_plan.plan_fact_data.plan_fail_touch <=
                  0,
                '!bg-yellow-400':
                  report_plan.plan_fact_data.conv_fail_touch -
                    report_plan.plan_fact_data.plan_fail_touch <=
                    10 &&
                  report_plan.plan_fact_data.conv_fail_touch -
                    report_plan.plan_fact_data.plan_fail_touch >
                    0,
                '!bg-rose-400':
                  report_plan.plan_fact_data.conv_fail_touch -
                    report_plan.plan_fact_data.plan_fail_touch >
                  10,
              }"
            ></div>
            <p
              :class="{
                '!text-green-400':
                  report_plan.plan_fact_data.conv_fail_touch -
                    report_plan.plan_fact_data.plan_fail_touch <=
                  0,
                '!text-yellow-400':
                  report_plan.plan_fact_data.conv_fail_touch -
                    report_plan.plan_fact_data.plan_fail_touch <=
                    10 &&
                  report_plan.plan_fact_data.conv_fail_touch -
                    report_plan.plan_fact_data.plan_fail_touch >
                    0,
                '!text-rose-400':
                  report_plan.plan_fact_data.conv_fail_touch -
                    report_plan.plan_fact_data.plan_fail_touch >
                  10,
              }"
              class="text-right"
            >
              {{ report_plan.plan_fact_data.conv_fail_touch }}%
            </p>
          </div>
        </div>
      </div>
      <!-- КАСАНИЯ -->

      <!-- ЛИДЫ -->
      <div class="voronka-row mb-2">
        <div class="voronka-top-row flex">
          <p class="number-vtr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_count_lead }}
          </p>
          <p class="text-vtr">лиды</p>
          <div class="progress-wrap-vtr">
            <div
              role="progressopen"
              :style="'--valueOD:' + report_plan.plan_fact_data.conv_count_lead"
              class="progress-bar-vtr leads-color-bg min-w-[5px]"
            ></div>
            <p class="ml-4 text-right">
              {{ report_plan.plan_fact_data.conv_count_lead }}%
            </p>
          </div>
        </div>
        <div class="voronka-bottom-row flex">
          <p class="number-vbr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_fail_lead }}
          </p>
          <p class="ext-vbr">отказы с лидов</p>
          <div class="progress-wrap-vbr">
            <div
              role="progressopen"
              :style="'--valueOD:' + report_plan.plan_fact_data.conv_fail_lead"
              class="progress-bar-vbr min-w-[5px]"
              :class="{
                '!bg-green-400':
                  report_plan.plan_fact_data.conv_fail_lead -
                    report_plan.plan_fact_data.plan_fail_lead <=
                  0,
                '!bg-yellow-400':
                  report_plan.plan_fact_data.conv_fail_lead -
                    report_plan.plan_fact_data.plan_fail_lead <=
                    10 &&
                  report_plan.plan_fact_data.conv_fail_lead -
                    report_plan.plan_fact_data.plan_fail_lead >
                    0,
                '!bg-rose-400':
                  report_plan.plan_fact_data.conv_fail_lead -
                    report_plan.plan_fact_data.plan_fail_lead >
                  10,
              }"
            ></div>
            <p
              :class="{
                '!text-green-400':
                  report_plan.plan_fact_data.conv_fail_lead -
                    report_plan.plan_fact_data.plan_fail_lead <=
                  0,
                '!text-yellow-400':
                  report_plan.plan_fact_data.conv_fail_lead -
                    report_plan.plan_fact_data.plan_fail_lead <=
                    10 &&
                  report_plan.plan_fact_data.conv_fail_lead -
                    report_plan.plan_fact_data.plan_fail_lead >
                    0,
                '!text-rose-400':
                  report_plan.plan_fact_data.conv_fail_lead -
                    report_plan.plan_fact_data.plan_fail_lead >
                  10,
              }"
              class="text-right"
            >
              {{ report_plan.plan_fact_data.conv_fail_lead }}%
            </p>
          </div>
        </div>
      </div>
      <!-- ЛИДЫ -->

      <!-- ЗАЯВКИ -->
      <div class="voronka-row mb-2">
        <div class="voronka-top-row flex">
          <p class="number-vtr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_count_bid }}
          </p>
          <p class="text-vtr">заявки</p>
          <div class="progress-wrap-vtr">
            <div
              role="progressopen"
              :style="'--valueOD:' + report_plan.plan_fact_data.conv_count_bid"
              class="progress-bar-vtr orders-color-bg min-w-[5px]"
            ></div>
            <p class="ml-4 text-right">
              {{ report_plan.plan_fact_data.conv_count_bid }}%
            </p>
          </div>
        </div>
        <div class="voronka-bottom-row flex">
          <p class="number-vbr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_fail_bid }}
          </p>
          <p class="ext-vbr">отказы с заявки</p>
          <div class="progress-wrap-vbr">
            <div
              role="progressopen"
              :style="'--valueOD:' + report_plan.plan_fact_data.conv_fail_bid"
              class="progress-bar-vbr min-w-[5px]"
              :class="{
                '!bg-green-400':
                  report_plan.plan_fact_data.conv_fail_bid -
                    report_plan.plan_fact_data.plan_fail_bid <=
                  0,
                '!bg-yellow-400':
                  report_plan.plan_fact_data.conv_fail_bid -
                    report_plan.plan_fact_data.plan_fail_bid <=
                    10 &&
                  report_plan.plan_fact_data.conv_fail_bid -
                    report_plan.plan_fact_data.plan_fail_bid >
                    0,
                '!bg-rose-400':
                  report_plan.plan_fact_data.conv_fail_bid -
                    report_plan.plan_fact_data.plan_fail_bid >
                  10,
              }"
            ></div>
            <p
              class="text-right"
              :class="{
                '!text-green-400':
                  report_plan.plan_fact_data.conv_fail_bid -
                    report_plan.plan_fact_data.plan_fail_bid <=
                  0,
                '!text-yellow-400':
                  report_plan.plan_fact_data.conv_fail_bid -
                    report_plan.plan_fact_data.plan_fail_bid <=
                    10 &&
                  report_plan.plan_fact_data.conv_fail_bid -
                    report_plan.plan_fact_data.plan_fail_bid >
                    0,
                '!text-rose-400':
                  report_plan.plan_fact_data.conv_fail_bid -
                    report_plan.plan_fact_data.plan_fail_bid >
                  10,
              }"
            >
              {{ report_plan.plan_fact_data.conv_fail_bid }}%
            </p>
          </div>
        </div>
      </div>
      <!-- ЗАЯВКИ -->

      <!-- СЧЕТА -->
      <div class="voronka-row mb-2">
        <div class="voronka-top-row flex">
          <p class="number-vtr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_count_invoice }}
          </p>
          <p class="text-vtr">счета</p>
          <div class="progress-wrap-vtr">
            <div
              role="progressopen"
              :style="
                '--valueOD:' + report_plan.plan_fact_data.conv_count_invoice
              "
              class="progress-bar-vtr invoice-color-bg min-w-[5px]"
            ></div>
            <p class="ml-4 text-right">
              {{ report_plan.plan_fact_data.conv_count_invoice }}%
            </p>
          </div>
        </div>
        <div class="voronka-bottom-row flex">
          <p class="number-vbr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_fail_invoice }}
          </p>
          <p class="ext-vbr">отказы с счетов</p>
          <div class="progress-wrap-vbr">
            <div
              role="progressopen"
              :style="
                '--valueOD:' + report_plan.plan_fact_data.conv_fail_invoice
              "
              class="progress-bar-vbr min-w-[5px]"
              :class="{
                '!bg-green-400':
                  report_plan.plan_fact_data.conv_fail_invoice -
                    report_plan.plan_fact_data.plan_fail_invoice <=
                  0,
                '!bg-yellow-400':
                  report_plan.plan_fact_data.conv_fail_invoice -
                    report_plan.plan_fact_data.plan_fail_invoice <=
                    10 &&
                  report_plan.plan_fact_data.conv_fail_invoice -
                    report_plan.plan_fact_data.plan_fail_invoice >
                    0,
                '!bg-rose-400':
                  report_plan.plan_fact_data.conv_fail_invoice -
                    report_plan.plan_fact_data.plan_fail_invoice >
                  10,
              }"
            ></div>
            <p
              class="text-right"
              :class="{
                '!text-green-400':
                  report_plan.plan_fact_data.conv_fail_invoice -
                    report_plan.plan_fact_data.plan_fail_invoice <=
                  0,
                '!text-yellow-400':
                  report_plan.plan_fact_data.conv_fail_invoice -
                    report_plan.plan_fact_data.plan_fail_invoice <=
                    10 &&
                  report_plan.plan_fact_data.conv_fail_invoice -
                    report_plan.plan_fact_data.plan_fail_invoice >
                    0,
                '!text-rose-400':
                  report_plan.plan_fact_data.conv_fail_invoice -
                    report_plan.plan_fact_data.plan_fail_invoice >
                  10,
              }"
            >
              {{ report_plan.plan_fact_data.conv_fail_invoice }}%
            </p>
          </div>
        </div>
      </div>
      <!-- СЧЕТА -->

      <!-- ОПЛАЧЕНЫЕ СЧЕТА -->
      <div class="voronka-row mb-2">
        <div class="voronka-top-row flex">
          <p class="number-vtr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_count_invoice_done }}
          </p>
          <p class="text-vtr">оплаченные счета</p>
          <div class="progress-wrap-vtr">
            <div
              role="progressopen"
              :style="
                '--valueOD:' +
                report_plan.plan_fact_data.conv_count_invoice_done
              "
              class="progress-bar-vtr payedinv-color-bg min-w-[5px]"
            ></div>
            <p class="ml-4 text-right">
              {{ report_plan.plan_fact_data.conv_count_invoice_done }}%
            </p>
          </div>
        </div>
      </div>
      <!-- ОПЛАЧЕНЫЕ СЧЕТА -->

      <!-- АНКЕТА ПРОВЕРЕНА МОП -->
      <div class="voronka-row mb-2">
        <div class="voronka-top-row flex">
          <p class="number-vtr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_count_anketa_all }}
          </p>
          <p class="text-vtr text-left">Анкета проверена<br />МОП</p>
          <div class="progress-wrap-vtr">
            <div
              :style="
                '--valueOD:' + report_plan.plan_fact_data.conv_count_anketa
              "
              role="progressopen"
              class="progress-bar-vtr checked-color-bg min-w-[5px]"
            ></div>
            <p class="ml-4 text-right">
              {{ report_plan.plan_fact_data.conv_count_anketa }}%
            </p>
          </div>
        </div>
      </div>
      <!-- АНКЕТА ПРОВЕРЕНА МОП -->

      <!-- ОТКАЗЫ -->
      <div class="voronka-row mb-2">
        <div class="voronka-top-row flex">
          <p class="number-vtr min-w-[30px] text-right mr-4">
            {{ report_plan.plan_fact_data.fact_all_fails }}
          </p>
          <p class="text-vtr">отказы</p>
          <div class="progress-wrap-vtr">
            <div
              :style="'--valueOD:' + report_plan.plan_fact_data.conv_all_fails"
              role="progressopen"
              class="progress-bar-vtr cancel-color-bg min-w-[5px]"
            ></div>
            <p class="ml-4 text-right">
              {{ report_plan.plan_fact_data.conv_all_fails }}%
            </p>
          </div>
        </div>
      </div>
      <!-- ОТКАЗЫ -->
    </div>
    <!-- ОВОРОНКА ПО СОЗДАННЫМ ЛИДАМ -->
    <!-- ОТКРЫТЫЕ СДЕЛКИ -->
    <div
      class="bg-white flex flex-col w-1/3 pt-8 pb-6 mt-4 relative text-center rounded-[8px]"
    >
      <h2 class="title-MPRS">ОТКРЫТЫЕ СДЕЛКИ</h2>
      <p class="title-number mt-4 mb-8">
        {{
          report_plan.plan_fact_data.open_count_touch +
          report_plan.plan_fact_data.open_count_lead +
          report_plan.plan_fact_data.open_count_bid +
          report_plan.plan_fact_data.open_count_invoice +
          report_plan.plan_fact_data.open_count_invoice_done
        }}
      </p>

      <div class="voronka-top-row !mb-4 flex">
        <p class="text-vtr">Касания</p>
        <div class="progress-wrap-vtr">
          <div
            :style="'--valueOD:' + report_plan.plan_fact_data.open_count_touch"
            role="progressopen"
            class="progress-bar-vtr contacts-color-bg min-h-[40px] min-w-[5px]"
          ></div>
          <p class="ml-4 text-right">
            {{ report_plan.plan_fact_data.open_count_touch }}
          </p>
        </div>
      </div>
      <div class="voronka-top-row !mb-4 flex">
        <p class="text-vtr">лиды</p>
        <div class="progress-wrap-vtr">
          <div
            :style="'--valueOD:' + report_plan.plan_fact_data.open_count_lead"
            role="progressopen"
            class="progress-bar-vtr leads-color-bg min-h-[40px] min-w-[5px]"
          ></div>
          <p class="ml-4 text-right">
            {{ report_plan.plan_fact_data.open_count_lead }}
          </p>
        </div>
      </div>
      <div class="voronka-top-row !mb-4 flex">
        <p class="text-vtr">заказы</p>
        <div class="progress-wrap-vtr">
          <div
            :style="'--valueOD:' + report_plan.plan_fact_data.open_count_bid"
            role="progressopen"
            class="progress-bar-vtr orders-color-bg min-h-[40px] min-w-[5px]"
          ></div>
          <p class="ml-4 text-right">
            {{ report_plan.plan_fact_data.open_count_bid }}
          </p>
        </div>
      </div>
      <div class="voronka-top-row !mb-4 flex">
        <p class="text-vtr">счета</p>
        <div class="progress-wrap-vtr">
          <div
            :style="
              '--valueOD:' + report_plan.plan_fact_data.open_count_invoice
            "
            role="progressopen"
            class="progress-bar-vtr invoice-color-bg min-h-[40px] min-w-[5px]"
          ></div>
          <p class="ml-4 text-right">
            {{ report_plan.plan_fact_data.open_count_invoice }}
          </p>
        </div>
      </div>
      <div class="voronka-top-row !mb-4 flex">
        <p class="text-vtr">оплаченные счета</p>
        <div class="progress-wrap-vtr">
          <div
            :style="
              '--valueOD:' + report_plan.plan_fact_data.open_count_invoice_done
            "
            role="progressopen"
            class="progress-bar-vtr payedinv-color-bg min-h-[40px] min-w-[5px]"
          ></div>
          <p class="ml-4 text-right">
            {{ report_plan.plan_fact_data.open_count_invoice_done }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- ВЕРХНЯЯ ПАНЕЛЬ -->

  <div v-if="!showFact" class="absolute top-20 left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ManagerFactsPage",

  data() {
    return {
      forming: false,
      loadedStages: false,
      formingChart: false,
      loadedChart: false,

      dateFrom: null,
      dateTo: null,
      loaded: null,
      chartData: null,
      worker: "0",

      valueProgress: 85,
      valueProgress1: 55,
      valueProgress2: 115,

      showFact: false,
      report_plan: {},

      lead_dir: "all",
      inc_dir: "all",
      business: "all",
    };
  },

  props: {
    manager_id: Number,
    manager_name: String,
    dateSearch: String,
  },
  computed: {
    ...mapGetters(["reportFactManager", "profile"]),
  },

  methods: {
    ...mapActions(["getThisReportFactManager"]),
    openThisRPS() {
      this.$emit("closePopup");
    },
    addSpace(strNumber) {
      return String(strNumber).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },
    async changeDirection() {
      this.showFact = false;
      this.report_plan = {};
      await this.getThisReportFactManager({
        lead_dir: this.lead_dir,
        inc_dir: this.inc_dir,
        business: this.business,
      });

      this.showFact = true;
      this.report_plan = Object.assign({}, this.reportFactManager);
    },
  },
  async mounted() {
    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth();
    currentMonth = currentMonth + 1;
    var lastMonth = currentMonth - 1;

    if (currentMonth < 10) {
      this.dateFrom = "" + currentYear + "-0" + lastMonth;
    } else {
      this.dateFrom = "" + currentYear + "-" + lastMonth;
    }
    if (currentMonth < 10) {
      this.dateTo = "" + currentYear + "-0" + currentMonth;
    } else {
      this.dateTo = "" + currentYear + "-" + currentMonth;
    }

    await this.getThisReportFactManager({
      lead_dir: this.lead_dir,
      inc_dir: this.inc_dir,
      business: this.business,
    });

    this.showFact = true;
    this.report_plan = Object.assign({}, this.reportFactManager);
  },
};
</script>

<style scoped>
.number-vtr {
  color: rgb(115, 115, 115);
  font-size: 16.16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0%;
  text-transform: uppercase;
}
.text-vtr {
  color: rgb(38, 45, 41);
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0%;
  text-transform: uppercase;
  min-width: 170px;
  text-align: left;
}
.progress-wrap-vtr {
  align-items: center;
  display: flex;
}
.progress-wrap-vtr > p {
  color: rgb(38, 45, 41);
  font-size: 16.16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0%;
  text-align: left;
  min-width: 43px;
  text-transform: uppercase;
}

.voronka-row {
}
.voronka-top-row {
  align-items: center;
  padding: 5px 15px;
  margin-top: 10px;
  margin-bottom: 3px;
}
.progress-bar-vtr {
  border-radius: 4.57px;
  height: 30px;
  width: calc(var(--value) + 10px);
}

.contacts-color-bg {
  background-color: rgb(184, 237, 249);
}
.leads-color-bg {
  background-color: rgb(144, 210, 224);
}
.orders-color-bg {
  background-color: rgb(144, 176, 224);
}
.invoice-color-bg {
  background-color: rgb(160, 144, 224);
}
.payedinv-color-bg {
  background-color: rgb(203, 144, 224);
}
.checked-color-bg {
  background-color: rgb(121, 206, 119);
}
.cancel-color-bg {
  background-color: rgb(181, 181, 181);
}

.voronka-bottom-row {
  align-items: center;
  padding: 0 15px;
  color: rgb(171, 171, 171);
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.number-vbr {
}
.ext-vbr {
  min-width: 170px;
}
.progress-wrap-vbr {
  display: flex;
  align-items: center;
}

.progress-wrap-vbr {
  display: flex;
  align-items: center;
}

.progress-bar-vbr {
  min-width: 5px;
  background: rgb(234, 234, 234);
  border-radius: 5px;
  height: 22px;
}

.progress-bar-vbr[role="progressopen"] {
  width: calc(var(--valueOD) * 2px);
}

.progress-wrap-vbr > p {
  min-width: 43px;
}

.deal-row-wrap {
}
.dr-text {
}
.progress-wrap-dr {
}
.progress-bar-vbr {
}

.title-number {
  width: 100%;
  font-weight: 900;
  font-size: 30px;
  color: #c9c9c9;
  text-align: center;
}
.title-MPRS {
  color: rgb(38, 45, 41);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0%;
  text-transform: uppercase;
}
.oh-frick-go-back {
  box-sizing: border-box;
  border: 1px solid rgb(34, 197, 94);
  border-radius: 3px;
  background: rgb(254, 254, 254);
  color: rgb(38, 45, 41);
  font-size: 14.5px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 30px;
  transition: 0.3s ease;
}

.oh-frick-go-back:hover {
  background: rgb(34, 197, 94);
  color: #fff;
}

.flex-wrap-report {
  width: 100%;
  display: flex;
  align-items: center;
}
.date-report {
  background: #f8fafc;
  color: #262d29;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 9px 13px;
  margin-right: 15px;
}

.orders-tabs a {
  transition: 0.4s ease;
  color: #9ca3c0;
  text-align: center;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.orders-tabs a:first-child {
  margin-right: 10px;
}

.checkedTab {
  border-radius: 3px;
  background: #22c55e;
  color: #fff !important;
}
.button-form-report {
  border-radius: 3px;
  background: #22c55e;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 12px 88px;
}
.wrap-chart {
  width: 100%;
}
.chart-lol {
  border-radius: 5px;
  background: #f8fafc;
  box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
}
.chart-lol p {
  color: #262d29;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.month-report-top {
  padding: 10px 15px !important;
  border-radius: 10px;
}

.todayDateLabel {
  color: rgb(125, 125, 125);

  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.todayDate {
  color: rgb(38, 45, 41);

  font-size: 23px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.workDays {
  color: rgb(125, 125, 125);

  font-size: 14px;
  line-height: 34px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
.workDays > span {
  font-size: 24px;
}

.titleReport {
  color: rgb(38, 45, 41);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.progress-bar-vtr[role="progressopen"] {
  width: calc(var(--valueOD) * 2px);
  max-width: 200px;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #369;
  --secondary: rgb(232, 232, 232);
  --size: 220px;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.green[role="progressbar"] {
  --primary: rgb(34, 197, 94);
}
.yellow[role="progressbar"] {
  --primary: rgb(254, 171, 26);
}
.red[role="progressbar"] {
  --primary: rgb(250, 52, 76);
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 0.75turn at 50% 100%,
    var(--primary) calc((var(--percentage) - 25) * 1% / 2),
    var(--secondary) calc((var(--percentage) - 25) * 1% / 2 + 0.1%)
  );
  mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
  -webkit-mask-mode: alpha;
}

[role="progressbar"]::after {
  counter-reset: percentage calc(var(--value));
  content: counter(percentage) "%";
  font-family: Helvetica, Arial, sans-serif;
  font-size: 45px;
  color: var(--primary);
  line-height: 80%;
  font-weight: bolder;
}

.planText {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  color: rgb(171, 171, 171);
}
.planText > span {
  color: rgb(80, 80, 80);
}

.titleReport {
  color: rgb(38, 45, 41);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.numberToday {
  color: rgb(142, 142, 142);
  font-size: 36.57px;
  font-weight: 900;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.numberLabelToday {
  color: rgb(38, 45, 41);

  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.line100 {
  position: absolute;
  border: 1.09px solid rgb(0, 0, 0);
  width: 25px;
  top: 39px;
  right: 29px;
  z-index: 5;
  display: block;
  transform: rotate(-45deg);
}

.percent-number {
  color: rgb(38, 45, 41);
  font-size: 15.54px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0%;
  text-align: right;
  text-transform: uppercase;
}
.progress-name {
  color: rgb(38, 45, 41);
  font-size: 10.06px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.avatar {
  background: rgb(204, 204, 204);
  width: 33px;
  height: 33px;
  border-radius: 100px;
}
.progress-horisontal {
  position: relative;
  width: 130px;
  height: 50px;
}
.progress-block-inner {
  border-radius: 4.57px;
  background: rgb(232, 232, 232);
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 50px;

  top: 0%;
  left: 0;
}
.progress-block-outer {
  border-radius: 4.57px;
  background: rgb(34, 197, 94);
  position: absolute;
  z-index: 5;
  width: 120%;
  height: 25px;

  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.number-progress {
  color: rgb(38, 45, 41);
  font-size: 15.54px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0%;
  text-transform: uppercase;
}
.progress-horisontal {
  margin-left: auto;
}

.plan-table-wrap > div:last-child {
  border-bottom: 1px solid rgb(136, 136, 136);
}
.wrap-pt-grid {
  display: grid;
  grid-template-columns: 20% repeat(8, 10%);
  border-bottom: 1px solid rgb(232, 236, 236);
}
.wrap-plant-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.plan-table-title {
  padding-top: 25px;
  padding-bottom: 25px;
  color: rgb(38, 45, 41);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}

.wrap-pt-grid > * {
  color: rgb(38, 45, 41);
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;

  border-right: 1px solid rgb(232, 236, 236);
}
.wrap-pt-grid > *:last-child {
  border-right: none;
}
.wrap-pt-grid > *:nth-child(2) {
  border-left: 1px solid rgb(136, 136, 136);
}
.wrap-pt-grid > *:nth-child(3) {
  border-right: 1px solid rgb(136, 136, 136);
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
}
.title-col > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.wrap-semi-th {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-col {
  height: 50px;
}
.progress-pay-wrap {
  height: 30px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.progress-pay {
  position: absolute;
  height: 30px;
  border-radius: 8px;
  z-index: 2;
  background: #dfdfdf;
  width: 80%;
  left: 0;
  top: 0;
}
.progress-pay-wrap > .number-progress {
  z-index: 4;
  width: 100%;
  text-align: center !important;
}
</style>
