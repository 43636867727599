<template>
    <div class="bg-white rounded-md">
        <div class="p-4 relative v-bbgray">
            <h2 class="text-center font-bold text-lg uppercase">Услуги</h2>
        </div>

        <div class="mx-4 h-[500px] w-full max-w-full" ref="newChart"></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: "StatisticServices",

    computed: {
        ...mapGetters(["statisticShow", "statServices"]),
    },

    watch: {
        statServices(newValue) {
            if (newValue) {
                this.root.dispose();
                this.setup(newValue);
            }
        },
    },
    methods: {
        setup(chartData) {
            let root = am5.Root.new(this.$refs.newChart);
            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    layout: root.verticalHorizontal,
                    innerRadius: am5.percent(50),
                    tooltip: am5.Tooltip.new(root, {
                        getFillFromSprite: true,
                        autoTextColor: false,
                        labelText: "[bold]{category}: {value}",
                    }),
                })
            );

            let series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    name: "Series",
                    valueField: "value",
                    categoryField: "name",
                    legendLabelText: "{name}:",
                    legendValueText: "{value.formatNumber(#)}",
                })
            );

            series.labels.template.setAll({
                fontSize: 16,
                text: "{name}: {value}",
            });
            series
                .get("colors")
                .set("colors", [am5.color(0xfeab1a), am5.color(0x3cc45a), am5.color(0x0288d1), am5.color(0xf44336), am5.color(0x8e24aa)]);

            // chartData = [
            //     { id: 7, name: "Ремонт тахографов", value: 135 },
            //     { id: 8, name: "Тахографы. Основные услуги", value: 123 },
            //     { id: 2, name: "Тахографы. Доп.услуги", value: 101 },
            //     { id: 25, name: "Тахографы. Доп. товар", value: 37 },
            //     { id: 11, name: "Мониторинг. Основные базовые услуги", value: 29 },
            //     { id: 12, name: "Мониторинг. Ремонт", value: 18 },
            // ];

            if (chartData && chartData.parent_graf) {
                series.data.setAll(chartData.parent_graf);
            }

            let legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.percent(50),
                    x: am5.percent(50),
                    y: am5.percent(3),
                    layout: root.horizontalLayout,
                })
            );

            legend.data.setAll(series.dataItems);

            let total = 0;
            if (chartData && chartData.total) {
                total = chartData.total;
            }
            chart.children.unshift(
                am5.Label.new(root, {
                    text: total,
                    fontSize: 30,
                    fontWeight: "500",
                    textAlign: "center",
                    x: am5.percent(50),
                    y: am5.percent(50),
                    centerX: am5.percent(50),
                    centerY: am5.percent(50),
                    paddingTop: 0,
                    paddingBottom: 0,
                })
            );

            this.root = root;
        },
    },
    mounted() {
        this.setup(this.statServices);
    },
    beforeUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    },
};
</script>

<style lang="scss" scoped></style>
