<template>
    <div class="bg-white rounded-md">
        <div class="p-4 relative v-bbgray">
            <h2 class="text-center font-bold text-lg uppercase">Стандарты</h2>
        </div>
        <template v-if="statRating && statRating.data?.length > 0">
            <div
                class="pl-4 max-m:pr-4 py-4 flex transition duration-300 cursor-pointer hover:shadow-v1 max-m:flex-col max-m:relative"
                @click="this.changeShowPage('violations')"
                v-for="item in statRating.data"
                :key="item"
            >
                <div class="flex flex-col items-center justify-center mr-6 min-w-[10rem] w-48 max-m:self-center max-m:mb-8 max-m:mr-24">
                    <div class="w-11 h-11 rounded-full bg-green-500">
                        <IcoPic :pic="item.picture" :name="item.name" />
                    </div>

                    <div class="flex flex-col justify-center overflow-hidden">
                        <span class="text-base font-semibold text-center">{{ item.secondname }} {{ item.name }} {{ item.uid }}</span>
                    </div>
                </div>

                <div class="flex justify-around w-full max-m:justify-between max-s:flex-wrap">
                    <!-- Rhomb 1 -->
                    <div class="text-center uppercase">
                        <div class="relative">
                            <IcoRomb :status="'2'" :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'" />
                            <div class="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center">
                                <svg class="w-5 h-5 fill-white">
                                    <use xlink:href="@/assets/i.svg#nav-flow"></use>
                                </svg>
                                <span class="text-2xl text-white">{{ item.order_count }}</span>
                            </div>
                        </div>
                        <span class="uppercase text-gray-400 text-xs">Заказы</span>
                    </div>

                    <!-- Rhomb 2 -->
                    <div class="text-center uppercase">
                        <div class="relative flex flex-col items-center">
                            <IcoRomb v-if="item.punctuality_rating < 7" :status="'7'" :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'" />
                            <IcoRomb
                                v-if="item.punctuality_rating >= 7 && item.punctuality_rating < 9"
                                :status="'10'"
                                :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'"
                            />
                            <IcoRomb v-if="item.punctuality_rating >= 9" :status="'5'" :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'" />
                            <div class="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center">
                                <svg class="w-5 h-5 fill-white">
                                    <use xlink:href="@/assets/i.svg#ico-time"></use>
                                </svg>
                                <span class="text-2xl text-white">{{ item.punctuality_rating }}</span>
                                <div class="flex items-center absolute bottom-2">
                                    <svg class="w-2 h-2 fill-white mr-1">
                                        <use xlink:href="@/assets/i.svg#status-11"></use>
                                    </svg>
                                    <span class="text-xs text-white">{{ item.punctuality }}</span>
                                </div>
                            </div>
                        </div>
                        <span class="uppercase text-gray-400 text-xs">Дисциплина</span>
                    </div>

                    <!-- Rhomb 3 -->
                    <div class="text-center uppercase">
                        <div class="relative">
                            <IcoRomb v-if="item.quality < 7" :status="'7'" :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'" />
                            <IcoRomb
                                v-if="item.quality >= 7 && item.quality < 9"
                                :status="'10'"
                                :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'"
                            />
                            <IcoRomb v-if="item.quality >= 9" :status="'5'" :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'" />
                            <div class="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center">
                                <svg class="w-5 h-5 fill-white">
                                    <use xlink:href="@/assets/i.svg#nav-masters"></use>
                                </svg>
                                <span class="text-2xl text-white">{{ item.quality }}</span>
                            </div>
                        </div>
                        <span class="uppercase text-gray-400 text-xs">Качество</span>
                    </div>

                    <!-- Rhomb 4 -->
                    <div class="text-center uppercase">
                        <div class="relative">
                            <IcoRomb v-if="item.claim_rating < 7" :status="'7'" :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'" />
                            <IcoRomb
                                v-if="item.claim_rating >= 7 && item.claim_rating < 9"
                                :status="'10'"
                                :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'"
                            />
                            <IcoRomb v-if="item.claim_rating >= 9" :status="'5'" :sizes="'w-24 h-24 max-m:w-[5.5rem] max-m:h-[5.5rem]'" />
                            <div class="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center">
                                <svg class="w-5 h-5 fill-white">
                                    <use xlink:href="@/assets/i.svg#warranty"></use>
                                </svg>
                                <span class="text-2xl text-white">{{ item.claim_rating }}</span>
                            </div>
                        </div>
                        <span class="uppercase text-gray-400 text-xs">Надёжность</span>
                    </div>
                </div>

                <div class="h-14 overflow-hidden pl-6 pr-10 py-3 flex items-center ml-auto self-center relative max-m:absolute max-m:right-0">
                    <svg class="w-7 h-7 min-w-[1.75rem] fill-white z-2 mr-2">
                        <use xlink:href="@/assets/i.svg#ico-rating"></use>
                    </svg>
                    <span id="timer" class="text-white z-20 relative text-lg font-latobold">{{ item.rating }}</span>
                    <svg
                        class="absolute top-0 -left-8 h-full"
                        :class="[
                            { '!fill-status-5': item.rating > 9 },
                            { '!fill-status-10': item.rating >= 7 && item.rating < 9 },
                            { '!fill-status-7': item.rating < 7 },
                        ]"
                    >
                        <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                    </svg>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import IcoPic from "@/components/ui/IcoPic.vue";
import IcoRomb from "@/components/ui/IcoRomb.vue";

export default {
    name: "StatisticStandards",
    components: { IcoPic, IcoRomb },
    computed: {
        ...mapGetters(["s", "profile", "statisticShow", "statRating"]),
    },
    methods: {
        ...mapMutations(["changeShowPage"]),
        ...mapActions([]),
    },
    mounted() {},
};
</script>

<style lang="scss" scoped></style>
