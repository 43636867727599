<template>
  <div class="bg-white rounded-lg">
    <div class="p-5 flex justify-between items-center v-bbgray">
      <h3 class="text-base font-latobold">Менеджеры</h3>

      <template v-if="['Studio', 'Hosd'].includes(role)"> </template>
    </div>

    <div class="grid grid-cols-8 px-5 py-2 v-bbgray">
      <p class="text-gray-400 uppercase text-[11px]">СТАТУС</p>
      <p class="text-gray-400 uppercase text-[11px]">МЕНЕДЖЕР</p>
    </div>

    <template v-for="manager in ClientManagers" :key="manager">
      <div
        class="px-5 py-2 grid grid-cols-8 v-bbgray line hvr transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative"
      >
        <div @click="setClientManager(manager.uid)">
          <span
            v-if="manager.manager_id"
            class="w-6 h-6 bg-status-6 flex justify-center rounded-full items-center overflow-hidden mr-2"
          >
            <svg class="w-4 h-4 fill-white">
              <use xlink:href="@/assets/i.svg#ico-ok-line"></use>
            </svg>
          </span>
          <span
            v-else
            class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
          >
            <svg class="w-4 h-4 fill-white">
              <use xlink:href="@/assets/i.svg#ico-no-line"></use>
            </svg>
          </span>
        </div>

        <div class="flex items-center col-span-6">
          <div
            class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden"
          >
            <IcoPic :pic="manager.picture" :name="manager.name" />
          </div>

          <span>{{ manager.secondname }} {{ manager.name }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ClientManager",

  data() {
    return {
      moment: moment,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "ClientManagers",
    ]),
  },

  methods: {
    ...mapActions(["Tab", "setClientManager"]),
  },
};
</script>
