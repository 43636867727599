<template>
  <div class="flex pt-4 max-l:flex-col">
    <div
      class="flex-1 min-w-[200px] bg-white rounded-lg sticky top-6 h-max max-l:relative max-l:mb-4"
    >
      <div class="p-5 v-bbgray">
        <h2 class="text-center font-latobold text-xl">Инженеры</h2>
      </div>

      <div class="flex flex-col max-h-[600px] overflow-y-auto">
        <a
          v-for="m in selectMasters"
          :key="m"
          class="group hover:shadow-v-xl transition duration-300 cursor-pointer px-4 py-2 relative"
          :class="{
            'shadow-v-xl border-r-4 border-green-500': m.uid == master,
          }"
          @click="getCalEdit(m.uid)"
        >
          <div class="flex items-center">
            <div
              class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-green-500"
            >
              <IcoPic :pic="m?.picture" :name="m?.name" />
            </div>
            <div class="flex flex-col justify-center overflow-hidden pr-8">
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                >Инженер</span
              >
              <span class="font-latobold text-base">{{ m.secondname }}</span>
            </div>
          </div>
          <svg
            class="absolute opacity-0 w-3 h-3 group-hover:opacity-100 transition-opacity duration-300 right-4 top-1/2 -translate-y-1/2 fill-green-500"
            :class="{ 'opacity-100': m.uid == master }"
          >
            <use xlink:href="@/assets/i.svg#ico-next-one"></use>
          </svg>
        </a>
      </div>
    </div>

    <div class="ml-4 w-3/4 max-l:w-full max-l:ml-0">
      <div
        v-if="CalforEdit && CalforEdit.length > 20"
        class="p-4 bg-white rounded-lg"
      >
        <h2 class="text-center text-xl font-latobold">Смены</h2>
        <p class="text-center mb-8">
          Нажми на время / день, когда инженер будет работать
        </p>

        <div class="flx flx-sa">
          <!-- <div class="btn jsmonth" month="{{ sett.p }}">{{ mnths[(sett.p|date('m')) + 0] }}</div> -->
          <!-- <div class="btn jsmonth active" month="{{ sett.m }}">{{ mnths[(sett.m|date('m')) + 0] }}</div> -->
          <!-- <div class="btn jsmonth" month="{{ sett.f }}">{{ mnths[(sett.f|date('m')) + 0] }}</div> -->
        </div>

        <div class="calendar cal2">
          <div class="grid grid-cols-7">
            <div class="text-center text-gray-400 text-xs v-bbgray mr-1">
              ПН
            </div>
            <div class="text-center text-gray-400 text-xs v-bbgray mr-1">
              ВТ
            </div>
            <div class="text-center text-gray-400 text-xs v-bbgray mr-1">
              СР
            </div>
            <div class="text-center text-gray-400 text-xs v-bbgray mr-1">
              ЧТ
            </div>
            <div class="text-center text-gray-400 text-xs v-bbgray mr-1">
              ПТ
            </div>
            <div class="text-center text-rose-400 text-xs v-bbgray mr-1">
              СБ
            </div>
            <div class="text-center text-rose-400 text-xs v-bbgray">ВС</div>
          </div>
          <div class="grid grid-cols-7 auto-rows-max">
            <template v-for="cal in CalforEdit" :key="cal">
              <div class="">
                <div
                  v-if="cal.date"
                  class="flex py-1 px-2 items-center justify-around max-m:flex-col max-m:px-1"
                >
                  <svg
                    class="w-3 h-3 fill-status-5"
                    @click="
                      editCalMasterDay({ date: cal.date, uid: cal.slot[0].uid })
                    "
                  >
                    <use xlink:href="@/assets/i.svg#ico-ok"></use>
                  </svg>
                  <h4
                    class="mx-1 flex-1 text-center text-xs text-gray-400 tracking-tighter max-l:text-[10px] max-l:text-black max-m:mx-0 max-m:my-1"
                  >
                    {{ moment(cal.date).format("DD.MM") }}
                  </h4>
                  <svg
                    class="w-3 h-3 fill-status-1"
                    @click="
                      editCalMasterBoxes({
                        date: cal.date,
                        uid: cal.slot[0].uid,
                      })
                    "
                  >
                    <use xlink:href="@/assets/i.svg#nav-masters"></use>
                  </svg>
                </div>
                <div class="mb-3">
                  <template v-for="slot in cal.slot" :key="slot">
                    <div
                      class="bg-gray-100 text-[10px] text-gray-400 border-[1px] border-white h-[21px] px-2 flex justify-around items-center cursor-pointer"
                      :class="'calendar-view-' + slot.status"
                    >
                      <div
                        @click="
                          editCalMasterOne({ date: cal.date, slot: slot })
                        "
                      >
                        {{ slot.time.slice(0, 5) }}
                      </div>
                      <svg
                        v-if="slot.status == 2"
                        class="w-3 h-3 fill-status-7"
                      >
                        <use xlink:href="@/assets/i.svg#ico-closed"></use>
                      </svg>
                      <!-- <svg v-if="slot.status == 3" class="flr f-green">
                                                    <use xlink:href="@/assets/i.svg#nav-flow"></use>
                                                </svg> -->
                      <svg
                        v-if="slot.box > 0"
                        class="w-3 h-3 fill-status-1"
                        @click="
                          editCalMasterBox({ date: cal.date, slot: slot })
                        "
                      >
                        <use xlink:href="@/assets/i.svg#nav-masters"></use>
                      </svg>
                      <svg
                        class="w-3 h-3 fill-gray-400"
                        v-else
                        @click="
                          editCalMasterBox({ date: cal.date, slot: slot })
                        "
                      >
                        <use xlink:href="@/assets/i.svg#nav-masters-line"></use>
                      </svg>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="bg-white p-4 flex-1 rounded-lg">
        <div class="flex p-4 bg-green-100 rounded-lg items-center">
          <svg class="h-6 w-6 fill-green-500">
            <use xlink:href="@/assets/i.svg#ico-prev-one"></use>
          </svg>
          <span class="ml-8 uppercase text-green-500">Выбери инженера</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "CalendarEdit",

  data() {
    return {
      moment: moment,
      master: "",
      calendar: [],
      mnths: [
        "",
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
      nbrday: [
        "",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
        "Воскресенье",
      ],
    };
  },
  computed: {
    ...mapGetters(["role", "selectMasters", "CalforEdit"]),
  },
  methods: {
    ...mapActions([
      "getCalEdit",
      "editCalMasterDay",
      "editCalMasterOne",
      "editCalMasterBoxes",
      "editCalMasterBox",
    ]),

    pickM(uid) {
      if (this.CalforEdit) {
        this.master = uid;
        this.calendar = [];
        this.CalforEdit.forEach((day) => {
          if (day.date && day.slot) {
            this.calendar.push({
              date: day.date,
              slot: day.slot.filter(
                (item) => parseInt(item.uid) == parseInt(this.master)
              ),
            });
          } else {
            this.calendar.push({ date: "", slot: "" });
          }
        });
      }
    },
  },

  mounted() {
    //alert("!!!!");
    window.DEBUG_CALENDAR_EDIT = this;
  },
};
</script>
