<template>
    <div class="flex max-xl:flex-col">
        <div class="w-96 my-4 bg-white rounded max-l:mx-auto">
            <div class="p-5 v-bbgray">
                <h2 class="uppercase text-center text-xl font-latobold">Профиль</h2>

                <!-- <template v-if="role == 'Master'">
                    <div class="flex w-12 absolute top-8 h-12 px-6 py-4">
                        <svg class="absolute top-1/2 -translate-y-1/2 right-4 w-3 h-3 fill-green-500 group-hover:opacity-100 transition-opacity duration-150">
                            <use xlink:href="@/assets/i.svg#ico-rating"></use>
                        </svg>
                        <span id="timer">{{ raiting }}</span>
                        <svg class="absolute top-1/2 -translate-y-1/2 right-4 w-3 h-3 fill-green-500 group-hover:opacity-100 transition-opacity duration-150">
                            <use xlink:href="@/assets/i.svg#bg-timer"></use>
                        </svg>
                    </div>
                </template> -->
            </div>
            <div class="p-5 v-bbgray">
                <div class="flex text-base text-zinc-800 font-latobold flex-col items-center">
                    <div class="w-48 h-48 my-5 mx-auto bg-green-500 overflow-hidden relative">
                        <span class="avatar w-full h-full group">
                            <input
                                type="file"
                                id="profileavatar"
                                class="absolute invisible opacity-0"
                                multiple
                                @change="Upload({ e: $event, item: 'profile', type: 'profile', id: profile.uid })"
                            />
                            <label
                                for="profileavatar"
                                class="w-1/2 h-1/2 flex items-center justify-center rounded-full absolute -top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 group-hover:bg-black group-hover:opacity-50 group-hover:top-1/2"
                            >
                                <svg class="fill-white w-5 h-5">
                                    <use xlink:href="@/assets/i.svg#ico-edit"></use>
                                </svg>
                            </label>
                            <img
                                v-if="profile?.picture"
                                class="w-full h-full object-cover"
                                :src="'https://api.tahocrm.ru/uploads/users/' + profile?.picture"
                            />
                        </span>
                    </div>

                    {{ profile.name }} {{ profile.secondname }}
                </div>
                <div class="flex">
                    <!-- <a class="mx-auto mt-4 v-btn bg-sky-500 hover:bg-sky-400 transition-colors duration-150" @click="editProfile">
                        <svg class="fill-white w-16px h-16px mr-3">
                            <use xlink:href="@/assets/i.svg#ico-edit"></use>
                        </svg>
                        <span class="v-btn_text">РЕДАКТИРОВАТЬ</span>
                    </a> -->
                    <a class="mx-auto mt-4 v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150" @click="Logout">
                        <span class="v-btn_text">ВЫХОД</span>
                    </a>
                </div>
            </div>

            <div class="p-4 v-bbgray">
                <h3 class="ml-4 mb-2 uppercase text-base font-latobold">Роли</h3>
                <template v-for="r in myRoles" :key="r">
                    <p class="my-1 flex cursor-pointer group text-green-500 hover:text-green-400 transition-colors duration-150 relative">
                        <svg v-if="role == r" class="absolute fill-green-500 group-hover:fill-green-400 transition-colors duration-150 h-4 w-4">
                            <use xlink:href="@/assets/i.svg#ico-ok"></use>
                        </svg>
                        <a v-if="role == r" class="ml-5">{{ roleNames[r] }}</a>
                        <a v-else class="ml-5" @click="changeRole(r)">{{ roleNames[r] }}</a>
                    </p>
                </template>
            </div>

            <template v-if="role == 'Master'">
                <div class="">
                    <h2 class="py-4 text-center text-lg uppercase font-latobold v-bbgray">Категории услуг инженера</h2>
                    <div class="p-5">
                        <div class="list">
                            <template v-for="item in workerCats" :key="item">
                                <div class="cell mb5">
                                    <div class="flex items-center my-1">
                                        <div
                                            v-if="item.masterPrice?.id"
                                            class="flex items-center"
                                            @click="ChangeCat(item.id)"
                                        >
                                            <span class="w-6 h-6 bg-status-5 flex justify-center rounded-full items-center overflow-hidden mr-2">
                                                <svg class="w-4 h-4 fill-white">
                                                    <use xlink:href="@/assets/i.svg#ico-ok-line"></use>
                                                </svg>
                                            </span>
                                        </div>

                                        <div
                                            v-else
                                            class="flex items-center"
                                            @click="ChangeCat(item.id)"
                                        >
                                            <span class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2">
                                                <svg class="w-4 h-4 fill-white">
                                                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                                                </svg>
                                            </span>
                                        </div>
                                        <span class="font-latobold">{{ item.name }}</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="flex flex-col ml-4 mt-4 flex-1">
            <!-- <template v-if="achive">
                <div class="p-5 bg-white rounded mb-4 flex flex-wrap">
                    <div class="my-4 relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out">
                        <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                            <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                                <use xlink:href="@/assets/i.svg#ico-rating"></use>
                            </svg>
                            <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="worker.raiting">
                                {{ worker.raiting }}
                            </h2>
                            <svg
                                class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
                                :class="[
                                    { '!fill-status-5': worker.raiting > 9 },
                                    { '!fill-status-10': worker.raiting > 7 && worker.raiting < 9 },
                                    { '!fill-status-7': worker.raiting < 7 },
                                ]"
                            >
                                <use xlink:href="@/assets/i.svg#bg-romb"></use>
                            </svg>
                        </div>
                        <span
                            class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                            >Рейтинг</span
                        >
                    </div>

                    <div class="my-4 relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out">
                        <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                            <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                                <use xlink:href="@/assets/i.svg#ico-time"></use>
                            </svg>
                            <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="worker.a1">
                                {{ worker.a1 }}
                            </h2>
                            <svg
                                class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
                                :class="[
                                    { '!fill-status-5': worker.raiting > 9 },
                                    { '!fill-status-10': worker.raiting > 7 && worker.raiting < 9 },
                                    { '!fill-status-7': worker.raiting < 7 },
                                ]"
                            >
                                <use xlink:href="@/assets/i.svg#bg-romb"></use>
                            </svg>
                        </div>
                        <span
                            class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                            >ДИСЦИПЛИНА</span
                        >
                    </div>

                    <div class="my-4 relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out">
                        <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                            <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                                <use xlink:href="@/assets/i.svg#nav-masters"></use>
                            </svg>
                            <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="worker.a2">
                                {{ worker.a2 }}
                            </h2>
                            <svg
                                class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
                                :class="[
                                    { '!fill-status-5': worker.raiting > 9 },
                                    { '!fill-status-10': worker.raiting > 7 && worker.raiting < 9 },
                                    { '!fill-status-7': worker.raiting < 7 },
                                ]"
                            >
                                <use xlink:href="@/assets/i.svg#bg-romb"></use>
                            </svg>
                        </div>
                        <span
                            class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                            >КАЧЕСТВО</span
                        >
                    </div>

                    <div class="my-4 relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out">
                        <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                            <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                                <use xlink:href="@/assets/i.svg#warranty"></use>
                            </svg>
                            <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="worker.a3">
                                {{ worker.a3 }}
                            </h2>
                            <svg
                                class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
                                :class="[
                                    { '!fill-status-5': worker.raiting > 9 },
                                    { '!fill-status-10': worker.raiting > 7 && worker.raiting < 9 },
                                    { '!fill-status-7': worker.raiting < 7 },
                                ]"
                            >
                                <use xlink:href="@/assets/i.svg#bg-romb"></use>
                            </svg>
                        </div>
                        <span
                            class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                            >НАДЁЖНОСТЬ</span
                        >
                    </div>
                </div>
            </template> -->

            <div class="bg-white rounded mb-4 flex flex-wrap">
                <div class="w-full p-5 v-bbgray">
                    <h2 class="uppercase text-lg font-latobold">Telegram token</h2>
                </div>
                <div class="w-full p-5">{{ botHash }}</div>
            </div>

            <template v-if="messages">
                <div class="bg-white rounded mb-4 flex flex-wrap">
                    <div class="w-full p-5 v-bbgray">
                        <h2 class="uppercase text-lg font-latobold">Настройка сообщений</h2>
                    </div>

                    <div class="w-full p-5">
                        <template v-for="item in messages" :key="item">
                            <div class="cell mb5">
                                <div class="flex items-center my-1" @click="toggleDisallow(item.id)">
                                    <div v-if="item.disallow?.id" class="flex items-center">
                                        <span class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2">
                                            <svg class="w-4 h-4 fill-white">
                                                <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                                            </svg>
                                        </span>
                                    </div>
                                    <div v-else class="flex items-center">
                                        <span class="w-6 h-6 bg-status-5 flex justify-center rounded-full items-center overflow-hidden mr-2">
                                            <svg class="w-4 h-4 fill-white">
                                                <use xlink:href="@/assets/i.svg#ico-ok-line"></use>
                                            </svg>
                                        </span>
                                    </div>
                                    <span class="font-latobold">{{ item.name }}</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>

            <template v-if="role == 'Client'">
                <h2 class="py-4 text-center text-lg uppercase font-latobold v-bbgray">ИНСТРУКЦИЯ</h2>
                <div class="p-5">
                    <iframe
                        class="m-auto mb-4"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/UE-HdTe7fmY"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                    <iframe
                        class="m-auto"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/4AI3HaXnmXA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import API from "../services/api";

export default {
    name: "PageProfile",

    computed: {
        ...mapGetters(["s", "profile", "role", "myRoles", "roles", "roleNames", "achive", "raiting", "cats", "messages", "botHash", "workerCats"]),
    },

    methods: {
        ...mapActions(["changeRole", "Logout", "Upload", "editProfile", "toggleDisallow", "getWorkerById", "ChangeCat", "getProfile"]),

        saveProfile() {
            this.pro = "view";

            let formData = new FormData();
            formData.append("auth_key", localStorage.getItem("AUTH"));
            formData.append("type_request", "change_common_info");
            formData.append("name", this.profile.name);
            formData.append("secondname", this.profile.secondname);
            formData.append("email", this.profile.email);
            formData.append("phone", this.profile.phone);
            formData.append("user_username", this.profile.username);
            console.log("отправили запрос");

            // axios.defaults.headers.common["Authorization"] = "token123";

            axios.post("https://api.tahocrm.ru/ajax/profile", formData).then((res) => {
                this.userAlert = res.data.alert;
                console.log(res.data);
            });
        },
    },

    async mounted() {
        window.DEBUG_PROFILE = this;
        window.API = API;
        await this.getProfile();
        await this.getWorkerById(this.profile.id);
    },
};
</script>
