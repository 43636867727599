<template>
  <svg
    v-if="status == 1"
    class="w-full h-full fill-white"
    :class="'fill-status-' + status"
  >
    <use xlink:href="@/assets/i.svg#status-1"></use>
  </svg>
  <svg
    v-if="status == 2"
    class="w-full h-full fill-white"
    :class="'fill-status-' + status"
  >
    <use xlink:href="@/assets/i.svg#nav-masters"></use>
  </svg>
  <svg
    v-if="status == 6"
    class="w-full h-full fill-white"
    :class="'fill-status-' + status"
  >
    <use xlink:href="@/assets/i.svg#status-6"></use>
  </svg>
  <svg
    v-if="status == 8"
    class="w-full h-full fill-white"
    :class="'fill-status-' + status"
  >
    <use xlink:href="@/assets/i.svg#status-11"></use>
  </svg>
  <svg
    v-if="status == 11"
    class="w-full h-full fill-white"
    :class="'fill-status-' + status"
  >
    <use xlink:href="@/assets/i.svg#ico-no"></use>
  </svg>
</template>

<script>
export default {
  name: "IcoStatusLead",
  props: {
    status: Number,
  },
};
</script>
