import API from "../../services/api";
import moment from "moment";

export default {
    state: {
        cals: [],
        filteredCals: [],
        calsForEdit: [],
        dates: [],
        day: [],
        managerCal: [],
    },

    mutations: {
        setCals: (state, cals) => {
            state.cals = cals;
            state.filteredCals = cals;
        },
        setCalsForEdit: (state, cals) => {
            state.calsForEdit = cals;
        },
        setDates: (state, dates) => {
            state.dates = dates;
        },
        setCalDay: (state, day) => {
            state.day = day;
        },
        setManagerCal: (state, managerCal) => {
            state.managerCal = managerCal;
        },
    },

    actions: {
        getCal(ctx) {
            ctx.dispatch("loadSettings").then(() => {
                ctx.dispatch("loadCals");
            });

            if (ctx.rootState.s.calendar == 5) {
                return;
            }

            API.GET("calendar/calendars", { type: ctx.rootState.s.calendar }).then((res) => {
                ctx.commit("setCals", res.data.cals);
                ctx.commit("setDates", res.data.dates);
                ctx.dispatch("setCalType", ctx.rootState.s.calendar);
                ctx.dispatch("findCalendar", ctx.rootState.s);
            });
        },

        loadCals(ctx) {
            let cals = [];
            if (cals && cals?.length > 0) {
                ctx.commit("setCals", cals);
            }
        },

        findCalendar(ctx, s) {
            let list = ctx.state.cals;
            if (s.manager != "" && s.manager != undefined) {
                list = [];
                let count = [];
                ctx.state.cals.forEach((day) => {
                    if (day.count) {
                        count = day.count.filter((item) => String(item) == String(s.manager));
                        list.push({ date: day.date, count: count });
                    }
                });
            }

            if (list.length != ctx.state.cals.length) {
                s.clear = true;
            }

            ctx.state.filteredCals = list;
            ctx.dispatch("setSettings", s);
        },

        getCalDay(ctx, date) {
            console.log("getCalDay ", date);
            API.GET("calendar/day", { type: ctx.rootState.s.calendar, date: date }).then((res) => {
                ctx.commit("setCalDay", res.data);
            });
        },

        async getCalManager(ctx, manager_id) {
            console.log("getCalManager ", manager_id);
            let res = await API.GET("report/list-cal", { id: manager_id });

            if (res.status == 200) {
                ctx.commit("setManagerCal", res.data.manager_cal);
                console.log('res.data! ', res.data.manager_cal)
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }
        },

        changeCalManager(ctx, data_cal) {
            console.log("changeCalManager", data_cal);
            let formData = new FormData();
            formData.append("id", data_cal.id);
            formData.append("days", data_cal.days);
            API.POST("report/change-work", formData).then((res) => {
                ctx.commit("setManagerCal", res.data.manager_cal);
                console.log('res.data! ', res.data.manager_cal)
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }); 
        },

        async setAllCalManager(ctx, manager_id) {
            console.log("setCalManager", manager_id);
            let res = await API.GET("report/create-cal", { id: manager_id })
            
            if (res.status == 200) {
                ctx.commit("setManagerCal", res.data.manager_cal);
                console.log('res.data! ', res.data.manager_cal)
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            } 
        },

        async setAllCalManager2025(ctx, manager_id) {
            console.log("setCalManager", manager_id);
            let res = await API.GET("report/create-cal-new", { id: manager_id })
            
            if (res.status == 200) {
                // ctx.commit("setManagerCal", res.data.manager_cal);
                console.log('res.data! ', res.data.manager_cal)
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            } 
        },       

        delCalDay(ctx) {
            ctx.commit("setCalDay", { cal_time: null, day: null });
        },

        setCalType(ctx, type) {
            // console.log(type);
            ctx.rootState.s.calendar = type;
            ctx.dispatch("setSettings", ctx.rootState.s);
        },

  

        // СМЕНЫ МАСТЕРА

        // получить календарь мастера для редактирования смен
        getCalEdit(ctx, master_id) {
            console.log("getCalEdit ", master_id);
            API.GET("calendar/master-for-edit", { master_id: master_id }).then((res) => {
                ctx.commit("setCalsForEdit", res.data.cals);
                // ctx.commit("setDates", res.data.dates);
            });
        },

        // назначить/отменить целый день
        editCalMasterDay(ctx, data) {
            console.log("editCalMasterDay", data);
            ctx.state.calsForEdit.forEach((day) => {
                if (day.date == data.date) {
                    day.slot = day.slot.map((item) => {
                        if (item.status == 0) {
                            item.status = 1;
                        } else if (item.status == 1) {
                            item.status = 0;
                        }
                        return item;
                    });
                }
            });

            let formData = new FormData();
            formData.append("type", "day");
            formData.append("date", data.date);
            formData.append("uid", data.uid);
            API.POST("calendar/edit", formData).then((res) => {
                console.log("editCalMasterDay res", res.data);
            });
        },

        editCalMasterOne(ctx, data) {
            if (data.slot.status == 0) {
                data.slot.status = 1;
            } else if (data.slot.status == 1) {
                data.slot.box = 0;
                data.slot.status = 0;
            }

            let formData = new FormData();
            formData.append("type", "one");
            formData.append("date", data.date);
            formData.append("time", data.slot.time);
            formData.append("uid", data.slot.uid);
            API.POST("calendar/edit", formData);
        },

        editCalMasterBoxes(ctx, data) {
            ctx.state.calsForEdit.forEach((day) => {
                if (day.date == data.date) {
                    day.slot = day.slot.map((item) => {
                        if (item.box == 0) {
                            item.box = 1;
                            item.status = 1;
                        } else if (item.box > 0) {
                            item.box = 0;
                        }
                        return item;
                    });
                }
            });

            let formData = new FormData();
            formData.append("type", "boxes");
            formData.append("date", data.date);
            formData.append("uid", data.uid);
            API.POST("calendar/edit", formData);
        },

        editCalMasterBox(ctx, data) {
            if (data.slot.box > 0) {
                data.slot.box = 0;
            } else {
                data.slot.box = 1;
                data.slot.status = 1;
            }

            let formData = new FormData();
            formData.append("type", "box");
            formData.append("date", data.date);
            formData.append("time", data.slot.time);
            formData.append("uid", data.slot.uid);
            API.POST("calendar/edit", formData);
        },
    },

    getters: {
        // календарь кол-ва заказов, задач
        Calendar(state, rootState) {
            if (state.filteredCals) {
                const srt = moment(rootState.s.month).startOf("month").format("YYYY-MM-DD");
                const end = moment(rootState.s.month).endOf("month").format("YYYY-MM-DD");

                let month = state.filteredCals.filter((item) => item.date >= srt && item.date <= end);
                const weekday = moment(srt).isoWeekday();
                let i = 1;
                while (i < weekday) {
                    month.unshift({ date: "", count: 0 });
                    i++;
                }
                return month;
            }
            return null;
        },

        CalforEdit(state, rootState) {
            if (state.calsForEdit) {
                const srt = moment(rootState.s.month).startOf("month").format("YYYY-MM-DD");

                let cal = state.calsForEdit;
                const weekday = moment(srt).isoWeekday();
                let i = 1;
                while (i < weekday) {
                    cal.unshift({ date: "", count: 0 });
                    i++;
                }
                return cal;
            }
            return null;
        },

        CalDates(state) {
            return state.dates;
        },

        calendarDay(state) {
            return state.day;
        },
        managerCal(state) {
            return state.managerCal;
        }
    },
};
