<template>
  <template v-if="order.id">
    <div class="flex max-xl:flex-col">
      <div class="w-[400px] my-4 max-xl:mx-auto">
        <div class="bg-white rounded-[4px]">
          <div class="p-4 v-bbgray">
            <div class="flex items-center relative">
              <div class="w-12 h-12 relative">
                <span
                  class="avatar w-full h-full flex items-center justify-center bg-green-500"
                  :class="color[sts]"
                >
                  <span
                    class="w-5 h-5 flex items-center justify-center first:!fill-white"
                    ><IcoStatus :status="sts"
                  /></span>
                </span>
              </div>
              <div class="flex flex-col ml-4">
                <span class="text-gray-400 text-[10px] uppercase">{{
                  st[sts]
                }}</span>
                <span class="font-latobold text-xl text-black"
                  >№{{ order.iid }}</span
                >
              </div>
            </div>
          </div>

          <div class="p-5">
            <div v-if="order.address" class="flex relative group">
              <div class="flex items-center">
                <svg class="min-w-5 w-5 h-5 fill-gray-400">
                  <use xlink:href="@/assets/i.svg#ico-address"></use>
                </svg>
                <div class="flex flex-col ml-4">
                  <span class="text-gray-400 text-[10px] uppercase"
                    >Адрес выезда</span
                  >
                  <span class="font-latobold text-base text-gray-700">{{
                    order.address
                  }}</span>
                </div>
              </div>
            </div>

            <div v-else-if="order.box" class="flex relative group">
              <div class="flex items-center">
                <svg class="w-5 h-5 fill-gray-400 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-address"></use>
                </svg>
                <div class="flex flex-col">
                  <span
                    class="mb-1 text-gray-400 uppercase font-latobold text-xs"
                    >Бокс</span
                  >
                  <span class="font-latobold text-base">{{
                    order.mybox?.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-[4px] bg-white mt-4 relative order-conn">
          <template v-if="order.client_id != null">
            <div class="flex relative group miw-w-full p-5 v-bbgray">
              <a class="flex items-center cursor-pointer group">
                <div
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                >
                  <IcoPic
                    :pic="order.client?.picture"
                    :name="order.client?.name"
                  />
                </div>
                <div class="flex flex-col justify-center overflow-hidden pr-8">
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >Клиент</span
                  >
                  <span
                    class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                    >{{ order.client?.name }}</span
                  >
                </div>
              </a>
            </div>
          </template>

          <template v-if="order.client_id != null">
            <div class="p-5 v-bbgray flex items-center group relative">
              <template v-if="order.contact_id != null">
                <a class="flex cursor-pointer group">
                  <div
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                  >
                    <IcoPic
                      :pic="order.contact?.picture"
                      :name="order.contact?.name"
                    />
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >Контакт</span
                    >
                    <span
                      class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                      >{{ order.contact?.secondname }}
                      {{ order.contact?.name }}</span
                    >
                  </div>
                </a>
                <template v-if="order.contact?.phone">
                  <a
                    class="rounded-full absolute top-1/2 -translate-y-1/2 right-16 w-7 h-7 hover:w-36 overflow-hidden flex items-center bg-green-500 transition-all duration-300"
                    :href="'tel:' + order.contact.phone"
                  >
                    <svg class="w-4 h-4 ml-[6px] fill-white">
                      <use xlink:href="@/assets/i.svg#ico-phone"></use>
                    </svg>
                    <span class="absolute left-7 p-1 text-white">{{
                      order.contact?.phone
                    }}</span>
                  </a>
                </template>
              </template>
            </div>

            <div class="p-5 v-bbgray flex items-center group relative">
              <template v-if="order.ts_id">
                <a class="flex cursor-pointer group">
                  <div
                    class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 uppercase"
                  >
                    <span class="text-xl text-white">{{
                      order.ts?.mark?.slice(0, 1)
                    }}</span>
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >{{ order.ts?.mark }}</span
                    >
                    <span
                      class="text-slate-600 w-max rounded-sm v-tsnum px-2 text-base v-gray font-latoblod-serif"
                      >{{ order.ts?.num }}</span
                    >
                  </div>
                </a>
              </template>
            </div>
          </template>
        </div>

        <div id="times">
          <template v-if="order.dates">
            <div v-for="d in order.dates" :key="d" class="block cho">
              <div class="p-5 bg-white order-conn mt-4 relative rounded-[4px]">
                <div class="cursor-pointer group">
                  <div class="flex items-center justify-around">
                    <div class="flex">
                      <svg class="w-5 h-5 fill-gray-400">
                        <use xlink:href="@/assets/i.svg#nav-date"></use>
                      </svg>
                      <p class="font-latobold ml-2">{{ d?.date }}</p>
                    </div>
                    <div class="flex">
                      <svg class="w-5 h-5 fill-green-500">
                        <use xlink:href="@/assets/i.svg#ico-time"></use>
                      </svg>
                      <p class="font-latobold ml-2">
                        {{ d?.start?.slice(0, 5) }} - {{ d?.end?.slice(0, 5) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="bg-white order-conn mt-4 relative rounded-[4px]">
          <div v-if="order.master_id" class="p-5 v-bbgray">
            <a class="flex items-center cursor-pointer group">
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-green-500"
              >
                <IcoPic
                  :pic="order.master?.picture"
                  :name="order.master?.name"
                />
              </div>
              <div class="flex flex-col justify-center overflow-hidden pr-8">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >Инженер</span
                >
                <span
                  class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                  >{{ order.master?.name }} {{ order.master?.secondname }}</span
                >
              </div>
            </a>
          </div>

          <div v-if="order.manager_id" class="p-5 v-bbgray">
            <div class="flex items-center cursor-pointer group">
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-green-500"
              >
                <IcoPic
                  :pic="order.manager?.picture"
                  :name="order.manager?.name"
                />
              </div>
              <div class="flex flex-col justify-center overflow-hidden pr-8">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >Менеджер</span
                >
                <span
                  class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                  >{{ order.manager?.name }}
                  {{ order.manager?.secondname }}</span
                >
              </div>
            </div>
          </div>

          <div v-if="order.logist_id" class="p-5 v-bbgray">
            <div class="flex items-center cursor-pointer group">
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-green-500"
              >
                <IcoPic
                  :pic="order.logist?.picture"
                  :name="order.logist?.name"
                />
              </div>
              <div class="flex flex-col justify-center overflow-hidden pr-8">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >Логист</span
                >
                <span
                  class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                  >{{ order.logist?.name }} {{ order.logist?.secondname }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col ml-4 flex-1 mb-4">
        <div class="bg-white rounded-[4px] mt-4">
          <template v-if="order.prices">
            <div
              v-for="p in order.prices"
              :key="p"
              class="flex p-5 items-center v-bbgray justify-between"
            >
              <div class="max-w-[50%] w-full flex items-center">
                <span
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4 overflow-hidden"
                >
                  <svg class="w-5 h-5 fill-white">
                    <use xlink:href="@/assets/i.svg#nav-service"></use>
                  </svg>
                </span>
                <span class="text-base font-latobold">{{ p.price.name }}</span>
              </div>
            </div>
          </template>
        </div>

        <div class="bg-white rounded-[4px] mt-4">
          <template v-if="order.company_id">
            <template v-for="c in selectOrderCompanies" :key="c.id">
              <div
                v-if="order.company_id == c.id"
                class="flex relative group p-5 v-bbgray"
              >
                <a
                  class="flex items-center cursor-pointer group"
                  @click="showCompany(c)"
                >
                  <div
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4"
                  >
                    <IcoPic :pic="c.picture" :name="c.name" />
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      v-if="firm == 1 || firm == 3"
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >Компания
                    </span>
                    <span
                      v-if="firm == 2"
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >ФИЗ ЛИЦО
                    </span>
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                      >{{ c.name }}</span
                    >
                  </div>
                </a>
                <div
                  v-if="[1, 7].includes(order.act)"
                  class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
                >
                  <a class="group cursor-pointer" @click="delCompany()">
                    <svg
                      class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-300 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                </div>
              </div>
            </template>
          </template>

          <template v-else>
            <div class="p-5">
              <div class="flex items-center w-full">
                <p class="text-red-500 leading-none w-full max-w-[160px] mr-6">
                  Выбрать компанию
                </p>
                <div class="relative w-full">
                  <input
                    type="text"
                    class="bg-white h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                    :class="{ 'border-rose-300': !order.company_id }"
                    @click="findCompany()"
                    @input="findCompany()"
                  />
                  <div
                    v-if="companyFilteredList.length"
                    class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                  >
                    <div
                      v-for="cl in companyFilteredList"
                      :key="cl"
                      @click="pickCompany(cl)"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>{{ cl.name }}</span>
                      <span class="text-xs">{{ cl.iid }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div v-if="order.category == 1" class="bg-white rounded-[4px] mt-4">
          <template v-if="order.mhd_id">
            <template v-for="m in selectOrderContacts" :key="m.id">
              <div
                v-if="order.mhd_id == m.id"
                class="flex relative group p-5 v-bbgray"
              >
                <a
                  class="flex items-center cursor-pointer group"
                  @click="showContact(m)"
                >
                  <div
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4"
                  >
                    <IcoPic :pic="m.picture" :name="m.name" />
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      v-if="firm == 1 || firm == 3"
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >МЧД</span
                    >
                    <span
                      v-if="firm == 2"
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >Заявитель</span
                    >
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                      >{{ m.name }} {{ m.secondname }}</span
                    >
                  </div>
                </a>

                <div
                  v-if="[1, 7].includes(order.act)"
                  class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
                >
                  <a class="group cursor-pointer" @click="delMhd()">
                    <svg
                      class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-300 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                </div>
              </div>
            </template>
          </template>

          <template v-else>
            <div class="p-5">
              <div class="flex items-center w-full">
                <p
                  v-if="firm == 1 || firm == 3"
                  class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                >
                  Ответственный по МЧД
                </p>
                <p
                  v-if="firm == 2"
                  class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                >
                  Заявитель
                </p>
                <div class="relative w-full">
                  <input
                    type="text"
                    class="bg-white h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                    @click="findMhd()"
                    @input="findMhd()"
                  />
                  <div
                    v-if="mhdFilteredList.length"
                    class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                  >
                    <div
                      v-for="cl in mhdFilteredList"
                      :key="cl"
                      @click="pickMhd(cl)"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>{{ cl.name }}</span>
                      <!-- <span class="text-xs">{{ cl.iid }}</span> -->
                      <span class="flex items-center" v-if="cl.mhd == 1">
                        <svg class="w-3 h-3 fill-green-500 mr-2">
                          <use xlink:href="@/assets/i.svg#ico-ok"></use>
                        </svg>
                        МЧД
                      </span>
                      <span class="flex items-center" v-else-if="cl.act == 1">
                        <svg class="w-3 h-3 fill-green-500 mr-2">
                          <use xlink:href="@/assets/i.svg#ico-ok"></use>
                        </svg>
                        АКТИВАЦИЯ
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div v-if="order.category == 2" class="bg-white rounded-[4px] mt-4">
          <template v-if="order.client.tariff">
            <template v-for="tariff in tariffList" :key="tariff.id">
              <div
                v-if="order.client.tariff == tariff.id"
                class="flex relative group p-5 v-bbgray"
              >
                <a class="flex items-center cursor-pointer group">
                  <div
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4"
                  >
                    <IcoPic :pic="null" :name="tariff.name" />
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >Тариф</span
                    >
                    <span
                      class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                    >
                      {{ tariff.name }}
                    </span>
                  </div>
                </a>

                <!-- <div
                                    v-if="[1, 7].includes(order.act)"
                                    class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
                                >
                                    <a class="group cursor-pointer" @click="delTariff()">
                                        <svg class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-300 transition-colors duration-150">
                                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                                        </svg>
                                    </a>
                                </div> -->
              </div>
            </template>
          </template>

          <template v-else>
            <div class="p-5">
              <div class="flex items-center w-full">
                <p class="text-red-500 leading-none w-full max-w-[160px] mr-6">
                  Выбрать тариф
                </p>
                <div class="relative w-full">
                  <input
                    type="text"
                    class="bg-white h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                    :class="{ 'border-rose-300': !order.client.tariff }"
                    @click="findTariff()"
                  />
                  <div
                    v-if="tariffFilteredList && tariffFilteredList.length > 0"
                    class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                  >
                    <div
                      v-for="tariff in tariffFilteredList"
                      :key="tariff"
                      @click="pickTariff(tariff.id)"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>{{ tariff.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <template v-if="order.client?.vialon">
          <div class="bg-white rounded-[4px] mt-4 flex relative group p-5">
            <a class="flex items-center cursor-pointer group">
              <div
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4"
              >
                <IcoPic :pic="null" :name="order.client?.vialon" />
              </div>
              <div class="flex flex-col justify-center overflow-hidden pr-8">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >ВИАЛОН</span
                >
                <span
                  class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                >
                  {{ order.client?.vialon }}
                </span>
              </div>
            </a>

            <div
              class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
            >
              <a class="group cursor-pointer" @click="setVialon()">
                <svg
                  class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-300 transition-colors duration-150"
                >
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
              </a>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="bg-white rounded-[4px] mt-4 p-5">
            <div class="flex items-center w-full">
              <p class="text-red-500 leading-none w-full max-w-[160px] mr-6">
                Виалон
              </p>
              <div class="relative w-full">
                <input
                  type="text"
                  class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                  :class="{ '!border-rose-300': !vialon }"
                  v-model="vialon"
                  @change="setVialon({ vialon: vialon })"
                />
              </div>
            </div>
          </div>
        </template>

        <div class="bg-white rounded-[4px] mt-4">
          <template v-if="order.ts_id">
            <template v-for="t in selectOrderTs" :key="t.id">
              <div
                v-if="order.ts_id == t.id"
                class="flex relative group p-5 v-bbgray"
              >
                <a class="flex cursor-pointer group" @click="showCar(t)">
                  <div
                    class="rounded-full h-11 w-11 bg-status-1 flex items-center justify-center mr-4 uppercase"
                  >
                    <span class="text-xl text-white">{{
                      t.mark?.slice(0, 1)
                    }}</span>
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >{{ t.mark }}</span
                    >
                    <span
                      class="text-slate-600 w-max rounded-sm v-tsnum px-2 text-base v-gray font-latoblod-serif"
                      >{{ order.ts?.num }}</span
                    >
                  </div>
                </a>
              </div>
            </template>
          </template>

          <template v-else>
            <div class="p-5">
              <div class="p0 c-red">ТС НЕ ВЫБРАНО</div>
            </div>
          </template>
        </div>

        <template v-if="anketa && anketa == 1">
          <div class="p-5 bg-white rounded-[4px] mt-4">
            <div class="flex items-center">
              <div class="flex items-center cursor-pointer" @click="showDoc()">
                <span
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4"
                >
                  <svg class="fill-white w-5 h-5">
                    <use xlink:href="@/assets/i.svg#doc"></use>
                  </svg>
                </span>
                <span class="text-base font-latobold uppercase"
                  >АНКЕТА АКТИВАЦИИ</span
                >
              </div>
              <template v-if="!order.activation">
                <a
                  class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 ml-auto"
                >
                  <span
                    class="v-btn_text flex items-center"
                    @click="createActivation(), showDoc()"
                  >
                    <svg
                      class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    <span>СОЗДАТЬ</span>
                  </span>
                </a>
              </template>
              <template v-else>
                <a
                  v-if="[1, 7].includes(order.act)"
                  class="v-btn v-btn--sm bg-status-1 ml-auto"
                >
                  <span
                    class="v-btn_text flex items-center"
                    @click="checkAnketaOrder()"
                  >
                    <svg
                      class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                    >
                      <use xlink:href="@/assets/i.svg#doc"></use>
                    </svg>
                    <span>НА ПРОВЕРКУ</span>
                  </span>
                </a>
                <a v-if="order.act == 2" class="ml-auto">
                  <span class="v-btn_text flex items-center text-sky-500">
                    ЖДЕТ ПРОВЕРКИ
                  </span>
                </a>
              </template>
            </div>
          </div>
        </template>

        <template v-if="anketa && anketa == 2">
          <div class="p-5 bg-white rounded-[4px] mt-4">
            <div class="flex items-center">
              <div class="flex items-center cursor-pointer" @click="showDoc()">
                <span
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4"
                >
                  <svg class="fill-white w-5 h-5">
                    <use xlink:href="@/assets/i.svg#doc"></use>
                  </svg>
                </span>
                <span class="text-base font-latobold uppercase"
                  >АНКЕТА МОНИТОРИНГ</span
                >
              </div>
              <template v-if="!order.activation">
                <a
                  class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 ml-auto"
                >
                  <span
                    class="v-btn_text flex items-center"
                    @click="createActivation(), showDoc()"
                  >
                    <svg
                      class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    <span>СОЗДАТЬ</span>
                  </span>
                </a>
              </template>
              <template v-else>
                <a
                  v-if="[1, 7].includes(order.act)"
                  class="v-btn v-btn--sm bg-status-1 ml-auto"
                >
                  <span
                    class="v-btn_text flex items-center"
                    @click="checkAnketaOrder()"
                  >
                    <svg
                      class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                    >
                      <use xlink:href="@/assets/i.svg#doc"></use>
                    </svg>
                    <span>НА ПРОВЕРКУ</span>
                  </span>
                </a>
                <a v-if="order.act == 2" class="ml-auto">
                  <span class="v-btn_text flex items-center text-sky-500">
                    ЖДЕТ ПРОВЕРКИ
                  </span>
                </a>
              </template>
            </div>
          </div>
        </template>

        <div
          v-if="order.activation?.id"
          class="p-5 bg-white mt-4 rounded-[4px]"
        >
          <div class="relative">
            <CommentList
              :id="parseInt(order.activation.id)"
              :iid="null"
              type="activation"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="s.popup == 'doc'"
      class="w-5/6 bg-white absolute left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-l:w-11/12 max-l:max-w-11/12"
    >
      <OrderDoc />
    </div>
    <div
      v-if="s.popup"
      @click="closePopup"
      class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
    ></div>
  </template>

  <div v-else class="loader opacity1 mt30">
    <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import API from "../../services/api";

import CommentList from "../../components/CommentList.vue";
import OrderDoc from "../../components/order/OrderDoc.vue";
import IcoPic from "../../components/ui/IcoPic.vue";
import IcoStatus from "../../components/ui/IcoStatus.vue";

export default {
  name: "OrderVeiw",

  components: { CommentList, OrderDoc, IcoPic, IcoStatus },

  data() {
    return {
      companyFilteredList: [],
      mhdFilteredList: [],
      tariffList: [
        { id: 1, name: "Месяц" },
        { id: 2, name: "Месяц с техподдержкой" },
        { id: 3, name: "Год" },
        { id: 4, name: "Год с техподдержкой" },
      ],
      tariffFilteredList: [],
      timer: { time: null, color: null },
      skzi: "",
      vialon: "",
      roles: [
        "",
        "Менеджер",
        "Логист",
        "Мастерская",
        "Инженер",
        "КК",
        "Клиент",
        "Директор",
        "Admin",
        "Финансист",
        "Ассистент",
        "Бухгалтер",
      ],
      st: {
        1: "новый",
        3: "назначен",
        4: "В работе",
        5: "На проверке",
        6: "завершен",
        7: "отклонен",
        8: "проверен КК",
        10: "отложен",
        12: "ЗАПОЛНИТЬ АНКЕТУ",
        13: "ПРОВЕРИТЬ АНКЕТУ",
        18: "отменен",
        20: "архив",
      },
      ico: {
        1: "status-1",
        3: "status-3",
        4: "status-4",
        5: "status-5",
        6: "status-6",
        7: "status-1",
        8: "status-8",
        10: "status-1",
        12: "doc",
        13: "doc",
        18: "status-1",
        20: "status-20",
      },
      color: {
        1: "bbu",
        3: "bbd",
        4: "bbd",
        5: "bgn",
        6: "bgd",
        7: "brd",
        8: "bgd",
        10: "bor",
        12: "bpr",
        13: "bpr",
        18: "brd",
        20: "bgy",
      },
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "role",
      "order",
      "times",
      "selectOrderCompanies",
      "selectOrderContacts",
      "selectOrderTs",
      "selectManagers",
      "selectMasters",
    ]),

    sts() {
      return this.order.status ? parseInt(this.order.status) : 0;
    },

    anketa() {
      if (this.order.category == 1) {
        if (
          this.order &&
          this.order.company_id &&
          this.order.mhd_id &&
          this.order.ts_id
        ) {
          return 1;
        }
      }
      if (this.order.category == 2) {
        if (this.order && this.order.client.tariff) {
          return 2;
        }
      }
      return 0;
    },

    firm() {
      if (this.order.company_id && this.selectOrderCompanies.length > 0) {
        let firm = this.selectOrderCompanies.filter(
          (t) => t.id === this.order.company_id
        );
        return firm[0].firm;
      }
      return null;
    },
  },

  methods: {
    ...mapActions([
      "getOrderView",
      "setOrder",
      "checkAnketaOrder",
      "setSettings",
      "closePopup",
      "createActivation",
      "setCompany",
      "setContact",
      "setCar",
      "setVialon",
    ]),

    findCompany() {
      this.companyFilteredList = this.selectOrderCompanies;
    },

    pickCompany(item) {
      this.order.company_id = item.id;
      let formData = new FormData();
      formData.append("id", this.order.id);
      formData.append("client_id", this.order.client_id);
      formData.append("company_id", item.id);
      API.POST("order/change-company", formData);
      this.companyFilteredList = "";
    },

    showCompany(company) {
      this.setCompany(company);
      console.log(this.s);
      this.s.popup = "edit";
      this.s.back = "/order/view";
      this.setSettings(this.s);
      this.$router.push("/client/company");
    },

    delCompany() {
      this.order.company_id = null;
      this.order.activation = null;
      let formData = new FormData();
      formData.append("id", this.order.id);
      formData.append("company_id", 0);
      API.POST("order/change-company", formData).then((res) => {
        this.setOrder(res.data.order);
      });
    },

    findMhd() {
      this.mhdFilteredList = this.selectOrderContacts;
    },

    pickMhd(item) {
      this.order.mhd_id = item.id;
      let formData = new FormData();
      formData.append("id", this.order.id);
      formData.append("mhd_id", item.id);
      API.POST("order/change-mhd", formData);
      this.mhdFilteredList = "";
    },

    findTariff() {
      this.tariffFilteredList = this.tariffList;
    },

    pickTariff(id) {
      this.order.client.tariff = id;
      let formData = new FormData();
      formData.append("id", this.order.client_id);
      formData.append("tariff", id);
      API.POST("client/change-tariff", formData);
      this.tariffFilteredList = "";
    },

    // delTariff() {
    //     this.order.client.tariff = null;
    //     this.order.activation = null;

    //     let formData = new FormData();
    //     formData.append("id", this.order.client_id);
    //     formData.append("tariff", 0);
    //     API.POST("order/change-tariff", formData).then((res) => {
    //         this.setOrder(res.data.order);
    //     });
    // },

    showContact(contact) {
      this.setContact(contact);
      console.log(this.s);
      this.s.popup = "edit";
      this.s.back = "/order/view";
      this.setSettings(this.s);
      this.$router.push("/client/contact");
    },

    delMhd() {
      this.order.mhd_id = null;
      this.order.activation = null;

      let formData = new FormData();
      formData.append("id", this.order.id);
      formData.append("mhd_id", 0);
      API.POST("order/change-mhd", formData).then((res) => {
        this.setOrder(res.data.order);
      });
    },

    showCar(car) {
      this.setCar(car);
      this.s.popup = "edit";
      this.s.back = "/order/view";
      this.setSettings(this.s);
      this.$router.push("/client/car");
    },

    // выбрана ли услуга с активацией
    Activate() {
      this.skzi = 0;
      if (this.order.prices) {
        let pid = this.order.prices.map((p) => p.price_id);
        let price = this.selectPrices.filter(
          (p) => pid.includes(p.id) && p.act == 1
        );
        if (price[0]) {
          this.skzi = 1;
        }
      }
    },

    showDoc() {
      if (this.order.activation) {
        this.s.popup = "doc";
        this.setSettings(this.s);
      }
      console.log("showDoc", this.order.activation);
    },
  },

  mounted() {
    this.getOrderView();
    window.DEBUG_ORDER_VIEW = this;
  },
};
</script>
