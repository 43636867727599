<template>
  <div class="flex-wrap-report my-5">
    <!-- <input class="date-report" type="date" v-model="dateFrom"/>
            <input class="date-report" type="date" /> -->
    <input type="month" class="month-report-top mr-5" v-model="dateTop" />
    <div
      v-if="forming && loadedStages"
      class="orders-tabs px-5 bg-white rounded-lg flex flex-wrap justify-around items-center"
    >
      <a
        :class="{ checkedTab: tab == 'ReportClosedOrders' }"
        class="link py-3 px-4 uppercase"
        @click="tab = 'ReportClosedOrders'"
        >Закрытые заказы</a
      >
      <a
        :class="{ checkedTab: tab == 'ReportOpenedOrders' }"
        class="link py-3 px-4 uppercase"
        @click="tab = 'ReportOpenedOrders'"
        >Открытые заказы</a
      >
    </div>
    <select
      v-if="false"
      class="h-9 px-3 ml-auto bg-slate-50 w-1/6"
      @change="changeUser"
      v-model="worker"
    >
      <option value="0" disabled>Сотруник</option>
      <option :value="i" v-for="(w, i) in WorkerList" :key="w">
        {{ w.secondname }} {{ w.name }}
      </option>
    </select>
  </div>

  <div class="my-5">
    <button @click="formDatShit" class="button-form-report">
      Сформировать
    </button>
  </div>

  <div v-if="forming && !loadedStages" class="left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>

  <div v-if="forming && loadedStages" class="">
    <ReportOpenedOrders
      v-if="tab == 'ReportOpenedOrders'"
      :stages="openData.stages"
      :planes="planData"
      :grouped="openData.grouped"
    />
    <ReportClosedOrders
      v-if="tab == 'ReportClosedOrders'"
      :stages="closeData.stages"
      :planes="planData"
      :grouped="closeData.grouped"
    />
  </div>

  <div
    v-if="forming && loadedStages && tab == 'ReportClosedOrders'"
    class="flex px-5 pt-4"
  >
    <input class="date-report" type="month" v-model="dateFrom" />
    <input class="date-report" type="month" v-model="dateTo" />

    <button
      :disabled="dateFrom == null || dateTo == null"
      @click="formDatChart"
      class="button-form-report"
    >
      Сформировать график
    </button>
  </div>

  <div
    v-if="tab == 'ReportClosedOrders' && formingChart && !loadedChart"
    class="left-0 flex-cc w-full"
  >
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
  <div
    class="wrap-chart my-5 pb-5 px-5"
    v-if="tab == 'ReportClosedOrders' && formingChart && loadedChart"
  >
    <div v-if="chartData" class="chart-lol pt-5">
      <p>динамика длины сделки</p>
      <Chart class="" :data="chartData?.chart || []" />
    </div>
    <div v-else>
      <h2 style="text-align: center; font-size: 25px; font-weight: 700">
        Нет данных для показа
      </h2>
    </div>
  </div>
</template>

<script>
import API from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import ReportOpenedOrders from "@/pages/ReportOpenedOrders.vue";
import ReportClosedOrders from "@/pages/ReportClosedOrders.vue";
import Chart from "@/components/report/Chart.vue";

const randomArray = (size) =>
  Array(size)
    .fill(null)
    .map(() => 50000 + Math.ceil(Math.random() * 7000));

export default {
  name: "ReportLength",
  components: {
    ReportOpenedOrders,
    ReportClosedOrders,
    Chart,
  },
  data() {
    return {
      forming: false,
      loadedStages: false,

      formingChart: false,
      loadedChart: false,

      dateFrom: null,
      dateTo: null,
      dateTop: null,
      tab: "ReportClosedOrders",
      worker: "0",
      stages: {
        funnel: 8.11,
        lastFunnel: 6.13,
        mintrans: 0.9,
        postponed: 0.9,
        appointed: 3.5,
        working: 1,
        completed: 0.8,
      },
      planes: {
        funnel: 7,
        mintrans: 1,
        postponed: 1,
        appointed: 3,
        working: 1,
        completed: 1,
      },
      grouped: {
        excess: randomArray(20),
        checked: randomArray(30),
        canseled: randomArray(40),

        mintrans: randomArray(10),
        postponed: randomArray(5),
        appointed: randomArray(5),
        working: randomArray(3),
        completed: randomArray(10),
        opened: randomArray(70),
        postponedList: randomArray(20),
        empty: randomArray(10),
        urgently: randomArray(10),
        manager: randomArray(3),
        client: randomArray(3),
      },
      loaded: null,
      chartData: null,
    };
  },
  computed: {
    ...mapGetters(["WorkerList", "statisticShow", "statOrdersGraf"]),
    rnd() {
      var now = new Date();
      var out = [];
      for (var i = 0; i < 25; i++) {
        out.unshift({
          date: +now,
          fact: Math.random() * 10 - 5,
          plan: 3,
        });
        now.setMonth(now.getMonth() - 1);
      }
      return out;
    },
    closeData() {
      return {
        stages: {
          funnel: this.loaded?.stages_close?.funnel_length || 0,
          lastFunnel: this.loaded?.stages_close?.funnel_length_previous || 0,
          mintrans: this.loaded?.stages_close?.mean_mintrance || 0,
          postponed: this.loaded?.stages_close?.mean_cancel || 0,
          appointed: this.loaded?.stages_close?.mean_assigned || 0,
          working: this.loaded?.stages_close?.mean_in_work || 0,
          completed: this.loaded?.stages_close?.mean_done || 0,
          all_penalty_count: this.loaded?.stages_close?.all_penalty_count || 0,
          qq_check_count: this.loaded?.stages_close?.qq_check_count || 0,
          canceled_count: this.loaded?.stages_close?.canceled_count || 0,
        },
        grouped: {
          excess: this.loaded?.stages_close?.all_penalty_ids || [],
          checked: [],
          canceled: this.loaded?.stages_close?.canceled_ids || [],

          mintrans:
            this.loaded?.stages_close?.penalty_mintrance_order_ids || [],
          postponed: this.loaded?.stages_close?.penalty_cancel_order_ids || [],
          appointed:
            this.loaded?.stages_close?.penalty_assigned_order_ids || [],
          working: this.loaded?.stages_close?.penalty_work_order_ids || [],
          completed: this.loaded?.stages_close?.penalty_done_order_ids || [],
          opened: [],
          postponedList: [],
          empty: [],
          urgently: [],
          manager: [],
          client: [],
        },
      };
    },
    openData() {
      return {
        stages: {
          funnel: this.loaded?.stages_open?.funnel_length || 0,
          lastFunnel: this.loaded?.stages_open?.funnel_length_previous || 0,
          mintrans: this.loaded?.stages_open?.mean_mintrance || 0,
          postponed: this.loaded?.stages_open?.mean_cancel || 0,
          appointed: this.loaded?.stages_open?.mean_assigned || 0,
          working: this.loaded?.stages_open?.mean_in_work || 0,
          completed: this.loaded?.stages_open?.mean_done || 0,
          all_open_orders_count:
            this.loaded?.stages_open?.all_open_orders_count || 0,
          canceled_all_count: this.loaded?.stages_open?.canceled_all_count || 0,
          all_penalty_count: this.loaded?.stages_open?.all_penalty_count || 0,
          canceled_all_ids: this.loaded?.stages_open?.canceled_all_ids || 0,
        },
        grouped: {
          excess: this.loaded?.stages_open?.all_penalty_ids || [],
          checked: [],
          canseled: this.loaded?.stages_open?.canceled_all_ids || [],

          mintrans: [],
          postponed: [],
          appointed: [],
          working: [],
          completed: [],
          opened: [],
          postponedList: [],
          empty: this.loaded?.stages_open?.canceled_zero_ids || [],
          urgently: this.loaded?.stages_open?.canceled_one_ids || [],
          manager: this.loaded?.stages_open?.canceled_two_ids || [],
          client: this.loaded?.stages_open?.canceled_three_ids || [],
        },
      };
    },
    planData() {
      return {
        funnel: this.loaded?.planes?.funnel_length || 0,
        mintrans: this.loaded?.planes?.mean_mintrance || 0,
        postponed: this.loaded?.planes?.mean_cancel || 0,
        appointed: this.loaded?.planes?.mean_assigned || 0,
        working: this.loaded?.planes?.mean_in_work || 0,
        completed: this.loaded?.planes?.mean_done || 0,
      };
    },
  },
  methods: {
    ...mapActions(["getWorkers", "getStatAll", "getStatShow"]),
    changeUser() {},

    async formDatChart() {
      this.formingChart = true;
      this.loadedChart = false;
      window.DEBUG_REPORT = this;
      //this.getStatShow();
      //this.getStatAll();
      this.getWorkers();
      this.chartData = (
        await API.GET("report/report-chart", {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        })
      ).data;
      this.loadedChart = true;
    },

    async formDatShit() {
      this.forming = true;
      this.loadedStages = false;
      window.DEBUG_REPORT = this;
      //this.getStatShow();
      //this.getStatAll();
      this.getWorkers();
      this.loaded = (
        await API.GET("report/report", { date: this.dateTop })
      ).data;
      this.loadedStages = true;
    },
  },
  async mounted() {
    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth();
    // this.dateTop = currentYear;
    currentMonth = currentMonth + 1;
    // console.log('Текущий год - ', currentYear);
    // console.log('Текущий месяц - ', currentMonth);

    if (currentMonth < 10) {
      this.dateTop = "" + currentYear + "-0" + currentMonth;
    } else {
      this.dateTop = "" + currentYear + "-" + currentMonth;
    }
    console.log("Дата на обработку - ", this.dateTop);
  },
};
</script>

<style scoped>
.flex-wrap-report {
  width: 100%;
  display: flex;
  align-items: center;
}
.date-report {
  background: #f8fafc;
  color: #262d29;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 9px 13px;
  margin-right: 15px;
}

.orders-tabs a {
  transition: 0.4s ease;
  color: #9ca3c0;
  text-align: center;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.orders-tabs a:first-child {
  margin-right: 10px;
}

.checkedTab {
  border-radius: 3px;
  background: #22c55e;
  color: #fff !important;
}
.button-form-report {
  border-radius: 3px;
  background: #22c55e;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 12px 88px;
}
.wrap-chart {
  width: 100%;
}
.chart-lol {
  border-radius: 5px;
  background: #f8fafc;
  box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
}
.chart-lol p {
  color: #262d29;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.month-report-top {
  padding: 10px 15px !important;
  border-radius: 10px;
}
</style>
