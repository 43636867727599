<template>
    <div class="my-4">
        <div class="my-4 bg-white rounded-lg flex flex-wrap justify-evenly items-center">
            <button class="link p-4 uppercase font-bold" @click="setStatShow('main')" :class="{ 'text-status-5': statisticShow === 'main' }">
                Главная
            </button>
            <button class="link p-4 uppercase font-bold" @click="setStatShow('orders')" :class="{ 'text-status-5': statisticShow === 'orders' }">
                Заказы
            </button>
            <button class="link p-4 uppercase font-bold" @click="setStatShow('services')" :class="{ 'text-status-5': statisticShow === 'services' }">
                Услуги
            </button>
            <button class="link p-4 uppercase font-bold" @click="setStatShow('salaries')" :class="{ 'text-status-5': statisticShow === 'salaries' }">
                Зарплаты
            </button>
            <button class="link p-4 uppercase font-bold" @click="setStatShow('loading')" :class="{ 'text-status-5': statisticShow === 'loading' }">
                Загрузка
            </button>
            <button class="link p-4 uppercase font-bold" @click="setStatShow('quality')" :class="{ 'text-status-5': statisticShow === 'quality' }">
                Контроль Качества
            </button>
            <button
                class="link p-4 uppercase font-bold"
                @click="setStatShow('standards')"
                :class="{ 'text-status-5': statisticShow === 'standards' }"
            >
                Стандарты
            </button>
        </div>

        <div>
            <div class="justify-between items-center flexy pb-5 flex-wrap">
                <div class="flex mb-4 flex-1 flex-wrap justify-center">
                    <select class="my-2 px-4 h-9 w-40 mr-4 bg-slate-50" @change="pickMaster(master)" v-model="master">
                        <option value="0">Инженер</option>
                        <template v-for="m in selectMasters" :key="m">
                            <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
                        </template>
                    </select>

                    <input
                        class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
                        type="date"
                        v-model="s.start"
                        @change="findStats(s)"
                    />
                    <input
                        class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
                        type="date"
                        v-model="s.end"
                        @change="findStats(s)"
                    />

                    <div class="min-w-[320px] flex justify-around items-center v-bbgray flex-1 mr-4 h-9 my-2 bg-slate-50">
                        <div
                            class="p-4 text-gray-400 uppercase text-sm leading-none cursor-pointer rounded font-latobold relative hover:text-green-500 transition-colors duration-150"
                            @click="pickPrevDate()"
                        >
                            {{ Month(prevMonth) }} <sup>'{{ YY(prevMonth) }}</sup>
                        </div>
                        <div
                            class="bg-green-500 p-4 text-white uppercase text-sm leading-none cursor-pointer rounded font-latobold relative transition-colors duration-150"
                            :class="{
                                '!bg-green-500':
                                    s.start >= moment(this.s.month).startOf('month').format('YYYY-MM-DD') &&
                                    s.end <= moment(this.s.month).endOf('month').format('YYYY-MM-DD'),
                            }"
                        >
                            {{ Month(currentMonth) }} <sup>'{{ YY(currentMonth) }}</sup>
                        </div>
                        <div
                            class="p-4 text-gray-400 uppercase text-sm leading-none cursor-pointer rounded font-latobold relative hover:text-green-500 transition-colors duration-150"
                            @click="pickNextDate()"
                        >
                            {{ Month(nextMonth) }} <sup>'{{ YY(nextMonth) }}</sup>
                        </div>
                    </div>
                    <button class="my-2 v-btn bg-gray-500 hover:bg-gray-400 mr-4" v-if="s.clear">
                        <span class="v-btn_text" @click="Clear">Сброс</span>
                    </button>
                </div>
            </div>
        </div>

        <div id="data">
            <StatisticMain v-if="statisticShow === 'main'" />
            <StatisticOrders v-if="statisticShow === 'orders'" />
            <StatisticServices v-if="statisticShow === 'services'" />
            <StatisticSalaries v-if="statisticShow === 'salaries'" />
            <StatisticLoading v-if="statisticShow === 'loading'" />
            <StatisticQuality v-if="statisticShow === 'quality'" />
            <StatisticQualityItem v-if="statisticShow === 'qualityItem'" />
            <StatisticStandards v-if="statisticShow === 'standards'" />
            <StatisticViolations v-if="statisticShow === 'violations'" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import StatisticMain from "@/components/statistic/StatisticMain.vue";
import StatisticOrders from "@/components/statistic/StatisticOrders.vue";
import StatisticServices from "@/components/statistic/StatisticServices.vue";
import StatisticSalaries from "@/components/statistic/StatisticSalaries.vue";
import StatisticLoading from "@/components/statistic/StatisticLoading.vue";
import StatisticQuality from "@/components/statistic/StatisticQuality.vue";
import StatisticQualityItem from "@/components/statistic/StatisticQualityItem.vue";
import StatisticStandards from "@/components/statistic/StatisticStandards.vue";
import StatisticViolations from "@/components/statistic/StatisticViolations.vue";

export default {
    name: "PageStatistic",

    components: {
        StatisticMain,
        StatisticOrders,
        StatisticServices,
        StatisticSalaries,
        StatisticLoading,
        StatisticQuality,
        StatisticQualityItem,
        StatisticStandards,
        StatisticViolations,
    },

    data() {
        return {
            moment: moment,
            master: 0,
            year: "",
            mnths: ["", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
            nbrday: ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        };
    },
    computed: {
        ...mapGetters([
            "s",
            "profile",
            "statisticShow",
            "statQualityScore",
            "Calendar",
            "CalDates",
            "statOrders",
            "selectMasters",
            "getClients",
            "setClient",
            "settings",
            "statCalendar",
        ]),
        prevMonth() {
            return moment(this.s.month).subtract(1, "M").startOf("month").format("YYYY-MM-DD");
        },
        currentMonth() {
            return this.s.month;
        },
        nextMonth() {
            return moment(this.s.month).add(1, "M").startOf("month").format("YYYY-MM-DD");
        },
    },
    methods: {
        ...mapActions([
            "setSetting",
            "getStatAll",
            "getCalDay",
            "pickMonth",
            "findStats",
            "getStatShow",
            "setStatShow",
            "clearSettings",
            "resetStatFilter",
            "pickStatMonth",
        ]),

        Month(m) {
            return this.mnths[moment(m).format("M")];
        },
        YY(y) {
            return moment(y).format("YY");
        },
        Clear() {
            this.clearSettings();
            this.resetStatFilter();
        },
        startEnd() {
            this.s.start = moment(this.s.month).startOf("month").format("YYYY-MM-DD");
            this.s.end = moment(this.s.month).endOf("month").format("YYYY-MM-DD");
        },
        pickPrevDate() {
            this.pickMonth(this.prevMonth);
            this.startEnd();
            this.findStats(this.s);
        },
        pickCurrDate() {
            this.pickMonth();
            this.startEnd();
            this.findStats(this.s);
        },
        pickNextDate() {
            this.pickMonth(this.nextMonth);
            this.startEnd();
            this.findStats(this.s);
        },
        pickMaster(master) {
            this.s.master = master;
            this.findStats(this.s);
        },
    },

    mounted() {
        this.setSetting();
        this.getStatShow();
        this.getStatAll();
        // if (!this.s.start && !this.s.end && !this.s.master) {
        // 	this.pickMonth(moment().startOf('month').format('YYYY-MM-DD'));
        // 	this.startEnd();
        // 	this.findStats(this.s);
        // }
    },
};
</script>
