<template>
    <div v-if="s.popup == 'edit'" class="w-3/4 absolute z-20 bg-white rounded-lg top-4 bottom-4">
        <CarEdit @close="close" />
    </div>
    <div v-if="s.popup" @click="close" id="overlay" class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>

    <template v-if="ClientCars">
        <div class="bg-white rounded-lg mt-6">
            <div class="p-5 flex justify-between items-center v-bbgray">
                <h3 class="text-base font-latobold">
                    Транспорт <span id="countcar">{{ ClientCars.length }}</span> из {{ client.ts }}
                </h3>
            </div>

            <div class="grid grid-cols-[1fr_8rem_8rem_8rem_12rem_2rem_2rem] px-5 py-2 v-bbgray">
                <p class="text-gray-400 uppercase text-[11px]">МАРКА</p>
                <p class="text-gray-400 uppercase text-[11px] text-center">ТАХОГРАФ</p>
                <p class="text-gray-400 uppercase text-[11px] text-center">МОНИТОР.</p>
                <p class="text-gray-400 uppercase text-[11px] text-center">ПРОПУСК</p>
            </div>

            <div v-for="item in ClientCars" :key="item" class="px-5 py-2 grid grid-cols-[1fr_8rem_8rem_8rem_12rem_2rem_2rem] v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative group">
                <div class="cell name" @click="editCar(item), (this.s.popup = 'edit')">
                    <div class="flex items-center status-{{ item.status }}">
                        <span class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-lg text-white uppercase">
                            {{ item.mark?.slice(0, 1) }}
                        </span>
                        <div class="flex flex-col">
                            <span class="text-gray-400 uppercase font-latobold mb-2 text-[11px]">{{ item.mark }}</span>
                            <div class="v-gray font-latoblod-serif">
                                <span class="text-slate-600 w-auto rounded-sm v-tsnum px-2 py-1 text-base">{{ item.num }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center">{{ item.sell1 }}</div>
                <div class="text-center">{{ item.sell2 }}</div>
                <div class="text-center">{{ item.sell3 }}</div>
            </div>
        </div>
    </template>

    <div v-else class="flex justify-center p-5">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>

    <!-- <div v-if="s.popup == 'view'" id="popnew" class="cox100 col70 active">
        <CarView @close="close" />
    </div>
    <div v-if="s.popup == 'edit'" id="popnew" class="cox100 col70 active">
        <CarEdit @close="close" />
    </div>
    <div v-if="s.popup" @click="close" id="overlay"></div>

    <template v-if="ClientCars">
        <div class="block clm4 list mini">
            <div class="cont flx flx-sb">
                <h3 class="m0">
                    Транспорт <span id="countcar">{{ ClientCars.length }}</span>
                </h3>
            </div>

            <div class="head flx-sb">
                <p class="name">Марка</p>
                <p class="cell id tac">Тахограф</p>
                <p class="cell id tac">Монитор</p>
                <p class="cell id tac">Пропуск</p>
                <p class="id"></p>
            </div>

            <div v-for="item in ClientCars" :key="item" class="line flx-sb hvr">
                <div class="cell name" @click="showCar(item), this.s.popup == 'view'">
                    <div class="preview status-{{ item.status }}">
                        <span class="avatardel">{{ item.mark?.slice(0, 1) }}</span>
                        <div>
                            <sup>{{ item.mark }}</sup> <span class="tsnum">{{ item.num }}</span>
                        </div>
                    </div>
                </div>
                <div class="cell id tac">{{ item.sell1 }}</div>
                <div class="cell id tac">{{ item.sell2 }}</div>
                <div class="cell id tac">{{ item.sell3 }}</div>

                <div class="flx flx-end cell id hov">
                    <a class="flx mr15" @click="editCar(item), this.s.popup == 'edit'">
                        <svg class="f-gray"><use xlink:href="@/assets/i.svg#ico-edit"></use></svg>
                    </a>
                </div>
            </div>
        </div>
    </template>

    <div v-else class="loader opacity1 mt30">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CarEdit from "../../components/client/CarEdit";

export default {
    name: "MyCar",

    components: { CarEdit },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "client", "ClientCars"]),
    },

    methods: {
        ...mapActions(["Tab", "getClientCars", "showCar", "editCar", "clearCar", "delCar", "goBack"]),

        close() {
            this.clearCar();
            this.goBack();
        },
    },

    mounted() {
        this.getClientCars();
    },
};
</script>
