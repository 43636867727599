<template>
    <div></div>
    <!-- <div class="fixed z-[100] top-0 right-0 p-2">
        <div v-if="alerts.length" :class="{ 'top-60': alerts.length < 0 }" class="transition-all duration-500 absolute top-0 right-0">
            <div
                v-for="alert in alerts"
                :key="alert"
                class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 my-4"
                :class="alert.class"
            >
                <p>{{ alert.msg }}</p>
            </div>
        </div>
    </div> -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "AlertMsg",

    computed: {
        ...mapGetters(["alerts"]),
    },

    watch: {
        alerts: {
            handler() {
                // console.log("watch alerts", this.alerts);
                if (this.alerts?.length <= 0) {
                    return;
                }
                if (this.alerts[0].type == "success") {
                    this.$toast.success(this.alerts[0].msg);
                }
                if (this.alerts[0].type == "error") {
                    this.$toast.error(this.alerts[0].msg);
                }
                if (this.alerts[0].type == "warning") {
                    this.$toast.warning(this.alerts[0].msg);
                }
                if (this.alerts[0].type == "info") {
                    this.$toast.info(this.alerts[0].msg);
                }
            },
            deep: true,
        },
    },
};
</script>
