<template>
	<div class="bg-white rounded-md">
		<div class="p-4 relative v-bbgray">
			<h2 class="text-center font-bold text-lg uppercase">Нарушения</h2>
			<button
				class="absolute v-btn bg-green-500 hover:bg-green-400 v-btn_text top-1/2 -translate-y-1/2 right-4"
				@click="this.changeShowPage('standards')"
			>
				Назад
			</button>
		</div>

		<div class="p-4 flex justify-around max-m:flex-wrap max-s:flex-col max-s:space-y-4">
			<div class="flex flex-col items-center justify-center px-8">
				<p class="text-lg font-bold text-status-5">4445</p>
				<span class="uppercase text-gray-400 text-xs">Заказ</span>
			</div>

			<div class="flex flex-col items-center justify-center px-8">
				<p class="text-lg font-bold">01.06.2022</p>
				<span class="uppercase text-gray-400 text-xs">Дата</span>
			</div>

			<div class="flex flex-col items-center justify-center px-8">
				<p class="text-lg font-bold">09:00 - 09:45</p>
				<span class="uppercase text-gray-400 text-xs">Должен выполнить</span>
			</div>

			<div class="flex flex-col items-center justify-center px-8">
				<p class="text-lg font-bold text-status-7">
					<span class="text-status-7">06:54</span>
					<span> - </span>
					<span class="text-status-5">09:40</span>
				</p>
				<span class="uppercase text-gray-400 text-xs">Выполнил</span>
			</div>

			<div class="flex flex-col items-center justify-center px-8">
				<p class="text-lg font-bold">3.33</p>
				<span class="uppercase text-gray-400 text-xs">Оценка</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
	name: 'StatisticViolations',
	computed: {
		...mapGetters([
			's',
			'profile',
			'statisticShow',

			'statOrders',
			'statRating',
		]),
	},
	methods: {
		...mapMutations(['changeShowPage']),
		...mapActions([
			'getStatOrders',
			'getStatRating',
		])
	},
	mounted() {
		this.getStatOrders();
		this.getStatRating();
	}
};
</script>

<style lang="scss" scoped></style>
