<template>
  <div
    id="count"
    class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px"
  >
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(0)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TsReportCounter"
        >
          {{ TsReportCounter["count"]["zero"] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-20 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >0</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(1)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TsReportCounter"
        >
          {{ TsReportCounter["count"][1] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-20 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >1</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ TsReportCounter["realts"][1] }} из
        {{ TsReportCounter["count"][1] }}</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(10)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TsReportCounter"
        >
          {{ TsReportCounter["count"][10] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >2-10</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ TsReportCounter["realts"][10] }} из
        {{ TsReportCounter["numts"][10] }}</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(30)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TsReportCounter"
        >
          {{ TsReportCounter["count"][30] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-2 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >11-30</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ TsReportCounter["realts"][30] }} из
        {{ TsReportCounter["numts"][30] }}</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(50)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TsReportCounter"
        >
          {{ TsReportCounter["count"][50] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-5 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >31-50</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ TsReportCounter["realts"][50] }} из
        {{ TsReportCounter["numts"][50] }}</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(99)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TsReportCounter"
        >
          {{ TsReportCounter["count"][99] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >>50</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ TsReportCounter["realts"][99] }} из
        {{ TsReportCounter["numts"][99] }}</span
      >
    </div>
  </div>

  <div class="pb-4 w-full flex flex-wrap items-center">
    <h2
      class="mr-15px min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4"
    >
      <span class="w-full text-lg leading-none text-teal-700 block"
        >{{ this.CarList?.length }}
      </span>
      {{ TsReportCounter["monitorts"] }} из {{ TsReportCounter["allts"] }}
    </h2>

    <div class="flex-2_0 mr-15px w-1/6 my-4">
      <input
        type="text"
        class="h-9 w-full px-3 py-2 text-sm bg-slate-50"
        placeholder="Название"
        v-model="s.findme"
        @input="findCar(s)"
      />
    </div>

    <!-- <input class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" type="date" v-model="s.start" @change="findCar(s)" /> -->

    <!-- <input class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" type="date" v-model="s.end" @change="findCar(s)" /> -->

    <select
      class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4"
      @change="findCar(s)"
      v-model="s.category"
    >
      <option value="" disabled="true" selected="true">Выберите тариф</option>
      <option value="0">Тариф</option>
      <option value="none">Без тарифа</option>
      <option :value="1">месяц</option>
      <option :value="2">месяц+</option>
      <option :value="3">год</option>
      <option :value="4">год+</option>
    </select>

    <div class="flex py-4">
      <button class="v-btn bg-gray-500 hover:bg-gray-400 mr-4" v-if="s.clear">
        <span class="v-btn_text" @click="Clear">Сброс</span>
      </button>
    </div>
  </div>
  <select v-model="itemsInPage" style="padding: 7px">
    <option value="10">10</option>
    <option value="20">20</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <Paginator :count="pageCount" v-model="page" />

  <div
    v-if="CarList?.length > 0 && showReportCars"
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <div class="relative h-10 min-w-full">
      <div
        class="grid grid-cols-8 gap-2 items-center h-10 v-bbgray px-4 uppercase absolute min-w-full"
      >
        <div class="font-latobold text-gray-400 text-[11px]">№</div>
        <div class="font-latobold text-gray-400 text-[11px] col-span-4">
          Клиент
        </div>
        <div class="font-latobold text-gray-400 text-[11px]">Кол-во</div>
        <div class="font-latobold text-gray-400 text-[11px]">Виалон</div>
        <div class="font-latobold text-gray-400 text-[11px]">Тариф</div>
      </div>
    </div>
    <template v-for="item in PaginatedCarList" :key="item">
      <a
        class="grid grid-cols-8 gap-2 px-4 py-2 v-bbgray bg-teal-light odd:bg-white items-center transition duration-300 cursor-pointer hover:shadow-v1 max-2xl:grid-cols-order_m max-2xl:auto-rows-[50px]"
        :class="'status-' + item.status"
        @click="showClient(item)"
      >
        <div class="flex">{{ item.iid }}</div>
        <div class="flex w-full h-full items-center col-span-4">
          <span
            class="text-white w-11 h-11 bg-status-1 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
          >
            {{ item.name.slice(0, 1) }}
          </span>
          <span class="text-base font-semibold break-all max-h-10 leading-5">{{
            item.name
          }}</span>
        </div>

        <div>{{ item.ts }}</div>
        <div>{{ item.monitoringCars[0]?.vialon }}</div>
        <div>{{ trf[item.tariff] }}</div>

        <div class="min-w-[80px] v-gray font-latoblod-serif">
          <span
            v-if="item.num"
            class="block text-center text-slate-600 w-full min-w-full rounded-sm v-tsnum px-2 py-1 text-xs uppercase"
          >
            {{ item.num }}
          </span>
        </div>
      </a>
    </template>
  </div>
  <div v-else-if="s.loader" class="absolute top-60 left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
  <div
    v-else
    class="absolute top-60 left-0 flex-cc w-full text-slate-300 text-2xl text-center"
  >
    Нет данных
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Paginator from "@/components/ui/Paginator";

export default {
  name: "CarReport",
  components: { Paginator },
  data() {
    return {
      showReportCars: false,
      moment: moment,
      trf: {
        1: "месяц",
        2: "месяц+",
        3: "год",
        4: "год+",
      },
      page: 0,
      itemsInPage: 10,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "CarList",
      "TsReportCounter",
      "selectManagers",
    ]),
    pageCount() {
      return Math.ceil((this.CarList?.length ?? 0) / this.itemsInPage);
    },
    PaginatedCarList() {
      return this.CarList.slice(
        this.page * this.itemsInPage,
        (this.page + 1) * this.itemsInPage
      );
    },
  },

  methods: {
    ...mapActions([
      "getCarsReport",
      "findCar",
      "statusReportCar",
      "fillCars",
      "find",
      "setSettings",
      "clearSettings",
      "showClient",
    ]),

    filterStatus(status) {
      this.s.status = status;
      this.setSettings(this.s);
      this.statusReportCar(this.s);
    },

    cause(cause) {
      this.s.pay = cause;
      this.setSettings(this.s);
      this.findCar(this.s);
    },

    Clear() {
      console.log("Clear");
      this.clearSettings();
      this.fillCars();
    },
  },

  async mounted() {
    this.clearSettings();
    await this.getCarsReport();
    this.showReportCars = true;
  },
};
</script>
