<template>
  <div class="pb-4 w-full flex flex-wrap items-center">
    <h2
      class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4"
    >
      <span class="w-full text-lg leading-none text-teal-700">
        {{ this.priceCategories?.length }}
      </span>
    </h2>
    <template v-if="['Studio', 'Hosd', 'Director', 'Finance'].includes(role)">
      <div class="flex">
        <button
          class="v-btn bg-teal-700 hover:bg-teal-600 transition-colors duration-150 mr-2 uppercase text-white font-latobold"
        >
          <router-link to="/price">Услуги</router-link>
        </button>
        <button
          class="v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150"
          @click="addPriceCategory(), (s.popup = true)"
        >
          <svg class="fill-white w-4 h-4 mr-3 rotate-45">
            <use xlink:href="@/assets/i.svg#ico-no"></use>
          </svg>
          <span class="v-btn_text">Создать</span>
        </button>
      </div>
    </template>
  </div>

  <div
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <div class="relative h-10 min-w-full">
      <div
        class="grid grid-cols-category-table items-center h-10 v-bbgray px-4 uppercase absolute min-w-full"
      >
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
          name="priceCategories.id"
        >
          №
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
          name="priceCategories.name"
        >
          Название
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
          name="priceCategories.parent_id"
        >
          Родительская
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
          name="priceCategories.who"
        >
          Кто создал
        </div>
      </div>
    </div>

    <div id="list" v-if="priceCategories?.length > 0">
      <template v-for="item in priceCategories" :key="item">
        <div
          :class="{
            'bg-teal-light odd:bg-white': item.del == 0,
            'bg-red-400': item.del == 1,
          }"
          class="grid grid-cols-category-table v-bbgray p-4 items-center transition duration-300 hover:shadow-v1 cursor-pointer group max-2xl:grid-cols-all-mobile max-2xl:auto-rows-[50px]"
          @click="setPriceCategory(item), (s.popup = true)"
        >
          <div class="font-latobold cell id">{{ item.id }}</div>

          <a
            :class="{
              'group-hover:text-green-500': item.del == 0,
              'group-hover:text-black': item.del == 1,
            }"
            class="font-latobold text-base transition-colors duration-150"
            >{{ item.name }}</a
          >

          <div class="cell id text-left font-latobold text-base">
            {{ typeOfParent[item.parent_id - 1] }}
          </div>
          <div class="cell id text-left font-latobold text-base">
            {{ item.who }}
          </div>
        </div>
      </template>
    </div>
    <div v-else class="py-2 flex justify-center">
      <div>
        <img src="https://api.tahocrm.ru/images/loader.svg" width="44" />
      </div>
    </div>
  </div>

  <template v-if="s.popup">
    <div
      id="popnew"
      class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <a
        class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
        @click="close"
      >
        <span class="text-white mr-2">Закрыть</span>
        <svg class="fill-white w-4 h-4">
          <use xlink:href="@/assets/i.svg#ico-no"></use>
        </svg>
      </a>
      <div>
        <div class="p-5 text-center font-latobold text-lg v-bbgray">
          <h2>КАТЕГОРИЯ</h2>
        </div>
        <div class="p-5 v-bbgray">
          <div class="flex flex-col">
            <label class="text-gray-400">Название</label>
            <input
              type="text"
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              v-model="priceCategory.name"
              @change="savePriceCategory(priceCategory)"
            />
          </div>
        </div>
        <div class="p-5 v-bbgray">
          <div class="flex flex-col">
            <label class="text-gray-400">Родительская категория</label>
            <select
              v-model="priceCategory.parent_id"
              @change="savePriceCategory(priceCategory)"
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
            >
              <option disabled value="">Выберите категорию</option>
              <option value="1">Тахограф</option>
              <option value="2">Мониторинг</option>
              <option value="3">Карты</option>
              <option value="4">Пропуск</option>
              <option value="5">Обучение</option>
              <option value="6">Общая</option>
              <option value="7">Водители</option>
            </select>
          </div>
        </div>
        <div class="p-5">
          <div class="flex flex-col">
            <label class="text-gray-400">Описание</label>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              v-model="priceCategory.info"
              @change="savePriceCategory(priceCategory)"
            />
          </div>
        </div>
        <a
          v-if="priceCategory.del == 0"
          class="mx-auto my-4 v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
          @click="delPriceCategory(priceCategory), (s.popup = false)"
        >
          <svg class="fill-white w-16px h-16px mr-3">
            <use xlink:href="@/assets/i.svg#ico-delete"></use>
          </svg>
          <span class="v-btn_text">УДАЛИТЬ</span>
        </a>

        <a
          v-else
          class="mx-auto my-4 v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150"
          @click="unDelPriceCategory(priceCategory), (s.popup = false)"
        >
          <svg class="fill-white w-16px h-16px mr-3">
            <use xlink:href="@/assets/i.svg#ico-delete"></use>
          </svg>
          <span class="v-btn_text">ВОССТАНОВИТЬ</span>
        </a>
      </div>
    </div>
    <div
      @click="close"
      id="overlay"
      class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
    ></div>
  </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      typeOfParent: [
        "Тахограф",
        "Мониторинг",
        "Карты",
        "Пропуск",
        "Обучение",
        "Общая",
      ],
    };
  },
  computed: {
    ...mapGetters(["s", "profile", "role", "priceCategories", "priceCategory"]),

    parentCategories() {
      return this.priceCategories.filter((item) => item.parent_id === "0");
    },
  },
  methods: {
    ...mapActions([
      "getPrices",
      "addPriceCategory",
      "savePriceCategory",
      "unDelPriceCategory",
      "setPriceCategory",
      "delPriceCategory",
    ]),

    childCategories(id) {
      return this.priceCategories.filter((item) => item.parent_id === id);
    },

    close() {
      this.s.popup = false;
    },
  },
  mounted() {
    this.getPrices();
  },
};
</script>

<style>
.grid-cols-category-table {
  grid-template-columns: 5% 65% 15% 15%;
}
</style>
