import API from "../../services/api";
import router from "@/router/router";
import moment from "moment";

export default {
    state: {
        claims: [],
        filteredClaims: [],
        countClaims: [],
        claim: {},
        claimContacts: [],
    },

    mutations: {
        setClaims: (state, claims) => {
            state.claims = claims;
            state.filteredClaims = claims;
            state.countClaims = claims;
        },

        filteredClaims: (state, claims) => {
            state.filteredClaims = claims;
        },

        setClaim: (state, claim) => {
            state.claim = claim;
        },

        setClaimContacts: (state, contacts) => {
            state.claimContacts = contacts;
        },
    },

    actions: {
        getClaims(ctx) {
            let claims = JSON.parse(localStorage.getItem("claims"));
            if (claims) {
                ctx.commit("setClaims", claims);
            }
            API.GET("claim/claims").then((res) => {
                localStorage.setItem("claims", JSON.stringify(res.data.claims));
                ctx.commit("setClaims", res.data.claims);
                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findClaim", ctx.rootState.s);
                });
            });
        },

        findClaim(ctx, s) {
            let list = ctx.state.claims;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let num = null;
                    let client = null;
                    iid = item.iid.toString().indexOf(s.findme) !== -1;
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme) !== -1;
                    }
                    if (iid || num || client) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.manager != "" && s.manager != undefined) {
                list = list.filter((item) => String(item.manager_id) == String(s.manager));
            }

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => String(item.master_id) == String(s.master));
            }

            if (s.pay != "" && s.pay != undefined) {
                list = list.filter((item) => s.pay.includes(parseInt(item.pay)));
            }

            if (s.pay_status != "" && s.pay_status != undefined) {
                list = list.filter((item) => String(item.pay_status) == String(s.pay_status));
            }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category) == String(s.category));
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.date && item.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.date && item.date <= s.end);
            }

            if (s.claim != "" && s.claim != undefined) {
                list = list.filter((item) => item.r_id > 0);
            }

            if (s.claim != "" && s.claim != undefined) {
                list = list.filter((item) => parseInt(item.status) == 12 || parseInt(item.status) == 13);
            }

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => String(item.status) == String(s.status));
            }

            if (list.length != ctx.state.claims.length) {
                s.clear = true;
            }

            ctx.state.filteredClaims = list;
            ctx.dispatch("setSettings", s);
        },

        getClaim(ctx) {
            let claim = localStorage.getItem("claim");
            if (claim) {
                claim = JSON.parse(claim);
                ctx.commit("setClaim", claim);
                API.GET("claim/one", { iid: ctx.state.claim.iid }).then((res) => {
                    ctx.commit("setClaim", res.data.claim);
                    ctx.commit("setClaimContacts", res.data.contacts);
                    localStorage.setItem("claim", JSON.stringify(res.data.claim));
                });
            }
        },

        setClaim(ctx, claim) {
            ctx.commit("setClaim", claim);
            localStorage.setItem("claim", JSON.stringify(claim));
            router.push("/claim/view");
        },

        setClaimbyID(ctx, iid) {
            localStorage.setItem("claim", JSON.stringify({ iid: iid }));
            ctx.dispatch("getClaim");
        },

        saveClaim(ctx, claim) {
            // console.log("saveClaim");
            let formData = new FormData();
            formData.append("iid", claim.iid);
            formData.append("contact_id", claim.contact_id);
            formData.append("call_type", claim.call_type);
            if (claim.call_at) {
                formData.append("call_at", moment(claim.call_at).format("YYYY-MM-DD HH:mm:ss"));
            }
            formData.append("theme", claim.theme);
            formData.append("problem_id", claim.problem_id);
            formData.append("problem", claim.problem);
            formData.append("solution", claim.solution);
            formData.append("fault", claim.fault);
            API.POST("claim/save-claim", formData);
        },

        // УСЛУГА
        setClaimDiagnos(ctx, data) {
            // let order = ctx.state.order;
            // order.prices.push({ loader: 1 });
            // ctx.dispatch("setClaim", order);

            let formData = new FormData();
            formData.append("iid", ctx.state.claim.iid);
            formData.append("price_id", data.price_id);
            formData.append("warranty", data.warranty);
            API.POST("claim/add-diagnosis", formData).then((res) => {
                ctx.dispatch("setOrder", res.data.order);
                router.push("/order/edit");
            });
        },

        setClaimSolution(ctx, data) {
            console.log("data: ", data);

            let formData = new FormData();
            formData.append("iid", ctx.state.claim.iid);
            formData.append("warranty", data.warranty);
            API.POST("claim/add-solution", formData).then((res) => {
                ctx.dispatch("setOrder", res.data.order);
                router.push("/order/edit");
            });
        },

        doneClaim(ctx) {
            console.log("doneClaim");
            let formData = new FormData();
            formData.append("iid", ctx.state.claim.iid);
            API.POST("claim/done-claim", formData).then((res) => {
                ctx.commit("setClaim", res.data.claim);
            });
        },

        delClaim(ctx) {
            let formData = new FormData();
            formData.append("iid", ctx.state.claim.iid);
            API.POST("claim/del-claim", formData);
            router.push("/claim");
        },
    },

    getters: {
        ClaimList(state) {
            return state.filteredClaims;
        },

        ClaimCounter(state) {
            let count = [];
            if (state.countClaims.length > 0) {
                count[1] = 0;
                count[3] = 0;
                count[6] = 0;
                count[8] = 0;
                count[11] = 0;
                state.countClaims.forEach((elem) => {
                    if (elem.status == 1) {
                        count[1]++;
                    }
                    if (elem.status == 3) {
                        count[3]++;
                    }
                    if (elem.status == 6) {
                        count[6]++;
                    }
                    if (elem.status == 8) {
                        count[8]++;
                    }
                    if (elem.status == 11) {
                        count[11]++;
                    } // другое правило для нарушений
                });
            }
            return count;
        },

        claim(state) {
            return state.claim;
        },

        selectClaimContacts(state) {
            return state.claimContacts;
        },
    },
};
