<template>
	<VueAwesomePaginate
		:total-items="count"
		:items-per-page="1"
		v-model="value"
		:on-click="alert"
		:max-pages-shown="5"
	/>
</template>
<script>
//import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
export default {
	name: "PaginatorView",
	props: {
		count: Number,
		modelValue:  Number,
		click: Function,
	},
	data(){
		return {
			value: 1,
		}
	},
	methods:{
		alert(x){
			this.$emit('update:modelValue', x - 1);
			if(this.click)setTimeout(this.click, 10);
		}
	},
	updated(){
		if(this.count === 0)
			return this.$emit('update:modelValue', 0);
		if(this.modelValue >= this.count)
			this.$emit('update:modelValue', this.count - 1);
		this.value = this.modelValue + 1;
	},
	mounted(){
		this.value = this.modelValue + 1;
	}
}
</script>
<style>
	.paginate-buttons{
		padding: 5px 10px;
		color: #fff;
		border-radius: 3px;
		background: rgb(15, 154, 241);
		margin: 0 2px;
	}
	.active-page{
		background: rgb(203, 203, 203);
		color: #000
	}
	.pagination-container{
		margin-bottom: 15px;
		margin-left: 15px;
	}
	
</style>