<template>
  <div class="flex w-full">
    <div
      class="bg-white flex w-fit pr-4 mt-4 relative rounded-[8px] items-center"
    >
      <div class="flex relative group p-4 v-bbgray min-w-[375px]">
        <div v-if="choosenManager.name" class="flex justify-between">
          <div class="flex items-center cursor-pointer group">
            <div
              class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
            >
              <span class="w-6 h-6">
                <IcoPic
                  :pic="choosenManager?.picture"
                  :name="choosenManager?.name"
                />
              </span>
            </div>
            <div class="flex flex-col justify-center overflow-hidden pr-8">
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                >Менеджер</span
              >
              <span
                class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                >{{ choosenManager?.name }}
                {{ choosenManager?.secondname }}</span
              >
            </div>
          </div>
          <div
            class="absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150"
          >
            <a class="group cursor-pointer" @click="choosenManager = {}">
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </a>
          </div>
        </div>

        <template v-else>
          <div class="flex items-center w-full">
            <p
              class="text-gray-400 leading-none w-full min-w-[100px] max-w-[100px] mr-6"
            >
              Менеджер
            </p>
            <div class="relative min-w-[250px]">
              <input
                type="text"
                v-model.trim="text_manager"
                class="bg-white h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                @input="findManager()"
                @click="findManager()"
              />
              <hr />
              <div
                v-if="managerList.length"
                class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
              >
                <div
                  v-for="m in managerList"
                  :key="m"
                  @click="setManager(m)"
                  class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                >
                  <span>{{ m.secondname }} {{ m.name }}</span>
                  <span class="text-xs">{{ m.uid }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <button
        v-if="choosenManager.name"
        @click="setCalM(choosenManager.uid)"
        class="button-form-report mr-2"
      >
        Заполнить 2024 год
      </button>
      <button
        v-if="choosenManager.name"
        @click="setCalM2025(choosenManager.uid)"
        class="button-form-report1"
      >
        Заполнить 2025 год
      </button>
    </div>

    <div
      class="bg-white flex w-full ml-4 px-4 mt-4 relative rounded-[8px] items-center"
    >
      <h2 class="legend-title-text mr-12">Легенда</h2>
      <div>
        <div class="flex items-center text-gray-500">
          <div class="rounded-[12px] w-5 h-5 bg-green-300 mr-1"></div>
          <p class="caption-text">- рабочий день менеджера</p>
        </div>
        <div class="flex items-center text-gray-500">
          <div class="rounded-[12px] w-5 h-5 bg-gray-200 mr-1"></div>
          <p class="caption-text">- выходной день менеджера</p>
        </div>
      </div>
      <div class="ml-8">
        <div class="flex">
          <p class="work-text mr-1">15</p>
          <p class="caption-text text-gray-500">- рабочий календарный день</p>
        </div>
        <div class="flex">
          <p class="weekend work-text mr-1">15</p>

          <p class="caption-text text-gray-500">- выходной календарный день</p>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="showCal"
    class="bg-white rounded-[8px] quartal w-full mr-4 py-3 px-4 mt-4"
  >
    <div v-for="month in calendarNew" :key="month" class="wrap-cal mb-6">
      <div class="month-wrap">
        <h3
          v-if="month[7].day_date.substr(5, 2) == '01'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Январь
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '02'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Февраль
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '03'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Март
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '04'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Апрель
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '05'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Май
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '06'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Июнь
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '07'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Июль
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '08'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Август
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '09'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Сентябрь
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '10'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Октябрь
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '11'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Ноябрь
        </h3>
        <h3
          v-if="month[7].day_date.substr(5, 2) == '12'"
          class="font-bold text-center mb-2"
        >
          {{ month[7].day_date.substr(0, 4) }} - Декабрь
        </h3>
      </div>

      <div class="calendar" style="border: 1px solid #ccc">
        <div
          :class="{ weekend: day == 'Сб' || day == 'Вс' }"
          class="text-center font-bold bg-gray-200"
          style="border: 1px solid #000"
          v-for="day in daysOfWeek"
          :key="day"
        >
          {{ day }}
        </div>
      </div>
      <div class="calendar" style="border-top: none">
        <div
          class="day"
          v-for="day in month"
          :key="day.day_date"
          @click="changeDay(choosenManager.uid, day)"
          :class="{
            'border border-gray-300': day.day_date != '-',
            'weekend px-1 hover:bg-green-300 transition-all duration-150 cursor-pointer hover:shadow-v2':
              day.weekend == 1 && day.day_date != '1111',
            'bg-green-200 px-1 hover:bg-gray-200 transition-all duration-150 cursor-pointer hover:shadow-v2':
              day.working == 1 && day.day_date != '1111',
            'bg-gray-100 px-1 hover:bg-green-300 transition-all duration-150 cursor-pointer hover:shadow-v2':
              day.working == 0 && day.day_date != '1111',
          }"
        >
          {{ day.day_date.substr(8, 2) }}<br />
        </div>
      </div>
    </div>
  </div>

  <h2 class="help-for-monke" style="text-align: center" v-if="emptyMonth">
    Календарь не заполнен<br />создайте его
  </h2>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ReportCalendar",
  data() {
    return {
      text_manager: "",
      choosenManager: {},
      managerList: [],
      daysOfWeek: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      showCal: false,
      emptyMonth: false,
      calendarNew: [],
      blankDay: {
        day_date: "1111",
        working: 0,
        weekend: 1,
        number: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["s", "profile", "selectManagers", "managerCal"]),
  },
  methods: {
    ...mapActions([
      "getCalManager",
      "setAllCalManager",
      "setAllCalManager2025",
      "changeCalManager",
    ]),

    async setCalM() {
      await this.setAllCalManager(this.choosenManager.uid);
      if (this.managerCal == null) {
        setTimeout(() => {
          this.setManager(this.choosenManager);
        }, 500);
      }
    },
    async setCalM2025() {
      await this.setAllCalManager2025(this.choosenManager.uid);
      if (this.managerCal == null) {
        setTimeout(() => {
          this.setManager(this.choosenManager);
        }, 500);
      }
    },

    async setManager(manager) {
      this.choosenManager = manager;
      await this.getCalManager(manager.uid);
      this.calendarNew = {};
      this.showCal = false;
      this.emptyMonth = false;

      let addBlank, addEnd;
      if (this.managerCal != null) {
        this.calendarNew = Object.assign([], this.managerCal.months);
        for (let i = 0; i < this.calendarNew.length; i++) {
          if (this.calendarNew[i][0].number != 1) {
            addBlank = this.calendarNew[i][0].number - 1;
            for (let j = 0; j < addBlank; j++) {
              this.calendarNew[i].unshift(this.blankDay);
            }
          }
          addEnd = 42 - this.calendarNew[i].length;
          for (let k = 0; k < addEnd; k++) {
            this.calendarNew[i].push(this.blankDay);
          }
        }
        this.showCal = true;
      } else {
        this.emptyMonth = true;
      }
    },
    changeDay(id_manager, day) {
      this.changeCalManager({
        id: id_manager,
        days: day.day_date,
      });

      if (day.working == 1) {
        day.working = 0;
      } else {
        day.working = 1;
      }
    },
    formDatCrap() {
      alert("form dat crap");
    },
    findManager() {
      console.log("findMaster", this.selectManagers);
      this.managerList = this.selectManagers.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.text_manager.toLowerCase()) !==
          -1
      );
    },
  },
};
</script>

<style scoped>
.help-for-monke {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #b5b5b5;
  font-weight: 600;
  font-size: 40px;
  line-height: normal;
  user-select: none;
}
.button-form-report {
  border-radius: 3px;
  background: #22c55e;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 16.5px;
  text-transform: uppercase;
  padding: 12px 18px;
  height: 60px;
}
.button-form-report1 {
  border-radius: 3px;
  background: #226ec5;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 16.5px;
  text-transform: uppercase;
  padding: 12px 18px;
  height: 60px;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.quartal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.day {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.weekend {
  color: rgb(243, 66, 53);
}
.month-wrap > h3 {
  font-size: 18px;
}

.legend-title-text {
  font-weight: 900;
  font-size: 24px;
}

.caption-text,
.work-text {
  line-height: 25px;
}
.caption-text {
  font-size: 16px;
  font-weight: 600;
}
.work-text {
  font-size: 20px;
  font-weight: 700;
}
</style>
