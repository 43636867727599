<template>
  <div
    v-if="orders"
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <a
      v-for="item in orders"
      :key="item"
      style="grid-template-columns: repeat(18, 1fr)"
      class="grid grid-cols-order px-4 py-2 gap-2 v-bbgray bg-teal-light odd:bg-white items-center transition duration-300 cursor-pointer hover:shadow-v1 max-2xl:gr id-cols-order_m max-2xl:auto-rows-[50px]"
      :class="'status-' + item.status"
    >
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
      >
        <input type="checkbox" :value="item.id" v-model="checked" />
      </div>
      <div class="flex w-16" @click="showOrderReport(item)">
        <template v-if="item.violate">
          <div class="w-5 h-5">
            <svg class="w-full h-full ml-2 fill-status-11">
              <use xlink:href="@/assets/i.svg#status-11"></use>
            </svg>
          </div>
        </template>
        <template v-else>
          <div class="w-5 h-5 mr-2">
            <LineIco :status="parseInt(item.status)" />
          </div>
        </template>

        <template v-if="item.r_id > 0">
          <div class="w-5 h-5">
            <svg class="w-full h-full ml-2 fill-black">
              <use xlink:href="@/assets/i.svg#status-19"></use>
            </svg>
          </div>
        </template>

        <template v-if="item.category == 1">
          <template v-if="item.act == 3">
            <div class="w-5 h-5">
              <svg class="w-full h-full ml-2 fill-status-6">
                <use xlink:href="@/assets/i.svg#status-6"></use>
              </svg>
            </div>
          </template>
          <template v-if="item.act == 4">
            <div class="w-5 h-5">
              <svg class="w-full h-full ml-2 fill-status-4">
                <use xlink:href="@/assets/i.svg#ico-mail"></use>
              </svg>
            </div>
          </template>
          <template v-if="item.act == 7">
            <div class="w-5 h-5">
              <svg class="w-full h-full ml-2 fill-status-7">
                <use xlink:href="@/assets/i.svg#status-7"></use>
              </svg>
            </div>
          </template>
          <template v-if="item.act == 8">
            <div class="w-5 h-5">
              <svg class="w-full h-full ml-2 fill-status-8">
                <use xlink:href="@/assets/i.svg#status-8"></use>
              </svg>
            </div>
          </template>
        </template>

        <template v-if="item.category == 2">
          <template v-if="item.act == 1">
            <div class="w-5 h-5">
              <svg class="w-full h-full ml-2 fill-status-1">
                <use xlink:href="@/assets/i.svg#sputnik"></use>
              </svg>
            </div>
          </template>
          <template v-if="item.act == 2">
            <div class="w-5 h-5">
              <svg class="w-full h-full ml-2 fill-status-10">
                <use xlink:href="@/assets/i.svg#sputnik"></use>
              </svg>
            </div>
          </template>
          <template v-if="item.act == 8">
            <div class="w-5 h-5">
              <svg class="w-full h-full ml-2 fill-status-8">
                <use xlink:href="@/assets/i.svg#sputnik"></use>
              </svg>
            </div>
          </template>
        </template>
      </div>

      <div class="mr-4 text-black w-10" @click="showOrderReport(item)">
        #{{ item.iid }}
      </div>

      <template v-if="['Master'].includes(role)">
        <div
          v-if="item.dates[0]?.date"
          class="flex items-center col-span-2 max-m:col-span-2"
          @click="showOrderReport(item)"
        >
          <svg class="fill-status-6 min-w-[1rem] w-4 h-4 mr-2">
            <use xlink:href="@/assets/i.svg#ico-time"></use>
          </svg>
          <div class="text-black text-sm">
            {{ moment(item.dates[0]?.date).format("DD.MM") }}
            {{ item.dates[0]?.start?.slice(0, 5) }}
          </div>
        </div>
      </template>

      <div
        v-else
        class="items-center col-span-2 max-m:col-span-2"
        @click="showOrderReport(item)"
      >
        <div
          v-if="item.dates && item.dates[0]?.date"
          class="flex items-center mb-1"
        >
          <svg class="fill-status-6 min-w-[1rem] w-4 h-4 mr-2">
            <use xlink:href="@/assets/i.svg#ico-time"></use>
          </svg>
          <div class="text-black text-sm">
            {{ moment(item.dates[0]?.date).format("DD.MM") }}
            {{ item.dates[0]?.start?.slice(0, 5) }}
          </div>
        </div>
        <div class="flex items-center ml-2">
          <svg class="fill-gray-400 w-2 h-2 mr-1 rotate-45">
            <use xlink:href="@/assets/i.svg#ico-no"></use>
          </svg>
          <div class="text-gray-400 text-xs">
            {{ moment(item.create_at).format("DD.MM.YYYY") }}
          </div>
        </div>
      </div>

      <template v-if="role == 'Client'">
        <div class="items-center col-span-4" @click="showOrderReport(item)">
          <div
            class="mr-4 flex w-full h-full items-center v-gray font-latoblod-serif"
          >
            <span
              class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
              :class="'bg-status-' + item.status"
              >{{ item.mark?.slice(0, 1) }}</span
            >
            {{ item.mark }}
            <span
              v-if="item.num"
              class="text-slate-600 w-auto rounded-sm v-tsnum ml-2 px-2 py-1 text-base"
            >
              {{ item.num }}
            </span>
          </div>
        </div>
      </template>

      <template v-else>
        <div
          class="text-black mr-4 flex w-full h-full items-center col-span-4 max-m:col-span-4"
          @click="showOrderReport(item)"
        >
          <div
            v-if="item.correct != 1"
            class="text-white w-11 h-11 mr-2 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-7"
          >
            <svg class="h-5 w-5 fill-white">
              <use xlink:href="@/assets/i.svg#status-11"></use>
            </svg>
          </div>
          <span
            v-else
            class="text-white w-11 h-11 mr-2 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-1"
          >
            {{ item.cname?.slice(0, 1) }}
          </span>

          <div
            v-if="item.status == 10"
            class="flex flex-col justify-center overflow-hidden pr-4"
          >
            <span
              class="text-[10px] leading-none"
              :class="{
                '!text-red-500': item.delay == 1,
                ' text-yellow-500': item.delay == 2,
                'text-green-500': item.delay == 3,
              }"
              >{{ delay[item.delay] }}</span
            >
            <span
              class="text-base font-semibold break-all max-h-10 leading-5"
              >{{ item.cname }}</span
            >
          </div>
          <div v-else class="flex flex-col justify-center overflow-hidden pr-4">
            <span
              class="text-base font-semibold break-all max-h-10 leading-5"
              >{{ item.cname }}</span
            >
          </div>
          <svg v-if="item.vip" class="h-5 w-5 fill-gray">
            <use xlink:href="@/assets/i.svg#ico-vip"></use>
          </svg>
        </div>
      </template>

      <div
        v-if="['Manager', 'Logist', 'Master', 'Studio', 'Hosd'].includes(role)"
        class="text-black text-xs tex-left max-h-16 px-4 col-span-2 max-m:col-span-4"
      >
        <span v-if="item.box">Бокс</span>
        <span v-else>{{ item.address }}</span>
      </div>

      <template v-if="item.s_one_write_off && item.s_one_realization">
        <div
          class="w-5 h-5"
          title="Данные 1C указаны"
          @click="showOrderReport(item)"
        >
          <svg class="w-full h-full ml-2 fill-status-5">
            <use xlink:href="@/assets/i.svg#status-6"></use>
          </svg>
        </div>
      </template>

      <template v-else>
        <div
          class="w-5 h-5"
          title="Требуется указать поля для 1C"
          @click="showOrderReport(item)"
        >
          <svg class="w-full h-full ml-2 fill-status-11">
            <use xlink:href="@/assets/i.svg#ico-no"></use>
          </svg>
        </div>
      </template>
      <template v-if="!['Manager', 'Logist', 'Studio', 'Hosd'].includes(role)">
        <div
          v-if="item.mname"
          class="font-latoblod-serif font-[16px] text-v-gray relative flex justify-start items-center col-span-2 max-m:col-span-2"
          @click="showOrderReport(item)"
        >
          <div
            class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
            :class="'bg-status-' + item.status"
            @click="showOrderReport(item)"
          >
            <IcoPic :pic="item.mpicture" :name="item.mname" />
          </div>
          <div
            class="flex flex-col justify-center overflow-hidden"
            @click="showOrderReport(item)"
          >
            <span class="text-[10px] text-gray-400 leading-none uppercase"
              >Менеджер</span
            >
            <span class="text-black overflow-hidden">{{ item.mname }}</span>
          </div>
        </div>
        <div
          v-else
          class="flex justify-start items-center col-span-2 max-m:col-span-2"
          @click="showOrderReport(item)"
        >
          <span
            class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2"
          >
            <svg class="w-4 h-4 fill-white">
              <use xlink:href="@/assets/i.svg#ico-avatar"></use>
            </svg>
          </span>
        </div>
      </template>

      <template v-if="role != 'Master'">
        <div
          v-if="item.wname"
          class="font-latoblod-serif font-[16px] flex justify-start items-center col-span-2 max-m:col-span-2"
          @click="showOrderReport(item)"
        >
          <div
            class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
            :class="'bg-status-' + item.status"
          >
            <IcoPic :pic="item.wpicture" :name="item.wname" />
          </div>
          <div class="flex flex-col justify-center overflow-hidden">
            <span class="text-[10px] text-gray-400 leading-none uppercase"
              >Инженер</span
            >
            <span class="text-black">{{ item.wname }}</span>
          </div>
        </div>
        <div
          v-else
          class="flex justify-start items-center col-span-2 max-m:col-span-2"
          @click="showOrderReport(item)"
        >
          <span
            class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2"
          >
            <svg class="w-4 h-4 fill-white">
              <use xlink:href="@/assets/i.svg#ico-avatar"></use>
            </svg>
          </span>
        </div>
      </template>

      <template v-if="role != 'Client'">
        <div
          class="min-w-[90px] v-gray font-latoblod-serif col-span-2 max-m:col-span-2"
          @click="showOrderReport(item)"
        >
          <span
            v-if="item.num"
            class="block text-center text-slate-600 w-full min-w-full rounded-sm v-tsnum px-2 py-1 text-xs uppercase"
          >
            {{ item.num }}
          </span>
        </div>
      </template>

      <div
        class="min-w-[90px] v-gray font-latoblod-serif col-span-2 max-m:col-span-2"
        @click="showOrderReport(item)"
      >
        {{ item.s_one_write_off }}
        <br />
        {{ item.s_one_realization }}
      </div>
    </a>
  </div>
  <div v-else class="flex-cc w-full text-slate-300 text-2xl text-center">
    Нет данных
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import LineIco from "@/components/ui/LineIco.vue";
export default {
  name: "ReportOrderList",
  methods: mapActions(["showOrderReport"]),
  computed: mapGetters(["role", "roles"]),
  components: {
    LineIco,
  },
  props: ["orders"],
  data() {
    return {
      moment: moment,
      delay: { 1: "СРОЧНО", 2: "МЕНЕДЖЕР", 3: "КЛИЕНТ" },
      checked: [],
    };
  },
  watch: {
    order: {
      deep: false,
      handler() {},
    },
  },
  mounted() {
    console.log("Пришло", this.orders);
  },
};
</script>
