<template>
    <template v-for="comment in comments[type + id]" :key="comment">
        <div class="mb-4 flex max-l:flex-col max-l:mb-0" :class="{ r: comment.from_uid == profile.uid }">
            <div v-if="comment.from_uid == profile.uid" class="group mt-4 max-l:mb-2" @click="del(comment)">
                <svg class="fill-gray-400 w-4 h-4 group-hover:fill-rose-500 transition-colors duration-300">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
            </div>
            <div class="bg-gray-100 flex-1 flex-col rounded-xl px-4 flex py-2 mx-4 justify-between items-start max-l:w-full max-l:mx-0 max-l:mb-2">
                <div class="flex w-full">
                    <span class="text-[10px] text-gray-500 mr-4">
                        {{ moment(comment.create_at).format("DD.MM") }}
                    <br />
                        {{ moment(comment.create_at).format("HH:mm") }}
                    </span>
                    <p class="flex-1 text-justify whitespace-pre-line">{{ comment.text }}</p>
                </div>
               
                <div class="flex mt-4 w-full overflow-y-auto">
                    <a v-for="i in (comment?.img ?? [])"
                        :key="i"
                        class="flex flex-col mr-4 text-center"
                        :href="imagePrefix + 'comment/orig/' + i.img"
                        target="_blank"
                    >
                        <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                            <img
                                v-if="i.img.includes('png') || i.img.includes('jpg') 
                                    || i.img.includes('jpeg') || i.img.includes('bmp')"
                                class="w-full h-full object-cover"
                                :src="imagePrefix + 'comment/mini/' + i.img"
                                alt=""
                            />
                            <img
                                v-else-if="i.img.includes('pdf')"
                                class="w-full h-full object-cover"
                                src="https://api.tahocrm.ru/images/pdf-file.png"
                                alt=""
                            />
                            <img
                                v-else
                                class="w-full h-full object-cover"
                                src="https://api.tahocrm.ru/images/txt-file.png"
                                alt=""
                            />
                        </span>
                    </a>
                </div>
            </div>
            <div class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-green-500 items-start max-l:self-end">
                <span v-if="comment.picture">
                    <img class="w-full h-full object-cover" :src="'https://api.tahocrm.ru/uploads/users/' + comment.picture" alt="" />
                </span>
                <span v-else class="w-full h-full flex items-center justify-center text-white text-lg font-latobold">
                    {{ comment.name?.slice(0, 1) }}
                </span>
            </div>
        </div>
    </template>

    <div>
        <textarea class="w-full px-4 py-2 bg-white v-bgray rounded-md" type="text" name="comment" v-model="text_comment"></textarea>
        
        <div class="flex justify-end  mt-2">
            <div class="flex mt-4 w-full overflow-y-auto">
                <a v-for="i in preloadedImages"
                    :key="i"
                    class="flex flex-col mr-4 text-center"
                    :href="imagePrefix + 'comment/orig/' + i.img"
                    target="_blank"
                >
                    <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                        <img
                            v-if="i.img.includes('png') || i.img.includes('jpg') 
                                || i.img.includes('jpeg') || i.img.includes('bmp')"
                            class="w-full h-full object-cover"
                            :src="imagePrefix + 'comment/mini/' + i.img"
                            alt=""
                        />
                        <img
                            v-else-if="i.img.includes('pdf')"
                            class="w-full h-full object-cover"
                            src="https://api.tahocrm.ru/images/pdf-file.png"
                            alt=""
                        />
                        <img
                            v-else
                            class="w-full h-full object-cover"
                            src="https://api.tahocrm.ru/images/txt-file.png"
                            alt=""
                        />

                    </span>
                </a>
            </div>
            <UploadImg :item="{}" :id="id" type="comment" :status="100" name="Прикрепить картинки" @upload="uploaded($event)"/>
            <button class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 h-[40px] ml-4" @click="add()">
                <span class="v-btn_text flex items-center">
                    <svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linea">
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    НАПИСАТЬ
                </span>
            </button>
        </div>
        
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UploadImg from "./ui/UploadImg.vue";
import moment from "moment";
import API from "@/services/api";

export default {
    name: "CommentList",
    components: {UploadImg},
    data() {
        return {
            imagePrefix: API.IMG,
            moment: moment,
            text_comment: "",
            preloadedImages: [],
        };
    },
    props: {
        type: String,
        id: Number,
        iid: Number,
    },

    computed: {
        ...mapGetters(["profile", "comments"]),
    },

    methods: {
        ...mapActions(["getComments", "addComment", "delComment"]),

        add() {
            console.log("add");
            let formData = new FormData();
            formData.append("type", this.type);
            formData.append("oid", this.id);
            if (this.iid) {
                formData.append("iid", this.iid);
            }
            formData.append("to", null);
            formData.append("text", this.text_comment);
            formData.append("img", this.preloadedImages.map(({id})=>id).join(' '));
            this.addComment({ formData: formData, key: this.type + this.id });
            this.text_comment = "";
        },

        del(comment) {
            console.log("del");
            let formData = new FormData();
            formData.append("id", comment.id);
            formData.append("type", this.type);
            formData.append("oid", this.id);
            if (this.iid) {
                formData.append("iid", this.iid);
            }
            formData.append("text", this.text_comment);
            this.delComment({ comment: comment, formData: formData, key: this.type + this.id });
        },
        uploaded(data){
            let picture = data?.data?.picture ?? [];
            this.preloadedImages.push(...picture);
        }
    },
    mounted() {
        this.getComments({ type: this.type, id: this.id, iid: this.iid });
        window.DEBUG_COMMENT = this;
    },
};
</script>
