<template>
  <div class="hello" ref="chartdiv"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
  name: "ChartFactMarge",
  props: {
    data: Array,
  },
  mounted() {
    let data = [];
    let pushObject = {};
    for (let i = 0; i < this.data.length; i++) {
      (pushObject = {
        category: this.data[i].date,
        value1: this.data[i].margin.plan,
        value2: this.data[i].margin.fact,
      }),
        data.push(pushObject);
    }

    let root = am5.Root.new(this.$refs.chartdiv);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    );

    chart
      .get("colors")
      .set("colors", [am5.color("#CECECE"), am5.color("#6BC18B")]);

    console.log("this.data", this.data);

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 30,
        }),
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          cellStartLocation: 0.05,
          cellEndLocation: 0.95,
        }),
        categoryField: "category",
      })
    );
    xAxis.data.setAll(data);

    // Create series
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "План",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value1",
        categoryXField: "category",
        sequencedInterpolation: true,

        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]{categoryY}: {valueY}",
        }),
      })
    );
    series1.data.setAll(data);

    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Факт",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value2",
        categoryXField: "category",
        sequencedInterpolation: true,

        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]{categoryY}: {valueY}",
        }),
      })
    );
    series2.data.setAll(data);

    series1.columns.template.setAll({
      width: am5.percent(100),
    });
    series2.columns.template.setAll({
      width: am5.percent(100),
    });

    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {}));
    legend.data.setAll(chart.series.values);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    this.root = root;
  },

  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: 500px;
}
.orders-tabs a {
  transition: 0.4s ease;
  color: #9ca3c0;
  text-align: center;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.orders-tabs a:first-child {
  margin-right: 10px;
}
</style>
