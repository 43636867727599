<template>
  <template v-if="contact">
    <a
      class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
      @click="$emit('close')"
    >
      <span class="text-white mr-2">Закрыть</span>
      <svg class="fill-white w-4 h-4">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </a>

    <div class="h-full overflow-y-auto">
      <div class="p-5 v-bbgray">
        <div class="flex items-center cursor-pointer group">
          <span
            class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg uppercase"
          >
            {{ contact.name?.slice(0, 1) }}
          </span>
          <span
            class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
            >{{ contact.secondname }} {{ contact.name }}</span
          >
        </div>
      </div>

      <div class="p-5 v-bbgray">
        <div class="flex flex-wrap">
          <template v-for="i in contact.images" :key="i">
            <div class="relative m-2 w-16">
              <IcoImg :img="i.img" :status="i.status" />
            </div>
          </template>

          <template v-for="i in contact.docs" :key="i">
            <div class="relative m-2 w-16">
              <a
                class="flex flex-col w-full text-center"
                :href="'https://api.tahocrm.ru/uploads/client/orig/' + i.file"
                download
              >
                <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                  <img
                    class="w-full h-full object-cover"
                    src="https://api.tahocrm.ru/images/pdf-file.png"
                  />
                </span>
                <p>{{ i.name }}</p>
              </a>
            </div>
          </template>
        </div>
      </div>

      <div class="p-5 v-bbgray flex flex-wrap">
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Фамилия</label>
          <h3 class="font-latobold text-base">{{ contact.secondname }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Имя</label>
          <h3 class="font-latobold text-base">{{ contact.name }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Отчество</label>
          <h3 class="font-latobold text-base">{{ contact.middlename }}</h3>
        </div>
      </div>

      <div class="p-5 v-bbgray flex flex-wrap">
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Должность</label>
          <h3 class="font-latobold text-base">{{ contact.position }}</h3>
        </div>

        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Роль</label>
          <h3 class="font-latobold text-base">{{ r[contact.role] }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <input class="mychbox" type="checkbox" v-model="contact.act" />
          <label for="warranty">Активация</label>
        </div>
      </div>

      <div class="p-5 v-bbgray flex flex-wrap">
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Телефон</label>
          <h3 class="font-latobold text-base">{{ contact.phone }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Телефон 2</label>
          <h3 class="font-latobold text-base">{{ contact.phone }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Email</label>
          <h3 class="font-latobold text-base">{{ contact.email }}</h3>
        </div>
      </div>

      <div class="p-5 v-bbgray flex flex-wrap">
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Телеграм</label>
          <h3 class="font-latobold text-base">{{ contact.telegram }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Инстаграм</label>
          <h3 class="font-latobold text-base">{{ contact.instagram }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>ВК</label>
          <h3 class="font-latobold text-base">{{ contact.vk }}</h3>
        </div>
      </div>

      <div class="p-5 v-bbgray flex flex-wrap">
        <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
          <label>Пол</label>
          <h3 class="font-latobold text-base">{{ contact.sex }}</h3>
        </div>
        <div
          v-if="contact.birthday"
          class="min-w-[160px] w-1/4 flex flex-col my-2 px-4"
        >
          <label>Дата рождения</label>
          <h3 class="font-latobold text-base">
            {{ contact.birthday.substr(8, 2) }}.{{
              contact.birthday.substr(5, 2)
            }}.{{ contact.birthday.substr(0, 4) }}
          </h3>
        </div>
        <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
          <label>СНИЛС</label>
          <h3 class="font-latobold text-base">{{ contact.snils }}</h3>
        </div>
        <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
          <label>ИНН</label>
          <h3 class="font-latobold text-base">{{ contact.inn }}</h3>
        </div>
        <div class="w-full flex flex-col my-2 px-4">
          <label>Адрес (прописка)</label>
          <h3 class="font-latobold text-base">{{ contact.address }}</h3>
        </div>
      </div>

      <div class="p-5 v-bbgray flex flex-wrap">
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Серия паспорта</label>
          <h3 class="font-latobold text-base">{{ contact.pass_s }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Номер паспорта</label>
          <h3 class="font-latobold text-base">{{ contact.pass_num }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Дата выдачи</label>
          <h3 class="font-latobold text-base">{{ contact.pass_date }}</h3>
        </div>

        <div class="clear"></div>

        <div class="min-w-[160px] w-2/3 flex flex-col my-2 px-4">
          <label>Кем выдан</label>
          <h3 class="font-latobold text-base">{{ contact.pass_who }}</h3>
        </div>
        <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
          <label>Код подразделения</label>
          <h3 class="font-latobold text-base">{{ contact.pass_code }}</h3>
        </div>

        <div class="clear"></div>

        <div class="min-w-[160px] w-2/3 flex flex-col my-2 px-4">
          <label>Место рождения</label>
          <h3 class="font-latobold text-base">{{ contact.pass_place }}</h3>
        </div>
      </div>
    </div>
  </template>

  <div v-else class="py-2 flex justify-center">
    <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "ContactView",

  emits: ["close"],

  data() {
    return {
      f: { 1: "Юр. лицо", 2: "Физ. лицо", 3: "ИП" },
      r: {
        1: "ответственный за тахограф",
        2: "ответственный за мониторинг",
        3: "ответственный за пропуск",
        4: "ответственный за транспорт (все)",
        5: "ответственный за карты",
        6: "бухгалтерия",
      },
      imgName: {
        24: "ИНН",
        25: "СНИЛС",
        26: "Паспорт основной",
        27: "Паспорт прописка",
        28: "СНИЛС",
        29: "ИНН",
        30: "МЧД",
      },
    };
  },

  computed: {
    ...mapGetters(["role", "client", "contact"]),
    ...mapState(["s", "roles", "profile", "countClients"]),
  },

  methods: {
    ...mapActions([]),

    bool() {
      this.contact.act = Boolean(this.contact.act);
      this.contact.mhd = Boolean(this.contact.mhd);
      this.contact.lk = Boolean(this.contact.lk);
    },
  },

  mounted() {
    this.bool();
  },
};
</script>
