<template>
  <template v-if="client">
    <a
      class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
      @click="$emit('close')"
    >
      <span class="text-white mr-2">Закрыть</span>
      <svg class="fill-white w-4 h-4">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </a>

    <div v-if="merge > 0" class="h-full overflow-y-auto 111">
      <div class="flex">
        <div class="w-1/2 mt-4 p-5">
          <h2 class="text-center mt-10">Данные из клиента</h2>
          <p class="text-2xl text-center">
            {{ clientMerge?.from?.client.full }}
          </p>
          <!-- <p>{{ clientMerge?.from?.client.address }}</p>
                    <p>{{ clientMerge?.from?.client.phone }}</p>
                    <p>{{ clientMerge?.from?.client.email }}</p> -->

          <div v-if="clientMerge?.from?.companies.length > 0">
            <h4 class="mt-4 text-lg font-latobold">КОМПАНИИ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
              v-for="item in clientMerge?.from?.companies"
              :key="item.id"
            >
              <div
                class="rounded-full h-6 w-6 bg-green-500 flex items-center justify-center mr-4"
              >
                <IcoPicSmall :pic="null" :name="item.name" />
              </div>
              {{ item.name }}
            </div>
          </div>
          <!-- <div v-if="clientMerge?.from?.banks.length > 0">
                        <h4 class="mt-4 text-lg font-latobold">РЕКВИЗИТЫ</h4>
                        <div
                            class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
                            v-for="item in clientMerge?.from?.banks"
                            :key="item.id"
                        >
                            {{ item.bik }} {{ item.bankname }}
                        </div>
                    </div> -->

          <div v-if="clientMerge?.from?.contacts.length > 0">
            <h4 class="mt-4 text-lg font-latobold">КОНТАКТЫ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
              v-for="item in clientMerge?.from?.contacts"
              :key="item.id"
            >
              <div
                class="rounded-full h-6 w-6 bg-green-500 flex items-center justify-center mr-4"
              >
                <IcoPicSmall :pic="null" :name="item.name" />
              </div>
              {{ item.name }} {{ item.secondname }}
            </div>
          </div>

          <div v-if="clientMerge?.from?.cars.length > 0">
            <h4 class="mt-4 text-lg font-latobold">ТС</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
              v-for="item in clientMerge?.from?.cars"
              :key="item.id"
            >
              <div
                class="rounded-full h-6 w-6 bg-green-500 flex items-center justify-center mr-4"
              >
                <IcoPicSmall :pic="null" :name="item.mark" />
              </div>
              {{ item.mark }} {{ item.num }}
            </div>
          </div>

          <div v-if="clientMerge?.from?.orders.length > 0">
            <h4 class="mt-4 text-lg font-latobold">ЗАКАЗЫ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
            >
              <div v-for="item in clientMerge?.from?.orders" :key="item.id">
                <span class="mr-2 font-latobold">№{{ item.iid }}</span>
              </div>
            </div>
          </div>

          <div v-if="clientMerge?.from?.claims.length > 0">
            <h4 class="mt-4 text-lg font-latobold">РЕКЛАМАЦИИ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
            >
              <div v-for="item in clientMerge?.from?.claims" :key="item.id">
                <span class="mr-2 font-latobold">№{{ item.iid }}</span>
              </div>
            </div>
          </div>

          <div v-if="clientMerge?.from?.tasks.length > 0">
            <h4 class="mt-4 text-lg font-latobold">ЗАДАЧИ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
            >
              <div v-for="item in clientMerge?.from?.tasks" :key="item.id">
                <span class="mr-2 font-latobold">№{{ item.iid }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/2 mt-4 p-5">
          <h2 class="text-center mt-10">Будут добавлены к этим данным</h2>
          <p class="text-2xl text-center">{{ clientMerge?.to?.client.full }}</p>
          <!-- <p>{{ clientMerge?.to?.client.address }}</p>
                    <p>{{ clientMerge?.to?.client.phone }}</p>
                    <p>{{ clientMerge?.to?.client.email }}</p> -->

          <div v-if="clientMerge?.to?.companies.length > 0">
            <h4 class="mt-4 text-lg font-latobold">КОМПАНИИ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
              v-for="item in clientMerge?.to?.companies"
              :key="item.id"
            >
              <div
                class="rounded-full h-6 w-6 bg-green-500 flex items-center justify-center mr-4"
              >
                <IcoPicSmall :pic="null" :name="item.name" />
              </div>
              {{ item.name }}
            </div>
          </div>
          <!-- <div v-if="clientMerge?.to?.banks && clientMerge?.to?.banks.length > 0">
                        <h4 class="mt-4 text-lg font-latobold">РЕКВИЗИТЫ</h4>
                        <div class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2" v-for="item in clientMerge?.to?.banks" :key="item.id">
                            {{ item.bik }} {{ item.bankname }}
                        </div>
                    </div> -->

          <div v-if="clientMerge?.to?.contacts.length > 0">
            <h4 class="mt-4 text-lg font-latobold">КОНТАКТЫ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
              v-for="item in clientMerge?.to?.contacts"
              :key="item.id"
            >
              <div
                class="rounded-full h-6 w-6 bg-green-500 flex items-center justify-center mr-4"
              >
                <IcoPicSmall :pic="null" :name="item.name" />
              </div>
              {{ item.name }} {{ item.secondname }}
            </div>
          </div>

          <div v-if="clientMerge?.to?.cars.length > 0">
            <h4 class="mt-4 text-lg font-latobold">ТС</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
              v-for="item in clientMerge?.to?.cars"
              :key="item.id"
            >
              <div
                class="rounded-full h-6 w-6 bg-green-500 flex items-center justify-center mr-4"
              >
                <IcoPicSmall :pic="null" :name="item.mark" />
              </div>
              {{ item.mark }} {{ item.num }}
            </div>
          </div>

          <div v-if="clientMerge?.to?.orders.length > 0">
            <h4 class="mt-4 text-lg font-latobold">ЗАКАЗЫ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
            >
              <div v-for="item in clientMerge?.to?.orders" :key="item.id">
                <span class="mr-2 font-latobold">№{{ item.iid }}</span>
              </div>
            </div>
          </div>

          <div v-if="clientMerge?.to?.claims.length > 0">
            <h4 class="mt-4 text-lg font-latobold">РЕКЛАМАЦИИ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
            >
              <div v-for="item in clientMerge?.to?.claims" :key="item.id">
                <span class="mr-2 font-latobold">№{{ item.iid }}</span>
              </div>
            </div>
          </div>

          <div v-if="clientMerge?.to?.tasks.length > 0">
            <h4 class="mt-4 text-lg font-latobold">ЗАДАЧИ</h4>
            <div
              class="bg-teal-light v-bbgray odd:bg-white flex items-center p-2"
            >
              <div v-for="item in clientMerge?.to?.tasks" :key="item.id">
                <span class="mr-2 font-latobold">№{{ item.iid }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="my-10 v-btn w-32 m-auto text-center bg-green-500 hover:bg-green-400"
        @click="goClientMerge(clientMerge?.from?.client?.iid)"
      >
        <span class="v-btn_text">ОБЪЕДИНИТЬ</span>
      </div>
    </div>

    <div v-else class="h-full overflow-y-auto">
      <div class="p-5 v-bbgray">
        <a class="flex items-center group cursor-pointer">
          <div
            class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg"
          >
            <IcoPic :pic="client.picture" :name="client.name" />
          </div>
          <span
            class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
            >{{ client.name }}</span
          >
        </a>
        <!-- <a class="flex items-center group cursor-pointer">
                    <div class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg">
                        {{ company.name?.slice(0, 1) }}
                    </div>
                    <div class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300">{{ company.name }}</div>
                </a> -->
        <!-- <form id="avatar" method="post" enctype="multipart/form-data">
                    <div class="form-group col25 p0">
                        <input type="hidden" name="PictureForm[picture]" value="" />
                        <input type="file" id="pictureclient" name="PictureForm[picture]" />
                        <input type="hidden" name="client" v-model="client.iid" />
                    </div>
                </form> -->
      </div>

      <div class="p-5 v-bbgray flex flex-wrap">
        <div class="w-1/3 px-4 my-2">
          <label class="flex flex-col">
            <span class="text-gray-400">Короткое Имя</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.name"
              @change="saveClient(client)"
            />
          </label>
        </div>
        <div class="w-1/3 px-4 my-2">
          <label class="flex flex-col">
            <span class="text-gray-400">Юр/Физ лицо</span>
            <select
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              v-model="client.company"
              @change="saveClient(client)"
            >
              <option value="1" :class="{ selected: client.company == 1 }">
                Юр. лицо
              </option>
              <option value="2" :class="{ selected: client.company == 2 }">
                Физ. лицо
              </option>
              <!-- <option value="3" :class="{ selected: client.company == 3 }">ИП</option> -->
            </select>
          </label>
        </div>
        <div class="w-1/3 flex flex-col px-4 my-2 justify-center">
          <input
            class="mychbox"
            type="checkbox"
            id="vip"
            v-model="client.vip"
            @change="saveClient(client)"
          />
          <label class="text-gray-400" for="vip">VIP</label>
        </div>

        <div class="w-full px-4 my-2">
          <label>
            <span class="text-gray-400">Полное наименование</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.full"
              @change="saveClient(client)"
            />
          </label>
        </div>

        <div class="w-1/3 px-4 my-2">
          <label>
            <span class="text-gray-400">Код 1С</span>
            <h3 v-if="client.code">{{ client.code }}</h3>
            <h3 v-else>---</h3>
            <!-- <input
                            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                            type="text"
                            disabled
                            v-model="client.code"
                            @change="saveClient(client)"
                        /> -->
          </label>
        </div>

        <div class="w-1/3 px-4 my-2">
          <label class="text-gray-400">Направление</label>
          <select
            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
            v-model="client.business"
            @change="saveClient(client)"
          >
            <!-- <option value="">Выбрать</option> -->
            <option value="1">Логистический бизнес (перевозки)</option>
            <option value="2">Строительный бизнес, спецтехника</option>
            <option value="3">
              Среднегабаритная перевозка (городские перевозки)
            </option>
            <option value="4">
              Собственная доставка (свой транспортный отдел)
            </option>
            <option value="5">Другое</option>
          </select>

          <input
            v-if="client.business == 5"
            class="bg-slate-50 h-9 px-3 py-2 mt-2 w-full v-bgray rounded-sm text-sm text-teal-700"
            type="text"
            placeholder="Впишите сферу бизнеса"
            v-model="client.business_dop"
            @change="saveClient(client)"
          />
        </div>
        <div class="w-1/3 px-4 my-2">
          <label>
            <span class="text-gray-400">Кол-во ТС</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.ts"
              @change="saveClient(client)"
            />
          </label>
        </div>
      </div>
      <div class="p-5 flex flex-wrap v-bbgray">
        <div class="w-1/3 px-4 my-2">
          <label>
            <span class="text-gray-400">Общий телефон</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.phone"
              @change="saveClient(client)"
            />
          </label>
        </div>

        <div class="w-1/3 px-4 my-2">
          <label>
            <span class="text-gray-400">Общий e-mail</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.email"
              @change="saveClient(client)"
            />
          </label>
        </div>

        <div class="w-1/3 px-4 my-2">
          <label>
            <span class="text-gray-400">Имя Виалон</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.vialon"
              @change="saveClient(client)"
            />
          </label>
        </div>
        <div class="clear"></div>

        <!-- <div class="w-1/3 px-4 my-2">
                    <div class="w-full">
                        <label class="">Город</label>
                        <input type="hidden" name="city_id" id="city_id" v-model="client.city_id" @change="saveClient(client)" />
                        <input type="text" class=" " name="city" placeholder="Город" v-model="client.city?.name" @change="saveClient(client)" />
                        <div id="cities_select"></div>
                    </div>
                </div> -->

        <div class="w-full px-4 my-2">
          <label>
            <span class="text-gray-400">Юридический адрес</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.address"
              @change="saveClient(client)"
            />
          </label>
        </div>
      </div>

      <div class="p-5 flex flex-wrap v-bbgray">
        <div class="w-1/3 px-4 my-2 flex flex-col">
          <label class="flex flex-col mb-4">
            <span class="text-gray-400">Адрес автопарка 1</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.address1"
              @change="saveClient(client)"
            />
          </label>
          <input
            class="mychbox"
            type="checkbox"
            id="box1"
            v-model="client.box1"
            @change="saveClient(client)"
          />
          <label for="box1">Бокс</label>
        </div>

        <div class="w-1/3 px-4 my-2">
          <label class="flex flex-col mb-4">
            <span class="text-gray-400">Адрес автопарка 2</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.address2"
              @change="saveClient(client)"
            />
          </label>
          <input
            class="mychbox"
            type="checkbox"
            id="box2"
            v-model="client.box2"
            @change="saveClient(client)"
          />
          <label for="box2">Бокс</label>
        </div>

        <div class="w-1/3 px-4 my-2">
          <label class="flex flex-col mb-4">
            <span class="text-gray-400">Адрес автопарка 3</span>
            <input
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              type="text"
              v-model="client.address3"
              @change="saveClient(client)"
            />
          </label>
          <input
            class="mychbox"
            type="checkbox"
            id="box3"
            v-model="client.box3"
            @change="saveClient(client)"
          />
          <label for="box3">Бокс</label>
        </div>
      </div>

      <div class="p-5 v-bbgray flex flex-wrap">
        <div
          class="w-1/3 px-4 my-2"
          v-if="['Director', 'Studio', 'Hosd'].includes(role)"
        >
          <label>
            <span class="text-gray-400">Ответственный менеджер</span>
            <select
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              @change="saveClient(client)"
              v-model="client.manager_id"
            >
              <option value="">Менеджер</option>
              <template v-for="m in selectManagers" :key="m">
                <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
              </template>
            </select>
          </label>
        </div>

        <div class="w-1/3 px-4 my-2">
          <label>
            <span class="text-gray-400">Условия продаж</span>
            <select
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              v-model="client.terms"
              @change="saveClient(client)"
            >
              <!-- <option value="">Выбрать</option> -->
              <option value="1">Стандарт</option>
              <option value="2">Спецусловия</option>
              <option value="3">ГосКонтракт</option>
            </select>
          </label>
        </div>

        <div class="w-1/3 px-4 my-2">
          <label>
            <span class="text-gray-400">НДС</span>
            <select
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              v-model="client.nds"
              @change="saveClient(client)"
            >
              <!-- <option value="0">Выбрать</option> -->
              <option value="1">с НДС</option>
              <option value="2">без НДС</option>
              <option value="3">НДС/без НДС</option>
            </select>
          </label>
        </div>
      </div>

      <div class="p-5 v-bbgray">
        <div class="w-full px-4 my-2">
          <label>
            <span class="text-gray-400">Способ привлечения</span>
            <select
              class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
              v-model="client.utm"
              @change="saveClient(client)"
            >
              <!-- <option value="0">Выбрать</option> -->
              <option value="1">Интернет (сайт)</option>
              <option value="2">Рекомендация</option>
              <option value="3">Соц сети</option>
              <option value="4">Авито</option>
              <option value="5">Агент</option>
              <option value="6">База потерянные клиенты</option>
              <option value="7">База Лукаша</option>
              <option value="8">Холодный обзвон</option>
              <option value="9">Рассылка</option>
              <option value="10">Первичная клиентская база</option>
              <option value="11">Звонок навипорт</option>
              <option value="12">Вторичный клиент</option>
            </select>
          </label>
        </div>
      </div>

      <div class="px-9 pt-5 pb-10">
        <p class="mb-4">
          Введите номер клиента, данные из которого нужно добавить в клиента "{{
            client.name
          }}"
        </p>
        <div class="flex">
          <input
            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
            type="text"
            placeholder="Введите ID клиента"
            v-model="from_iid"
          />
          <div
            class="ml-4 v-btn bg-green-500 hover:bg-green-400"
            @click="findForMerge(from_iid)"
          >
            <span class="v-btn_text">ОБЪЕДИНИТЬ</span>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ClientEdit",

  emits: ["close"],

  data() {
    return {
      from_iid: "",
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "selectManagers",
      "clientMerge",
    ]),

    merge() {
      if (this.clientMerge?.from?.companies?.length > 0) {
        return 1;
      }
      if (this.clientMerge?.from?.contacts?.length > 0) {
        return 1;
      }
      if (this.clientMerge?.from?.cars?.length > 0) {
        return 1;
      }
      if (this.clientMerge?.from?.orders?.length > 0) {
        return 1;
      }
      if (this.clientMerge?.from?.lks?.length > 0) {
        return 1;
      }
      if (this.clientMerge?.from?.tasks?.length > 0) {
        return 1;
      }
      return 0;
    },
  },

  methods: {
    ...mapActions([
      "saveClient",
      "findForMerge",
      "setClientMerge",
      "goClientMerge",
    ]),

    bool() {
      this.client.vip = Boolean(this.client.vip);
      this.client.monitoring = Boolean(this.client.monitoring);
      this.client.box1 = Boolean(this.client.box1);
      this.client.box2 = Boolean(this.client.box2);
      this.client.box3 = Boolean(this.client.box3);
    },
  },

  mounted() {
    this.bool();
  },

  unmounted() {
    this.setClientMerge(null);
  },
};
</script>
