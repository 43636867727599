<template>
    <div
        v-if="s.popup == 'task'"
        id="popnew"
        class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
        <TaskEdit @close="close" />
    </div>
    <div v-if="s.popup" @click="close" class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80" id="overlay"></div>

    <div id="count" class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px">
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(2)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="MarketingCounter">
                    {{ MarketingCounter[2] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Новые</span
            >
        </div>

        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(6)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#ico-ok"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="MarketingCounter">
                    {{ MarketingCounter[6] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Проверены</span
            >
        </div>
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus('vio')"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-11"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="MarketingCounter">
                    {{ MarketingCounter["vio"] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-7 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Нарушения</span
            >
        </div>
    </div>

    <div class="pb-4 w-full flex flex-wrap items-center">
        <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
            <span class="w-full text-lg leading-none text-teal-700">{{ this.MarketingList?.length }} </span>
        </h2>
        <div class="flex-2_0 mr-15px min-w-[180px] my-4">
            <input type="text" class="h-9 w-full px-3 py-2 text-sm bg-slate-50" placeholder="Название" v-model="s.findme" @input="findMarketing(s)" />
        </div>

        <select class="my-2 h-9 w-44 mr-4 bg-slate-50" @change="findMarketing(s)" v-model="s.category">
            <option disabled value="">КАТЕГОРИИ ТС</option>
            <option value="zero">0 ТС</option>
            <option value="1">1 ТС</option>
            <option value="10">2-10 ТС</option>
            <option value="30">11-30 ТС</option>
            <option value="50">31-50 ТС</option>
            <option value="99">>50 ТС</option>
        </select>

        <select class="my-2 h-9 w-44 mr-4 bg-slate-50" @change="findMarketing(s)" v-model="s.worker">
            <option value="">ОТВЕТСТВЕННЫЙ</option>
            <template v-for="m in selectWorkers" :key="m">
                <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
            </template>
        </select>

        <div class="flex py-4">
            <a v-if="s.clear" class="v-btn bg-gray-500 hover:bg-gray-400 w-max mr-4" @click="Clear">
                <span class="v-btn_text">Сброс</span>
            </a>
        </div>
    </div>
    <select v-model="itemsInPage" style="padding: 7px;">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
    <Paginator :count="pageCount" v-model="page"/>

    <div id="list" class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto" v-if="MarketingList?.length > 0">
        <template v-for="item in PaginatedMarketingList" :key="item">
            <div
                class="grid grid-cols-task-table p-3 items-center v-bbgray cursor-pointer transition duration-300 hover:shadow-v1 odd:bg-white bg-teal-light max-m:grid-cols-all-mobile max-m:auto-rows-[50px]"
                :class="{
                    'status-1': item.status == 2,
                    'status-6': item.status == 6,
                }"
                @click="showTask(item)"
            >
                <div class="">
                    <template v-if="item.violate">
                        <svg class="w-5 h-5 fill-status-7"><use xlink:href="@/assets/i.svg#status-11"></use></svg>
                    </template>
                    <template v-else>
                        <svg class="w-5 h-5 fill-status-1" v-if="item.status == 2">
                            <use xlink:href="@/assets/i.svg#status-1"></use>
                        </svg>
                        <svg class="w-5 h-5 fill-status-3" v-if="item.status == 3">
                            <use xlink:href="@/assets/i.svg#status-3"></use>
                        </svg>
                        <svg class="w-5 h-5 fill-status-6" v-if="item.status == 6">
                            <use xlink:href="@/assets/i.svg#status-8"></use>
                        </svg>
                    </template>
                </div>

                <div>
                    <template v-if="item.status == 6 && item.type == 101">
                        <svg v-if="item.lead_id > 0" class="w-5 h-5 fill-status-6"><use xlink:href="@/assets/i.svg#nav-flow"></use></svg>
                        <svg v-else class="w-5 h-5 fill-status-7"><use xlink:href="@/assets/i.svg#ico-no"></use></svg>
                    </template>
                </div>

                <div class="px-4">#{{ item.iid }}</div>

                <div>{{ moment(item.date).format("DD.MM.YYYY") }}</div>

                <div>
                    <template v-for="t in tasksTypes" :key="t">
                        <template v-if="t.id == item.type">
                            <div class="flex flex-col items-start">
                                <span class="text-[10px] text-gray-400 leading-none uppercase mb-1">{{ t.dep }}</span>
                                <span class="text-base font-latobold">{{ t.name }}</span>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="flex items-center col-span-2">
                    <div v-if="item.cname" class="flex">
                        <span
                            class="min-w-[2.75rem] w-11 h-11 rounded-full mr-3 overflow-hidden flex items-center justify-center text-white bg-status-1 text-lg"
                            :class="{ '!bg-status-7': item.violate }"
                        >
                            {{ item.ts }}
                        </span>
                        <div class="flex flex-col items-start">
                            <span class="text-[10px] text-gray-400 leading-none uppercase mb-1">КЛИЕНТ</span>
                            <span>{{ item.cname }}</span>
                        </div>
                    </div>
                </div>

                <div class="flex items-center">
                    <div
                        class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden"
                        :class="{ 'bg-status-20': item.status == 1, 'bg-status-1': item.status == 2, 'bg-status-6': item.status == 6 }"
                    >
                        <IcoPic :pic="item.upicture" :name="item.uname" />
                    </div>
                    <div class="flex flex-col">
                        <span class="text-[10px] text-gray-400 leading-none">ОТВЕТСТВЕННЫЙ</span>
                        <span class="text-base font-semibold break-all max-h-10 leading-5 whitespace-pre-wrap">{{ item.uname }}</span>
                    </div>
                </div>
            </div>
        </template>
    </div>
    <div v-else-if="s.loader" class="absolute top-60 left-0 flex-cc w-full"><img src="https://api.tahocrm.ru/images/loader.svg" /></div>
    <div v-else class="absolute top-60 left-0 flex-cc w-full text-slate-300 text-2xl text-center">Нет данных</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TaskEdit from "../components/TaskEdit";
import Paginator from "@/components/ui/Paginator";
import moment from "moment";

export default {
    name: "MarketingList",

    components: { TaskEdit, Paginator },

    data() {
        return {
            moment: moment,
            page: 0,
            itemsInPage: 10,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "MarketingList", "MarketingCounter", "selectWorkers", "tasksTypes", "tasksWorkers"]),
        pageCount(){
            return Math.ceil((this.MarketingList?.length ?? 0) / this.itemsInPage);
        },
        PaginatedMarketingList(){
            return this.MarketingList.slice(this.page * this.itemsInPage, (this.page + 1) * this.itemsInPage);
        },
    },

    methods: {
        ...mapActions([
            "getMarketings",
            "showTask",
            "setTask",
            "findMarketing",
            "statusMarketing",
            "find",
            "setSettings",
            "clearSettings",
            "closePopup",
        ]),

        filterStatus(status) {
            this.s.status = status;
            this.statusMarketing(this.s);
        },

        Clear() {
            this.clearSettings();
            this.findMarketing(this.s);
        },

        close() {
            this.setTask(null);
            this.closePopup();
        },
    },

    mounted() {
        this.getMarketings();
        window.DEBUG_MARCETING = this;
    },
};
</script>
