<template>
    <!-- <div class="bg-white rounded-md">
        <div class="p-4 relative v-bbgray">
            <h2 class="text-center font-bold text-lg uppercase">Зарплаты</h2>
        </div>

        <div
            class="p-4 flex justify-around max-s:items-center flex-wrap max-s:flex-col max-s:space-y-4 v-bbgray even:bg-slate-50"
            v-for="(item, index) in statCountSalaries"
            :key="index"
        >
            <div class="flex items-center justify-center pr-6 max-m:self-center max-s:pr-0">
                <div class="w-11 h-11 rounded-full bg-green-500 mr-2">
                    <IcoPic :pic="item?.wpicture" :name="item.wname" />
                </div>

                <div class="flex flex-col justify-center overflow-hidden">
                    <span class="text-base font-semibold w-56">{{ item.wname }} {{ item.wsecondname }}</span>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center mx-2 w-[110px]">
                <p class="text-lg font-bold text-status-2">{{ item["type_1-1"].toLocaleString() }}</p>
                <span class="uppercase text-gray-400 text-xs">В работе</span>
            </div>

            <div class="flex flex-col items-center justify-center mx-2 w-[110px]">
                <p class="text-lg font-bold text-status-5">{{ item["type_1-8"].toLocaleString() }}</p>
                <span class="uppercase text-gray-400 text-xs">Начислено</span>
            </div>

            <div class="flex flex-col items-center justify-center mx-2 w-[110px]">
                <p class="text-lg font-bold text-status-10">{{ item["type_2-3-4"].toLocaleString() }}</p>
                <span class="uppercase text-gray-400 text-xs">Бонусы</span>
            </div>

            <div class="flex flex-col items-center justify-center mx-2 w-[110px]">
                <p class="text-lg font-bold text-status-5">{{ item["type_7"].toLocaleString() }}</p>
                <span class="uppercase text-gray-400 text-xs">Вых. бонусы</span>
            </div>

            <div class="flex flex-col items-center justify-center mx-2 w-[110px]">
                <p class="text-lg font-bold text-status-7">{{ item["type_9"].toLocaleString() }}</p>
                <span class="uppercase text-gray-400 text-xs">Штраф</span>
            </div>

            <div class="flex flex-col items-center justify-center w-[110px]">
                <p class="text-lg font-bold text-status-19">{{ item["total"].toLocaleString() }}</p>
                <span class="uppercase text-gray-400 text-xs">Всего</span>
            </div>
        </div>
    </div> -->

    <div class="bg-white rounded-md">
        <div class="p-4 relative v-bbgray">
            <h2 class="text-center font-bold text-lg uppercase">Зарплаты</h2>
        </div>

        <div class="mx-4 h-[500px] w-full max-w-full" ref="newChart"></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: "StatisticSalaries",

    computed: {
        ...mapGetters(["statisticShow", "statSalaries"]),
    },

    watch: {
        statSalaries(newValue) {
            if (newValue) {
                this.root.dispose();
                this.setup(newValue);
            }
        },
    },
    methods: {
        setup(chartData) {
            let root = am5.Root.new(this.$refs.newChart);
            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    layout: root.verticalHorizontal,
                    innerRadius: am5.percent(50),
                    tooltip: am5.Tooltip.new(root, {
                        getFillFromSprite: true,
                        autoTextColor: false,
                        labelText: "[bold]{category}: {value}",
                    }),
                })
            );

            let series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    name: "Series",
                    valueField: "value",
                    categoryField: "secondname",
                    legendLabelText: "{secondname}:",
                    legendValueText: "{value.formatNumber(#)}",
                })
            );

            series.labels.template.setAll({
                fontSize: 16,
                text: "{secondname}: {value}",
            });
            series
                .get("colors")
                .set("colors", [am5.color(0xfeab1a), am5.color(0x3cc45a), am5.color(0x0288d1), am5.color(0xf44336), am5.color(0x8e24aa)]);

            if (chartData && chartData.graf) {
                series.data.setAll(chartData.graf);
            }

            let legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.percent(50),
                    x: am5.percent(50),
                    y: am5.percent(3),
                    layout: root.horizontalLayout,
                })
            );

            legend.data.setAll(series.dataItems);

            let total = 0;
            if (chartData && chartData.total) {
                total = chartData.total;
            }
            chart.children.unshift(
                am5.Label.new(root, {
                    text: total,
                    fontSize: 30,
                    fontWeight: "500",
                    textAlign: "center",
                    x: am5.percent(50),
                    y: am5.percent(50),
                    centerX: am5.percent(50),
                    centerY: am5.percent(50),
                    paddingTop: 0,
                    paddingBottom: 0,
                })
            );

            this.root = root;
        },
    },
    mounted() {
        this.setup(this.statSalaries);
    },
    beforeUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    },
};
</script>

<style lang="scss" scoped></style>
