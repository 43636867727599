<template>
    <template v-if="ClientClaims">
        <div class="bg-white rounded-lg">
            <div class="p-5 flex justify-between items-center v-bbgray">
                <h3 class="text-base font-latobold">Рекламации {{ ClientClaims?.length }}</h3>
            </div>

            <div class="grid grid-cols-4 px-5 py-2 v-bbgray">
                <p class="text-gray-400 uppercase text-[11px]">Рекламация</p>
                <p class="text-gray-400 uppercase text-[11px]">Дата</p>
                <p class="text-gray-400 uppercase text-[11px]">ТС</p>
            </div>

            <template v-for="claim in ClientClaims" :key="claim">
                <div
                    class="px-5 py-2 grid grid-cols-4 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative"
                    :id="'ord' + claim.iid"
                    :class="'line hvr status-' + claim.status"
                >
                    <div class="flex items-center" @click="setClaim(claim)">
                        <span
                            class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-lg text-white"
                            :class="'!bg-status-' + claim.status"
                        >
                            <span class="w-5 h-5">
                                <IcoStatus :status="parseInt(claim.status)" />
                            </span>
                        </span>
                        <span class="font-latobold text-base">№{{ claim.iid }}</span>
                    </div>

                    <div>
                        <p class="flex flex-col">
                            <span>{{ moment(claim.create_at).format("DD.MM.YYYY") }}</span>
                        </p>
                    </div>

                    <div class="v-gray font-latoblod-serif">
                        <span v-if="claim.num" class="text-slate-600 w-auto rounded-sm v-tsnum px-2 py-1 text-base">{{ claim.num }}</span>
                    </div>
                </div>
            </template>
        </div>
    </template>

    <div v-else class="p-5 flex justify-center">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import IcoStatus from "./ui/IcoStatus.vue";

export default {
    name: "ClientClaim",

    components: { IcoStatus },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "client", "ClientClaims"]),
    },

    methods: {
        ...mapActions(["Tab", "setClaim", "getClientTabsClaims"]),
    },

    async mounted() {
        await this.getClientTabsClaims();
    },
};
</script>
