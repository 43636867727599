<template>
  <div v-if="client && client.length > 0" class="bg-white rounded-lg">
    <div class="p-5 v-bbgray">
      <a class="flex items-center cursor-pointer group">
        <span
          class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg"
          id="picture"
        >
          <img
            class="w-full h-full object-cover"
            v-if="client.picture"
            :src="'https://api.tahocrm.ru/uploads/users/' + client.picture"
            alt=""
          />
          <p v-else>{{ client.name?.slice(0, 1) }}</p>
        </span>
        <div class="flex flex-col justify-center overflow-hidden pr-8">
          <span class="text-[10px] text-gray-400 leading-none uppercase mb-1">{{
            client.code
          }}</span>
          <span
            class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
          >
            {{ client.name }}
          </span>
        </div>
      </a>
    </div>
    <div class="p-5 v-bbgray">
      <div class="flex">
        <span class="w-2/5">Полное наименование</span>
        <span class="font-latobold">{{ client.full }}</span>
      </div>

      <div class="flex">
        <span class="w-2/5">Адрес</span>
        <span class="font-latobold"
          >{{ client.city?.name }} {{ client.address }}</span
        >
      </div>

      <div v-if="client.address1" class="flex">
        <span class="w-2/5"
          >Адрес автопарка
          <span v-if="client.box1"> с боксом</span>
          <span v-else> без бокса</span>
        </span>
        <span class="font-latobold">{{ client.address1 }}</span>
      </div>
      <div v-if="client.address2" class="flex">
        <span class="w-2/5"
          >Адрес автопарка
          <span v-if="client.box2"> с боксом</span>
          <span v-else> без бокса</span>
        </span>
        <span class="font-latobold">{{ client.address2 }}</span>
      </div>
      <div v-if="client.address3" class="flex">
        <span class="w-2/5"
          >Адрес автопарка
          <span v-if="client.box3"> с боксом</span>
          <span v-else> без бокса</span>
        </span>
        <span class="font-latobold">{{ client.address3 }}</span>
      </div>

      <div class="flex">
        <span class="w-2/5">Телефон</span>
        <span class="font-latobold">{{ client.phone }}</span>
      </div>
      <div class="flex">
        <span class="w-2/5">Email</span>
        <span class="font-latobold">{{ client.email }}</span>
      </div>
    </div>

    <div class="p-5 v-bbgray">
      <div class="flex">
        <span class="w-2/5">НДС</span>
        <span class="font-latobold">{{ n[client.nds] }}</span>
      </div>
    </div>

    <div class="p-5 v-bbgray flex-col">
      <!-- 
                <div class="flx">
                    <span class="w-2/5">Тип транспорта</span>
                    <span class="font-latobold">-</span>
                </div> 
            -->

      <div class="flex">
        <span class="w-2/5">Направление</span>
        <span class="font-latobold">{{ b[client.business] }}</span>
      </div>
      <div class="flex">
        <span class="w-2/5">Кол-во ТС</span>
        <span class="font-latobold" v-if="client.ts">{{ client.ts }}</span>
      </div>
    </div>

    <div class="p-5">
      <div class="flex">
        <span class="w-2/5">Способ привлечения</span>
        <span class="font-latobold" v-if="client.utm">{{ f[client.utm] }}</span>
      </div>
    </div>

    <!-- <div v-if="user.id == client.manager_id" class="p-5">
            <div class="flex">
                <span class="w-2/5">Личный кабинет</span>
                <span v-if="client.uid" class="font-latobold text-sky-500">СОЗДАН</span>
                <span v-else class="font-latobold text-rose-500">НЕ СОЗДАН</span>
            </div>
        </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import axios from "axios";

export default {
  name: "ClientInfo",

  data() {
    return {
      f: [
        "",
        "Интернет (сайт)",
        "Рекомендация",
        "Соц сети",
        "Авито",
        "Агент",
        "База потерянные клиенты",
        "База Лукаша",
        "Холодный обзвон",
        "Рассылка",
        "Первичная клиентская база",
        "Звонок навипорт",
        "Вторичный клиент",
      ],
      b: [
        "",
        "Логистический бизнес (перевозки)",
        "Строительный бизнес, спецтехника",
        "Среднегабаритная перевозка (городские перевозки)",
        "Собственная доставка (свой транспортный отдел)",
        "Другое",
      ],
      n: ["", "с НДС", "без НДС", "НДС/без НДС"],
      user: {},
    };
  },
  computed: { ...mapGetters(["client"]) },
  methods: {},

  mounted() {},
};
</script>
