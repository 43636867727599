<template>
    <template v-if="car">
        <a
            class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
            @click="$emit('close')"
        >
            <span class="text-white mr-2">Закрыть</span>
            <svg class="fill-white w-4 h-4">
                <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
        </a>

        <div class="h-full overflow-y-auto">
            <div class="p-5 v-bbgray">
                <div class="flex items-center">
                    <span class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg uppercase">
                        {{ car.mark?.slice(0, 1) }}
                    </span>
                    <div class="flex">
                        <span class="text-base font-latobold mr-2">{{ car.mark }}</span>
                        <span class="v-gray font-latoblod-serif">
                            <span class="text-slate-600 w-auto rounded-sm v-tsnum px-2 py-1 text-base">{{ car.num }}</span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="p-5 v-bbgray">
                <div class="flex flex-wrap">
                    <template v-for="i in car.images" :key="i">
                        <IcoImg :img="i.img" :status="i.status" />
                    </template>
                </div>
            </div>

            <div class="p-5 v-bbgray flex flex-wrap">
                <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                    <label>Марка</label>
                    <h3 class="font-latobold text-base">{{ car.mark }}</h3>
                </div>
                <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                    <label>Гос номер</label>
                    <h3 class="font-latobold text-base">{{ car.num }}</h3>
                </div>
                <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                    <label>Год выпуска</label>
                    <h3 class="font-latobold text-base">{{ car.year }}</h3>
                </div>
                <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                    <label>Vin</label>
                    <h3 class="font-latobold text-base">{{ car.win }}</h3>
                </div>
            </div>

            <div class="p-5 v-bbgray flex flex-wrap">
                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                    <sup>{{ car.warranty_at ? car.warranty_at : "-" }} </sup><br />
                    <input class="mychbox" type="checkbox" v-model="car.warranty" />
                    <label for="warranty">Гарантийное ТС</label>
                </div>
                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                    <label>Состояние ТС</label>
                    <h3 class="font-latobold text-base">
                        {{
                            car.state == 1
                                ? "Работает"
                                : car.state == 2
                                ? "В ремонте"
                                : car.state == 3
                                ? "Неисправна"
                                : car.state == 4
                                ? "Конструктивные особенности"
                                : ""
                        }}
                    </h3>
                </div>
                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                    <label>Максимальная скорость</label>
                    <h3 class="font-latobold text-base">{{ car.maxspeed }}</h3>
                </div>
            </div>

            <div class="p-5 v-bbgray flex flex-wrap">
                <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                    <label>Вид топлива</label>
                    <h3 class="font-latobold text-base">{{ car.fuel == 1 ? "Газ" : car.fuel == 2 ? "Бензин" : car.fuel == 3 ? "ДТ" : "" }}</h3>
                </div>
                <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                    <label>Кол-во баков</label>
                    <h3 class="font-latobold text-base">{{ car.tank }}</h3>
                </div>
                <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                    <label>Объем бака л</label>
                    <h3 class="font-latobold text-base">{{ car.val }}</h3>
                </div>
                <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                    <input class="mychbox" type="checkbox" disabled v-model="car.shape" />
                    <label for="shape">Нестандартная форма баков</label>
                </div>
            </div>

            <div class="p-5 v-bbgray flex flex-wrap">
                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                    <input class="mychbox" type="checkbox" disabled v-model="car.flow" />
                    <label for="flow">Наличие "перетекайки"</label>
                </div>
                <div class="min-w-[160px] w-2/3 flex flex-col my-2 px-4">
                    <input class="mychbox" type="checkbox" disabled v-model="car.block" />
                    <label for="block">Есть блокировка "перетекайки"</label>
                </div>
            </div>

            <div class="p-5 v-bbgray flex flex-wrap">
                <div class="flex w-full">
                    <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                        <sup>{{ car.garbage_at ? car.garbage_at : "-" }} </sup><br />
                        <input class="mychbox" type="checkbox" :class="{ disabled: car.garbage }" />
                        <label for="garbage">Надо чистить бак</label>
                    </div>
                    <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                        <sup>{{ car.demounting_at ? car.demounting_at : "-" }} </sup><br />
                        <input class="mychbox" type="checkbox" disabled v-model="car.demounting" />
                        <label for="demounting">Нужен демонтаж баков</label>
                    </div>
                </div>
            </div>

            <div class="p-5 flex flex-wrap">
                <div class="w-full">
                    <h2 class="font-latobold text-base mb-2">Уведомления</h2>
                    <div class="flex w-full flex-wrap">
                        <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                            <input class="mychbox" type="checkbox" disabled v-model="car.notice_speed" />
                            <label for="notice_speed">Скорость</label>
                        </div>
                        <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                            <input class="mychbox" type="checkbox" disabled v-model="car.notice_hours" />
                            <label for="notice_hours">График работы</label>
                        </div>
                        <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                            <input class="mychbox" type="checkbox" disabled v-model="car.notice_zone" />
                            <label for="notice_zone">Геозона</label>
                        </div>
                        <div class="min-w-[160px] w-1/4 flex flex-col my-2 px-4">
                            <input class="mychbox" type="checkbox" disabled v-model="car.notice_fuel" />
                            <label for="notice_fuel">Слив топлива</label>
                        </div>
                    </div>
                    <span class="mt-2">Комментарий</span>
                    <h3 class="font-latobold text-base">{{ car.notice_other }}</h3>
                </div>
            </div>
        </div>
    </template>

    <div v-else class="loader opacity1 mt30">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    name: "CarView",

    emits: ["close"],

    data() {
        return {};
    },
    computed: {
        ...mapGetters(["role", "client", "car"]),
        ...mapState(["s", "roles", "profile"]),
    },

    methods: {
        ...mapActions([]),

        bool() {
            this.car.warranty = Boolean(this.car.warranty);
            this.car.shape = Boolean(this.car.shape);
            this.car.flow = Boolean(this.car.flow);
            this.car.block = Boolean(this.car.block);
            this.car.garbage = Boolean(this.car.garbage);
            this.car.demounting = Boolean(this.car.demounting);
            this.car.notice_speed = Boolean(this.car.notice_speed);
            this.car.notice_hours = Boolean(this.car.notice_hours);
            this.car.notice_zone = Boolean(this.car.notice_zone);
            this.car.notice_fuel = Boolean(this.car.notice_fuel);
        },
    },

    mounted() {
        this.bool();
    },
};
</script>
