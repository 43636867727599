<template>
    <div class="bg-white rounded-md">
        <div class="p-4 relative v-bbgray">
            <h2 class="text-center font-bold text-lg uppercase">Контроль Качества</h2>
        </div>
        <div>
            <div>
                <div
                    class="pl-4 max-m:pr-4 py-4 flex max-m:flex-col max-m:relative transition duration-300 hover:shadow-v1 v-bbgray odd:bg-slate-50"
                    v-for="item in statQuality"
                    :key="item"
                >
                    <div class="flex flex-col items-center justify-center pr-6 max-m:self-center max-m:mb-8 max-m:mt-4 max-m:mr-24">
                        <div class="flex flex-col justify-center overflow-hidden">
                            <span class="text-xl font-semibold text-left max-m:text-center w-32">{{ item.name }} </span>
                        </div>
                    </div>

                    <div class="flex justify-around w-full max-m:justify-between max-s:flex-wrap">
                        <div class="flex flex-col items-center justify-center px-2">
                            <p class="text-lg font-bold">{{ item.count }}</p>
                            <span class="uppercase text-gray-400 text-xs">Всего</span>
                        </div>
                        <div class="flex flex-col items-center justify-center px-2">
                            <p class="text-lg font-bold">{{ item.no }}</p>
                            <span class="uppercase text-gray-400 text-xs">Нет отзыва</span>
                        </div>
                        <div class="flex flex-col items-center justify-center px-2">
                            <p class="text-lg font-bold">{{ item.yes }}</p>
                            <span class="uppercase text-gray-400 text-xs">Есть отзыв</span>
                        </div>

                        <div
                            class="flex flex-col items-center justify-center px-2 cursor-pointer group"
                            @click="getStatQualityOrders({ category: item.category, score: 'bad' }), changeShowPage('qualityItem')"
                        >
                            <p class="text-lg font-bold text-status-7">{{ item.bad }}</p>
                            <span class="uppercase text-gray-400 text-xs group-hover:text-gray-700 transition-colors duration-300">Меньше 7</span>
                        </div>

                        <div
                            class="flex flex-col items-center justify-center px-2 cursor-pointer group"
                            @click="getStatQualityOrders({ category: item.category, score: 'norm' }), changeShowPage('qualityItem')"
                        >
                            <p class="text-lg font-bold text-status-10">{{ item.norm }}</p>
                            <span class="uppercase text-gray-400 text-xs group-hover:text-gray-700 transition-colors duration-300">
                                7 &lt; &gt; 10
                            </span>
                        </div>

                        <div class="flex flex-col items-center justify-center px-2">
                            <p class="text-lg font-bold text-status-6">{{ item.good }}</p>
                            <span class="uppercase text-gray-400 text-xs">10 баллов</span>
                        </div>
                    </div>

                    <div class="h-14 overflow-hidden pl-6 pr-10 py-3 flex items-center ml-auto self-center relative max-m:absolute max-m:right-0">
                        <svg class="w-7 h-7 min-w-[1.75rem] fill-white z-2 mr-2">
                            <use xlink:href="@/assets/i.svg#ico-rating"></use>
                        </svg>
                        <span id="timer" class="text-white z-20 relative text-lg font-latobold w-12 min-w-[3rem] text-right">
                            {{ item.avg.toFixed(2) }}
                        </span>
                        <svg
                            class="absolute top-0 -left-8 h-full fill-status-6"
                            :class="[
                                { '!fill-status-6': item.avg.toFixed(2) >= 9 },
                                { '!fill-status-10': item.avg.toFixed(2) >= 7 && item.avg.toFixed(2) < 9 },
                                { '!fill-status-7': item.avg.toFixed(2) < 7 },
                            ]"
                        >
                            <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                        </svg>
                    </div>
                </div>
            </div>
            <!-- <div class="pl-4 max-m:pr-4 py-4 flex max-m:flex-col max-m:relative transition duration-300 hover:shadow-v1 v-bbgray">
                <div class="flex flex-col items-center justify-center pr-6 max-m:self-center max-m:mb-8 max-m:mt-4 max-m:mr-24">
                    <div class="flex flex-col justify-center overflow-hidden">
                        <span class="text-xl font-semibold text-left max-m:text-center uppercase w-32">Итого</span>
                    </div>
                </div>

                <div class="flex justify-around w-full max-m:justify-between max-s:flex-wrap">
                    <div class="flex flex-col items-center justify-center px-2">
                        <p class="text-lg font-bold">{{ statQuality.reduce((prev, curr) => prev + curr.allReviews, 0) }}</p>
                        <span class="uppercase text-gray-400 text-xs">Всего</span>
                    </div>
                    <div class="flex flex-col items-center justify-center px-2">
                        <p class="text-lg font-bold">{{ statQuality.reduce((prev, curr) => prev + curr.withoutReview, 0) }}</p>
                        <span class="uppercase text-gray-400 text-xs">Нет отзыва</span>
                    </div>
                    <div class="flex flex-col items-center justify-center px-2">
                        <p class="text-lg font-bold">{{ statQuality.reduce((prev, curr) => prev + curr.withReview, 0) }}</p>
                        <span class="uppercase text-gray-400 text-xs">Есть отзыв</span>
                    </div>
                    <div class="flex flex-col items-center justify-center px-2">
                        <p class="text-lg font-bold text-status-6">{{ statQuality.reduce((prev, curr) => prev + curr.reviews.good, 0) }}</p>
                        <span class="uppercase text-gray-400 text-xs">10 баллов</span>
                    </div>
                    <div class="flex flex-col items-center justify-center px-2 cursor-pointer group">
                        <p
                            class="text-lg font-bold text-status-10"
                            @click="
                                this.changeStatQualityScore({ category: 'all', score: 'normal' });
                                this.changeShowPage('qualityItem');
                            "
                        >
                            {{ statQuality.reduce((prev, curr) => prev + curr.reviews.normal, 0) }}
                        </p>
                        <span class="uppercase text-gray-400 text-xs group-hover:text-gray-700 transition-colors duration-300">7 &lt; &gt; 10 </span>
                    </div>
                    <div
                        class="flex flex-col items-center justify-center px-2 cursor-pointer group"
                        @click="
                            this.changeStatQualityScore({ category: 'all', score: 'bad' });
                            this.changeShowPage('qualityItem');
                        "
                    >
                        <p class="text-lg font-bold text-status-7">
                            {{ statQuality.reduce((prev, curr) => prev + curr.reviews.bad, 0) }}
                        </p>
                        <span class="uppercase text-gray-400 text-xs group-hover:text-gray-700 transition-colors duration-300">Меньше 7</span>
                    </div>
                </div>

                <div class="h-14 overflow-hidden pl-6 pr-10 py-3 flex items-center ml-auto self-center relative max-m:absolute max-m:right-0">
                    <svg class="w-7 h-7 min-w-[1.75rem] fill-white z-2 mr-2">
                        <use xlink:href="@/assets/i.svg#ico-rating"></use>
                    </svg>
                    <span id="timer" class="text-white z-20 relative text-lg font-latobold w-12 min-w-[3rem] text-right">
                        <span v-if="statQuality.length > 0">
                            {{
                                (
                                    statQuality.reduce((prev, curr) => prev + curr.totalScore, 0) /
                                    statQuality.reduce((prev, curr) => prev + curr.withReview, 0)
                                ).toFixed(2)
                            }}
                        </span>
                        <span class="text-xl" v-else>--</span>
                    </span>
                    <svg
                        class="absolute top-0 -left-8 h-full fill-status-6"
                        :class="[
                            {
                                '!fill-status-6':
                                    (
                                        statQuality.reduce((prev, curr) => prev + curr.totalScore, 0) /
                                        statQuality.reduce((prev, curr) => prev + curr.withReview, 0)
                                    ).toFixed(2) > 9,
                            },
                            {
                                '!fill-status-10':
                                    (
                                        statQuality.reduce((prev, curr) => prev + curr.totalScore, 0) /
                                        statQuality.reduce((prev, curr) => prev + curr.withReview, 0)
                                    ).toFixed(2) > 7 &&
                                    (
                                        statQuality.reduce((prev, curr) => prev + curr.totalScore, 0) /
                                        statQuality.reduce((prev, curr) => prev + curr.withReview, 0)
                                    ).toFixed(2) < 9,
                            },
                            {
                                '!fill-status-7':
                                    (
                                        statQuality.reduce((prev, curr) => prev + curr.totalScore, 0) /
                                        statQuality.reduce((prev, curr) => prev + curr.withReview, 0)
                                    ).toFixed(2) < 7,
                            },
                        ]"
                    >
                        <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                    </svg>
                </div>
            </div> -->
        </div>
        <div v-if="statQualityOrders && statQualityOrders.length > 0">
            {{ statQualityOrders }}
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "StatisticQuality",

    computed: {
        ...mapGetters(["statisticShow", "statQuality"]),
    },

    methods: {
        ...mapMutations(["changeShowPage"]),

        ...mapActions(["getStatQualityOrders"]),
    },
    mounted() {},
};
</script>
