<template>
  <div class="relative h-full">
    <a
      class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
      @click="closePop"
    >
      <span class="text-white mr-2">Сохранить и закрыть</span>
      <svg class="fill-white w-4 h-4">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </a>
    <div class="overflow-y-auto w-full h-full">
      <template v-if="calendarik.length > 0">
        <div class="p-5 flex flex-col justify-center v-bbgray items-center">
          <h2 class="text-center" v-if="date && start">
            <span class="text-gray-400">Вы выбрали:</span>
            <div class="flex mt-2">
              <div class="flex items-center">
                <svg class="w-5 h-5 fill-gray-400 mr-2">
                  <use xlink:href="@/assets/i.svg#nav-date"></use>
                </svg>
                <span class="leading-none font-latobold text-lg">{{
                  date
                }}</span>
              </div>

              <div class="flex ml-6">
                <svg class="w-5 h-5 fill-gray-400 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-time"></use>
                </svg>
                <span class="leading-none font-latobold text-lg"
                  >с {{ start.slice(0, 5) }} - до {{ end.slice(0, 5) }}</span
                >
              </div>
            </div>
          </h2>

          <div
            v-if="order.dates?.length == 0"
            class="flex mt-5 justify-center flex-wrap max-h-[160px] overflow-y-auto"
          >
            <div
              class="w-24 flex flex-col items-center mx-2 p-2"
              @click="ClearCal()"
            >
              <span
                class="w-9 h-9 rounded-full text-white bg-rose-500 overflow-hidden text-lg flex items-center justify-center font-latobold mb-2"
                >Х</span
              >
              <div class="text-center">Очистить выбор</div>
            </div>
            <template v-for="m in masterList" :key="m">
              <div
                class="h-max w-24 p-2 flex flex-col items-center rounded-md opacity-50 mx-2"
                :class="{
                  '!opacity-100 border-[1px] border-green-500': master == m.uid,
                }"
                @click="pickCalMaster(m)"
              >
                <span
                  v-if="m.picture"
                  class="w-9 h-9 rounded-full overflow-hidden bg-green-500 mb-2"
                >
                  <img
                    class="w-full h-full object-cover"
                    :src="'https://api.tahocrm.ru/uploads/users/' + m.picture"
                  />
                </span>
                <span
                  v-else
                  class="w-9 h-9 rounded-full overflow-hidden text-lg flex justify-center items-center text-white bg-green-500 mb-2"
                  >{{ m.name.slice(0, 1) }}</span
                >
                <div class="text-center">{{ m.name }} {{ m.secondname }}</div>
              </div>
            </template>
          </div>
        </div>

        <div class="mb-6 min-w-[800px] overflow-y-auto">
          <div class="grid grid-cols-calendar__popup-edit text-center">
            <div class=""></div>
            <div class="m-1 text-xs text-gray-500 v-bbgray">ПН</div>
            <div class="m-1 text-xs text-gray-500 v-bbgray">ВТ</div>
            <div class="m-1 text-xs text-gray-500 v-bbgray">СР</div>
            <div class="m-1 text-xs text-gray-500 v-bbgray">ЧТ</div>
            <div class="m-1 text-xs text-gray-500 v-bbgray">ПТ</div>
            <div class="m-1 text-xs text-rose-500 v-bbgray">СБ</div>
            <div class="m-1 text-xs text-rose-500 v-bbgray">ВС</div>
          </div>

          <div class="grid grid-cols-calendar__popup-time">
            <div class="w-full text-center text-[11px] mt-7">
              <div class="px-2 h-[23px] flex items-center justify-center">
                08:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                09:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                10:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                11:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                12:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                13:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                14:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                15:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                16:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                17:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                18:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                19:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                20:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                21:00
              </div>

              <div class="h-[28px]">&nbsp;</div>

              <div class="px-2 h-[23px] flex items-center justify-center">
                08:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                09:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                10:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                11:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                12:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                13:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                14:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                15:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                16:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                17:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                18:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                19:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                20:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                21:00
              </div>

              <div class="h-[28px]">&nbsp;</div>

              <div class="px-2 h-[23px] flex items-center justify-center">
                08:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                09:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                10:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                11:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                12:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                13:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                14:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                15:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                16:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                17:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                18:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                19:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                20:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                21:00
              </div>

              <div class="h-[28px]">&nbsp;</div>

              <div class="px-2 h-[23px] flex items-center justify-center">
                08:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                09:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                10:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                11:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                12:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                13:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                14:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                15:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                16:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                17:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                18:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                19:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                20:00
              </div>
              <div class="px-2 h-[23px] flex items-center justify-center">
                21:00
              </div>
            </div>

            <div class="grid grid-cols-7">
              <template v-for="day in calendarik" :key="day">
                <div class="text-center text-sm mx-2">
                  <h4 class="p-1">{{ moment(day.date).format("DD.MM") }}</h4>
                  <div
                    class="grid grid-cols-calendar__pop-cols grid-rows-calendar__pop-rows gap-[2px]"
                  >
                    <template v-for="slot in day.slots" :key="slot">
                      <div
                        v-if="slot.status == 0"
                        class="bg-gray-100 px-1"
                      ></div>
                      <div
                        v-if="slot.status == 1"
                        class="bg-green-200 px-1 hover:bg-white transition-all duration-150 cursor-pointer hover:shadow-v2"
                        @click="pickSlot(day.date, slot)"
                      ></div>
                      <div
                        v-if="slot.status == 2"
                        class="bg-rose-200 px-1 cursor-not-allowed flex items-center justify-center"
                      >
                        <svg class="opacity-40 fill-rose-500 w-3 h-3">
                          <use xlink:href="@/assets/i.svg#ico-closed"></use>
                        </svg>
                      </div>
                      <div
                        v-if="slot.status == 3"
                        class="bg-sky-300 px-1 cursor-not-allowed flex items-center justify-center"
                      >
                        <svg class="opacity-40 fill-white w-3 h-3">
                          <use xlink:href="@/assets/i.svg#nav-flow"></use>
                        </svg>
                      </div>
                      <div
                        v-if="slot.status == 4"
                        class="bg-green-500 px-1 cursor-pointer flex items-center justify-center"
                        @click="pickSlot(day.date, slot)"
                      >
                        <svg class="fill-white w-3 h-3">
                          <use xlink:href="@/assets/i.svg#ico-ok"></use>
                        </svg>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="loader opacity1 mt30">
        <div>
          <img src="https://api.tahocrm.ru/images/loader.svg" width="44" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  name: "OrderCal",

  data() {
    return {
      moment: moment,
      slots: [],
      masterList: [],
      date: "",
      start: "",
      end: "",
      master: "",
    };
  },

  emits: ["close"],

  computed: {
    ...mapGetters([
      "order",
      "OrderCal",
      "srtCal",
      "endCal",
      "times",
      "calendarik",
      "selectMasters",
    ]),
    generatedCal() {
      var now = new Date();
      now.setDate(now.getDate() + 1 - now.getDay());
      var days = [];
      days.length = 28;
      days = days.fill(null);
      days = days.map((_, d) => {
        var day = new Date(now);
        day.setDate(now.getDate() + d);
        return day.toJSON().slice(0, 10);
      });
      var slots = [];
      slots.length = (23 - 8) * 4;
      slots = slots.fill(null);
      now.setHours(8);
      now.setMinutes(0);
      now.setSeconds(0);
      now = new Date(now - now.getTimezoneOffset() * 60 * 1000);
      slots = slots.map((_, s) => {
        var time = new Date(+now + s * 60 * 15 * 1000).toJSON().slice(11, 19);
        return {
          time: time,
          status: 0,
        };
      });
      slots = JSON.stringify(slots);
      return days.map((d) => ({
        date: d,
        slots: JSON.parse(slots),
      }));
    },
  },

  methods: {
    ...mapActions([
      "pickSlot",
      "saveDate",
      "setMaster",
      "delMaster",
      "setCalendarik",
    ]),
    ...mapMutations(["setNewcal"]),

    // КАЛЕНДАРЬ
    showCalendar() {
      console.log("showCalendar");

      let calendarik = [];
      let calList = this.OrderCal;

      if (!calList?.length) {
        this.setCalendarik(this.generatedCal);
        return;
      }

      let s = this.srtCal;
      let e = this.endCal;
      if (this.order.box > 0) {
        calList = calList.filter((c) => c.box == this.order.box);
      }

      if (this.order.address?.length > 0) {
        calList = calList.filter((c) => c.box == "0");
      }

      if (this.order.master_id > 0) {
        calList = calList.filter((c) => c.uid == this.order.master_id);
      }

      while (s <= e) {
        let arr = calList.filter((c) => c.date == s);
        let slots = [];

        for (var j = 0; j < this.times.length; ++j) {
          let now = this.times[j];
          let t = arr.filter((a) => a.start == now);
          if (t.length > 0 && this.order.master_id) {
            slots.push({
              time: now,
              status: t.sort((a, b) => a.status - b.status)[0]["status"],
            });
          } else {
            slots.push({ time: now, status: 0 });
          }
        }

        calendarik.push({ date: s, slots: slots });
        s = new Date(s);
        var newDate = s.setDate(s.getDate() + 1);
        s = new Date(newDate).toISOString().split("T")[0];
      }

      this.setCalendarik(calendarik);

      if (this.slots) {
        this.reSelectSlots();
      }
      console.log("Календарь из модалки: ", calendarik);
    },

    // снова отменить выбраные слоты, после смены мастера
    reSelectSlots() {
      console.log("reSelectSlots");
      this.calendarik.forEach((day) => {
        day.slots.forEach((sl) => {
          if (day.date == this.date && this.slots.includes(sl.time)) {
            sl.status = 4;
          }
        });
      });
    },

    pickSlot(date, slot) {
      // console.log("pickSlot1", date);
      // console.log("pickSlot2", moment().format("YYYY-MM-DD"));
      // console.log("pickSlot3", slot);
      // console.log("pickSlot4", moment().format("HH:mm:ss"));

      if (this.order.master_id) {
        this.masterList = [];
        this.masterList.push(this.order.master);
      } else {
        this.masterList = this.selectMasters;
      }

      // нельзя в прошлое
      if (date < moment().format("YYYY-MM-DD")) {
        console.log("дата");
        // return;
      } else {
        if (
          date == moment().format("YYYY-MM-DD") &&
          slot.time < moment().format("HH:mm:ss")
        ) {
          console.log("время");
          // return;
        }
      }

      // если дата отличается от даты уже выбранного слота - то slots очищается (выбрали другой день)
      if (this.date != date) {
        this.date = date;
        this.calendarik.forEach((day) => {
          day.slots.forEach((sl) => {
            if (sl.status == 4) {
              sl.status = 1;
            }
          });
        });
        this.slots = [];
      }

      // занесем в this.slots - выбранные слоты
      if (slot) {
        if (slot.status == 1) {
          slot.status = 4;
          this.slots.push(slot.time);
        } else if (slot.status == 4) {
          slot.status = 1;
          this.slots = this.slots.filter((t) => t !== slot.time);
        }
      }

      // покажем только доступных мастеров
      if (!this.master) {
        let calList = this.OrderCal;

        if (this.order.box > 0) {
          calList = calList.filter((c) => c.box == this.order.box);
        }

        if (this.slots != "") {
          calList = calList.filter(
            (c) =>
              c.date == date &&
              c.start == slot.time &&
              (c.status == 1 || (c.status == 2 && c.order_id == this.order.id))
          );
        }

        let uids = calList.map((s) => s.uid);
        console.log("uids", uids);
        this.masterList = this.masterList.filter((m) =>
          uids.includes(m.uid.toString())
        );
        console.log("this.masterList", this.masterList);

        // если доступен только 1 мастер - назначим его автоматически
        if (this.masterList.length == 1) {
          console.log("master: ", this.masterList[0].uid);
          this.master = this.masterList[0].uid;
        }
      } else {
        this.masterList = this.masterList.filter((m) => m.uid == this.master);
      }

      if (this.slots.length > 0) {
        this.slots.sort(function (a, b) {
          return a.localeCompare(b);
        });
        console.log("slots: ", this.slots);

        this.start = this.slots[0];

        let t1 = new Date(this.date + " " + this.slots[this.slots.length - 1]);
        this.end = new Date(+t1 + 15 * 6e4);
        this.end = this.end.toLocaleString().slice(12, 20);
      }
    },

    async pickCalMaster(item) {
      console.log("pickCalMaster");
      this.master = item.uid;
      await this.setMaster(item);
      // this.setCalendarik(this.generatedCal);
      this.showCalendar();
    },

    async ClearCal() {
      console.log("ClearCal");
      this.slots = [];
      this.date = "";
      this.start = "";
      this.order.master_id = null;
      this.master = null;
      this.masterList = this.selectMasters;
      await this.delMaster();
      //this.showCalendar();
      this.setCalendarik(this.generatedCal);
    },

    closePop() {
      if (this.slots.length > 0) {
        this.order.dates.push({ loader: 1 });
      }
      this.$emit("close");
      this.saveDate({
        date: this.date,
        slots: this.slots,
        master: this.master,
      });
    },
  },
  mounted() {
    window.DEBUG_ORDER_CAL = this;
    this.showCalendar();
    if (this.selectMasters) {
      this.masterList = this.selectMasters;
    }
    if (this.order.master_id) {
      this.masterList = [];
      this.masterList.push(this.order.master);
      this.master = this.order.master_id;
    }
  },
};
</script>
