<template>
  <a
    class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
    @click="closePopup"
  >
    <span class="text-white mr-2">Закрыть</span>
    <svg class="fill-white w-4 h-4">
      <use xlink:href="@/assets/i.svg#ico-no"></use>
    </svg>
  </a>
  <div class="h-full overflow-y-auto">
    <template v-if="order.category == 1">
      <template v-if="order.activation && order.company?.firm == 1">
        <h3 class="my-4 text-lg font-latobold text-center">АНКЕТА АКТИВАЦИИ</h3>

        <div class="p-5 flex flex-wrap">
          <div
            class="w-1/4 px-4"
            @click="showCompanyformPopup(order.company_id)"
          >
            <h2 class="font-latobold text-lg v-bbgray mb-2">
              Юридическое лицо
            </h2>
            <div class="flex flex-wrap">
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.country }"
              >
                <span class="text-gray-400 text-xs">Страна регистрации</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.country"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.country }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.cfull }"
              >
                <span class="text-gray-400 text-xs">Полное наименование</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.cfull"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.cfull }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.cname }"
              >
                <span class="text-gray-400 text-xs"
                  >Сокращенное наименование</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.cname"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.cname }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.cinn }"
              >
                <span class="text-gray-400 text-xs">ИНН организации</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.cinn"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.cinn }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.cogrn }"
              >
                <span class="text-gray-400 text-xs">ОГРН</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.cogrn"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.cogrn }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.caddress }"
              >
                <span class="text-gray-400 text-xs">Юр. aдрес</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.caddress"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.caddress }}</p>
              </div>
            </div>
          </div>

          <div class="w-1/4 px-4" @click="showContactformPopup(order.mhd_id)">
            <h2 class="font-latobold text-lg v-bbgray mb-2">
              Ответственный по МЧД
            </h2>
            <div class="flex flex-wrap">
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.secondname }"
              >
                <span class="text-gray-400 text-xs">Фамилия</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.secondname"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.secondname }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.firstname }"
              >
                <span class="text-gray-400 text-xs">Имя</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.firstname"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.firstname }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.middlename }"
              >
                <span class="text-gray-400 text-xs">Отчество</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.middlename"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.middlename }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.sex }"
              >
                <span class="text-gray-400 text-xs">Пол</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.sex"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.sex }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.birthday }"
              >
                <span class="text-gray-400 text-xs">Дата рождения</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.birthday"
                >
                  Заполнить
                </div>
                <p v-else>
                  <span v-if="order.activation.birthday[4] != '-'">
                    {{ order.activation.birthday.substr(0, 2) }}-{{
                      order.activation.birthday.substr(4, 2)
                    }}-{{ order.activation.birthday.substr(0, 4) }}
                  </span>

                  <span v-else>
                    {{ order.activation.birthday.substr(8, 2) }}.{{
                      order.activation.birthday.substr(5, 2)
                    }}.{{ order.activation.birthday.substr(0, 4) }}
                  </span>
                </p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.snils }"
              >
                <span class="text-gray-400 text-xs">СНИЛС</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.snils"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.snils }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.inn }"
              >
                <span class="text-gray-400 text-xs">ИНН</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.inn"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.inn }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.address }"
              >
                <span class="text-gray-400 text-xs">Адрес (прописка)</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.address"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.address }}</p>
              </div>

              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.nationality }"
              >
                <span class="text-gray-400 text-xs">Гражданство</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.nationality"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.nationality }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.doc }"
              >
                <span class="text-gray-400 text-xs">Вид документа</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.doc"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.doc }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pseries }"
              >
                <span class="text-gray-400 text-xs">Серия</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pseries"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pseries }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pnum }"
              >
                <span class="text-gray-400 text-xs">Номер</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pnum"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pnum }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.pwho }"
              >
                <span class="text-gray-400 text-xs">Кем выдан</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pwho"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pwho }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pdate }"
              >
                <span class="text-gray-400 text-xs">Дата выдачи</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pdate"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pdate }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pcode }"
              >
                <span class="text-gray-400 text-xs">Код подраз-ния</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pcode"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pcode }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pplace }"
              >
                <span class="text-gray-400 text-xs">Место рождения</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pplace"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pplace }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.phone }"
              >
                <span class="text-gray-400 text-xs">Телефон</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.phone"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.phone }}</p>
              </div>
            </div>
          </div>

          <div class="w-1/4 px-4" @click="showTsformPopup(order.ts_id)">
            <h2 class="font-latobold text-lg v-bbgray mb-2">
              Транспортное средство
            </h2>
            <div class="flex flex-wrap">
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.tcountry }"
              >
                <span class="text-gray-400 text-xs">Страна регистрации ТС</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.tcountry"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.tcountry }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.category }"
              >
                <span class="text-gray-400 text-xs"
                  >Категория ТС (м1/м2/м3/N1/N2/N3)</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.category"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.category }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.mark }"
              >
                <span class="text-gray-400 text-xs">Марка</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.mark"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.mark }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.model }"
              >
                <span class="text-gray-400 text-xs">Модель</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.model"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.model }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.year }"
              >
                <span class="text-gray-400 text-xs">Год выпуска</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.year"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.year }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.color }"
              >
                <span class="text-gray-400 text-xs">Цвет</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.color"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.color }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.num }"
              >
                <span class="text-gray-400 text-xs">Регистрационный номер</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.num"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.num }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.vin }"
              >
                <span class="text-gray-400 text-xs">VIN</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.vin"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.vin }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.pts }"
              >
                <span class="text-gray-400 text-xs"
                  >Документ о регистрации</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pts"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pts }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.sts }"
              >
                <span class="text-gray-400 text-xs"
                  >Паспорт ТС серия, указан в СТС</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.sts"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.sts }}</p>
              </div>
            </div>
          </div>

          <div class="w-1/4 px-4">
            <h2 class="font-latobold text-lg v-bbgray mb-2">Вложения</h2>
            <div class="flex flex-wrap">
              <template v-for="i in order.activation.companyImages" :key="i">
                <IcoImg :img="i.img" :status="i.status" />
              </template>
              <template v-for="i in order.activation.contactImages" :key="i">
                <IcoImg :img="i.img" :status="i.status" />
              </template>
              <template v-for="i in order.activation.tsImages" :key="i">
                <IcoImg :img="i.img" :status="i.status" />
              </template>
            </div>
          </div>
        </div>
      </template>

      <template v-if="order.activation && order.company?.firm == 2">
        <h3 class="my-4 text-lg font-latobold text-center">АНКЕТА АКТИВАЦИИ</h3>

        <div class="p-5 flex flex-wrap">
          <div
            class="w-1/4 px-4"
            @click="showCompanyformPopup(order.company_id)"
          >
            <h2 class="font-latobold text-lg v-bbgray mb-2">Физическое лицо</h2>
            <div class="flex flex-wrap">
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.country }"
              >
                <span class="text-gray-400 text-xs">Страна регистрации</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.country"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.country }}</p>
              </div>
            </div>
          </div>

          <div class="w-1/4 px-4" @click="showContactformPopup(order.mhd_id)">
            <h2 class="font-latobold text-lg v-bbgray mb-2">
              Данные заявителя
            </h2>
            <div class="flex flex-wrap">
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.secondname }"
              >
                <span class="text-gray-400 text-xs">Фамилия</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.secondname"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.secondname }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.firstname }"
              >
                <span class="text-gray-400 text-xs">Имя</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.firstname"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.firstname }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.middlename }"
              >
                <span class="text-gray-400 text-xs">Отчество</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.middlename"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.middlename }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.sex }"
              >
                <span class="text-gray-400 text-xs">Пол</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.sex"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.sex }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.birthday }"
              >
                <span class="text-gray-400 text-xs">Дата рождения</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.birthday"
                >
                  Заполнить
                </div>
                <p v-else>
                  <span v-if="order.activation.birthday[4] != '-'">
                    {{ order.activation.birthday }}
                  </span>

                  <span v-else>
                    {{ order.activation.birthday.substr(8, 2) }}.{{
                      order.activation.birthday.substr(5, 2)
                    }}.{{ order.activation.birthday.substr(0, 4) }}
                  </span>
                </p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.snils }"
              >
                <span class="text-gray-400 text-xs">СНИЛС</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.snils"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.snils }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.inn }"
              >
                <span class="text-gray-400 text-xs">ИНН</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.inn"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.inn }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.address }"
              >
                <span class="text-gray-400 text-xs">Адрес (прописка)</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.address"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.address }}</p>
              </div>

              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.nationality }"
              >
                <span class="text-gray-400 text-xs">Гражданство</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.nationality"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.nationality }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.doc }"
              >
                <span class="text-gray-400 text-xs">Вид документа</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.doc"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.doc }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pseries }"
              >
                <span class="text-gray-400 text-xs">Серия</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pseries"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pseries }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pnum }"
              >
                <span class="text-gray-400 text-xs">Номер</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pnum"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pnum }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.pwho }"
              >
                <span class="text-gray-400 text-xs">Кем выдан</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pwho"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pwho }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pdate }"
              >
                <span class="text-gray-400 text-xs">Дата выдачи</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pdate"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pdate }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pcode }"
              >
                <span class="text-gray-400 text-xs">Код подраз-ния</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pcode"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pcode }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pplace }"
              >
                <span class="text-gray-400 text-xs">Место рождения</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pplace"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pplace }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.phone }"
              >
                <span class="text-gray-400 text-xs">Телефон</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.phone"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.phone }}</p>
              </div>
            </div>
          </div>

          <div class="w-1/4 px-4" @click="showTsformPopup(order.ts_id)">
            <h2 class="font-latobold text-lg v-bbgray mb-2">
              Транспортное средство
            </h2>
            <div class="flex flex-wrap">
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.tcountry }"
              >
                <span class="text-gray-400 text-xs">Страна регистрации ТС</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.tcountry"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.tcountry }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.category }"
              >
                <span class="text-gray-400 text-xs"
                  >Категория ТС (м1/м2/м3/N1/N2/N3)</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.category"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.category }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.mark }"
              >
                <span class="text-gray-400 text-xs">Марка</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.mark"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.mark }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.model }"
              >
                <span class="text-gray-400 text-xs">Модель</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.model"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.model }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.year }"
              >
                <span class="text-gray-400 text-xs">Год выпуска</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.year"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.year }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.color }"
              >
                <span class="text-gray-400 text-xs">Цвет</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.color"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.color }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.num }"
              >
                <span class="text-gray-400 text-xs">Регистрационный номер</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.num"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.num }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.vin }"
              >
                <span class="text-gray-400 text-xs">VIN</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.vin"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.vin }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.pts }"
              >
                <span class="text-gray-400 text-xs"
                  >Документ о регистрации</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pts"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pts }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.sts }"
              >
                <span class="text-gray-400 text-xs"
                  >Паспорт ТС серия, указан в СТС</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.sts"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.sts }}</p>
              </div>
            </div>
          </div>

          <div class="w-1/4 px-4">
            <h2 class="font-latobold text-lg v-bbgray mb-2">Вложения</h2>
            <div class="flex flex-wrap">
              <template v-for="i in order.activation.companyImages" :key="i">
                <IcoImg :img="i.img" :status="i.status" />
              </template>
              <template v-for="i in order.activation.contactImages" :key="i">
                <IcoImg :img="i.img" :status="i.status" />
              </template>
              <template v-for="i in order.activation.tsImages" :key="i">
                <IcoImg :img="i.img" :status="i.status" />
              </template>
            </div>
          </div>
        </div>
      </template>

      <template v-if="order.activation && order.company?.firm == 3">
        <h3 class="my-4 text-lg font-latobold text-center">АНКЕТА АКТИВАЦИИ</h3>

        <div class="p-5 flex flex-wrap">
          <div
            class="w-1/4 px-4"
            @click="showCompanyformPopup(order.company_id)"
          >
            <h2 class="font-latobold text-lg v-bbgray mb-2">Данные ИП</h2>
            <div class="flex flex-wrap">
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.ipsecondname }"
              >
                <span class="text-gray-400 text-xs">Фамилия</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.ipsecondname"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.ipsecondname }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.ipname }"
              >
                <span class="text-gray-400 text-xs">Имя</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.ipname"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.ipname }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.ipmiddlename }"
              >
                <span class="text-gray-400 text-xs">Отчество</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.ipmiddlename"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.ipmiddlename }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.ipsex }"
              >
                <span class="text-gray-400 text-xs">Пол</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.ipsex"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.ipsex }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.ipbirthday }"
              >
                <span class="text-gray-400 text-xs">Дата рождения</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.ipbirthday"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.ipbirthday }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.ipsnils }"
              >
                <span class="text-gray-400 text-xs">СНИЛС</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.ipsnils"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.ipsnils }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.cinn }"
              >
                <span class="text-gray-400 text-xs">ИНН</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.cinn"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.cinn }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ 'text-rose-500': !order.activation.cogrn }"
              >
                <span class="text-gray-400 text-xs">ОГРНИП</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.cogrn"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.cogrn }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.caddress }"
              >
                <span class="text-gray-400 text-xs">Адрес (прописка)</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.caddress"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.caddress }}</p>
              </div>
            </div>
          </div>

          <div class="w-1/4 px-4" @click="showContactformPopup(order.mhd_id)">
            <h2 class="font-latobold text-lg v-bbgray mb-2">
              Ответственный по МЧД
            </h2>
            <div class="flex flex-wrap">
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.secondname }"
              >
                <span class="text-gray-400 text-xs">Фамилия</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.secondname"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.secondname }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.firstname }"
              >
                <span class="text-gray-400 text-xs">Имя</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.firstname"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.firstname }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.middlename }"
              >
                <span class="text-gray-400 text-xs">Отчество</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.middlename"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.middlename }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.sex }"
              >
                <span class="text-gray-400 text-xs">Пол</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.sex"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.sex }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.birthday }"
              >
                <span class="text-gray-400 text-xs">Дата рождения</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.birthday"
                >
                  Заполнить
                </div>
                <p v-else>
                  <span v-if="order.activation.birthday[4] != '-'">
                    {{ order.activation.birthday }}
                  </span>

                  <span v-else>
                    {{ order.activation.birthday.substr(8, 2) }}.{{
                      order.activation.birthday.substr(5, 2)
                    }}.{{ order.activation.birthday.substr(0, 4) }}
                  </span>
                </p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.snils }"
              >
                <span class="text-gray-400 text-xs">СНИЛС</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.snils"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.snils }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.inn }"
              >
                <span class="text-gray-400 text-xs">ИНН</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.inn"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.inn }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.address }"
              >
                <span class="text-gray-400 text-xs">Адрес (прописка)</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.address"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.address }}</p>
              </div>

              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.nationality }"
              >
                <span class="text-gray-400 text-xs">Гражданство</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.nationality"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.nationality }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.doc }"
              >
                <span class="text-gray-400 text-xs">Вид документа</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.doc"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.doc }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pseries }"
              >
                <span class="text-gray-400 text-xs">Серия</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pseries"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pseries }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pnum }"
              >
                <span class="text-gray-400 text-xs">Номер</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pnum"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pnum }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.pwho }"
              >
                <span class="text-gray-400 text-xs">Кем выдан</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pwho"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pwho }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pdate }"
              >
                <span class="text-gray-400 text-xs">Дата выдачи</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pdate"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pdate }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pcode }"
              >
                <span class="text-gray-400 text-xs">Код подраз-ния</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pcode"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pcode }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.pplace }"
              >
                <span class="text-gray-400 text-xs">Место рождения</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pplace"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pplace }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-1/2"
                :class="{ '!text-rose-500': !order.activation.phone }"
              >
                <span class="text-gray-400 text-xs">Телефон</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.phone"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.phone }}</p>
              </div>
            </div>
          </div>

          <div class="w-1/4 px-4" @click="showTsformPopup(order.ts_id)">
            <h2 class="font-latobold text-lg v-bbgray mb-2">
              Транспортное средство
            </h2>
            <div class="flex flex-wrap">
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.tcountry }"
              >
                <span class="text-gray-400 text-xs">Страна регистрации ТС</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.tcountry"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.tcountry }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.category }"
              >
                <span class="text-gray-400 text-xs"
                  >Категория ТС (м1/м2/м3/N1/N2/N3)</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.category"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.category }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.mark }"
              >
                <span class="text-gray-400 text-xs">Марка</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.mark"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.mark }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.model }"
              >
                <span class="text-gray-400 text-xs">Модель</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.model"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.model }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.year }"
              >
                <span class="text-gray-400 text-xs">Год выпуска</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.year"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.year }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.color }"
              >
                <span class="text-gray-400 text-xs">Цвет</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.color"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.color }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.num }"
              >
                <span class="text-gray-400 text-xs">Регистрационный номер</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.num"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.num }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.vin }"
              >
                <span class="text-gray-400 text-xs">VIN</span>
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.vin"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.vin }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.pts }"
              >
                <span class="text-gray-400 text-xs"
                  >Документ о регистрации</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.pts"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.pts }}</p>
              </div>
              <div
                class="flex flex-col my-2 w-full"
                :class="{ '!text-rose-500': !order.activation.sts }"
              >
                <span class="text-gray-400 text-xs"
                  >Паспорт ТС серия, указан в СТС</span
                >
                <div
                  class="text-rose-500 uppercase"
                  v-if="!order.activation.sts"
                >
                  Заполнить
                </div>
                <p v-else>{{ order.activation.sts }}</p>
              </div>
            </div>
          </div>

          <div class="w-1/4 px-4">
            <h2 class="font-latobold text-lg v-bbgray mb-2">Вложения</h2>
            <div class="flex flex-wrap">
              <template v-for="i in order.activation.companyImages" :key="i">
                <IcoImg :img="i.img" :status="i.status" />
              </template>
              <template v-for="i in order.activation.contactImages" :key="i">
                <IcoImg :img="i.img" :status="i.status" />
              </template>
              <template v-for="i in order.activation.tsImages" :key="i">
                <IcoImg :img="i.img" :status="i.status" />
              </template>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-if="order.category == 2">
      <h3 class="my-4 text-lg font-latobold text-center">АНКЕТА МОНИТОРИНГ</h3>

      <div class="p-5 flex flex-wrap">
        <div class="w-2/3 px-4" @click="showTsformPopup(order.ts_id)">
          <h2 class="font-latobold text-lg v-bbgray mb-2">
            Транспортное средство
          </h2>
          <div class="flex flex-wrap">
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.tcountry }"
            >
              <span class="text-gray-400 text-xs">Страна регистрации ТС</span>
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.tcountry"
              >
                Заполнить
              </div>
              <p v-else>{{ order.activation.tcountry }}</p>
            </div>
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.category }"
            >
              <span class="text-gray-400 text-xs"
                >Категория ТС (м1/м2/м3/N1/N2/N3)</span
              >
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.category"
              >
                Заполнить
              </div>
              <p v-else>{{ order.activation.category }}</p>
            </div>
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.mark }"
            >
              <span class="text-gray-400 text-xs">Марка</span>
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.mark"
              >
                Заполнить
              </div>
              <p v-else>{{ order.activation.mark }}</p>
            </div>
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.model }"
            >
              <span class="text-gray-400 text-xs">Модель</span>
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.model"
              >
                Заполнить
              </div>
              <p v-else>{{ order.activation.model }}</p>
            </div>
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.year }"
            >
              <span class="text-gray-400 text-xs">Год выпуска</span>
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.year"
              >
                Заполнить
              </div>
              <p v-else>{{ order.activation.year }}</p>
            </div>
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.color }"
            >
              <span class="text-gray-400 text-xs">Цвет</span>
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.color"
              >
                Заполнить
              </div>
              <p v-else>{{ order.activation.color }}</p>
            </div>
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.num }"
            >
              <span class="text-gray-400 text-xs">Регистрационный номер</span>
              <div class="text-rose-500 uppercase" v-if="!order.activation.num">
                Заполнить
              </div>
              <p v-else>{{ order.activation.num }}</p>
            </div>
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.vin }"
            >
              <span class="text-gray-400 text-xs">VIN</span>
              <div class="text-rose-500 uppercase" v-if="!order.activation.vin">
                Заполнить
              </div>
              <p v-else>{{ order.activation.vin }}</p>
            </div>
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.pts }"
            >
              <span class="text-gray-400 text-xs">Документ о регистрации</span>
              <div class="text-rose-500 uppercase" v-if="!order.activation.pts">
                Заполнить
              </div>
              <p v-else>{{ order.activation.pts }}</p>
            </div>
            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.sts }"
            >
              <span class="text-gray-400 text-xs"
                >Паспорт ТС серия, указан в СТС</span
              >
              <div class="text-rose-500 uppercase" v-if="!order.activation.sts">
                Заполнить
              </div>
              <p v-else>{{ order.activation.sts }}</p>
            </div>
            <div class="flex flex-col my-2 w-1/2">
              <input
                class="mychbox"
                id="warr"
                type="checkbox"
                disabled
                v-model="order.activation.warranty"
              />
              <label for="warr">Гарантийное ТС</label>
            </div>

            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.state }"
            >
              <span class="text-gray-400 text-xs">Состояние ТС</span>
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.state"
              >
                Заполнить
              </div>
              <p v-else>
                {{
                  order.activation.state == 1
                    ? "Работает"
                    : order.activation.state == 2
                    ? "В ремонте"
                    : order.activation.state == 3
                    ? "Неисправна"
                    : order.activation.state == 4
                    ? "Конструктивные особенности"
                    : ""
                }}
              </p>
            </div>

            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.maxspeed }"
            >
              <span class="text-gray-400 text-xs">Максимальная скорость</span>
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.maxspeed"
              >
                Заполнить
              </div>
              <p v-else>{{ order.activation.maxspeed }}</p>
            </div>

            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.fuel }"
            >
              <span class="text-gray-400 text-xs">Вид топлива</span>
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.fuel"
              >
                Заполнить
              </div>
              <p v-else>
                {{
                  order.activation.fuel == 1
                    ? "Газ"
                    : order.activation.fuel == 2
                    ? "Бензин"
                    : order.activation.fuel == 3
                    ? "ДТ"
                    : ""
                }}
              </p>
            </div>

            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.tank }"
            >
              <span class="text-gray-400 text-xs">Кол-во баков</span>
              <div
                class="text-rose-500 uppercase"
                v-if="!order.activation.tank"
              >
                Заполнить
              </div>
              <p v-else>{{ order.activation.tank }}</p>
            </div>

            <div
              class="flex flex-col my-2 w-1/2"
              :class="{ '!text-rose-500': !order.activation.val }"
            >
              <span class="text-gray-400 text-xs">Объем бака в л</span>
              <div class="text-rose-500 uppercase" v-if="!order.activation.val">
                Заполнить
              </div>
              <p v-else>{{ order.activation.val }}</p>
            </div>

            <div class="flex flex-col my-2 w-1/2">
              <input
                class="mychbox"
                id="warr"
                type="checkbox"
                disabled
                v-model="order.activation.shape"
              />
              <label for="warr">Нестандартная форма баков</label>
            </div>

            <div class="flex flex-col my-2 w-1/2">
              <input
                class="mychbox"
                id="warr"
                type="checkbox"
                disabled
                v-model="order.activation.flow"
              />
              <label for="warr">Наличие "перетекайки"</label>
            </div>

            <div class="flex flex-col my-2 w-1/2">
              <input
                class="mychbox"
                id="warr"
                type="checkbox"
                disabled
                v-model="order.activation.block"
              />
              <label for="warr">Блокировка "перетекайки"</label>
            </div>
          </div>

          <h2 class="font-latobold text-lg v-bbgray mb-2">Уведомления</h2>
          <div class="flex flex-wrap">
            <div class="flex flex-col my-2 w-1/2">
              <input
                class="mychbox"
                id="warr"
                type="checkbox"
                disabled
                v-model="order.activation.notice_speed"
              />
              <label for="warr">Скорость</label>
            </div>
            <div class="flex flex-col my-2 w-1/2">
              <input
                class="mychbox"
                id="warr"
                type="checkbox"
                disabled
                v-model="order.activation.notice_hours"
              />
              <label for="warr">График работы</label>
            </div>
            <div class="flex flex-col my-2 w-1/2">
              <input
                class="mychbox"
                id="warr"
                type="checkbox"
                disabled
                v-model="order.activation.notice_zone"
              />
              <label for="warr">Геозона</label>
            </div>
            <div class="flex flex-col my-2 w-1/2">
              <input
                class="mychbox"
                id="warr"
                type="checkbox"
                disabled
                v-model="order.activation.notice_fuel"
              />
              <label for="warr">Слив топлива</label>
            </div>
            <div class="flex flex-col my-2 w-full">
              <span class="text-gray-400 text-xs">Другое</span>
              <p>{{ order.activation.notice_other }}</p>
            </div>
          </div>
        </div>

        <div class="w-1/3 px-4">
          <h2 class="font-latobold text-lg v-bbgray mb-2">Вложения</h2>
          <div class="flex flex-wrap">
            <template v-for="i in order.activation.companyImages" :key="i">
              <IcoImg :img="i.img" :status="i.status" />
            </template>
            <template v-for="i in order.activation.tsImages" :key="i">
              <IcoImg :img="i.img" :status="i.status" />
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrderDoc",

  computed: {
    ...mapGetters([
      "s",
      "order",
      "selectOrderCompanies",
      "selectOrderContacts",
      "selectOrderTs",
      "activ",
    ]),
  },

  methods: {
    ...mapActions([
      "setSettings",
      "closePopup",
      "setCompany",
      "setContact",
      "setCar",
    ]),

    showCompanyformPopup(id) {
      console.log(id);
      let companies = this.selectOrderCompanies.filter((item) => item.id == id);
      if (companies) {
        this.setCompany(companies[0]);
        this.s.popup = "edit";
        this.s.back = "/order/view";
        this.s.backpop = "doc";
        this.setSettings(this.s);
        this.$router.push("/client/company");
      }
    },

    showContactformPopup(id) {
      let contacts = this.selectOrderContacts.filter((item) => item.id == id);
      if (contacts) {
        this.setContact(contacts[0]);
        this.s.popup = "edit";
        this.s.back = "/order/view";
        this.s.backpop = "doc";
        this.setSettings(this.s);
        this.$router.push("/client/contact");
      }
    },

    showTsformPopup(id) {
      let ts = this.selectOrderTs.filter((item) => item.id == id);
      if (ts) {
        this.setCar(ts[0]);
        this.s.popup = "edit";
        this.s.back = "/order/view";
        this.s.backpop = "doc";
        this.setSettings(this.s);
        this.$router.push("/client/car");
      }
    },
  },

  mounted() {
    window.DEBUG_ORDER_DOC = this;
  },
};
</script>
