<template>
  <a
    class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
    @click="$emit('close')"
  >
    <span class="text-white mr-2">Закрыть</span>
    <svg class="fill-white w-4 h-4">
      <use xlink:href="@/assets/i.svg#ico-no"></use>
    </svg>
  </a>
  <div class="flex max-h-full max-l:flex-col">
    <template v-if="task?.id">
      <div class="w-1/2 overflow-y-auto max-l:w-full max-l:h-1/2 max-h-full">
        <div class="p-5 v-bbgray">
          <div class="flex items-center">
            <TaskStatus :task="task" />
            <div class="pl-4">
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                v-if="task.status == 1"
                >ЧЕРНОВИК</span
              >
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                v-if="task.status == 2"
                >ОПУБЛИКОВАНА</span
              >
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                v-if="task.status == 4"
                >В РАБОТЕ</span
              >
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                v-if="task.status == 5"
                >ЗАВЕРШЕНА</span
              >
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                v-if="task.status == 6"
                >ПРОВЕРЕНА</span
              >
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                v-if="task.status == 7"
                >ОТКЛОНЕНА</span
              >
              <span
                class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                v-if="task.status == 19"
                >НАРУШЕНИЕ</span
              >
              <h2 class="font-latobold flex text-xl">
                #{{ task.iid }}
                <span
                  title="Было нарушение"
                  v-if="task.violate == 2 || task.status == 19"
                >
                  <svg class="w-5 h-5 ml-2 mt-1 fill-status-11">
                    <use xlink:href="@/assets/i.svg#status-11"></use>
                  </svg>
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div v-if="canShowGoParent">
          <button
            class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 mt-2 ml-5"
            @click="goToParent"
          >
            <span v-if="task.object == 1" class="v-btn_text flex items-center">
              <!--<svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linea">
                                <use xlink:href="/img/i.cc1a44ec.svg#ico-no"></use>
                            </svg>-->
              К заказу
            </span>
            <span
              v-else-if="task.object == 2"
              class="v-btn_text flex items-center"
            >
              <!--<svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linea">
                                <use xlink:href="/img/i.cc1a44ec.svg#ico-no"></use>
                            </svg>-->
              К задаче выше
            </span>
            <span
              v-else-if="task.object == 4"
              class="v-btn_text flex items-center"
            >
              <!--<svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linea">
                                <use xlink:href="/img/i.cc1a44ec.svg#ico-no"></use>
                            </svg>-->
              К клиенту
            </span>
            <span
              v-else-if="task.object == 11"
              class="v-btn_text flex items-center"
            >
              <!--<svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linea">
                                <use xlink:href="/img/i.cc1a44ec.svg#ico-no"></use>
                            </svg>-->
              К лиду
            </span>
            <span
              v-else-if="task.object == 12"
              class="v-btn_text flex items-center"
            >
              <!--<svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linea">
                               <use xlink:href="/img/i.cc1a44ec.svg#ico-no"></use>
                           </svg>-->
              К Касанию
            </span>
            <span v-else class="v-btn_text flex items-center">
              <!--<svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linea">
                                <use xlink:href="/img/i.cc1a44ec.svg#ico-no"></use>
                            </svg>-->
              Наверх
            </span>
          </button>
        </div>

        <div v-if="task.status == 1 && !showtime && !fasttime" class="my-4">
          <p>
            <span class="pl-5 pr-4 font-latobold text-base"
              >Начать задачу:</span
            >
          </p>

          <div class="pl-5 pr-4">
            <div class="flex" style="align-items: center">
              <a
                @click="fasttime = true"
                class="block w-[11rem] my-1 mr-4 w-max v-btn bg-orange-500 hover:bg-orange-400 flex items-center"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
                <span class="v-btn_text">Срочная задача</span>
              </a>

              <p class="text-base h-full">
                время до взятия в работу
                <span class="font-latobold">15 минут после публикации</span>
              </p>
            </div>

            <div class="flex" style="align-items: center">
              <a
                @click="showtime = true"
                class="block w-[11rem] my-1 mr-4 w-max v-btn bg-violet-500 hover:bg-violet-400 flex items-center"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#status-7"></use>
                </svg>
                <span class="v-btn_text">Выбрать время</span>
              </a>
              <p class="text-base h-full">
                время до взятия в работу
                <span class="font-latobold">выбирается вручную</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 
        <div v-if="task.status == 1" class="my-4 flex">
          <p class="pl-5 pr-4 text-base">
            <span class="font-latobold"
              >Время на выполнение задачи (в минутах)</span
            >
          </p>
          <input
            style="
              border-radius: 5px;
              border: 1px solid #000;
              padding: 3px 10px;
              width: 80px;
            "
            type="number"
            min="0"
            v-model="task.minute"
            @change="setTaskMinutes(task.minute)"
          />
        </div> -->
        <div v-if="task.status > 1" class="my-4 flex">
          <p class="pl-5 pr-4 text-base">
            Время на выполнение задачи:
            <span class="font-latobold">{{ task.minute }} минут(а)</span>
          </p>
        </div>

        <div v-if="!showtime && fasttime" class="my-4 flex">
          <p class="pl-5 pr-4 text-base">
            <span class="font-latobold"
              >Начать выполнение в течении 15 минут</span
            >
          </p>
        </div>

        <div
          v-if="(showtime && !fasttime) || task.status > 1"
          class="my-4 flex"
        >
          <p class="pl-5 pr-4 text-base">
            <span class="font-latobold">Время до взятия в работу:</span>
          </p>

          <input
            v-if="task.status == 1"
            style="
              border-radius: 5px;
              border: 1px solid #000;
              padding: 3px 10px;
            "
            type="datetime-local"
            v-model="task.date"
            @change="setTaskDate(task.date)"
          />
          <input
            v-else
            style="
              border-radius: 5px;
              border: 1px solid #000;
              padding: 3px 10px;
            "
            type="datetime-local"
            disabled="true"
            v-model="task.date"
            @change="setTaskDate(task.date)"
          />
        </div>

        <div v-if="task.start_at != null" class="my-4">
          <span class="pl-5 pr-8 font-latobold text-base"
            >Задача началась:</span
          >
          <input
            style="
              border-radius: 5px;
              border: 1px solid #000;
              padding: 3px 10px;
            "
            type="datetime-local"
            disabled="true"
            v-model="task.start_at"
          />
        </div>
        <div v-if="task.end_at != null" class="my-4">
          <span class="pl-5 pr-4 font-latobold text-base"
            >Задача завершена:
          </span>
          <input
            style="
              border-radius: 5px;
              border: 1px solid #000;
              padding: 3px 10px;
            "
            type="datetime-local"
            disabled="true"
            v-model="task.end_at"
          />
        </div>
        <div v-if="task.accept_at != null" class="my-4">
          <span class="pl-5 pr-4 font-latobold text-base"
            >Задача проверена:
          </span>
          <input
            style="
              border-radius: 5px;
              border: 1px solid #000;
              padding: 3px 10px;
            "
            type="datetime-local"
            disabled="true"
            v-model="task.accept_at"
          />
        </div>
        <template v-if="role != 'Client'">
          <div class="p-5 v-bbgray" v-if="task.client_id">
            <div class="flex items-center" @click="showThisClient()">
              <span
                class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#ico-info"></use>
                </svg>
              </span>
              <div class="flex flex-col items-start">
                <p
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                >
                  КЛИЕНТ
                </p>
                <p class="font-latobold text-base">{{ task.client?.name }}</p>
              </div>
            </div>
          </div>
          <div class="p-5 v-bbgray" v-if="task.order_id">
            <div
              class="flex items-center"
              @click="showOrder(task.order), (this.s.popup = false)"
            >
              <span
                class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#ico-info"></use>
                </svg>
              </span>
              <div class="flex flex-col items-start">
                <p
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                >
                  ЗАКАЗ
                </p>
                <p class="font-latobold text-base">{{ task.order?.iid }}</p>
              </div>
            </div>
          </div>

          <div class="p-5 v-bbgray" v-if="task.contact_id">
            <div class="flex items-center" @click="showThisClientObj()">
              <span
                class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
              >
                <svg class="w-5 h-5 fill-white">
                  <use xlink:href="@/assets/i.svg#ico-info"></use>
                </svg>
              </span>
              <div class="flex flex-col items-start">
                <p
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                >
                  КОНТАКТ
                </p>
                <p class="font-latobold text-base">
                  {{ task.contact_name }} {{ task.contact_phone }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div v-else class="p-5 v-bbgray">
                        <div class="flex items-center">
                            <span class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4">
                                <svg class="w-5 h-5 fill-white"><use xlink:href="@/assets/i.svg#ico-info"></use></svg>
                            </span>
                            <div class="flex flex-col items-start">
                                <p class="text-[10px] text-gray-400 leading-none uppercase mb-1">КОНТАКТ</p>
                                <p class="text-base text-red-500">Не заполнен</p>
                            </div>
                        </div>
                    </div> -->

          <!-- <div class="p-5 v-bbgray" v-if="task.obj">
                        <div class="flex items-center" @click="showClient(task.obj), (this.s.popup = false)">
                            <span class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4">
                                <svg class="w-5 h-5 fill-white"><use xlink:href="@/assets/i.svg#ico-info"></use></svg>
                            </span>
                            <div class="flex flex-col items-start">
                                <p class="text-[10px] text-gray-400 leading-none uppercase mb-1">{{ o[task.object] }}</p>
                                <p v-if="task.object == 1" class="font-latobold text-base">{{ task.obj?.iid }}</p>
                                <p v-if="task.object == 4" class="font-latobold text-base">{{ task.obj?.name }}</p>
                            </div>
                        </div>
                    </div> -->

          <template v-if="task.type > 0">
            <div class="p-5 v-bbgray relative group">
              <div class="flex items-center">
                <span
                  class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
                >
                  <svg class="w-5 h-5 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-timer"></use>
                  </svg>
                </span>
                <div>
                  <template v-for="t in tasksTypes" :key="t">
                    <template v-if="t.id == task.type">
                      <div class="flex flex-col items-start">
                        <span
                          class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                          >{{ t.dep }}</span
                        >
                        <span class="font-latobold text-base">{{
                          t.name
                        }}</span>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
              <div
                class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150 group cursor-pointer"
                v-if="task.status < 5"
              >
                <svg
                  @click="task.type = null"
                  class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                >
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
              </div>
            </div>
          </template>

          <template v-else>
            <template v-if="profile.uid == task.who && task.status == 1">
              <div class="p-5 v-bbgray">
                <div class="flex justify-around">
                  <div @click="setTaskDep(1)">
                    <div
                      class="rounded-full h-11 w-11 m-auto bg-gray-400 flex items-center justify-center relative group cursor-pointer hover:bg-green-500 transition-colors duration-150"
                      :class="{ '!bg-green-500': task.department == 1 }"
                    >
                      <svg class="w-5 h-5 fill-white">
                        <use xlink:href="@/assets/i.svg#ico-phone"></use>
                      </svg>
                    </div>
                    <span class="text-[10px] text-center uppercase py-1"
                      >менеджеры</span
                    >
                  </div>
                  <div @click="setTaskDep(2)">
                    <div
                      class="rounded-full h-11 w-11 m-auto bg-gray-400 flex items-center justify-center relative group cursor-pointer hover:bg-green-500 transition-colors duration-150"
                      :class="{ '!bg-green-500': task.department == 2 }"
                    >
                      <svg class="w-5 h-5 fill-white">
                        <use xlink:href="@/assets/i.svg#status-3"></use>
                      </svg>
                    </div>
                    <span class="text-[10px] text-center uppercase py-1"
                      >логисты</span
                    >
                  </div>
                  <div @click="setTaskDep(4)">
                    <div
                      class="rounded-full h-11 w-11 m-auto bg-gray-400 flex items-center justify-center relative group cursor-pointer hover:bg-green-500 transition-colors duration-150"
                      :class="{ '!bg-green-500': task.department == 4 }"
                    >
                      <svg class="w-5 h-5 fill-white">
                        <use xlink:href="@/assets/i.svg#status-4"></use>
                      </svg>
                    </div>
                    <span class="text-[10px] text-center uppercase py-1"
                      >инженеры</span
                    >
                  </div>
                  <div @click="setTaskDep(5)">
                    <div
                      class="rounded-full h-11 w-11 m-auto bg-gray-400 flex items-center justify-center relative group cursor-pointer hover:bg-green-500 transition-colors duration-150"
                      :class="{ '!bg-green-500': task.department == 5 }"
                    >
                      <svg class="w-5 h-5 fill-white">
                        <use xlink:href="@/assets/i.svg#status-19"></use>
                      </svg>
                    </div>
                    <span
                      class="text-[10px] text-center uppercase py-1 m-auto block"
                      >окк</span
                    >
                  </div>
                  <div @click="setTaskDep(8)">
                    <div
                      class="rounded-full h-11 w-11 m-auto bg-gray-400 flex items-center justify-center relative group cursor-pointer hover:bg-green-500 transition-colors duration-150"
                      :class="{ '!bg-green-500': task.department == 8 }"
                    >
                      <svg class="w-5 h-5 fill-white">
                        <use xlink:href="@/assets/i.svg#bill-2"></use>
                      </svg>
                    </div>
                    <span class="text-[10px] text-center uppercase py-1"
                      >бухгалтерия</span
                    >
                  </div>
                  <div @click="setTaskDep(9)">
                    <div
                      class="rounded-full h-11 w-11 m-auto bg-gray-400 flex items-center justify-center relative group cursor-pointer hover:bg-green-500 transition-colors duration-150"
                      :class="{ '!bg-green-500': task.department == 9 }"
                    >
                      <svg class="w-5 h-5 fill-white">
                        <use xlink:href="@/assets/i.svg#ico-comment"></use>
                      </svg>
                    </div>
                    <span
                      class="text-[10px] text-center uppercase py-1 m-auto block"
                      >CRM</span
                    >
                  </div>
                </div>
              </div>

              <div class="p-5 v-bbgray flex items-center">
                <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">
                  Тип задачи
                </p>
                <div class="relative w-full">
                  <select
                    v-if="task.department"
                    class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                    :class="{ '!border-rose-500': task.type < 1 }"
                    v-model="task.type"
                    @change="setTaskType(task)"
                  >
                    <option disabled value="">Выбрать</option>
                    <template v-for="option in tasksTypes" :key="option">
                      <option
                        v-if="option.dep_id == task.department"
                        :value="option.id"
                      >
                        {{ option.dep }}: {{ option.name }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
            </template>

            <div v-else class="p-5 v-bbgray">
              <div class="preview p0">
                <div
                  class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
                >
                  <svg class="w-5 h-5 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-timer"></use>
                  </svg>
                </div>
                <template v-for="t in tasksTypes" :key="t">
                  <template v-if="t.id == task.type">
                    <sup>{{ t.dep }}</sup>
                    {{ t.name }}
                  </template>
                </template>
              </div>
            </div>
          </template>
        </template>

        <div class="p-5 v-bbgray">
          <div v-if="task.who == 1" class="flex items-center">
            <div
              class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
            >
              <IcoPic :pic="'u1robot.jpg'" :name="'Robot'" />
            </div>
            <div>
              <p class="text-[10px] text-gray-400 leading-none uppercase mb-1">
                АВТОР
              </p>
              <p class="font-latobold text-base">Робот</p>
            </div>
          </div>
          <div v-else class="flex items-center">
            <div
              class="min-w-[1.5rem] rounded-full mr-4 overflow-hidden bg-green-500"
            >
              <IcoPic :pic="task.wpicture" :name="task.wname" />
            </div>

            <div class="flex flex-col items-start">
              <p class="text-[10px] text-gray-400 leading-none uppercase mb-1">
                АВТОР:
                <span class="text-gray-500 font-latobold">{{
                  roles[task.role]
                }}</span>
              </p>
              <p class="font-latobold text-base">
                {{ task.wname }} {{ task.wsecondname }}
              </p>
            </div>
          </div>
        </div>

        <div class="p-5 v-bbgray relative group">
          <div v-if="task.uid > 0">
            <div v-if="task.uid == 1" class="flex items-center">
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
              >
                <IcoPic :pic="'u1robot.jpg'" :name="'Robot'" />
              </div>
              <div>
                <p
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                >
                  ОТВЕТСТВЕННЫЙ
                </p>
                <p class="font-latobold text-base">ТЕХПОДДЕРЖКА</p>
              </div>
            </div>
            <div v-else class="flex items-center">
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
              >
                <IcoPic :pic="task.upicture" :name="task.uname" />
              </div>
              <div>
                <p
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                >
                  ОТВЕТСТВЕННЫЙ
                </p>
                <p class="font-latobold text-base">
                  {{ task.uname }} {{ task.usecondname }}
                </p>
              </div>
            </div>
            <div
              v-if="
                task.status < 5 || ['Studio', 'Hosd', 'Director'].includes(role)
              "
              class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150 group cursor-pointer"
            >
              <svg
                @click="delTaskUser(task)"
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </div>
          </div>

          <div v-else class="flex items-center w-full">
            <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">
              ОТВЕТСТВЕННЫЙ
            </p>
            <div class="relative w-full">
              <select
                v-if="task.department"
                class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                v-model="task.uid"
                @change="setTaskUser(task)"
                :class="{ '!border-rose-500': task.uid < 1 }"
              >
                <option disabled value="">Выбрать</option>
                <template v-for="worker in tasksWorkers" :key="worker">
                  <template v-for="r in worker.roles" :key="r">
                    <option v-if="r == task.department" :value="worker.uid">
                      {{ worker.name }} {{ worker.secondname }}
                    </option>
                    <option
                      v-if="task.department == 8 && (r == 9 || r == 11)"
                      :value="worker.uid"
                    >
                      {{ worker.name }} {{ worker.secondname }}
                    </option>
                  </template>
                </template>
              </select>
            </div>
          </div>
        </div>

        <div v-if="task.status == 6" class="p-5 v-bbgray relative group">
          <div class="flex items-center w-full">
            <div
              class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#status-8"></use>
              </svg>
            </div>
            <!-- переименовать поле в базе в lead_id -->
            <div
              v-if="task.type == 101 && task.lead_id"
              @click="showLead(task.lead), closePopup()"
            >
              <p class="text-[10px] text-gray-400 leading-none uppercase mb-1">
                РЕЗУЛЬТАТ
              </p>
              <p class="font-latobold text-base">Лид #{{ task.lead?.iid }}</p>
            </div>
          </div>
        </div>

        <div class="flex px-5 py-4 flex-wrap items-center">
          <template
            v-if="
              profile.uid == task.uid ||
              profile.uid == task.who ||
              role == 'Studio' ||
              role == 'Hosd'
            "
          >
            <template
              v-if="
                task.status == 1 &&
                (profile.uid == task.who || role == 'Studio' || role == 'Hosd')
              "
            >
              <a
                class="my-1 mx-1 w-max v-btn bg-sky-500 hover:bg-sky-400 flex items-center"
                @click="publishTask()"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
                <span class="v-btn_text">Опубликовать</span>
              </a>
              <a
                class="my-1 mx-1 w-max v-btn bg-rose-500 hover:bg-rose-400 flex items-center"
                @click="delTask(task)"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#ico-delete"></use>
                </svg>
                <span class="v-btn_text">Удалить</span>
              </a>
            </template>
            <template
              v-if="
                task.status == 2 &&
                ![101, 102].includes(parseInt(task.type)) &&
                (profile.uid == task.who || role == 'Studio' || role == 'Hosd')
              "
            >
              <a
                class="my-1 mx-1 w-max v-btn bg-gray-500 hover:bg-gray-400 flex items-center"
                @click="setTaskStatus(1)"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#ico-edit"></use>
                </svg>
                <span class="v-btn_text">В черновики</span>
              </a>
              <a
                class="my-1 mx-1 w-max v-btn bg-rose-500 hover:bg-rose-400 flex items-center"
                @click="delTask(task)"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#ico-delete"></use>
                </svg>
                <span class="v-btn_text">Удалить</span>
              </a>
            </template>

            <template
              v-if="
                (task.status == 2 || task.status == 19 || task.status == 7) &&
                task.business_proc == 0
              "
            >
              <a
                v-if="parseInt(task.uid) == parseInt(profile.uid)"
                class="my-1 mx-1 w-max v-btn bg-blue-500 hover:bg-blue-400 flex items-center"
                @click="setTaskStatus(4)"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#status-3"></use>
                </svg>
                <span class="v-btn_text">Начать</span>
              </a>
            </template>
            <template
              v-if="
                (task.status == 2 || task.status == 19 || task.status == 7) &&
                task.business_proc > 0
              "
            >
              <a
                v-if="parseInt(task.uid) == parseInt(profile.uid)"
                class="my-1 mx-1 w-max v-btn bg-blue-500 hover:bg-blue-400 flex items-center"
                @click="setTouchFromTask()"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#status-3"></use>
                </svg>
                <span class="v-btn_text">Создать касание</span>
              </a>
            </template>
            <template v-if="task.status == 4">
              <a
                v-if="
                  role == 'Studio' ||
                  role == 'Hosd' ||
                  parseInt(task.uid) == parseInt(profile.uid)
                "
                class="my-1 mx-1 w-max v-btn bg-teal-600 hover:bg-teal-500 flex items-center"
                @click="setTaskStatus(5)"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#ico-ok"></use>
                </svg>
                <span class="v-btn_text">Завершить</span>
              </a>
            </template>
            <template v-if="task.status == 5">
              <a
                v-if="
                  role == 'Studio' ||
                  role == 'Hosd' ||
                  parseInt(task.who) == parseInt(profile.uid)
                "
                class="my-1 mx-1 w-max v-btn bg-green-500 hover:bg-green-400 flex items-center"
                @click="setTaskStatus(6)"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#ico-ok"></use>
                </svg>
                <span class="v-btn_text">Принять</span>
              </a>
              <a
                v-if="
                  role == 'Studio' ||
                  role == 'Hosd' ||
                  parseInt(task.who) == parseInt(profile.uid)
                "
                class="my-1 mx-1 w-max v-btn bg-red-500 hover:bg-red-400 flex items-center"
                @click="setTaskStatus(7)"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <span class="v-btn_text">Отклонить</span>
              </a>
            </template>
          </template>

          <template
            v-if="profile.uid == task.uid || role == 'Studio' || role == 'Hosd'"
          >
            <!-- <template v-if="task.status < 5">
              <template
                v-if="task.type == 101 && task.object == 4 && task.oid > 0"
              >
                <a
                  class="my-1 mx-1 w-max v-btn bg-green-500 hover:bg-green-400 flex items-center"
                  @click="addTaskLead(task.oid)"
                >
                  <svg
                    class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                  >
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                  <span class="v-btn_text">Создать лид</span>
                </a>
              </template>
            </template> -->
            <template v-if="task.status == 4">
              <span class="ml-4 text-base font-latobold">Задача в работе</span>
            </template>
            <template v-if="task.status == 5">
              <span class="ml-4 text-base font-latobold">Задача завершена</span>
            </template>
            <template v-if="task.status == 6">
              <span class="ml-4 text-base font-latobold text-green-500"
                >Задача проверена</span
              >
            </template>
          </template>
        </div>
      </div>

      <div
        class="px-5 pt-12 pb-6 w-1/2 overflow-y-auto max-l:border-gray-700 max-l:border-t-2 max-l:w-full max-l:h-1/2 max-l:pt-3"
      >
        <div><CommentList :id="task.id" :iid="task.iid" type="task" /></div>

        <div class="flex flex-wrap py-5">
          <template v-for="i in task.images" :key="i">
            <div class="relative m-2 w-16">
              <a
                class="absolute -right-2 -top-2 group"
                v-if="profile.uid == i.uid"
                @click="delImg({ img: i, item: task })"
              >
                <svg
                  class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150"
                >
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
              </a>
              <a
                class="flex flex-col w-full text-center"
                :href="'https://api.tahocrm.ru/uploads/task/orig/' + i.img"
                target="_blank"
              >
                <span class="w-16 h-16 rounded-md overflow-hidden mb-1">
                  <img
                    v-if="
                      i.img.includes('png') ||
                      i.img.includes('jpg') ||
                      i.img.includes('jpeg') ||
                      i.img.includes('bmp')
                    "
                    class="w-full h-full object-cover"
                    :src="imagePrefix + 'task/mini/' + i.img"
                    alt=""
                  />
                  <img
                    v-else-if="i.img.includes('pdf')"
                    class="w-full h-full object-cover"
                    src="https://api.tahocrm.ru/images/pdf-file.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="w-full h-full object-cover"
                    src="https://api.tahocrm.ru/images/txt-file.png"
                    alt=""
                  />
                </span>
              </a>
            </div>
          </template>
        </div>
        <UploadImg
          :item="task"
          :id="parseInt(task.id)"
          type="task"
          :status="1"
          name="ИЗОБРАЖЕНИЕ"
        />
      </div>
    </template>
    <div v-else class="loader opacity1 mt30">
      <div>
        <img src="https://api.tahocrm.ru/images/loader.svg" width="44" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CommentList from "../components/CommentList.vue";
import UploadImg from "../components/ui/UploadImg.vue";
import IcoPic from "../components/ui/IcoPic.vue";
import TaskStatus from "../components/ui/TaskStatus.vue";
import API from "../services/api";

export default {
  name: "TaskEdit",

  components: { CommentList, UploadImg, IcoPic, TaskStatus },

  emits: ["close"],

  data() {
    return {
      imagePrefix: API.IMG,
      showtime: false,
      fasttime: false,
      timeDateDeadline: "",

      roles: [
        "",
        "Менеджер",
        "Логист",
        "Директор",
        "Инженер",
        "КК",
        "Клиент",
        "",
        "Admin",
        "Финансист",
        "Ассистент",
        "Бухгалтер",
      ],
    };
  },

  computed: {
    ...mapGetters([
      "profile",
      "s",
      "role",
      "task",
      "tasksWorkers",
      "tasksTypes",
    ]),
    canShowGoParent() {
      return true; // this.task.object==11;
    },
  },

  methods: {
    ...mapActions([
      "setTaskDep",
      "setTaskType",
      "delTaskType",
      "setTaskUser",
      "delTaskUser",
      "delTask",
      "setTaskStatus",
      "setTaskDate",
      "setTaskMinutes",
      "getTaskTypesWorkers",
      "delImg",
      "addLead",
      "closePopup",
      "showClient",
      "showContact",
      "showCar",
      "showLead",
      "showOrder",
      "showLeadById",
      "showTouchById",
      "getLead",
      "getTouch",
      "getOrder",
      "setTouchFromT",
    ]),
    addTaskLead(client_id) {
      let formData = new FormData();
      formData.append("client_id", client_id);
      formData.append("task_id", this.task.id);
      this.addLead(formData);
      this.closePopup();
    },
    setTouchFromTask() {
      let formData = new FormData();

      formData.append("client_id", this.task.client_id);
      formData.append("business_proc", this.task.business_proc);
      formData.append("task_id", this.task.id);

      this.setTouchFromT(formData);
      this.$emit("close");
    },

    publishTask() {
      // if (this.task.minute == null || this.task.minute == 0) {
      //   alert("Выставите время выполнение задачи");
      // } else {
      let currentDate = new Date();
      // так как время берется по гринвичу, я просто прибавляю 3 часа
      currentDate.setMinutes(currentDate.getMinutes() + 195);
      this.timeDateDeadline = currentDate
        .toISOString()
        .slice(0, 16)
        .replace("T", " ");
      console.log("this.fasttime", this.fasttime);
      if (this.fasttime == true) {
        this.setTaskDate(this.timeDateDeadline);
      }
      this.setTaskStatus(2);
      // }
    },

    showThisClient() {
      if (["Manager", "Logist", "Studio", "Finance"].includes(this.role)) {
        this.showClient(this.task.client);
        this.s.popup = false;
      }
    },
    showThisClientObj() {
      if (["Manager", "Logist", "Studio", "Finance"].includes(this.role)) {
        this.showClient(this.task.obj);
        this.s.popup = false;
      }
    },

    async goToParent() {
      try {
        if (this.canShowGoParent) {
          console.log("OBJECT", this.task.object, this.task.oid, this);
          switch (this.task.object) {
            case 12:
              this.showTouchById(this.task.oid);
              this.$emit("close");
              break;
            case 11:
              this.showLeadById(this.task.oid);
              this.$emit("close");
              break;
            case 1:
              this.showOrder(this.task.order);
              this.$emit("close");
              break;
            case 4:
              this.showClient(this.task.client);
              this.s.popup = false;
              //this.getClient();
              //this.$emit('close');
              break;
          }
        }
      } catch (_) {
        console.log(_);
      }
    },
    async compliteTask() {
      await this.setTaskStatus(this.task.type == 101 ? 5 : 6);
      if (this.task.object == 11) await this.goToParent();
    },
    currentClient() {
      console.log("TASK: ", this.task.client);
      if (!this.task.client) {
        let client_id =
          this.task?.lead?.client_id ?? this.task?.order?.client_id;
        console.log(
          "!!!",
          client_id,
          this.task?.lead?.client_id,
          this.task?.order?.client_id
        );
        if (client_id) {
          return API.GET("client/client-by-id", { id: client_id }).then(
            (res) => {
              this.task.client = res.data;
              this.task.client_id = client_id;
            }
          );
        }
      }
    },
  },

  mounted() {
    this.getTaskTypesWorkers();
    window.DEBUG_TASK_EDIT = this;
    //this.currentClient();
    setTimeout(() => this.currentClient(), 1000);
  },
  updated() {
    //this.urrentClient();
  },
};
</script>
