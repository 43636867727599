<template>
  <template v-if="client.id">
    <div class="flex -mx-2 mt-6">
      <div class="w-1/4 px-2">
        <div class="bg-white rounded-lg w-full">
          <div
            class="p-5 v-bbgray cursor-pointer"
            @click="this.$router.push('/client/company')"
          >
            <a class="flex items-center cursor-pointer group">
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg"
                id="picture"
              >
                <p>{{ ClientCompanies.length }}</p>
              </span>
              <div>КОМПАНИИ</div>
            </a>
          </div>
        </div>
      </div>

      <div class="w-1/4 px-2">
        <div class="bg-white rounded-lg w-full">
          <div
            class="p-5 v-bbgray cursor-pointer"
            @click="this.$router.push('/client/contact')"
          >
            <a class="flex items-center cursor-pointer group">
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg"
                id="picture"
              >
                <p>{{ ClientContacts.length }}</p>
              </span>
              <div>КОНТАКТЫ</div>
            </a>
          </div>
        </div>
      </div>

      <div class="w-1/4 px-2">
        <div class="bg-white rounded-lg w-full">
          <div
            class="p-5 v-bbgray cursor-pointer"
            @click="this.$router.push('/client/car')"
          >
            <a class="flex items-center cursor-pointer group">
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg"
                id="picture"
              >
                <p>{{ ClientCars.length }}</p>
              </span>
              <div>ТС</div>
            </a>
          </div>
        </div>
      </div>

      <div class="w-1/4 px-2">
        <div class="bg-white rounded-lg w-full">
          <div
            class="p-5 v-bbgray cursor-pointer"
            @click="this.$router.push('/order')"
          >
            <a class="flex items-center cursor-pointer group">
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg"
                id="picture"
              >
                <p>{{ ClientOrders.length }}</p>
              </span>
              <div>ЗАКАЗЫ</div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white rounded-lg mt-4">
      <div class="p-5 v-bbgray">
        <a class="flex items-center cursor-pointer group">
          <span
            class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg"
            id="picture"
          >
            <img
              class="w-full h-full object-cover"
              v-if="client.picture"
              :src="'https://api.tahocrm.ru/uploads/users/' + client.picture"
              alt=""
            />
            <p v-else>{{ client?.name.slice(0, 1) }}</p>
          </span>
          <div class="flex flex-col justify-center overflow-hidden pr-8">
            <span
              class="text-[10px] text-gray-400 leading-none uppercase mb-1"
              >{{ client?.code }}</span
            >
            <span
              class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
              >{{ client?.name }}</span
            >
          </div>
        </a>
      </div>
      <div class="p-5 v-bbgray">
        <div class="flex">
          <span class="w-2/5">Полное наименование</span>
          <span class="font-latobold">{{ client?.full }}</span>
        </div>

        <div class="flex">
          <span class="w-2/5">Адрес</span>
          <span class="font-latobold"
            >{{ client?.city?.name }} {{ client?.address }}</span
          >
        </div>

        <div v-if="client?.address1" class="flex">
          <span class="w-2/5"
            >Адрес автопарка
            <span v-if="client?.box1"> с боксом</span>
            <span v-else> без бокса</span>
          </span>
          <span class="font-latobold">{{ client?.address1 }}</span>
        </div>
        <div v-if="client?.address2" class="flex">
          <span class="w-2/5"
            >Адрес автопарка
            <span v-if="client?.box2"> с боксом</span>
            <span v-else> без бокса</span>
          </span>
          <span class="font-latobold">{{ client?.address2 }}</span>
        </div>
        <div v-if="client?.address3" class="flex">
          <span class="w-2/5"
            >Адрес автопарка
            <span v-if="client?.box3"> с боксом</span>
            <span v-else> без бокса</span>
          </span>
          <span class="font-latobold">{{ client?.address3 }}</span>
        </div>

        <div class="flex">
          <span class="w-2/5">Телефон</span>
          <span class="font-latobold">{{ client?.phone }}</span>
        </div>
        <div class="flex">
          <span class="w-2/5">Email</span>
          <span class="font-latobold">{{ client?.email }}</span>
        </div>
      </div>

      <div class="p-5 v-bbgray">
        <div class="flex">
          <span class="w-2/5">НДС</span>
          <span class="font-latobold">{{ n[client?.nds] }}</span>
        </div>
      </div>

      <div class="p-5 v-bbgray flex-col">
        <div class="flex">
          <span class="w-2/5">Направление</span>
          <span class="font-latobold">{{ b[client?.business] }}</span>
        </div>
        <div class="flex">
          <span class="w-2/5">Кол-во ТС</span>
          <span class="font-latobold" v-if="client?.ts">{{ client?.ts }}</span>
        </div>
      </div>

      <div class="p-5">
        <div class="flex">
          <span class="w-2/5">Способ привлечения</span>
          <span class="font-latobold" v-if="client?.utm">{{
            f[client?.utm]
          }}</span>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MyInfo",

  data() {
    return {
      f: [
        "",
        "Интернет (сайт)",
        "Рекомендация",
        "Соц сети",
        "Авито",
        "Агент",
        "База потерянные клиенты",
        "База Лукаша",
        "Холодный обзвон",
        "Рассылка",
        "Первичная клиентская база",
        "Звонок навипорт",
        "Вторичный клиент",
      ],
      b: [
        "",
        "Логистический бизнес (перевозки)",
        "Строительный бизнес, спецтехника",
        "Среднегабаритная перевозка (городские перевозки)",
        "Собственная доставка (свой транспортный отдел)",
        "Другое",
      ],
      n: ["", "с НДС", "без НДС", "НДС/без НДС"],
    };
  },

  computed: {
    ...mapGetters([
      "client",
      "ClientCompanies",
      "ClientContacts",
      "ClientCars",
      "ClientOrders",
    ]),

    plurComp() {
      let words = ["компания", "компании", "компаний"];
      let n = this.ClientCompanies.length;

      n = n % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) return words[2];
      if (n1 > 1 && n1 < 5) return words[1];
      if (n1 == 1) return words[0];
      return words[2];
      // return this.pluralize(this.ClientCompanies.length, ["компаний", "компании", "компания"]);
    },

    plurCont() {
      return this.pluralize(this.ClientContacts.length, [
        "контакт",
        "контакта",
        "контактов",
      ]);
    },

    plurCar() {
      return this.pluralize(this.ClientCars.length, ["ТС", "ТС", "ТС"]);
    },

    plurOrder() {
      return this.pluralize(this.ClientOrders.length, [
        "заказ",
        "заказа",
        "заказов",
      ]);
    },
  },

  methods: {
    ...mapActions(["myClient", "pluralize"]),

    pluralize(n, words) {
      n = n % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) return words[2];
      if (n1 > 1 && n1 < 5) return words[1];
      if (n1 == 1) return words[0];
      return words[2];
    },
  },

  mounted() {
    this.myClient();
  },
};
</script>
