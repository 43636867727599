<template>
  <div
    v-if="s.popup"
    @click="closePopup()"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
    id="overlay"
  ></div>
  <div
    v-if="s.popup == 'changeMan'"
    id="popnew"
    class="w-1/2 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <h2 class="text-center text-2xl mt-2">Выберите нового менеджера</h2>
    <p class="text-center">он будет назначен выбранным клиентам</p>
    <p class="text-center mb-2">выбрано клиентов: {{ checked.length }}</p>
    <div
      v-for="man in selectManagers"
      :key="man"
      class="flex px-4 py-2 v-bbgray items-center transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white"
      @click="setGroupManagerAndUpdate(man)"
    >
      <div class="w-11 h-11 rounded-full mr-3 overflow-hidden">
        <IcoPic :pic="man.picture" :name="man.name" />
      </div>
      <div class="w-full font-latobold">
        {{ man.name }} {{ man.secondname }}
      </div>
    </div>
  </div>

  <div
    class="mb-12 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px"
  >
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="Switch('all')"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
        >
          {{ activity?.all ?? "нет" }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-8 opacity-100 text-status-1 font-latobold"
      >
        Все<br />
        {{ activity.all_ts }} из {{ activity.all_countcar }}
      </span>
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="Switch('active')"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
        >
          {{ activity?.active ?? "нет" }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-5 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-8 opacity-100 text-status-5 font-latobold"
      >
        Активные<br />
        {{ activity.active_ts }} из {{ activity.active_countcar }}
      </span>
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="Switch('back')"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
        >
          {{ activity?.back ?? "нет" }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-10 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-8 opacity-100 text-status-10 font-latobold"
      >
        Возврат<br />
        {{ activity.back_ts }} из {{ activity.back_countcar }}
      </span>
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="Switch('passive')"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#status-5"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
        >
          {{ activity?.passive ?? "нет" }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-7 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-8 opacity-100 text-status-7 font-latobold"
      >
        Неактивные<br />
        {{ activity.passive_ts }} из {{ activity.passive_countcar }}
      </span>
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="Switch('empty')"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
        >
          {{ activity?.empty ?? "нет" }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-12 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-8 opacity-100 text-status-12 font-latobold"
      >
        Без заказов<br />
        {{ activity.empty_ts }} из {{ activity.empty_countcar }}
      </span>
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="Switch('new')"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
        >
          {{ activity?.new ?? "нет" }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-13 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-8 opacity-100 text-status-13 font-latobold"
      >
        Новые<br />
        {{ activity.new_ts }} из {{ activity.new_countcar }}
      </span>
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="Switch('dead')"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-no"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
        >
          {{ activity?.dead ?? "нет" }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-black group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-8 opacity-100 text-black font-latobold"
      >
        Мертвые<br />
        {{ activity.dead_ts }} из {{ activity.dead_countcar }}
      </span>
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="Switch('removed')"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-delete"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
        >
          {{ activity?.removed ?? "нет" }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-20 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
      >
        Удалённые
      </span>
    </div>
  </div>
  <div class="pb-4 w-full flex flex-wrap items-center">
    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
      type="date"
      v-model="s.active_start"
      @change="setSettings(s)"
    />
    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
      type="date"
      v-model="s.active_end"
      @change="setSettings(s)"
    />
    <button
      :disabled="!s.active_start && !s.active_end"
      class="v-btn bg-green-500 hover:bg-gray-400 mr-4 v-btn_text"
      @click="Update(false)"
    >
      Активные
    </button>
    <button
      :disabled="!s.active_start && !s.active_end"
      class="v-btn bg-red-500 hover:bg-gray-400 mr-4 v-btn_text"
      type="date"
      @click="Update(true)"
    >
      Не Активные
    </button>
  </div>
  <div class="pb-5 w-full flex flex-wrap items-center">
    <h2
      class="mr-15px min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4"
    >
      <span
        v-if="s.activity == 'all' || !s.activity"
        class="w-full text-lg leading-none text-teal-700"
      >
        {{ activity?.all ?? "нет" }}
      </span>
      <span
        v-if="s.activity == 'active'"
        class="w-full text-lg leading-none text-teal-700"
      >
        {{ activity?.active ?? "нет" }}
      </span>
      <span
        v-if="s.activity == 'back'"
        class="w-full text-lg leading-none text-teal-700"
      >
        {{ activity?.back ?? "нет" }}
      </span>
      <span
        v-if="s.activity == 'passive'"
        class="w-full text-lg leading-none text-teal-700"
      >
        {{ activity?.passive ?? "нет" }}
      </span>
      <span
        v-if="s.activity == 'empty'"
        class="w-full text-lg leading-none text-teal-700"
      >
        {{ activity?.empty ?? "нет" }}
      </span>
      <span
        v-if="s.activity == 'new'"
        class="w-full text-lg leading-none text-teal-700"
      >
        {{ activity?.new ?? "нет" }}
      </span>
      <span
        v-if="s.activity == 'dead'"
        class="w-full text-lg leading-none text-teal-700"
      >
        {{ activity?.dead ?? "нет" }}
      </span>
      <span
        v-if="s.activity == 'removed'"
        class="w-full text-lg leading-none text-teal-700"
      >
        {{ activity?.removed ?? "нет" }}
      </span>
      <br />
      {{ activity.ts }} из {{ activity.countcar }}
    </h2>

    <div class="flex-2_0 mr-15px min-w-[180px] my-4 relative group">
      <input
        type="text"
        class="h-9 w-full px-3 py-2 text-sm bg-slate-50"
        placeholder="Название, инн, фамилия, гос.номер"
        v-model="s.findme"
        @input="setSettings(s)"
      />
    </div>
    <select
      class="my-2 h-9 w-44 mr-4 bg-slate-50"
      v-model="s.manager"
      @change="setSettings(s)"
    >
      <option value="0" disabled="disabled">Менеджер</option>
      <option value="0">Без менеджера</option>
      <template v-for="m in selectManagers" :key="m">
        <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
      </template>
    </select>
    <select
      class="my-2 h-9 w-44 mr-4 bg-slate-50"
      v-model="s.carRange"
      @change="setSettings(s)"
    >
      <option value="0" disabled="disabled">Кол-во ТС</option>
      <option value="0">Все ТС</option>
      <option value="0 0">0</option>
      <option value="1 1">1</option>
      <option value="2 10">2-10</option>
      <option value="11 30">11-30</option>
      <option value="31 50">31-50</option>
      <option value="51">&gt;50</option>
    </select>

    <div
      class="mr-15px v-btn duration-100 transition ease-linear"
      style="align-items: center; display: flex"
      :class="{ 'bg-green-500 text-white': changed, 'bg-gray-300': !changed }"
      @click="Update()"
    >
      Поиск
      <svg
        :class="{ 'fill-white': changed }"
        class="ml-4 w-5 h-5 group-focus-within:fill-green-500 transition duration-150"
      >
        <use xlink:href="@/assets/i.svg#ico-search"></use>
      </svg>
    </div>
    <div
      class="mr-15px v-btn duration-100 transition ease-linear"
      style="align-items: center; display: flex"
      :class="{ 'bg-gray-500 text-white': clean, 'bg-gray-300': !clean }"
      @click="Clear()"
    >
      Сброс
      <svg
        :class="{ 'fill-white': changed }"
        class="ml-4 w-5 h-5 group-focus-within:fill-green-500 transition duration-150"
      >
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </div>
  </div>
  <div class="pb-4 w-full flex flex-wrap items-center">
    <select
      v-model="s.page_size_in_client_arhive"
      style="padding: 7px"
      @change="Search()"
    >
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
    <Paginator
      style="margin-bottom: 0"
      :count="pageCount"
      v-model="s.page_in_client_arhive"
      :click="Search"
    />
    <div class="flex items-center mx-4" v-if="checked?.length">
      С отмеченными ({{ checked.length }})
      <select v-model="action" class="my-2 mx-2 h-9 w-44 mr-4 bg-slate-50">
        <option value="">Ничего</option>
        <option value="del">Удалить</option>
        <option value="diy">В мёртвые</option>
        <option value="manager">Назначить менеджера</option>
        <option value="type0">Выбрать тип (Не выбран)</option>
        <option value="type1">Выбрать тип (Водитель)</option>
        <option value="type2">Выбрать тип (Перевозчик)</option>
      </select>
      <button
        :disabled="!action"
        @click="MassActionClick"
        :class="{ 'bg-rose-500': action, 'bg-rose-300': !action }"
        class="mr-15px v-btn duration-100 transition ease-linear"
      >
        Выполнить
      </button>
      <button
        class="v-btn duration-100 transition ease-linear bg-orange-400"
        @click="
          action = '';
          checked = [];
        "
      >
        Сбросить
      </button>
    </div>
  </div>

  <div style="position: relative" v-if="ClientArhive?.length > 0 && !s.loader">
    <div class="preloader-wrap" v-if="loadingWrap == true">
      <img src="@/assets/loading-clients.gif" alt="" />
    </div>
    <div
      class="w-full bg-white v-bgray2 mb-4 overflow-hidden rounded-xl relative overflow-x-auto"
    >
      <div
        class="grid grid-cols-[4rem_minmax(12rem,_1fr)_14rem_8rem_8rem_8rem_8rem_8rem] items-center h-10 v-bbgray px-4 uppercase min-w-full"
      >
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          <input
            type="checkbox"
            @change="SelectAllPage()"
            v-model="massCheckValue"
          />
          №
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          Наименование
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          активность
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          <div
            class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
          >
            Менеджер
          </div>
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          Создан
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          Заказов
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          ТС
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          Дата заказа
        </div>
      </div>

      <div
        v-for="item in PaginatedClientArhive"
        :key="item"
        class="grid grid-cols-[4rem_minmax(12rem,_1fr)_14rem_8rem_8rem_8rem_8rem_8rem] px-4 py-2 v-bbgray items-center transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white status-11 max-2xl:grid-cols-all-mobile max-2xl:auto-rows-[50px]"
        :class="{
          'status-11': item.correct == 0,
          'status-6': item.correct == 1,
        }"
      >
        <div class="cell w5">
          <input
            v-if="CanBeChecked(item)"
            type="checkbox"
            :value="item.id"
            v-model="checked"
          />
          {{ item.iid }}
        </div>
        <div class="cell name w40" @click="showClient(item)">
          <a
            class="flex items-center cursor-pointer group"
            @click="showClient(item)"
          >
            <div
              v-if="item.correct != 1"
              class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-8"
            >
              <svg class="h-5 w-5 fill-white">
                <use xlink:href="@/assets/i.svg#status-11"></use>
              </svg>
            </div>
            <template v-else>
              <div
                class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-6"
              >
                <IcoPic :pic="item.picture" :name="item.name" />
              </div>
            </template>
            <span
              class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
              >{{ item.name }}</span
            >
          </a>
        </div>
        <div
          class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
          :class="{
            'bg-status-5': item.activity === 'active' && !item.del,
            'bg-status-7': item.activity === 'passive' && !item.del,
            'bg-status-10': item.activity === 'back' && !item.del,
            'bg-status-19': item.activity === 'dead' && !item.del,
            'bg-status-12':
              (!item.activity && !item.del) || item.activity === 'nopay',
            'bg-status-13': item.activity === 'new' && !item.del,
            'bg-status-20': item.del,
          }"
        ></div>
        <div class="cell w20 flx">
          <div v-if="item.mname" class="flex items-center">
            <div
              class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-status-7"
            >
              <IcoPic :pic="item.mpicture" :name="item.mname" />
            </div>
            <span class="font-latobold">{{ item.mname }}</span>
          </div>
        </div>
        <div class="flex justify-center">
          <div v-if="item.create_at">
            {{ moment(item.create_at).format("DD.MM.YYYY") }}
          </div>
          <div v-else>-</div>
        </div>
        <div class="flex items-center justify-center">
          <svg class="w-5 h-5 fill-gray-400 mr-2">
            <use xlink:href="@/assets/i.svg#nav-flow"></use>
          </svg>
          <span>{{ item.countorders }}</span>
        </div>
        <div class="text-center">
          <span>{{ item.countcars }} из {{ item.ts }}</span>
          <template v-if="item.ts">
            <div
              class="mt-2 w-full flex h-[5px] rounded-full overflow-hidden bg-gray-200"
            >
              <div
                class="flex flex-col justify-center text-white w-full h-full"
                :style="'width:' + (item.countcars * 100) / item.ts + '%'"
                :class="{
                  'bg-status-7': (item.countcars * 100) / item.ts < 30,
                  'bg-status-10':
                    (item.countcars * 100) / item.ts > 29 &&
                    (item.countcars * 100) / item.ts < 60,
                  'bg-status-1':
                    (item.countcars * 100) / item.ts > 59 &&
                    (item.countcars * 100) / item.ts < 90,
                  'bg-status-5': (item.countcars * 100) / item.ts > 89,
                }"
              ></div>
            </div>
          </template>
        </div>
        <div class="text-center">
          <div v-if="item.lastorder">
            {{ moment(item.lastorder).format("DD.MM.YYYY") }}
          </div>
          <div v-else>-</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="s.loader" class="left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
  <div
    v-else
    style="position: relative"
    class="left-0 flex-cc w-full text-slate-400 text-2xl text-center"
  >
    <div class="preloader-wrap" v-if="loadingWrap == true">
      <img src="@/assets/loading-clients.gif" alt="" />
    </div>
    Нет данных<br />Используйте поиск
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Paginator from "@/components/ui/Paginator";

export default {
  name: "ClientArhive",
  components: { Paginator },

  data() {
    return {
      moment: moment,
      page: 0,
      checked: [],
      action: "",
      changed: false,
      massCheckValue: false,
      loadingWrap: false,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "role",
      "profile",
      "ClientArhive",
      "roles",
      "activity",
      "selectManagers",
    ]),
    PaginatedClientArhive() {
      return this.ClientArhive;
    },
    pageCount() {
      return Math.ceil(
        (this.activity[this.s.activity] ?? 0) /
          this.s.page_size_in_client_arhive
      );
    },
    clean() {
      return this.s.findme || this.s.manager || this.s.carRange !== "0";
    },
  },

  methods: {
    ...mapActions([
      "setGroupType",
      "getClientArhive",
      "getClientNoActive",
      "showClient",
      "setSettings",
      "clearSettings",
      "loadSettings",
      "setGroupManager",
      "closePopup",
      "removeClients",
      "killClients",
    ]),
    Refresh() {
      this.getClientArhive({
        page: this.s.page_in_client_arhive,
        page_size: this.s.page_size_in_client_arhive,
        findme: this.s.findme,
        activity: this.s.activity,
        manager: this.s.manager,
        car_range: this.s.carRange,
        active_start: this.s.active_start,
        active_end: this.s.active_end,
        flag_no_active: this.s.flag_no_active,
      });
    },
    Search() {
      // this.loadingWrap = true;
      // console.log("Wrapper 1", this.loadingWrap);

      this.setSettings(this.s);
      this.Refresh();

      // setTimeout(() => this.loadingWrap = false, 1500);
      // console.log("Wrapper 2", this.loadingWrap);
    },
    async Update(flag_no_active) {
      // alert('loading...');
      console.log("Обновляем");
      // await this.loadActivity({
      //     findme: this.s.findme,
      //     manager: this.s.manager,
      //     car_range: this.s.carRange,
      //     active_start: this.s.active_start,
      //     active_end: this.s.active_end,
      // });
      this.s.flag_no_active = flag_no_active;
      this.changed = false;
      this.Search();
    },
    async Passives() {
      this.changed = false;
      this.AlterRefresh();
    },
    Switch(activity) {
      this.s.activity = activity;
      this.s.page_in_client_arhive = 0;
      this.Search();
    },
    Change() {
      this.changed = true;
    },
    Clear() {
      this.clearSettings();
      this.Update();
    },
    MassActionClick() {
      switch (this.action) {
        case "del":
          return this.RemoveSelectedClients();
        case "diy":
          return this.KillSelectedClients();
        case "manager":
          return (this.s.popup = "changeMan");
        case "type0":
          this.setGroupTypeAndUpdate(0);
          break;
        case "type1":
          this.setGroupTypeAndUpdate(1);
          break;
        case "type2":
          this.setGroupTypeAndUpdate(2);
          break;
      }
    },
    async RemoveSelectedClients() {
      // let fd = new FormData();
      // this.checked.forEach((x) => fd.append("ids[]", x));
      // API.POST("client/remove-clients", fd).then((data) => {
      //   this.Update();
      //   this.checked = [];
      //   this.action = "";
      //   return data;
      // });

      await this.removeClients({ checked: this.checked });
      this.Update();
      this.checked = [];
      this.action = "";
      return true;
    },
    async KillSelectedClients() {
      // let fd = new FormData();
      // this.checked.forEach((x) => fd.append("ids[]", x));
      // API.POST("client/kill-clients", fd).then((data) => {
      //   this.Update();
      //   this.checked = [];
      //   this.action = "";
      //   return data;
      // });

      await this.killClients({ checked: this.checked });
      this.Update();
      this.checked = [];
      this.action = "";
      return true;
    },
    CanBeChecked(item) {
      if (this.$store.state.profile.role == "Studio") return true;
      if (this.$store.state.profile.role == "Manager")
        return item?.manager_id == this.$store.state.profile.profile.id;
      return false;
    },
    SelectAllPage() {
      console.log(this.massCheckValue);
      if (this.massCheckValue) {
        this.checked.push(...this.ClientArhive.map(({ id }) => id));
      } else {
        let ids = new Set(this.ClientArhive.map(({ id }) => id));
        this.checked = this.checked.filter((id) => !ids.has(id));
      }
    },

    async setGroupManagerAndUpdate(man) {
      await this.setGroupManager({
        clients: this.checked.join(","),
        manager_id: man.uid,
      });
      this.s.popup = false;
      await this.Update();
    },
    async setGroupTypeAndUpdate(typeC) {
      await this.setGroupType({
        clients: this.checked.join(","),
        type: typeC,
      });
      this.s.popup = false;
      await this.Update();
    },
  },

  async mounted() {
    this.loadSettings();
    await this.Update();
    window.DEBUG_CLIENT_ARHIVE = this;
  },
};
</script>

<style>
.preloader-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #6b6d7047;
  z-index: 1000;
  border-radius: 15px;
  backdrop-filter: blur(9px);
  transition: opacity 0.4s;
  filter: blur(2px);
  min-height: 65vh;
  top: 0;
}
.preloader-wrap img {
  margin: 0 auto;
}
</style>
