<template>
    <template v-if="doc">
        <a class="close flx" @click="$emit('close')">
            <span>Закрыть</span>
            <svg><use xlink:href="@/assets/i.svg#ico-no"></use></svg>
        </a>

        <div class="block form edit-form">
            <div class="cont title">
                <a class="preview">
                    <span class="avatardel">{{ doc.name?.slice(0, 1) }}</span>
                    <div>{{ doc.name }}</div>
                </a>
            </div>

            <div class="cox100 col75">
                <div class="cont">
                    <div class="fgrp cox100 col50 pr15">
                        <label>
                            Название
                            <input type="text" name="name" v-model="doc.name" @change="saveDoc(doc)" />
                        </label>
                    </div>
                    <div class="fgrp cox100 col50 pr15">
                        <label>
                            Дата окончания
                            <input type="date" name="date" v-model="doc.date" @change="saveDoc(doc)" />
                        </label>
                    </div>
                </div>
            </div>

            <div class="cox100 col25">
                <div class="mt20 mb20 bb">
                    <div class="flx flx-wrap tac">
                        <template v-for="i in doc.images" :key="i">
                            <div class="galimg">
                                <a class="del" v-if="profile.uid == i.uid" @click="delImg({ img: i, item: doc })">
                                    <svg class="f-gray f-delet"><use xlink:href="@/assets/i.svg#ico-no"></use></svg>
                                </a>
                                <a :href="'https://api.tahocrm.ru/uploads/client/' + i.img" target="_blank">
                                    <img :src="'https://api.tahocrm.ru/uploads/client/mini/' + i.img" alt="" />
                                </a>
                            </div>
                        </template>

                        <template v-for="i in doc.docs" :key="i">
                            <div class="galimg">
                                <a class="del" v-if="profile.uid == i.uid" @click="delDoc({ img: i, item: doc })">
                                    <svg class="f-gray f-delet"><use xlink:href="@/assets/i.svg#ico-no"></use></svg>
                                </a>
                                <a :href="'https://api.tahocrm.ru/uploads/client/orig/' + i.file" download>
                                    <img src="https://api.tahocrm.ru/images/pdf-file.png" />
                                </a>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="mb20">
                    <div class="p5">
                        <UploadImg :item="doc" :id="doc.id.toString()" type="doc" status="1" :name="'Сканы'" />
                    </div>
                </div>
            </div>
        </div>
    </template>

    <div v-else class="loader opacity1 mt30">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UploadImg from "../ui/UploadImg.vue";

export default {
    name: "DocEdit",

    components: { UploadImg },

    emits: ["close"],

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "client", "doc"]),
    },

    methods: {
        ...mapActions(["saveCompany", "delImg", "saveDoc"]),
    },

    mounted() {},
};
</script>
