<template>
    <div v-if="s.popup == 'edit'" class="w-3/4 absolute z-20 bg-white rounded-lg top-4 bottom-4">
        <CompanyEdit @close="close" />
    </div>
    <div v-if="s.popup" @click="close" class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80" id="overlay"></div>

    <template v-if="ClientCompanies">
        <div class="bg-white rounded-lg overflow-hidden mt-6">
            <div class="p-5 flex justify-between items-center v-bbgray">
                <h3 class="font-latobold text-base">Компании {{ ClientCompanies.length }}</h3>
            </div>
            <div class="grid grid-cols-2 px-5 py-2 v-bbgray">
                <p class="text-gray-400 uppercase text-[11px]">КОМПАНИЯ</p>
                <p class="text-gray-400 uppercase text-[11px]">ИНН</p>
            </div>

            <div v-for="item in ClientCompanies" :key="item" :id="item.iid" class="grid grid-cols-2 px-4 py-2 relative group items-center v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white">
                <div @click="editCompany(item), (this.s.popup = 'edit')">
                    <div class="flex items-center group cursor-pointer">
                        <div class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-lg text-white">
                            {{ item.name.slice(0, 1) }}
                        </div>
                        <span class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300">{{ item.name }}</span>
                    </div>
                </div>
                <div class="cell name">{{ item.inn }}</div>
            </div>
        </div>
    </template>

    <!-- <div v-if="s.popup == 'edit'" id="popnew" class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12">
        <CompanyEdit @close="close" />
    </div>
    <div v-if="s.popup" @click="close" id="overlay"></div>

    <template v-if="ClientCompanies">
        <div class="block clm4 list mini">
            <div class="cont flx flx-sb">
                <h3 class="m0">Компании {{ ClientCompanies.length }}</h3>
            </div>
            <div class="head flx-sb">
                <p class="name">Компания</p>
                <p class="name">Инн</p>
            </div>

            <div v-for="item in ClientCompanies" :key="item" :id="item.iid" class="line flx-sb hvr">
                <div class="cell name" @click="showCompany(item), (this.s.popup = 'view')">
                    <div class="preview">
                        <span class="avatardel">{{ item.name.slice(0, 1) }}</span>
                        <div>{{ item.name }}</div>
                    </div>
                </div>
                <div class="cell name">{{ item.inn }}</div>
                <div class="flx flx-end cell id hov">
                    <a class="flx mr15" @click="editCompany(item), (this.s.popup = 'edit')">
                        <svg class="f-gray"><use xlink:href="@/assets/i.svg#ico-edit"></use></svg>
                    </a>
                </div>
            </div>
        </div>
    </template> -->

    <div v-else class="loader opacity1 mt30">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CompanyEdit from "../../components/client/CompanyEdit";

export default {
    name: "MyCompany",

    components: { CompanyEdit },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "client", "ClientCompanies"]),
    },

    methods: {
        ...mapActions(["Tab", "getClientCompanies", "showCompany", "editCompany", "clearCompany", "delCompany", "goBack"]),

        close() {
            this.clearCompany();
            this.goBack();
        },
    },

    mounted() {
        this.getClientCompanies();
    },
};
</script>
