<template>
  <AlertMsg />
  <div class="h-full">
    <div
      class="fixed min-w-[212px] w-15p h-full overflow-hidden overflow-y-auto mob-max:overflow-y-auto pb-4 max-l:relative max-l:h-full max-l:w-full max-l:py-2"
    >
      <img
        class="fixed object-cover top-0 min-h-full max-w-none max-l:w-full max-l:h-full"
        src="@/assets/images/bg-leftbar.jpg"
        alt=""
      />
      <NavBar />
    </div>
    <div
      class="grid grid-cols-main__container h-screen w-full overflow-y-auto max-l:grid-cols-1"
    >
      <div
        class="px-4 col-start-2 bg-slate-200 relative overflow-auto max-l:col-start-auto"
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  methods: {
    ...mapActions(["getProfile"]),
  },
  mounted() {
    return this.getProfile();
  },
};
</script>

<style scoped>
.mclass {
  position: relative;
  min-height: 100%;
}
</style>
