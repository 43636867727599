<template>
    <template v-for="item in checkBonuses" :key="item.id">
        <div
            class="grid grid-cols-12 gap-2 min-h-60 px-4 py-2 v-bbgray bg-teal-light odd:bg-white items-center transition duration-300 cursor-pointer hover:shadow-v1"
        >
            <div class="flex items-center col-span-2">
                <div class="w-11 h-11 rounded-full bg-green-500 mr-2">
                    <IcoPic :pic="item?.wpicture" :name="item.wname" />
                </div>

                <div class="flex flex-col overflow-hidden">
                    <span class="text-[10px] text-gray-400 leading-none uppercase">Инженер</span>
                    <span class="text-black">{{ item.wname }}</span>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center" @click="editOrderId(item.violates[0]?.oid)">
                <span class="text-gray-400 text-[10px] leading-none uppercase">Заказ</span>
                <p class="text-sm font-bold text-status-5">{{ item.violates[0]?.iid }}</p>
            </div>

            <div class="flex items-center justify-center">
                <svg class="fill-status-6 min-w-[1rem] w-4 h-4 mr-2">
                    <use xlink:href="@/assets/i.svg#ico-time"></use>
                </svg>
                <div class="text-black text-sm">
                    {{ moment(item.date).format("DD.MM") }}
                </div>
            </div>

            <div class="flex flex-col items-center justify-center">
                <div v-for="vio in item.violates" :key="vio">
                    <template v-if="vio.type == 93">
                        <span class="text-gray-400 text-[10px] leading-none uppercase">начало</span>
                        <p class="text-sm text-red-500 text-center font-bold">{{ vio.count }} м.</p>
                    </template>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center">
                <div v-for="vio in item.violates" :key="vio">
                    <template v-if="vio.type == 94">
                        <span class="text-gray-400 text-[10px] leading-none uppercase">окончание</span>
                        <p class="text-sm text-red-500 text-center font-bold">{{ vio.count }} м.</p>
                    </template>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center">
                <span class="text-gray-400 text-[10px] leading-none uppercase">БОНУС</span>
                <p class="text-sm text-right font-bold">{{ item.sum }} р.</p>
            </div>

            <div class="flex flex-col items-center justify-center col-span-2">
                <span v-for="vio in item.violates" :key="vio">{{ vio.info }}</span>
            </div>

            <div class="flex items-center justify-center col-span-3">
                <template v-if="item.status == 2">
                    <div class="v-btn bg-green-500 hover:bg-green-400 flex items-center mr-4" @click="payBonus(item)">
                        <svg class="fill-white w-4 h-4 mr-2">
                            <use xlink:href="@/assets/i.svg#ico-ok"></use>
                        </svg>
                        <span class="v-btn_text">НАЧИСЛИТЬ</span>
                    </div>
                    <div class="v-btn bg-rose-500 hover:bg-rose-400 flex items-center" @click="delBonus(item)">
                        <svg class="fill-white w-4 h-4 mr-2">
                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                        <span class="v-btn_text">УДАЛИТЬ</span>
                    </div>
                </template>
                <template v-if="item.status == 8">
                    <svg class="fill-green-500 w-5 h-5 mr-2">
                        <use xlink:href="@/assets/i.svg#ico-ok"></use>
                    </svg>
                    Бонус начислен
                    <div class="v-btn ml-4 bg-slate-400 hover:bg-slate-300 flex items-center" @click="returnBonus(item)">
                        <span class="v-btn_text">ВЕРНУТЬ</span>
                    </div>
                </template>

                <template v-if="item.status == 7">
                    <svg class="fill-red-500 w-5 h-5 mr-2">
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    Бонус отменен
                    <div class="v-btn ml-4 bg-slate-400 hover:bg-slate-300 flex items-center" @click="returnBonus(item)">
                        <span class="v-btn_text">ВЕРНУТЬ</span>
                    </div>
                </template>
            </div>
        </div>
    </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "CheckBonuses",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["checkBonuses"]),
    },

    methods: {
        ...mapActions(["getBonuses", "payBonus", "delBonus", "returnBonus", "editOrderId"]),
    },

    mounted() {
        this.getBonuses();
    },
};
</script>
