<template>
    <section>
        <div class="goodone">
            <div class="flex-wrap-shit my-5">
                <div class="first-flex-wrap">
                    <div class="length-of-voronka">
                        <div class="days-gone">
                            {{ showNum(stages?.funnel) }} <span>дн</span>
                        </div>
                        <div class="label-days">
                            <span>длинна воронки</span>
                        </div>
                        <div class="plan">
                            ПЛАН: {{ showNum(planes?.funnel) }} <span>дн</span>
                        </div>
                        <div class="absolute-number"
                        :class="showSign(stages?.funnel - planes?.funnel)">
                            {{ showNum(stages?.funnel - planes?.funnel) }} <span>дн</span>
                        </div>
                    </div>
                    <div class="otklonenie-of-period">
                        <div class="days-change" :class="showSign(stages?.funnel - stages?.lastFunnel)">
                            {{ showNum(stages?.funnel - stages?.lastFunnel) }} <span>дн</span>
                        </div>
                        <div class="absolute-number"
                            :class="showSign(100 * (1 - stages?.lastFunnel / stages?.funnel))">
                            {{ showNum(100 * (1 - stages?.lastFunnel / stages?.funnel)) }}<span>%</span>
                        </div>
                        <div class="title">
                            <span>
                                Отклонение<br>от прошлого периода
                            </span>
                        </div>
                    </div>
                </div>
                <div class="second-flex-wrap">
                    <div
                        class="flex"
                    >
                        <DiffRomb
                            v-for="romb in rombs"
                            :key="romb"
                            :label="rombStyles[romb].label"
                            :ico="rombStyles[romb].ico"
                            :back="rombStyles[romb].back"
                            :stage="stages[romb]"
                            :plane="planes[romb]"
                        >
                        </DiffRomb>
                    </div>
                    <p class="mt-auto">длина этапа по закрытым заказам, дн</p>
                </div>
                <div class="third-flex-wrap">
                    <div
                        class="flex"
                    >
                        <!-- <ListRomb
                            :ico="rombStyles.checked.ico"
                            :num="stages?.qq_check_count"
                            :label="rombStyles.checked.label"
                            :back="rombStyles.checked.back"
                            @click="currentRomb = 'checked'; $L('checked')"
                        ></ListRomb>
                        <ListRomb
                            :ico="rombStyles.canseled.ico"
                            :num="stages?.canceled_count"
                            :label="rombStyles.canseled.label"
                            :back="rombStyles.canseled.back"
                            @click="currentRomb = 'canseled'; $L('canseled')"
                        ></ListRomb> -->
                        <ListRomb
                            :ico="rombStyles.checked.ico"
                            :num="stages?.qq_check_count"
                            :label="rombStyles.checked.label"
                            :back="rombStyles.checked.back"
                        ></ListRomb>
                        <ListRomb
                            :ico="rombStyles.canceled.ico"
                            :num="stages?.canceled_count"
                            :label="rombStyles.canceled.label"
                            :back="rombStyles.canceled.back"
                            @click="currentRomb = 'canceled'; $L('canceled')"
                        ></ListRomb>
                    </div>
                    <p>заказы за период</p>
                </div>
            </div>

            <div class="flex-wrap-shit my-5">
                <div
                    class="wrap-table-1"
                >
                    <p style="width: 135px;">{{rombStyles.excess.label}}</p>
                    <!-- <ListRomb
                        :ico="rombStyles.excess.ico"
                        :num="stages?.all_penalty_count"
                        :back="rombStyles.excess.back"
                        @click="currentRomb = 'excess'; $L('excess')"
                    ></ListRomb> -->
                    <ListRomb
                        :ico="rombStyles.excess.ico"
                        :num="stages?.all_penalty_count"
                        :back="rombStyles.excess.back"
                    ></ListRomb>
                </div>
                <div class="wrap-table-2">
                    <p>закрытые заказы с превышенной длиной этапа</p>
                    <div
                        class="flex"
                    >
                        <ListRomb
                            v-for="romb in rombs"
                            :key="romb"
                            :ico="rombStyles[romb].ico"
                            :num="grouped[romb]?.length"
                            :label="rombStyles[romb].label"
                            :back="rombStyles[romb].back"
                            @click="currentRomb = romb; $L(romb)"
                        ></ListRomb>
                    </div>
                </div>
            </div>
            <Modal
                v-if="currentRomb && currentRomb != 'canceled'"
                @close="currentRomb = null"
            >
                <LoadedOrderList
                    :ids="grouped[currentRomb]"
                />
            </Modal>
            <Modal
                v-if="currentRomb == 'canceled'"
                @close="currentRomb = null"
            >
                <LoadedOrderList
                    :ids="grouped?.canceled"
                />
            </Modal>            
        </div>
  </section>
    
</template>
<script>
import DiffRomb from '@/components/report/DiffRomb.vue';
import ListRomb from '@/components/report/ListRomb.vue';
import Modal from '@/components/report/Modal.vue';
import LoadedOrderList from '@/components/report/LoadedOrderList.vue';
export default {
    name: "ReportClosedorders",
    components: {DiffRomb, ListRomb, LoadedOrderList, Modal},
    props: {
        stages: Object,
        planes: Object,
        grouped: Object,
    },
    data(){
        return {
            rombs: [
                'mintrans',
                'postponed',
                'appointed',
                'working',
                'completed'
            ],
            rombStyles: {
                mintrans: {
                    ico: "ico-edit",
                    back: "fill-status-12",
                    label: "Минтранс",
                },
                postponed: {
                    ico: "status-10",
                    back: "fill-status-10",
                    label: "Отложены",
                },
                appointed: {
                    ico: "status-3",
                    back: "fill-status-3",
                    label: "Назначены",
                },
                working: {
                    ico: "nav-masters",
                    back: "fill-status-1",
                    label: "В работе",
                },
                completed: {
                    ico: "status-5",
                    back: "fill-status-5",
                    label: "Выполнено",
                },

                excess: {
                    ico: "status-11",
                    back: "fill-dark-red",
                    label: "Цикл превышен",
                },
                checked: {
                    ico: "ico-ok",
                    back: "fill-status-6",
                    label: "Проверены ТО",
                },
                canceled: {
                    ico: "ico-no",
                    back: "fill-status-7",
                    label: "Отменено",
                },
            },
            currentRomb: null,
        }
    },
    computed: {
    },
    methods: {
        showNum(n){
            return Math.abs(+n || 0).toLocaleString(Intl.NumberFormat, { 
                maximumFractionDigits: 1,
             });
        },
        showSign(n){
            return (Math.abs( +n || 0) < 0.05)
                ? 'null-diff'
                : +n > 0
                ? 'positive-diff'
                : 'negative-diff';
        },
        $L(...a){console.log(...a)},
    },
    mounted(){
        console.log('stages', this.stages);
        console.log('planes', this.planes);
        console.log('grouped', this.grouped);
        window.DEBUG_REPORT_CLOSE = this;
    },
}
</script>
<style scoped>

    .flex-wrap-shit {
        display: flex;
        justify-content: space-between;
    }
    .first-flex-wrap {
        min-width: 292px;
    }
    .second-flex-wrap {
        width: 100%;
        margin: 0 20px 0 15px;
    }
    .third-flex-wrap {
        min-width: 344px;
    }
    .first-flex-wrap > div,
    .second-flex-wrap,
    .third-flex-wrap {
        border-radius: 5px;
        background: #F8FAFC;
        box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
    }
    .first-flex-wrap > div:first-child {
        margin-bottom: 10px;
        padding: 15px 25px 10px 25px;
    }
    .first-flex-wrap > div:last-child {
        padding: 30px 83px 15px 25px;
    }

    .length-of-voronka, .otklonenie-of-period {
        position: relative;
    }
    .absolute-number {
        position: absolute;
        top: 15px;
        right: 9px;
        font-size: 24px; 
        font-weight: 700; 
    }
    .otklonenie-of-period .title, .label-days {
        color: #262D29;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .days-gone, .days-change {
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 11px;
    }
    .days-gone span, .days-change span {
        font-size: 20px; 
    }
    .days-gone {
        color: #8E8E8E;
    }

    .label-days{
        margin-bottom: 20px;
    }
    .plan {
        color: #ABABAB;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
    .plan span {
        font-size: 9px; 
    }

    .second-flex-wrap, .third-flex-wrap {
        min-height: 100%;
    }

    .second-flex-wrap > p, .third-flex-wrap > p {
        color: #262D29;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
    .second-flex-wrap, .third-flex-wrap {
        padding: 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .wrap-table-1 {
        border-radius: 5px;
        background: #F8FAFC;
        box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
        padding: 15px 79px 40px 79px;
    }
    .wrap-table-1 p, .wrap-table-2 p {
        color: #262D29;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    .wrap-table-2 {
        margin-left: 15px;
        width: 100%;
        border-radius: 5px;
        background: #F8FAFC;
        box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
        padding: 15px 5px 25px 5px;
    }

    .null-diff {color: rgb(131, 131, 19);}
    .positive-diff {color: #FA344C; }
    .negative-diff {color: #22C55E;}
    .positive-diff::before {content: ">"; padding-right: 5px;}
    .negative-diff::before {content: "<"; padding-right: 5px;}
</style>