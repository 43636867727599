<template>
  <div class="relative flex items-center overflow-hidden w-16 h-16">
    <svg class="z-2 relative w-1/2 h-1/2 fill-white mx-auto">
      <use v-if="task.status == '1'" xlink:href="@/assets/i.svg#ico-edit"></use>
      <use v-if="task.status == '2'" xlink:href="@/assets/i.svg#status-1"></use>
      <use v-if="task.status == '4'" xlink:href="@/assets/i.svg#status-3"></use>
      <use v-if="task.status == '5'" xlink:href="@/assets/i.svg#status-5"></use>
      <use v-if="task.status == '6'" xlink:href="@/assets/i.svg#status-6"></use>
      <use v-if="task.status == '7'" xlink:href="@/assets/i.svg#ico-no"></use>
      <use
        v-if="task.status == '19'"
        xlink:href="@/assets/i.svg#status-11"
      ></use>
    </svg>
    <svg class="absolute top-0 left-0 w-full h-full" :class="bg">
      <use xlink:href="@/assets/i.svg#bg-romb"></use>
    </svg>
  </div>
</template>

<script>
export default {
  name: "TaskStatus",
  props: {
    task: Object,
  },

  computed: {
    bg() {
      let bg = "fill-status-1";

      if (this.task.status == 1) {
        bg = "fill-status-20";
      }
      if (this.task.status == 2) {
        bg = "fill-status-1";
      }
      if (this.task.status == 4) {
        bg = "fill-status-3";
      }
      if (this.task.status == 5) {
        bg = "fill-status-5";
      }
      if (this.task.status == 6) {
        bg = "fill-status-6";
      }
      if (this.task.status == 19) {
        bg = "fill-status-11";
      }
      if (this.task.status == 7) {
        bg = "fill-status-7";
      }
      if (this.task.status == 20) {
        bg = "fill-status-20";
      }
      return bg;
    },
  },
};
</script>
