import API from "../../services/api";
import moment from "moment";

export default {
    state: {
        cars: [],
        filteredCars: {},
        countCars: {},
        carArhives: [],
        filteredCarArhives: [],
    },

    mutations: {
        setCars: (state, cars) => {
            state.cars = cars;
        },
        filteredCars: (state, cars) => {
            state.filteredCars = cars;
        },
        countCars: (state, cars) => {
            state.countCars = cars;
        },
    },

    actions: {
        async getCars(ctx) {
            const t0 = performance.now();

            ctx.dispatch("setLoader", true);

            ctx.dispatch("loadCars").then(() => {
                ctx.dispatch("statusCar", ctx.rootState.s);
            });

            API.GET("car/monitoring-cars").then((res) => {
                ctx.commit("setCars", res.data);
                ctx.commit("filteredCars", res.data);
                ctx.commit("countCars", res.data);

                ctx.dispatch("statusCar", ctx.rootState.s).then(() => {
                    console.log(performance.now() - t0, "ms");
                    ctx.dispatch("setLoader", false);
                });
            });
        },

        // отчет по ТС
        async getCarsReport(ctx) {
            
            const t0 = performance.now();
            ctx.dispatch("setLoader", true);
            await API.GET("car/monitoring-cars-report").then((res) => {
                ctx.commit("setCars", res.data);
                ctx.commit("filteredCars", res.data);
                ctx.commit("countCars", res.data);
                ctx.dispatch("statusCar", ctx.rootState.s).then(() => {
                    console.log(performance.now() - t0, "ms");
                    ctx.dispatch("setLoader", false);
                });
            });
        },

        loadCars(ctx) {
            let cars = [];
            if (cars && cars?.length > 0) {
                ctx.commit("setCars", cars);
                ctx.commit("filteredCars", cars);
            }
        },

        fillCars(ctx) {
            ctx.commit("filteredCars", ctx.state.cars);
        },

        getCarById(ctx, id) {
            ctx.dispatch("setCar", null);
            API.GET("car/one", { id: id }).then((res) => {
                ctx.dispatch("setCar", res.data.car);
                ctx.dispatch("getCompaniesByClientId", res.data.car.client_id);
            });
        },

        findCar(ctx, s) {
            if (!ctx.state.cars) {
                return null;
            }
            let list = ctx.state.cars;
            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let id = null;
                    let num = null;
                    let iid = null;
                    let client = null;
                    let vialon = null;
                    let name = null;
                    id = item.id.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.iid) {
                    iid = item.iid.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.client) {
                        client = item.client.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.vialon) {
                        vialon = item.vialon.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.name) {
                        name = item.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (id || iid || num || client || vialon || name) {
                        return true;
                    }
                    return false;
                });
            }
            if (s.pay != "" && s.pay != undefined) {
                list = list.filter((item) => parseInt(item.cancel_id) == parseInt(s.pay));
            }

            if (s.monitoring != "" && s.monitoring != undefined) {
                list = list.filter((item) => parseInt(item.monitoring) == parseInt(s.monitoring));
            }
            if (s.category != "" && s.category != undefined) {
                console.log("s.category", s.category);
                if (s.category == "none") {
                    list = list.filter((item) => item.tariff === null);
                } else {
                    list = list.filter((item) => parseInt(item.tariff) == parseInt(s.category));
                }
            }
            if (s.type != "" && s.type != undefined) {
                if (s.type == "none") {
                    list = list.filter((item) => item.operator === null);
                } else {
                    list = list.filter((item) => item.operator && String(item.operator?.toLowerCase()) == String(s.type.toLowerCase()));
                }
            }
            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.start >= s.start || item.end >= s.start);
            }
            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.start <= s.end || item.end <= s.end);
            }

            if (list.length != ctx.state.cars?.length) {
                s.clear = true;
            }
            ctx.commit("filteredCars", list);
            ctx.commit("countCars", list);
            ctx.dispatch("setSettings", s);
        },

        statusReportCar(ctx, s) {
            console.log('Статусы ТС Отчет');
            ctx.dispatch("findCar", s);

            let list = ctx.state.filteredCars;

            if(s.client != null){
                list = list.filter((item)=>{
                    if(s.client==0){
                        return !item.client_id;
                    }else return item.client_id == s.client;
                })
            }

            if (s.status == 0) {
                list = list.filter((item) => parseInt(item.ts) == 0);
            }

            if (s.status == 1) {
                list = list.filter((item) => parseInt(item.ts) == 1);
            }

            if (s.status == 10) {
                list = list.filter((item) => parseInt(item.ts) <= 10 && parseInt(item.ts) >= 2 );
            }

            if (s.status == 30) {
                list = list.filter((item) => parseInt(item.ts) <= 30 && parseInt(item.ts) >= 11 );
            }

            if (s.status == 50) {
                list = list.filter((item) => parseInt(item.ts) <= 50 && parseInt(item.ts) >= 31 );
            }

            if (s.status == 99) {
                list = list.filter((item) => parseInt(item.ts) > 50);
            }

            if (list.length != ctx.state.cars?.length) {
                s.clear = true;
            }

            ctx.commit("filteredCars", list);
        },


        statusCar(ctx, s) {
            console.log('Статусы');
            ctx.dispatch("findCar", s);
            let list = ctx.state.filteredCars;

            if(s.client != null){
                list = list.filter((item)=>{
                    if(s.client==0){
                        return !item.client_id;
                    }else return item.client_id == s.client;
                })
            }

            if (s.status == 1) {
                list = list.filter((item) => parseInt(item.monitoring) == 2 && item.start >= moment().startOf("month").format("YYYY-MM-DD"));
            }

            if (s.status == 2) {
                list = list.filter((item) => parseInt(item.monitoring) == 2);
            }

            if (s.status == 3) {
                list = list.filter((item) => parseInt(item.monitoring) == 1 && item.end >= moment().startOf("month").format("YYYY-MM-DD"));
            }

            if (s.status == 4) {
                list = list.filter((item) => parseInt(item.monitoring) == 1);
            }

            if (s.status == 5) {
                list = list.filter((item) => parseInt(item.monitoring) == 2 && item.start >= moment().startOf("month").format("YYYY-MM-DD"));
            }

            if (s.status == 9) {
                list = list.filter((item) => !item.monitoring || item.monitoring == null);
            }

            if (list.length != ctx.state.cars?.length) {
                s.clear = true;
            }

            ctx.commit("filteredCars", list);
        },

        findCarReport(ctx, s) {
            if (!ctx.state.cars) {
                return null;
            }
            let list = ctx.state.cars;
            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let id = null;
                    let num = null;
                    let client = null;
                    let vialon = null;
                    id = item.id.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.client) {
                        client = item.client.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.vialon) {
                        vialon = item.vialon.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (id || num || client || vialon) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.pay != "" && s.pay != undefined) {
                list = list.filter((item) => parseInt(item.cancel_id) == parseInt(s.pay));
            }

            if (s.monitoring != "" && s.monitoring != undefined) {
                list = list.filter((item) => parseInt(item.monitoring) == parseInt(s.monitoring));
            }

            if (s.category != "" && s.category != undefined) {
                if (s.category == "none") {
                    list = list.filter((item) => item.tariff === null);
                } else {
                    list = list.filter((item) => parseInt(item.tariff) == parseInt(s.category));
                }
            }

            if (s.type != "" && s.type != undefined) {
                if (s.type == "none") {
                    list = list.filter((item) => item.operator === null);
                } else {
                    list = list.filter((item) => item.operator && String(item.operator?.toLowerCase()) == String(s.type.toLowerCase()));
                }
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.start >= s.start || item.end >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.start <= s.end || item.end <= s.end);
            }

            if (list.length != ctx.state.cars?.length) {
                s.clear = true;
            }

            ctx.commit("filteredCars", list);
            ctx.commit("countCars", list);
            ctx.dispatch("setSettings", s);
        },

        // КОМПАНИЯ
        setCarCompany(ctx, item) {
            let company_id = null;
            if (item?.id) {
                company_id = item.id;
            }
            ctx.dispatch("setCar", ctx.rootState.client.car);

            let formData = new FormData();
            formData.append("id", ctx.rootState.client.car.id);
            formData.append("company_id", company_id);
            API.POST("car/change-company", formData).then((res) => {
                ctx.dispatch("setCar", res.data.car);
                ctx.dispatch("updCarList", res.data.car);
            });
        },
        async setCarClient(ctx, item){
            console.log("CAR", item, ctx.rootState.client.car,);
            ctx.dispatch("setCar", item.car);
            let formData = new FormData();
            formData.append("car_id", item.car.id);
            formData.append("client_id", item.client.id);
            let res = await API.POST("car/change-client", formData);
            ctx.dispatch("setCar", res.data.car);
            ctx.dispatch("updCarList", res.data.car);
        },

        onMonitoring(ctx, data) {
            let formData = new FormData();
            data.order_id ? formData.append("order_id", data.order_id) : null;
            data.vialon ? formData.append("vialon", data.vialon) : null;
            data.tariff ? formData.append("tariff", data.tariff) : null;
            data.start ? formData.append("start", data.start) : null;
            formData.append("car_id", data.car_id);
            formData.append("sim", data.sim);
            formData.append("operator", data.operator);
            API.POST("car/on-monitoring", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.car ? ctx.dispatch("setCar", res.data.car) : null;
                res.data.car ? ctx.dispatch("updCarList", res.data.car) : null;
            });
        },

        offMonitoring(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("car_id", data.car_id);
            formData.append("cancel_id", data.cancel_id);
            API.POST("car/off-monitoring", formData).then((res) => {
                res.data.car ? ctx.dispatch("setCar", res.data.car) : null;
                res.data.car ? ctx.dispatch("updCarList", res.data.car) : null;
            });
        },

        delMonitor(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);
            API.POST("car/del-monitoring", formData).then((res) => {
                res.data.car ? ctx.dispatch("setCar", res.data.car) : null;
                res.data.car ? ctx.dispatch("updCarList", res.data.car) : null;
            });
        },

        // не сделано на сервере - пока не нужно
        // setSim(ctx, data) {
        //     let formData = new FormData();
        //     formData.append("id", data.id); // monitoring.id
        //     formData.append("sim", data.sim);
        //     API.POST("car/set-sim", formData).then(() => {
        //         // res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
        //     });
        // },

        updCarList(ctx, car) {
            console.log("Меняем", car.id);
            if (ctx.state.cars?.length > 0) {
                ctx.state.cars.forEach(function (item, i) {
                    if (item.id == car.id) {
                        ctx.state.cars[i] = car;
                        console.log("Поменяли в основном списке");
                    }
                });
                ctx.commit("setCars", ctx.state.cars);
            }
            if (ctx.state.filteredCars?.length > 0) {
                ctx.state.filteredCars.forEach(function (item, i) {
                    if (item.id == car.id) {
                        ctx.state.filteredCars[i] = car;
                        console.log("Поменяли в фильтрованом списке");
                    }
                });
                ctx.commit("filteredCars", ctx.state.filteredCars);
            }
            if (ctx.state.countCars?.length > 0) {
                ctx.state.countCars.forEach(function (item, i) {
                    if (item.id == car.id) {
                        ctx.state.countCars[i] = car;
                        console.log("Поменяли в списке для подсчёта");
                    }
                });
                ctx.commit("countCars", ctx.state.countCars);
            }
            if (ctx.rootState.client.cars?.length > 0) {
                ctx.rootState.client.cars.forEach(function (item, i) {
                    if (item.id == car.id) {
                        ctx.rootState.client.cars[i] = car;
                        console.log("Поменяли в другом списке");
                    }
                });
                ctx.commit("setClientCars", ctx.rootState.client.cars);
            }
        },
    },

    getters: {
        CarList(state) {
            return state.filteredCars;
        },

        CarArhives(state) {
            return state.filteredCarArhives;
        },

        CarCounter(state) {
            let count = [];
            if (state.countCars?.length > 0) {
                count[1] = 0; // новые активные (с начало месяца)
                count[2] = 0; // все активные
                count[3] = 0; // новые отключенные (с начало месяца)
                count[4] = 0; // все отключенные
                count[5] = 0; // возврат
                count[9] = 0; // неактивные (никогда не подключались)
                count.ownerless = 0;
                state.countCars?.forEach((elem) => {
                    if (elem.monitoring == 2 && elem.start >= moment().startOf("month").format("YYYY-MM-DD")) {
                        count[1]++;
                    }
                    if (elem.monitoring == 2) {
                        count[2]++;
                    }
                    if (elem.monitoring == 1 && elem.end >= moment().startOf("month").format("YYYY-MM-DD")) {
                        count[3]++;
                    }
                    if (elem.monitoring == 1) {
                        count[4]++;
                    }
                    if (elem.monitoring == 2 && elem.start >= moment().startOf("month").format("YYYY-MM-DD")) {
                        count[5]++;
                    }
                    if (elem.monitoring < 1) {
                        count[9]++;
                    }
                    if(elem.client_id == 0 || elem.client_id == null){
                        count.ownerless++;
                    }
                });
            }
            return count;
        },

        TsReportCounter(state) {
            let count = [];
            let numts = [];
            let realts = [];
            let allts = 0;
            let monitorts = 0;
            if (state.countCars?.length > 0) {
                count["zero"] = 0;
                count[1] = 0;
                count[10] = 0;
                count[30] = 0;
                count[50] = 0;
                count[99] = 0;

                numts[10] = 0;
                numts[30] = 0;
                numts[50] = 0;
                numts[99] = 0;
                
                realts[1] = 0;
                realts[10] = 0;
                realts[30] = 0;
                realts[50] = 0;
                realts[99] = 0;
                console.log('state.countCars', state.countCars);
                state.countCars.forEach((elem) => {
                    if (elem.ts < 1 || elem.ts == null) {
                        count["zero"]++;
                    }
                    if (elem.ts > 0 && elem.ts < 2) {
                        count[1]++;
                        realts[1] = realts[1] + parseInt(elem.monitor_count_ts);
                    }
                    if (elem.ts > 1 && elem.ts < 11) {
                        count[10]++;
                        numts[10] = numts[10] + parseInt(elem.ts);
                        realts[10] = realts[10] + parseInt(elem.monitor_count_ts);
                    }
                    if (elem.ts > 10 && elem.ts < 31) {
                        count[30]++;
                        numts[30] = numts[30] + parseInt(elem.ts);
                        realts[30] = realts[30] + parseInt(elem.monitor_count_ts);
                    }
                    if (elem.ts > 30 && elem.ts < 51) {
                        count[50]++;
                        numts[50] = numts[50] + parseInt(elem.ts);
                        realts[50] = realts[50] + parseInt(elem.monitor_count_ts);
                    }
                    if (elem.ts > 50) {
                        count[99]++;
                        numts[99] = numts[99] + parseInt(elem.ts);
                        realts[99] = realts[99] + parseInt(elem.monitor_count_ts);
                    }
                });
                allts = count[1] + numts[10] + numts[30] + numts[50] + numts[99];
                monitorts = count[1] + realts[10] + realts[30] + realts[50] + realts[99];
            }
            return { count: count, numts: numts, realts: realts, allts: allts, monitorts: monitorts };
        },
    },
};
