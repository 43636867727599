<template>
  <template v-if="contact">
    <a
      class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
      @click="$emit('close')"
    >
      <span class="text-white mr-2">Закрыть</span>
      <svg class="fill-white w-4 h-4">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </a>

    <div class="h-full overflow-y-auto">
      <div class="p-5 v-bbgray flex items-center">
        <a class="flex items-center group cursor-pointer mr-12">
          <span
            class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg"
            >{{ contact.name?.slice(0, 1) }}</span
          >
          <span
            class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
            >{{ contact.name }}</span
          >
        </a>
        <template
          v-if="
            profile.uid == client.manager_id ||
            ['Assistant', 'Studio', 'Hosd'].includes(role)
          "
        >
          <template v-if="contact.lk">
            <div class="flex items-center">
              <p class="font-latobold mr-2">Создан личный кабинет:</p>
              <button
                class="v-btn bg-red-500 hover:bg-red-400"
                @click="delPass(contact.id)"
              >
                <span class="v-btn_text">УДАЛИТЬ ЛК</span>
              </button>
            </div>
          </template>
          <template v-else>
            <div class="flex" @click="sendPass(contact.id)">
              <button class="v-btn bg-green-500 hover:bg-green-400 ml-4">
                <span class="v-btn_text">ОТПРАВИТЬ ПАРОЛЬ ОТ ЛК</span>
              </button>
            </div>
          </template>
        </template>
        <template v-if="role == 'Client'">
          <div class="flex">
            <div class="mt-2">
              <input
                class="mychbox"
                id="mhd"
                type="checkbox"
                v-model="contact.mhd"
                @change="saveContact(contact)"
              />
              <label for="mhd">МЧД СОЗДАНА</label>
            </div>
            <a
              href="https://api.tahocrm.ru/images/MHD.pdf"
              target="_blank"
              download=""
              class="v-btn bg-green-500 hover:bg-green-400 ml-4"
            >
              <span class="v-btn_text">Инструкция для МЧД</span>
            </a>
          </div>
        </template>
      </div>

      <div class="flex h-full max-l:flex-col">
        <div class="flex-1 h-max">
          <div class="px-5 py-2 v-bbgray flex flex-wrap">
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">Фамилия</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.secondname"
                @change="saveContact(contact)"
              />
            </div>
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">Имя</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.name"
                @change="saveContact(contact)"
              />
            </div>
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">Отчество</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.middlename"
                @change="saveContact(contact)"
              />
            </div>
          </div>

          <div class="px-5 py-2 v-bbgray flex flex-wrap">
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">Должность</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.position"
                @change="saveContact(contact)"
              />
            </div>
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label>
                <span class="text-gray-400">Роль</span>
                <select
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  v-model="contact.role"
                  @change="saveContact(contact)"
                >
                  <option disabled value="">Выбрать</option>
                  <option value="1">ответственный за тахограф</option>
                  <option value="2">ответственный за мониторинг</option>
                  <option value="3">ответственный за пропуск</option>
                  <option value="4">ответственный за транспорт (все)</option>
                  <option value="5">ответственный за карты</option>
                  <option value="6">бухгалтерия</option>
                  <option value="7">получение СМС</option>
                </select>
              </label>
            </div>
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2 self-end">
              <input
                class="mychbox"
                type="checkbox"
                id="act"
                v-model="contact.act"
                @change="saveContact(contact)"
              />
              <label for="act">Активация</label>
            </div>
          </div>

          <div class="px-5 py-2 v-bbgray flex flex-wrap">
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">Телефон</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.phone"
                @change="saveContact(contact)"
              />
            </div>
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">Телефон 2</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.phone2"
                @change="saveContact(contact)"
              />
            </div>

            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">Email</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.email"
                @change="saveContact(contact)"
              />
            </div>
          </div>

          <div class="px-5 py-2 v-bbgray flex flex-wrap">
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">Телеграм</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.telegram"
                @change="saveContact(contact)"
              />
            </div>
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">Инстаграм</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.instagram"
                @change="saveContact(contact)"
              />
            </div>
            <div class="min-w-[160px] w-1/3 flex flex-col px-4 my-2">
              <label class="text-gray-400">ВК</label>
              <input
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                type="text"
                v-model="contact.vk"
                @change="saveContact(contact)"
              />
            </div>
          </div>

          <template v-if="contact.act">
            <div class="px-5 py-2 v-bbgray border-t flex flex-wrap">
              <h2 class="font-latobold text-base ml-4 mb-2 w-full">
                Паспортные данные
              </h2>
              <div class="min-w-[160px] w-1/4 flex flex-col px-4 my-2">
                <label class="text-gray-400">Пол</label>
                <select
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  v-model="contact.sex"
                  @change="saveContact(contact)"
                >
                  <option disabled value="">Выбрать</option>
                  <option :value="'М'">М</option>
                  <option :value="'Ж'">Ж</option>
                </select>
              </div>
              <!-- <div class="min-w-[160px] w-1/5 flex flex-col px-4 my-2">
                <label class="text-gray-400">Дата рождения</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.birthday"
                  @change="saveContact(contact)"
                />
              </div> -->
              <div class="min-w-[160px] w-1/4 flex flex-col px-4 my-2">
                <label class="text-gray-400">Дата рождения*</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="date"
                  v-model="contact.birthday"
                  @change="saveContact(contact)"
                />
              </div>
              <div class="min-w-[160px] w-1/4 flex flex-col px-4 my-2">
                <label class="text-gray-400">СНИЛС</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.snils"
                  @change="saveContact(contact)"
                />
              </div>
              <div class="min-w-[160px] w-1/4 flex flex-col px-4 my-2">
                <label class="text-gray-400">ИНН</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.inn"
                  @change="saveContact(contact)"
                />
              </div>
              <div class="w-full flex flex-col px-4 my-2">
                <label class="text-gray-400">Адрес (прописка)</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.address"
                  @change="saveContact(contact)"
                />
              </div>
            </div>

            <div class="px-5 py-2 flex flex-wrap">
              <div class="min-w-[160px] w-1/4 flex flex-col px-4 my-2">
                <label class="text-gray-400">Серия</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.pass_s"
                  @change="saveContact(contact)"
                />
              </div>
              <div class="min-w-[160px] w-1/4 flex flex-col px-4 my-2">
                <label class="text-gray-400">Номер</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.pass_num"
                  @change="saveContact(contact)"
                />
              </div>
              <div class="min-w-[160px] w-1/4 flex flex-col px-4 my-2">
                <label class="text-gray-400">Дата выдачи</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.pass_date"
                  @change="saveContact(contact)"
                />
              </div>
              <div class="min-w-[160px] w-1/4 flex flex-col px-4 my-2">
                <label class="text-gray-400">Код подразделения</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.pass_code"
                  @change="saveContact(contact)"
                />
              </div>
              <div class="w-full flex flex-col px-4 my-2">
                <label class="text-gray-400">Кем выдан</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.pass_who"
                  @change="saveContact(contact)"
                />
              </div>
              <div class="w-full flex flex-col px-4 my-2">
                <label class="text-gray-400">Место рождения</label>
                <input
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  type="text"
                  v-model="contact.pass_place"
                  @change="saveContact(contact)"
                />
              </div>
            </div>
          </template>
        </div>

        <div class="max-w-[300px] w-full p-5">
          <div class="">
            <div class="flex flex-wrap">
              <template v-for="i in contact.images" :key="i">
                <div class="relative m-2 w-16">
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delImg({ img: i, item: contact })"
                  >
                    <svg
                      class="bg-white rounded-full w-4 h-4 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <IcoImg :img="i.img" :status="i.status" />
                </div>
              </template>

              <template v-for="i in contact.docs" :key="i">
                <div class="relative m-2 w-16">
                  <a
                    class="absolute -right-2 -top-2 group"
                    v-if="profile.uid == i.uid"
                    @click="delDoc({ img: i, item: contact })"
                  >
                    <svg
                      class="bg-white rounded-full w-4 h-4 group-hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                  <a
                    :href="
                      'https://api.tahocrm.ru/uploads/client/orig/' + i.file
                    "
                    download
                  >
                    <img src="https://api.tahocrm.ru/images/pdf-file.png" />
                    <p>{{ i.name }}</p>
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div class="mb20">
            <div class="py-1">
              <UploadImg
                :item="contact"
                :id="contact.id"
                type="contact"
                :status="24"
                :name="imgName[24]"
              />
            </div>
            <div class="py-1">
              <UploadImg
                :item="contact"
                :id="contact.id"
                type="contact"
                :status="25"
                :name="imgName[25]"
              />
            </div>
            <div class="py-1">
              <UploadImg
                :item="contact"
                :id="contact.id"
                type="contact"
                :status="26"
                :name="imgName[26]"
              />
            </div>
            <div class="py-1">
              <UploadImg
                :item="contact"
                :id="contact.id"
                type="contact"
                :status="27"
                :name="imgName[27]"
              />
            </div>
            <div class="py-1">
              <UploadImg
                :item="contact"
                :id="contact.id"
                type="contact"
                :status="30"
                :name="imgName[30]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <div v-else class="loader opacity1 mt30">
    <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UploadImg from "../ui/UploadImg.vue";

export default {
  name: "ContactEdit",

  components: { UploadImg },

  emits: ["close"],

  data() {
    return {
      loadForm: true,
      imgName: {
        24: "ИНН: jpg, png",
        25: "СНИЛС: jpg, png",
        26: "Паспорт основной: jpg, png",
        27: "Паспорт прописка: jpg, png",
        28: "СНИЛС",
        29: "ИНН",
        30: "МЧД",
      },
    };
  },

  computed: {
    ...mapGetters(["s", "profile", "role", "roles", "client", "contact"]),
  },

  methods: {
    ...mapActions(["saveContact", "delImg", "sendPass", "delPass"]),

    bool() {
      if (this.contact) {
        this.contact.act = Boolean(this.contact.act);
        this.contact.mhd = Boolean(this.contact.mhd);
        this.contact.lk = Boolean(this.contact.lk);
      }
    },
  },

  mounted() {
    this.bool();
  },
};
</script>
