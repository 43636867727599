<template>
    <svg v-if="status == 1" class="w-full h-full" :class="'fill-status-' + status">
        <use xlink:href="@/assets/i.svg#status-1"></use>
    </svg>
    <svg v-if="status == 2" class="w-full h-full" :class="'fill-status-' + status">
        <use xlink:href="@/assets/i.svg#status-2"></use>
    </svg>
    <svg v-if="status == 3" class="w-full h-full" :class="'fill-status-' + status">
        <use xlink:href="@/assets/i.svg#status-3"></use>
    </svg>
    <svg v-if="status == 4" class="w-full h-full" :class="'fill-status-' + status">
        <use xlink:href="@/assets/i.svg#status-4"></use>
    </svg>
    <svg v-if="status == 5" class="w-full h-full" :class="'fill-status-' + status">
        <use xlink:href="@/assets/i.svg#status-5"></use>
    </svg>
    <svg v-if="status == 6" class="w-full h-full" :class="'fill-status-' + status">
        <use xlink:href="@/assets/i.svg#status-6"></use>
    </svg>
    <svg v-if="status == 8" class="w-full h-full" :class="'fill-status-' + status">
        <use xlink:href="@/assets/i.svg#status-8"></use>
    </svg>
    <svg v-if="status == 10" class="w-full h-full" :class="'fill-status-' + status">
        <use xlink:href="@/assets/i.svg#status-10"></use>
    </svg>
    <svg v-if="status == 11" class="w-full h-full" :class="'fill-status-' + status">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
    </svg>
</template>

<script>
export default {
    name: "LineIco",
    props: {
        status: Number,
    },
};
</script>
