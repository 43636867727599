<template>
  <div
    v-if="s.popup"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <ActivView @close="close" />
  </div>
  <div
    v-if="s.popup"
    @click="close"
    id="overlay"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
  ></div>

  <template v-if="ClientActivs">
    <div class="bg-white rounded-lg">
      <div class="p-5 v-bbgray">
        <h3 class="font-latobold text-base">
          Активации {{ ClientActivs.length }}
        </h3>
      </div>

      <div class="grid grid-cols-5 px-5 py-2 v-bbgray">
        <p class="text-gray-400 uppercase text-[11px] col-span-2">№</p>
        <p class="text-gray-400 uppercase text-[11px]">Дата</p>
        <p class="text-gray-400 uppercase text-[11px]">Заказ</p>
        <p class="text-gray-400 uppercase text-[11px]">ТС</p>
      </div>

      <div
        v-for="item in ClientActivs"
        :key="item"
        class="px-5 py-2 grid grid-cols-5 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative group status-{{ item.status }}"
      >
        <a
          class="flex items-center col-span-2"
          @click="showActiv(item), (s.popup = true)"
        >
          <div
            class="rounded-full h-11 min-w-[2.75rem] w-11 flex items-center justify-center mr-4 text-lg"
            :class="{
              'bg-status-1': item.status == 1,
              'bg-status-10': [2, 3].includes(item.status),
              'bg-status-3': item.status == 4,
              'bg-status-7': item.status == 7,
              'bg-status-8': item.status == 8,
            }"
          >
            <svg class="w-5 h-5 fill-white">
              <use
                v-if="item.status == 1"
                xlink:href="@/assets/i.svg#status-1"
              ></use>
              <use
                v-if="item.status == 2"
                xlink:href="@/assets/i.svg#status-5"
              ></use>
              <use
                v-if="item.status == 3"
                xlink:href="@/assets/i.svg#status-6"
              ></use>
              <use
                v-if="item.status == 4"
                xlink:href="@/assets/i.svg#ico-mail"
              ></use>
              <use
                v-if="item.status == 7"
                xlink:href="@/assets/i.svg#ico-no"
              ></use>
              <use
                v-if="item.status == 8"
                xlink:href="@/assets/i.svg#status-8"
              ></use>
            </svg>
          </div>

          <div class="flex flex-col">
            <span
              v-if="item.status == 1"
              class="text-status-1 text-[9px] uppercase"
              >ЖДЕТ ЗАПОЛНЕНИЯ</span
            >
            <span
              v-if="item.status == 2"
              class="text-status-10 text-[9px] uppercase"
              >ЖДЕТ ПРОВЕРКИ</span
            >
            <span
              v-if="item.status == 3"
              class="text-status-10 text-[9px] uppercase"
              >ЖДЕТ ОТПРАВКИ</span
            >
            <span
              v-if="item.status == 4"
              class="text-status-4 text-[9px] uppercase"
              >В МИНТРАНСЕ</span
            >
            <span
              v-if="item.status == 7"
              class="text-status-7 text-[9px] uppercase"
              >ОТКЛОНЕНА</span
            >
            <span
              v-if="item.status == 8"
              class="text-status-8 text-[9px] uppercase"
              >ОДОБРЕНА</span
            >
            <span class="font-latobold text-base">Анкета №{{ item.id }}</span>
          </div>
        </a>

        <div class="cell name">
          <p>{{ moment(item.create_at).format("DD.MM.YYYY") }}</p>
        </div>

        <a
          class="text-green-500 hover:text-green-400 transition-colors duration-150 cursor-pointer"
          @click="editOrderId(item.order_id)"
        >
          №{{ item.order_iid }}
        </a>

        <div class="v-gray font-latoblod-serif">
          <span
            class="text-slate-600 w-auto rounded-sm v-tsnum px-2 py-1 text-base"
            >{{ item.tsnum }}</span
          >
        </div>

        <template
          v-if="
            profile.uid == client.manager_id ||
            ['Assistant', 'Studio', 'Hosd'].includes(role)
          "
        >
          <div
            class="flex absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150"
          >
            <a class="cursor-pointer ml-4" @click="delActive(item)">
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </a>
          </div>
        </template>
      </div>
    </div>
  </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import ActivView from "../components/ActivView";

export default {
  name: "ClientActive",

  data() {
    return {
      moment: moment,
    };
  },

  components: { ActivView },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "ClientActivs",
      "activ",
    ]),
  },

  methods: {
    ...mapActions([
      "Tab",
      "showActiv",
      "editOrderId",
      "clearActiv",
      "delActive",
      "closePopup",
      "getClientTabsActivations",
    ]),

    close() {
      this.closePopup();
      this.clearActiv();
    },
  },
  async mounted() {
    await this.getClientTabsActivations();
  },
};
</script>
