<template>
    <div class="mx-4 h-[500px] w-full max-w-full" ref="root"></div>
</template>
<script>

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


export default {
    name: "RportChart",
    props: {
        data: Array,
    },
    data() {
        return {
            //root: null,
        };
    },
    methods: {
        initRoot(){
            this.root = am5.Root.new(this.$refs.root);
            this.root.setThemes([am5themes_Animated.new(this.root)]);
        },
        initChart(){
            this.chart = this.root.container.children.push(
                am5xy.XYChart.new(this.root, {
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    pinchZoomX: true,
                })
            );
            this.chart.get("colors").set("colors", [
                am5.color('#8F8F8F'),
                am5.color('#8E8E8E'),

            ]);
        },
        initCursor(){
            this.cursor = this.chart.set(
                "cursor",
                am5xy.XYCursor.new(this.root, {
                    behavior: "none",
                })
            );
            this.cursor.lineY.set("visible", false);
        },
        initXAxis(){
            let xAxis = this.chart.xAxes.push(
                am5xy.DateAxis.new(this.root, {
                    groupData: false,
                    baseInterval: {
                        timeUnit: "month",
                        count: 1,
                    },
                    renderer: am5xy.AxisRendererX.new(this.root, {
                        dateFormater: am5.DateFormatter.new(this.root, {})
                    }),
                    tooltip: am5.Tooltip.new(this.root, {}),
                })
            );
            this.xAxis = xAxis;
            xAxis.get("dateFormats")["day"] = "dd";
            xAxis.get("periodChangeDateFormats")["day"] = "MMMM";
        },
        initYAxis(){
            this.yAxis = this.chart.yAxes.push(
                am5xy.ValueAxis.new(this.root, {
                    // forceHidden: true,
                    renderer: am5xy.AxisRendererY.new(this.root, {}),
                })
            );
        },
        initLegend(){
            this.legend = this.chart.children.push(am5.Legend.new(this.root, {}));
            this.legend.data.setAll(this.chart.series.values);
        },
        initSeries(){
            let series = this.chart.series.push(
                am5xy.SmoothedXLineSeries.new(this.root, {
                    name: "Фактически",
                    minBulletDistance: 1,
                    xAxis: this.xAxis,
                    yAxis: this.yAxis,          
                    valueYField: "fact",
                    valueXField: "date",
                    tooltip: am5.Tooltip.new(this.root, {
                        labelText: "{valueY}",
                        strokeWidth: 2,
                    }),
                })
            );
            this.series = series;
            series.strokes.template.setAll({
                strokeWidth: 3,
            });
            series.set("fill", am5.color("#000000")); 
            series.data.processor = am5.DataProcessor.new(this.root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["date"],
            });
            series.bullets.push(()=>{
                var container = am5.Container.new(this.root, {
                    rotation: 0
                });

                // Circle
                var circle = container.children.push(am5.Container.new(this.root, {
                    centerX: am5.p50,
                    centerY: am5.p100,
                    dy: -15,
                    width: 40,
                    height: 10,
                    background: am5.RoundedRectangle.new(this.root, {
                    strokeOpacity: 1,
                    strokeWidth: 1,
                    fillOpacity: 0.8,
                    cornerRadiusTL: 20,
                    cornerRadiusTR: 20,
                    cornerRadiusBR: 20,
                    cornerRadiusBL: 20,
                    })
                }));

                // Label
                circle.children.push(am5.Label.new(this.root, {
                    text: "{valueY}",
                    oversizedBehavior: "wrap",
                    fill: '#000000',
                    maxWidth: 40,
                    fontSize: 15,
                    fontWeight: "bold",
                    x: am5.p50,
                    centerX: am5.p50,
                    y: am5.p50,
                    centerY: am5.p50,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    populateText: true
                }));


                return am5.Bullet.new(this.root, {
                    locationY: 0.5,
                    stacked: "up",
                    sprite: container
                })
            });
        },
        initPlanSeries(){
            let series = this.chart.series.push(
                am5xy.SmoothedXLineSeries.new(this.root, {
                    name: "Запланировано",
                    minBulletDistance: 1,
                    xAxis: this.xAxis,
                    yAxis: this.yAxis,
                    valueYField: "plan",
                    valueXField: "date",
                    tooltip: am5.Tooltip.new(this.root, {
                        labelText: "{valueY}",
                        strokeWidth: 4,
                        stroke: 'red',
                    }),
                })
            );
            this.planSeries = series;
            series.strokes.template.setAll({
                strokeWidth: 2,
                colorOpacity: 0.5,
                fillOpacity: 0.7,
                strokeDasharray: [8, 5],
            });
            series.set("fill", am5.color("#8E8E8E")); 
            series.data.processor = am5.DataProcessor.new(this.root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["date"],
            });
            series.bullets.push(()=>{
                var container = am5.Container.new(this.root, {
                    rotation: 0
                });

                // Circle
                var circle = container.children.push(am5.Container.new(this.root, {
                    centerX: am5.p50,
                    centerY: am5.p100,
                    dy: -15,
                    width: 40,
                    height: 10,
                    background: am5.RoundedRectangle.new(this.root, {
                    strokeOpacity: 1,
                    strokeWidth: 1,
                    fillOpacity: 0.8,
                    cornerRadiusTL: 20,
                    cornerRadiusTR: 20,
                    cornerRadiusBR: 20,
                    cornerRadiusBL: 20,
                    })
                }));

                // Label
                circle.children.push(am5.Label.new(this.root, {
                    text: "{valueY}",
                    oversizedBehavior: "wrap",
                    fill: '#8F8F8F',
                    maxWidth: 40,
                    fontSize: 15,
                    fontWeight: "bold",
                    x: am5.p50,
                    centerX: am5.p50,
                    y: am5.p50,
                    centerY: am5.p50,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    populateText: true
                }));

                return am5.Bullet.new(this.root, {
                    locationY: 0.5,
                    stacked: "up",
                    sprite: container
                })
            });
        },
        setLocale(){
            let root = this.root;
            root.locale.January = "Январь";
            root.locale.February = "Февраль";
            root.locale.March = "Март";
            root.locale.April = "Апрель";
            root.locale.May = "Май";
            root.locale.June = "Июнь";
            root.locale.July = "Июль";
            root.locale.August = "Август";
            root.locale.September = "Сентябрь";
            root.locale.October = "Октябрь";
            root.locale.November = "Ноябрь";
            root.locale.December = "Декабрь";
            
            root.locale.Jan = "Янв";
            root.locale.Feb = "Фев";
            root.locale.Mar = "Мар";
            root.locale.Apr = "Апр";
            root.locale.May = "Май";
            root.locale.Jun = "Июн";
            root.locale.Jul = "Июл";
            root.locale.Aug = "Авг";
            root.locale.Sep = "Сен";
            root.locale.Oct = "Окт";
            root.locale.Nov = "Ноя";
            root.locale.Dec = "Дек";
        },
        setValue(val){
            if (val && this.series && this.planSeries) {
                this.series.data.setAll(val);
                this.planSeries.data.setAll(val);
            }
        },
        showGraf() {
            this.initRoot();
            this.setLocale();
            this.initChart();
            this.initCursor();
            this.initXAxis();
            this.initYAxis();
            this.initSeries();
            this.initPlanSeries();
            this.initLegend();
            this.setValue(this.data);
        },
    },
    mounted(){
        this.showGraf();
        console.log('this.root', this.root)
    },
}
</script>
