import API from "../../services/api";
import router from "@/router/router";

export default {
    state: {
        prices: [],
        filteredPrices: [],
        priceCategories: [],
        price: {},
        priceCategory: {},
    },

    mutations: {
        setPrices: (state, prices) => {
            state.prices = prices;
            state.filteredPrices = prices;
        },

        filteredPrices: (state, prices) => {
            state.filteredPrices = prices;
        },

        setPriceCategories: (state, categories) => {
            state.priceCategories = categories;
        },

        setPrice: (state, price) => {
            state.price = price;
        },

        setPriceCategory: (state, category) => {
            state.priceCategory = category;
        },
    },

    actions: {
        getPrices(ctx) {
            API.GET("price/prices").then((res) => {
                ctx.dispatch("setPrices", res.data.prices);
                ctx.dispatch("setPriceCategories", res.data.categories);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findPrice", ctx.rootState.s);
                });
            });
        },

        setPrices(ctx, prices) {
            prices.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            ctx.commit("setPrices", prices);
            localStorage.setItem("prices", JSON.stringify(prices));
        },

        findPrice(ctx, s) {
            if (!ctx.state.prices) {
                return null;
            }
            let list = ctx.state.prices;
            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let name = null;
                    let code = null;
                    iid = item.iid.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    name = item.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.code) {
                        code = item.code.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (iid || name || code) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category_id) == String(s.category));
            }

            if (list.length != ctx.state.prices.length) {
                s.clear = true;
            }

            ctx.state.filteredPrices = list;
            ctx.dispatch("setSettings", s);
        },

        getPrice(ctx) {
            let price = localStorage.getItem("price");
            if (price) {
                price = JSON.parse(price);
                ctx.commit("setPrice", price);
            }
        },

        getPriceCategories(ctx) {
            let cats = localStorage.getItem("priceCategories");
            if (cats) {
                cats = JSON.parse(cats);
                ctx.commit("setPriceCategories", cats);
            }
            console.log("getPriceCategories");
        },

        addPrice(ctx) {
            API.GET("price/add-price").then((res) => {
                ctx.state.prices.push(res.data.price);
                ctx.dispatch("setPrices", ctx.state.prices);
                ctx.dispatch("showPrice", res.data.price);
            });
        },

        setPrice(ctx, price) {
            ctx.commit("setPrice", price);
            localStorage.setItem("price", JSON.stringify(price));
        },

        showPrice(ctx, price) {
            ctx.dispatch("setPrice", price);
            router.push("/price/view");
        },

        savePrice(ctx, price) {
            let formData = new FormData();
            if(price.prolon == true) {
                price.prolon = 1;
            }
            else {
                price.prolon = 0;
            }
            Object.keys(price).forEach(function (key) {
                
                formData.append(key, this[key]);
            }, price);

            API.POST("price/save-price", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            ctx.dispatch("setPrice", res.data.price);
            });
            // API.POST("price/save-price", formData);
            // ctx.dispatch("setPrice", price);
        },

        delPrice(ctx, price) {
            ctx.state.prices = ctx.state.prices.filter((item) => item.id !== price.id);
            ctx.dispatch("setPrices", ctx.state.prices);

            let formData = new FormData();
            formData.append("id", price.id);
            API.POST("price/delete", formData);

            router.push("/price");
        },
        undelPrice(ctx, price) {
            ctx.state.prices = ctx.state.prices.filter((item) => item.id !== price.id);
            ctx.dispatch("setPrices", ctx.state.prices);

            let formData = new FormData();
            formData.append("id", price.id);
            API.POST("price/undelete", formData);

            router.push("/price");
        },

        // КАТЕГОРИИ
        setPriceCategories(ctx, categories) {
            categories.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            ctx.commit("setPriceCategories", categories);
            localStorage.setItem("priceCategories", JSON.stringify(categories));
        },

        addPriceCategory(ctx) {
            API.GET("price/add-category").then((res) => {
                ctx.dispatch("setPriceCategory", res.data.category);
                ctx.state.priceCategories.push(res.data.category);
                // console.log("addPriceCategory", ctx.state.priceCategories);
                ctx.dispatch("setPriceCategories", ctx.state.priceCategories);
            });
        },

        setPriceCategory(ctx, category) {
            ctx.commit("setPriceCategory", category);
            localStorage.setItem("category", JSON.stringify(category));
        },

        savePriceCategory(ctx, category) {
            ctx.dispatch("setPriceCategory", category);
            ctx.dispatch("setPriceCategories", ctx.state.priceCategories);
            let formData = new FormData();
            Object.keys(category).forEach(function (key) {
                formData.append(key, this[key]);
            }, category);
            API.POST("price/save-category", formData);
        },

        delPriceCategory(ctx, category) {
            const updatedCategories = ctx.state.priceCategories.filter((cat) => cat.id !== category.id);

            let formData = new FormData();
            formData.append("id", category.id);
            API.POST("price/del-category", formData);
            ctx.commit("setPriceCategories", updatedCategories);
        },
        unDelPriceCategory(ctx, category) {
            const updatedCategories = ctx.state.priceCategories.filter((cat) => cat.id !== category.id);

            let formData = new FormData();
            formData.append("id", category.id);
            API.POST("price/undel-category", formData);
            ctx.commit("setPriceCategories", updatedCategories);
        },
    },

    getters: {
        PriceList(state) {
            // state.filteredPrices = state.filteredPrices.filter((item) => parseInt(item.status) != 8);
            return state.filteredPrices;
        },

        priceCategories(state) {
            return state.priceCategories;
        },

        price(state) {
            return state.price;
        },

        priceCategory(state) {
            return state.priceCategory;
        },
    },
};
