<template>
    <template v-if="image == 'pic'">
        <a class="w-16 overflow-hidden mb-4 mr-2 text-center" :href="imgPrefix + (types[type] ?? 'client') + '/' + img" download target="_blank">
            <div class="w-16 h-16 rounded-md overflow-hidden mb-1">
                <img class="w-full h-full object-cover" :src="imgPrefix +  (types[type] ?? 'client') +'/mini/' + img" />
            </div>
            <p class="w-full">{{ name[status] }}</p>
        </a>
    </template>

    <template v-if="image == 'pdf'">
        <a
            class="flex flex-col w-16 overflow-hidden mb-4 mr-2 text-center"
            :href="'https://api.tahocrm.ru/uploads/client/orig/' + img"
            download
            target="_blank"
        >
            <div class="w-16 h-16 rounded-md overflow-hidden mb-1">
                <img src="https://api.tahocrm.ru/images/pdf-file.png" />
            </div>
            <p class="w-full">{{ name[status] }}</p>
        </a>
    </template>

    <template v-if="image == 'unknown'">
        <a
            class="flex flex-col w-16 overflow-hidden mb-4 mr-2 text-center"
            :href="'https://api.tahocrm.ru/uploads/client/orig/' + img"
            download
            target="_blank"
        >
            <div class="w-16 h-16 rounded-md overflow-hidden mb-1">
                <img src="https://api.tahocrm.ru/images/txt-file.png" />
            </div>
            <p class="w-full">{{ name[status] }}</p>
        </a>
    </template>
</template>

<script>
import API from "@/services/api.js";
export default {
    name: "IcoImg",
    props: {
        img: String,
        status: Number,
        type: String,
    },

    data() {
        return {
            name: {
                2: "СТС лицо",
                12: "СТС оборот",
                3: "ПТС лицо",
                13: "ПТС оборот",
                4: "СТРАХОВКА",
                14: "Бак близко",
                15: "Бак издалека",
                22: "ЕГРЮЛ",
                23: "ОГРН",
                24: "ИНН",
                25: "СНИЛС",
                26: "Паспорт основной",
                27: "Паспорт прописка",
                28: "СНИЛС",
                29: "ИНН",
                30: "МЧД",
                101: "Согласие",
            },
            types: {
                '2': 'oper',
                '3': 'task',
                '4': 'client',
                '5': 'client',//'car',
                '6': 'client',//'company',
                '7': 'client',//'contact',
                '8': 'client',//'doc',
                '9': 'agreement',
                '10': 'ask',
            },
            imgPrefix: API.IMG,
        };
    },

    computed: {
        image() {
            let ext = this.img.split(".").pop();
            console.log("ext", ext);
            if (["jpeg", "jpg", "png", "bmp"].includes(ext)) {
                return "pic";
            }
            if (["pdf"].includes(ext)) {
                return "pdf";
            }
            return "unknown";
        },
    },
};
</script>
