<template>
    <div class="grid grid-cols-statistic-main gap-4 max-xl:grid-cols-statistic-main-md">
        <!-- #1 -->
        <div class="bg-white rounded-md grid grid-rows-statistic-main cursor-pointer" @click="setStatShow('orders')">
            <div class="p-4 text-base uppercase v-bbgray flex items-center">
                <svg class="fill-status-5 w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#nav-flow"></use>
                </svg>
                <h2 class="text-status-5 font-bold">Заказы</h2>
            </div>
            <template v-if="statOrders && statOrders['total'] > 0">
                <div class="p-2 text-2xl uppercase v-bbgray font-bold flex justify-around">
                    <span v-if="statOrders['total']">{{ statOrders["total"] }}</span>
                    <span v-else>0</span>
                </div>
                <div class="flex justify-around items-center p-2" v-if="statOrders['total']">
                    <div class="flex flex-col items-center">
                        <svg class="fill-status-5 w-4 h-4">
                            <use xlink:href="@/assets/i.svg#ico-ok"></use>
                        </svg>
                        <p class="text-4xl font-bold text-status-5">
                            <span v-if="statOrders['work']">{{ statOrders["work"] }}</span>
                            <span v-else>0</span>
                        </p>
                        <span class="text-xs text-gray-400">ЗАВЕРШЕНЫ TO</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <svg class="fill-status-6 w-4 h-4">
                            <use xlink:href="@/assets/i.svg#status-8"></use>
                        </svg>
                        <p class="text-4xl font-bold text-status-6">
                            <span v-if="statOrders['done']">{{ statOrders["done"] }}</span>
                            <span v-else>0</span>
                        </p>
                        <span class="text-xs text-gray-400">ПРОВЕРЕНЫ КК</span>
                    </div>
                </div>
            </template>
            <div v-else class="text-3xl font-bold text-gray-200 flex justify-around p-2 items-center">Данных нет</div>
        </div>

        <!-- #2 -->
        <div class="bg-white rounded-md grid grid-rows-statistic-main cursor-pointer" @click="setStatShow('services')">
            <div class="p-4 text-base uppercase v-bbgray flex items-center">
                <svg class="fill-status-5 w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#nav-service"></use>
                </svg>
                <h2 class="text-status-5 font-bold">Услуги</h2>
            </div>
            <template v-if="statServices && statServices.total > 0">
                <div class="px-4 py-2 text-2xl uppercase v-bbgray font-bold flex justify-around">
                    <span v-if="statServices">{{ statServices.total }}</span>
                    <span v-else>--</span>
                </div>
                <div class="flex flex-col px-4 py-2">
                    <h3 class="text-center font-bold text-sm mb-2">ТОП 4 категории услуг</h3>
                    <ul>
                        <!-- <li v-for="item in statServices.slice(0,4)" :key="item" class="flex justify-between text-xs">
                            <span>{{ item.name }}</span>
                            <span>{{ item.value }}</span>
                        </li> -->
                        <li class="flex justify-between text-xs">
                            <span>{{ statServices.graf[0].name }}</span>
                            <span>{{ statServices.graf[0].value }}</span>
                        </li>
                        <li class="flex justify-between text-xs">
                            <span>{{ statServices.graf[1].name }}</span>
                            <span>{{ statServices.graf[1].value }}</span>
                        </li>
                        <li class="flex justify-between text-xs">
                            <span>{{ statServices.graf[2].name }}</span>
                            <span>{{ statServices.graf[2].value }}</span>
                        </li>
                        <li class="flex justify-between text-xs">
                            <span>{{ statServices.graf[3].name }}</span>
                            <span>{{ statServices.graf[3].value }}</span>
                        </li>
                    </ul>
                </div>
            </template>
            <div v-else class="text-3xl font-bold text-gray-200 flex justify-around p-2 items-center">Данных нет</div>
        </div>

        <!-- #3 -->
        <div class="bg-white rounded-md grid grid-rows-statistic-main cursor-pointer" @click="setStatShow('salaries')">
            <div class="p-4 text-base uppercase v-bbgray flex items-center">
                <svg class="fill-status-5 w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#nav-flow"></use>
                </svg>
                <h2 class="text-status-5 font-bold">Зарплаты</h2>
            </div>
            <template v-if="statSalaries && statSalaries['total'] > 0">
                <div class="p-2 text-2xl uppercase v-bbgray font-bold flex justify-around">
                    <div>
                        <span v-if="statSalaries['total']">{{ statSalaries["total"].toLocaleString() }} </span>
                        <span v-else>0</span>
                        <span class="lowercase text-xs ml-2">р.</span>
                    </div>
                </div>
                <div class="flex justify-around items-center p-2" v-if="statSalaries['total']">
                    <div class="flex flex-col items-center">
                        <svg class="fill-status-4 w-4 h-4">
                            <use xlink:href="@/assets/i.svg#status-4"></use>
                        </svg>
                        <p class="text-2xl font-bold text-center text-status-3">
                            <span v-if="statSalaries['work']">{{ statSalaries["work"].toLocaleString() }}</span>
                            <span v-else>0</span>
                        </p>
                        <span class="text-xs text-gray-400">В РАБОТЕ</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <svg class="fill-status-6 w-4 h-4">
                            <use xlink:href="@/assets/i.svg#status-8"></use>
                        </svg>
                        <p class="text-2xl font-bold text-center text-status-6">
                            <span v-if="statSalaries['done']">{{ statSalaries["done"].toLocaleString() }}</span>
                            <span v-else>0</span>
                        </p>
                        <span class="text-xs text-gray-400">ЗАВЕРШЕНЫ</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <svg class="fill-status-10 w-4 h-4">
                            <use xlink:href="@/assets/i.svg#ico-rating"></use>
                        </svg>
                        <p class="text-2xl font-bold text-center text-status-10">
                            <span v-if="statSalaries['bonus']">{{ statSalaries["bonus"].toLocaleString() }}</span>
                            <span v-else>0</span>
                        </p>
                        <span class="text-xs text-gray-400">БОНУСЫ</span>
                    </div>
                </div>
            </template>
            <div v-else class="text-3xl font-bold text-gray-200 flex justify-around p-2 items-center">Данных нет</div>
        </div>

        <!-- <div class="bg-white rounded-md grid grid-rows-statistic-main cursor-pointer" @click="setStatShow('salaries')">
            <div class="p-4 text-base uppercase v-bbgray flex items-center">
                <svg class="fill-status-5 w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#zp-1"></use>
                </svg>
                <h2 class="text-status-5 font-bold">Зарплаты</h2>
            </div>
            <div class="p-2 text-2xl uppercase v-bbgray font-bold flex justify-around">
                <div>
                    <span v-if="statCountAllSalaries[5]">{{ statCountAllSalaries[5].toLocaleString() }}</span>
                    <span v-else>0</span>
                    <span class="lowercase text-xs ml-2">р</span>
                </div>
            </div>
            <div class="grid grid-cols-6 justify-around p-2 items-center relative">
                <div
                    class="flex flex-col items-center px-2 justify-center col-span-2 statSalItem"
                    v-for="(item, index) in statCountAllSalaries.slice(0, 5)"
                    :key="index"
                >
                    <svg
                        class="w-4 h-4 min-w-[1rem] min-h-[1rem]"
                        :class="{
                            'fill-status-2': index == 0,
                            'fill-status-8': index == 1,
                            'fill-status-10': index == 2,
                            'fill-status-5': index == 3,
                            'fill-status-7': index == 4,
                        }"
                    >
                        <use v-if="index == 0" xlink:href="@/assets/i.svg#status-4"></use>
                        <use v-if="index == 1" xlink:href="@/assets/i.svg#status-8"></use>
                        <use v-if="index == 2" xlink:href="@/assets/i.svg#ico-rating"></use>
                        <use v-if="index == 3" xlink:href="@/assets/i.svg#status-4"></use>
                        <use v-if="index == 4" xlink:href="@/assets/i.svg#status-4"></use>
                    </svg>
                    <span
                        class="text-lg font-bold text-center"
                        :class="{
                            'text-status-2': index == 0,
                            'text-status-8': index == 1,
                            'text-status-10': index == 2,
                            'text-status-5': index == 3,
                            'text-status-7': index == 4,
                        }"
                    >
                        <span>{{ item }}</span>
                    </span>
                </div>
                <div class="text-3xl font-bold absolute left-1/2 -translate-x-1/2 text-gray-200" v-if="statCountAllSalaries.length == 0">
                    Данных нет
                </div>
            </div>
        </div> -->

        <!-- #4 -->
        <div class="bg-white rounded-md grid grid-rows-statistic-main cursor-pointer" @click="setStatShow('loading')">
            <div class="p-4 text-base uppercase v-bbgray flex items-center">
                <svg class="fill-status-5 w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#nav-calendar"></use>
                </svg>
                <h2 class="text-status-5 font-bold">Загрузка</h2>
            </div>
            <template v-if="statLoading && statLoading.total > 0">
                <div class="p-2 text-2xl uppercase v-bbgray font-bold flex justify-around">
                    <div>
                        <span>{{ statLoading.total }}</span>
                        <span class="lowercase text-xs ml-2">ч.</span>
                    </div>
                </div>
                <div class="flex justify-around p-2 items-center">
                    <div class="flex flex-col items-center">
                        <svg class="fill-status-10 w-4 h-4">
                            <use xlink:href="@/assets/i.svg#status-5"></use>
                        </svg>
                        <p class="text-4xl font-bold text-status-10">{{ statLoading.wait }}</p>
                        <span class="text-xs text-gray-400">ПРОСТОЙ ч.</span>
                    </div>
                    <div class="flex flex-col items-center h-3/4">
                        <svg class="fill-status-5 w-4 h-4">
                            <use xlink:href="@/assets/i.svg#nav-masters"></use>
                        </svg>
                        <p class="text-4xl font-bold text-status-5">{{ statLoading.work }}</p>
                        <span class="text-xs text-gray-400">ЗАГРУЗКА ч.</span>
                    </div>
                    <!-- <div class="flex flex-col items-center">
                        <svg class="fill-status-20 w-4 h-4">
                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                        <span class="text-4xl font-bold text-status-20">{{ statCountLoading.wait }}</span>
                    </div> -->
                </div>
            </template>
            <div v-else class="text-3xl font-bold text-gray-200 flex justify-around p-2 items-center">Данных нет</div>
        </div>

        <!-- #5 -->
        <div class="bg-white rounded-md grid grid-rows-statistic-main cursor-pointer" @click="setStatShow('quality')">
            <div class="p-4 text-base uppercase v-bbgray flex items-center">
                <svg class="fill-status-5 w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#status-8"></use>
                </svg>
                <h2 class="text-status-5 font-bold">Контроль Качества</h2>
            </div>
            <template v-if="statQuality && statQuality.total.avg > 0">
                <div class="px-4 py-1 text-2xl uppercase v-bbgray font-bold flex justify-around">
                    <div class="flex items-center">
                        <svg class="fill-status-6 w-4 h-4 mr-2">
                            <use xlink:href="@/assets/i.svg#warranty"></use>
                        </svg>
                        <p class="text-status-6">{{ statQuality.total.avg }}</p>
                    </div>
                    <div class="flex items-center">
                        <svg class="fill-status-7 w-4 h-4 mr-2">
                            <use xlink:href="@/assets/i.svg#status-19"></use>
                        </svg>
                        <p class="text-status-7">{{ statQuality.total.claims }}</p>
                    </div>
                </div>
                <div class="flex justify-around p-2 items-center">
                    <div class="flex flex-col items-center">
                        <span class="text-xs font-bold text-status-7">0 - 6.9</span>
                        <span class="text-2xl font-bold text-status-7">{{ statQuality.total.bad }}</span>
                        <span class="text-xs text-gray-400">ПЛОХО</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="text-xs font-bold text-status-10">7 - 9.9</span>
                        <span class="text-2xl font-bold text-status-10">{{ statQuality.total.norm }}</span>
                        <span class="text-xs text-gray-400">ХОРОШО</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <span class="text-xs font-bold text-status-6">10</span>
                        <span class="text-2xl font-bold text-status-6">{{ statQuality.total.good }}</span>
                        <span class="text-xs text-gray-400">ОТЛИЧНО</span>
                    </div>
                </div>
            </template>
            <div v-else class="text-3xl font-bold text-gray-200 flex justify-around p-2 items-center">Данных нет</div>
        </div>

        <!-- #6 -->
        <div class="bg-white rounded-md grid grid-rows-statistic-main cursor-pointer" @click="setStatShow('standards')">
            <div class="p-4 text-base uppercase v-bbgray flex items-center">
                <svg class="fill-status-5 w-4 h-4 mr-3">
                    <use xlink:href="@/assets/i.svg#ico-rating"></use>
                </svg>
                <h2 class="text-status-5 font-bold">Стандарты</h2>
            </div>

            <template v-if="statRating && statRating.itog.total > 0">
                <div class="p-2 text-2xl uppercase v-bbgray font-bold flex justify-around">
                    <div
                        :class="[
                            { '!text-status-5': statRating.itog.total > 9 },
                            { '!text-status-10': statRating.itog.total >= 7 && statRating.itog.total < 9 },
                            { '!text-status-7': statRating.itog.total < 7 },
                        ]"
                    >
                        {{ statRating.itog.total }}
                    </div>
                </div>
                <div class="flex justify-around p-2 items-center">
                    <div class="flex flex-col items-center">
                        <svg
                            class="w-4 h-4 mb-1"
                            :class="[
                                { '!fill-status-5': statRating.itog.punctuality_rating > 9 },
                                { '!fill-status-10': statRating.itog.punctuality_rating >= 7 && statRating.itog.punctuality_rating < 9 },
                                { '!fill-status-7': statRating.itog.punctuality_rating < 7 },
                            ]"
                        >
                            <use xlink:href="@/assets/i.svg#ico-time"></use>
                        </svg>
                        <span
                            class="text-2xl font-bold"
                            :class="[
                                { '!text-status-5': statRating.itog.punctuality_rating > 9 },
                                { '!text-status-10': statRating.itog.punctuality_rating >= 7 && statRating.itog.punctuality_rating < 9 },
                                { '!text-status-7': statRating.itog.punctuality_rating < 7 },
                            ]"
                        >
                            {{ statRating.itog.punctuality_rating }}
                        </span>
                        <span class="text-xs text-gray-400">ДИСЦИПЛИНА</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <svg
                            class="w-4 h-4 mb-1"
                            :class="[
                                { '!fill-status-5': statRating.itog.quality_rating > 9 },
                                { '!fill-status-10': statRating.itog.quality_rating >= 7 && statRating.itog.quality_rating < 9 },
                                { '!fill-status-7': statRating.itog.quality_rating < 7 },
                            ]"
                        >
                            <use xlink:href="@/assets/i.svg#status-4"></use>
                        </svg>
                        <span
                            class="text-2xl font-bold"
                            :class="[
                                { '!text-status-5': statRating.itog.quality_rating > 9 },
                                { '!text-status-10': statRating.itog.quality_rating >= 7 && statRating.itog.quality_rating < 9 },
                                { '!text-status-7': statRating.itog.quality_rating < 7 },
                            ]"
                        >
                            {{ statRating.itog.quality_rating }}
                        </span>
                        <span class="text-xs text-gray-400">КАЧЕСТВО</span>
                    </div>
                    <div class="flex flex-col items-center">
                        <svg
                            class="w-4 h-4 mb-1"
                            :class="[
                                { '!fill-status-5': statRating.itog.claim_rating > 9 },
                                { '!fill-status-10': statRating.itog.claim_rating >= 7 && statRating.itog.claim_rating < 9 },
                                { '!fill-status-7': statRating.itog.claim_rating < 7 },
                            ]"
                        >
                            <use xlink:href="@/assets/i.svg#warranty"></use>
                        </svg>
                        <span
                            class="text-2xl font-bold"
                            :class="[
                                { '!text-status-5': statRating.itog.claim_rating > 9 },
                                { '!text-status-10': statRating.itog.claim_rating >= 7 && statRating.itog.claim_rating < 9 },
                                { '!text-status-7': statRating.itog.claim_rating < 7 },
                            ]"
                        >
                            {{ statRating.itog.claim_rating }}
                        </span>
                        <span class="text-xs text-gray-400">НАДЕЖНОСТЬ</span>
                    </div>
                </div>
            </template>
            <div v-else class="text-3xl font-bold text-gray-200 flex justify-around p-2 items-center">Данных нет</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "StatisticMain",
    computed: {
        ...mapGetters([
            // Change statisticShow value
            "statisticShow",

            // Connected Statistic states
            "statOrders",
            "statServices",
            "statSalaries",
            "statLoading",
            "statQuality",
            "statRating",

            // Counters
            "statCountOrders",
            "statCountAllSalaries",
            "statCountLoading",
            "statCountLoadingStatus",
            "statCountQuality",
            "statCountAvgQuality",
            "getRatingDiscipline",
            "getRatingQuality",
            "getRatingSafety",

            // Other
            "priceCategories",
        ]),
    },
    methods: {
        ...mapActions(["setStatShow", "getStatSalaries", "getStatLoading", "getStatRating", "getPrices"]),
    },
    mounted() {
        // this.getStatLoading();
        // this.getStatSalaries();
        // this.getStatRating();
        // this.getPrices();
    },
};
</script>
