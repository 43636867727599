<template>
    <h2 class="mr-15px min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
        <span class="w-full text-lg leading-none text-teal-700 block">КАРТА</span>
    </h2>
    <yandex-map :settings="settings">
        <!--Markers-->
    </yandex-map>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { yandexMap } from "vue-yandex-maps";

export default {
    name: "ClientMap",

    components: { yandexMap },
    // ymapMarker

    data() {
        return {
            settings: {
                apiKey: "",
                lang: "ru_RU",
                coordorder: "latlong",
                enterprise: false,
                version: "2.1",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "role", "profile"]),
    },

    methods: {
        ...mapActions(["getClients"]),
    },

    mounted() {
        this.getClients();
    },
};
</script>