<template>
  <div class="bg-white mt-4 rounded-lg">
    <div class="p-5 v-bbgray">
      <div class="flex">
        <span class="w-16 h-16 overflow-hidden relative mr-4 bg-status-1">
          <div class="avatar flex justify-center items-center h-full w-full">
            <svg class="fill-white w-8 h-8">
              <use xlink:href="@/assets/i.svg#status-1"></use>
            </svg>
          </div>
        </span>
        <div class="flex flex-col justify-center overflow-hidden pr-8">
          <span
            class="text-[10px] text-gray-400 leading-none uppercase mb-1"
            v-if="order.code"
          >
            {{ type }} <br />
            КОД: {{ order.code }}
          </span>
          <span
            class="text-[10px] text-gray-400 leading-none uppercase mb-1"
            v-else
            >{{ type }}</span
          >
          <a
            class="font-latobold text-xl hover:text-green-500 transition-colors duration-150 cursor-pointer"
            @click="showOrder(order)"
          >
            №{{ order.iid }}
          </a>
        </div>
      </div>
    </div>

    <div v-if="order.dates[0]?.date" class="p-5 v-bbgray">
      <div v-for="d in order.dates" :key="d" class="flex flex-wrap">
        <div class="flex items-center">
          <svg class="mr-4 fill-green-500 w-5 h-5">
            <use xlink:href="@/assets/i.svg#nav-date"></use>
          </svg>
          <p class="mr-4 font-latobold">{{ d.date }}</p>
        </div>
        <div class="flex items-center">
          <svg class="mr-4 fill-green-500 w-5 h-5">
            <use xlink:href="@/assets/i.svg#ico-time"></use>
          </svg>
          <p class="font-latobold">
            {{ d.start.slice(0, 5) }} - {{ d.end.slice(0, 5) }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="order.client || order.contact" class="p-5 v-bbgray">
      <div class="flex justify-between">
        <a
          class="flex items-center group cursor-pointer"
          @click="showThisClient()"
        >
          <div
            class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 font-latobold"
          >
            <IcoPic :pic="order.client?.picture" :name="order.client?.name" />
          </div>
          <div class="flex flex-col">
            <span class="text-[10px] text-gray-400 leading-none uppercase mb-1"
              >Клиент</span
            >
            <span
              class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
              >{{ order.client.name.slice(0, 20) }}</span
            >
            <span
              class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
              v-if="order.client.phone"
              >{{ order.client.phone }}</span
            >
          </div>
        </a>

        <a v-if="order.contact" class="preview cox60 p0">
          <div
            class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-green-500"
          >
            <IcoPic :pic="order.contact?.picture" :name="order.contact?.name" />
          </div>
          <div>
            <sup v-if="order.contact.position">{{
              order.contact.position
            }}</sup>
            <sup>Контакт</sup>
            {{ order.contact.name.slice(0, 20) }}<br />
            <span v-if="order.contact.phone">{{ order.contact.phone }}</span>
          </div>
        </a>
      </div>
    </div>

    <div v-if="order.operation" class="block">
      <div class="cont flx flx-sb status-1" @click="showOper">
        <a class="preview cox100 col50 p0 status">
          <span class="avatardel">
            <svg class="f-wht">
              <use xlink:href="@/assets/i.svg#status-1"></use>
            </svg>
          </span>
          ЗАКАЗ-НАРЯД
        </a>
        <div class="row">
          <ul class="flx flx-wrap m0">
            <li
              v-for="i in order.oper?.images"
              :key="i"
              class="miniimg mr5 mb5"
            >
              <img :src="'/uploads/oper/mini/' + i.img" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <template v-if="order.master_id">
      <div class="p-5 v-bbgray">
        <div class="flex justify-between">
          <template v-for="m in allMasters" :key="m.uid">
            <a
              v-if="order.master_id == m.uid"
              class="flex items-center group cursor-pointer"
            >
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-green-500"
              >
                <IcoPic :pic="m.picture" :name="m.name" />
              </div>
              <div class="flex flex-col justify-center overflow-hidden pr-8">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >Инженер</span
                >
                <span
                  class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                  >{{ m.name }} {{ m.secondname }}</span
                >
              </div>
            </a>
          </template>
        </div>
      </div>
    </template>

    <template v-if="order.prices">
      <div v-for="item in order.prices" :key="item" class="p-5 v-bbgray">
        <div class="flex items-center">
          <span
            class="rounded-full h-11 min-w-[2.75rem] w-11 flex items-center justify-center mr-4 text-lg text-white bg-status-1"
          >
            <svg class="w-5 h-5 fill-white">
              <use xlink:href="@/assets/i.svg#status-1"></use>
            </svg>
          </span>
          <span class="font-latobold text-base">{{ item.price.name }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ClaimOrder",

  data() {
    return {
      car: {},
      popup: false,
    };
  },

  props: {
    order: Object,
    type: String,
  },

  computed: {
    ...mapGetters(["s", "profile", "role", "roles", "client", "allMasters"]),
  },

  methods: {
    ...mapActions(["showOrder", "showClient"]),

    showThisClient() {
      if (["Manager", "Logist", "Studio", "Finance"].includes(this.role)) {
        this.showClient(this.order.client);
      }
    },

    showOper() {
      this.s.popup = "oper";
    },

    closePopup() {
      this.popup = false;
    },
  },
};
</script>
