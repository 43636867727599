<template>
    <template v-if="company">
        <a
            class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
            @click="$emit('close')"
        >
            <span class="text-white mr-2">Закрыть</span>
            <svg class="fill-white w-4 h-4">
                <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
        </a>

        <div class="h-full overflow-y-auto">
            <div class="p-5 v-bbgray">
                <a class="flex items-center group cursor-pointer">
                    <div class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg">
                        <span>{{ company.name?.slice(0, 1) }}</span>
                    </div>
                    <div class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300">{{ company.name }}</div>
                </a>
            </div>

            <div class="p-5 v-bbgray">
                <div class="flex flex-wrap text-center">
                    <template v-for="i in company.images" :key="i">
                        <IcoImg :img="i.img" :status="i.status" />
                    </template>

                    <template v-for="i in company.docs" :key="i">
                        <a class="mx-2 w-20 flex flex-col items-center" :href="'https://api.tahocrm.ru/uploads/client/orig/' + i.file" download>
                            <div class="mb-1 w-20 h-20 rounded-lg overflow-hidden">
                                <img class="w-full h-full object-cover" src="https://api.tahocrm.ru/images/pdf-file.png" />
                            </div>
                            <p class="text-xs">{{ i.name }}</p>
                        </a>
                    </template>
                </div>
            </div>

            <div class="p-5 v-bbgray flex flex-wrap">
                <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                    <label>Короткое имя</label>
                    <h3 class="font-latobold">{{ company.name }}</h3>
                </div>
                <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                    <label>Юр/Физ лицо</label>
                    <h3 class="font-latobold">{{ f[company.firm] }}</h3>
                </div>
                <template v-if="company.firm == 1 || company.firm == 3">
                    <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                        <label>Полное наименование</label>
                        <h3 class="font-latobold">{{ company.full }}</h3>
                    </div>
                    <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                        <label>Юр адрес</label>
                        <h3 class="font-latobold">{{ company.address }}</h3>
                    </div>
                    <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                        <label>ФИО директора</label>
                        <h3 class="font-latobold">{{ company.director }}</h3>
                    </div>
                    <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                        <label>На основании</label>
                        <h3 class="font-latobold">{{ company.osnovanie }}</h3>
                    </div>
                </template>
            </div>

            <div class="p-5 v-bbgray flex flex-wrap" id="ip">
                <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                    <label>ИНН</label>
                    <h3 class="font-latobold">{{ company.inn }}</h3>
                </div>
                <template v-if="company.firm == 1">
                    <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4" id="kpp">
                        <label>КПП</label>
                        <h3 class="font-latobold">{{ company.kpp }}</h3>
                    </div>
                    <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4" id="okpo">
                        <label>ОКПО</label>
                        <h3 class="font-latobold">{{ company.okpo }}</h3>
                    </div>
                </template>
                <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                    <label>ОГРН</label>
                    <h3 class="font-latobold">{{ company.ogrn }}</h3>
                </div>
            </div>

            <div class="p-5">
                <template v-if="company.banks">
                    <h3 class="font-latobold mb-2">Реквизиты</h3>
                    <div class="flex flex-wrap w-full" v-for="b in company.banks" :key="b">
                        <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                            <label>Бик</label>
                            <h3 class="font-latobold">{{ b.bik }}</h3>
                        </div>
                        <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                            <label>Название банка</label>
                            <h3 class="font-latobold">{{ b.bankname }}</h3>
                        </div>
                        <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                            <label>Р/С</label>
                            <h3 class="font-latobold">{{ b.rs }}</h3>
                        </div>
                        <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                            <label>К/С</label>
                            <h3 class="font-latobold">{{ b.ks }}</h3>
                        </div>

                        <!-- <div class="fgrp cox100">
                            <div class="hr"></div>
                        </div> -->
                    </div>
                </template>
            </div>

            <div v-if="company.firm == 2" class="flex flex-wrap" id="fiz">
                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                    <label>Серия паспорта</label>
                    <h3 class="font-latobold text-base">{{ company.passserial }}</h3>
                </div>
                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                    <label>Номер паспорта</label>
                    <h3 class="font-latobold text-base">{{ company.passnum }}</h3>
                </div>
                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                    <label>Дата выдачи</label>
                    <h3 class="font-latobold text-base">{{ company.passdate }}</h3>
                </div>
                <div class="clear"></div>
                <div class="min-w-[160px] w-2/3 flex flex-col my-2 px-4">
                    <label>Кем выдан</label>
                    <h3 class="font-latobold text-base">{{ company.passwho }}</h3>
                </div>
                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                    <label>Код подразделения</label>
                    <h3 class="font-latobold text-base">{{ company.passcode }}</h3>
                </div>
                <div class="clear"></div>
                <div class="min-w-[160px] w-2/3 flex flex-col my-2 px-4">
                    <label>Адрес</label>
                    <h3 class="font-latobold text-base">{{ company.address }}</h3>
                </div>
            </div>
        </div>
    </template>

    <div v-else class="py-2 flex justify-center">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "CompanyView",

    emits: ["close"],

    data() {
        return {
            f: { 1: "Юр. лицо", 2: "Физ. лицо", 3: "ИП" },
            imgName: {
                22: "Выписка ЕГРЮЛ",
                23: "ОГРН",
                24: "ИНН",
                25: "СНИЛС",
                26: "Паспорт основной",
                27: "Паспорт прописка",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "client", "company"]),
    },

    methods: {
        ...mapActions([]),

        bool() {
            // this.company.warranty = Boolean(this.company.warranty);
        },
    },

    mounted() {
        this.bool();
    },
};
</script>
