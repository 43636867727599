import API from "../../services/api";

export default {
    state: {
        bonuses: [],
    },

    mutations: {
        setBonuses: (state, bonuses) => {
            state.bonuses = bonuses;
        },
    },

    actions: {
        getBonuses(ctx) {
            API.GET("check/bonuses").then((res) => {
                ctx.commit("setBonuses", res.data);
            });
        },

        payBonus(ctx, item) {
            ctx.state.bonuses.forEach((el) => {
                if (el.id == item.id) {
                    item.status = 8;
                    return item;
                }
            });
            ctx.commit("setBonuses", ctx.state.bonuses);

            let formData = new FormData();
            formData.append("id", item.id);
            API.POST("check/pay-bonus", formData);
        },

        delBonus(ctx, item) {
            ctx.state.bonuses.forEach((el) => {
                if (el.id == item.id) {
                    item.status = 7;
                    return item;
                }
            });
            ctx.commit("setBonuses", ctx.state.bonuses);

            let formData = new FormData();
            formData.append("id", item.id);
            API.POST("check/del-bonus", formData);
        },

        returnBonus(ctx, item) {
            ctx.state.bonuses.forEach((el) => {
                if (el.id == item.id) {
                    item.status = 2;
                    return item;
                }
            });
            ctx.commit("setBonuses", ctx.state.bonuses);

            let formData = new FormData();
            formData.append("id", item.id);
            API.POST("check/return-bonus", formData);
        },

        reKK(ctx) {
            ctx.commit("setAlert", { msg: "Проверка начата", type: "info" });
            API.GET("order/re-kk").then(() => {
                ctx.commit("setAlert", { msg: "КК перепроверен", type: "info" });
            });
        },
    },

    getters: {
        checkBonuses(state) {
            return state.bonuses;
        },
    },
};
