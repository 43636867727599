<template>
    <OrderList
        v-if="orders?.length"
        :orders="orders"
    ></OrderList>
    <div v-else class="flex-cc w-full">
        <img src="https://api.tahocrm.ru/images/loader.svg" />
    </div>
</template>
<script>
import API from "@/services/api";
import OrderList from "@/components/report/OrderList.vue";
export default {
    name: "ReportLoadedOrderList",
    props: {
        ids: Array
    },
    components: {
        OrderList
    },
    data() {
        return {
            orders: []
        }
    },
    watch: {
        ids: {
            deep: false,
            async handler(from, to){
                
                if(from === to)return;
                
                let loaded = await API.GET("order/get-by-ids", {ids: this.ids.join(' ')});
                this.orders = loaded.data.orders;
                console.log("Отправляем",this.orders);

            }
        }
    },
    async mounted(){
        console.log('this.ids', this.ids);
        let loaded = await API.GET("order/get-by-ids", {ids: this.ids.join(' ')});
        this.orders = loaded.data.orders;
        console.log("Отправляем",this.orders);
        window.DEBUG_ReportLoadedOrderList = this;
    },
}
</script>