<template>
    <div id="count" class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px">
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(1)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="ClaimCounter">
                    {{ ClaimCounter[1] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Новые</span
            >
        </div>

        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(3)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-3"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="ClaimCounter">
                    {{ ClaimCounter[3] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-2 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Назначены</span
            >
        </div>
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(6)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#ico-ok"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="ClaimCounter">
                    {{ ClaimCounter[6] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Завершены</span
            >
        </div>
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(8)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-8"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="ClaimCounter">
                    {{ ClaimCounter[8] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Проверены</span
            >
        </div>
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(11)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-11"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="ClaimCounter">
                    {{ ClaimCounter[11] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-7 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Нарушения</span
            >
        </div>
    </div>

    <div class="pb-4 w-full flex flex-wrap items-center">
        <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
            <span class="w-full text-lg leading-none text-teal-700">
                {{ this.ClaimList?.length }}
            </span>
        </h2>

        <div class="flex-2_0 mr-15px min-w-[180px] my-4">
            <input type="text" class="h-9 w-full px-3 py-2 text-sm bg-slate-50" placeholder="Название" v-model="s.findme" @input="findClaim(s)" />
        </div>

        <input
            class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
            type="date"
            v-model="s.start"
            @change="findClaim(s)"
        />
        <input
            class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
            type="date"
            v-model="s.end"
            @change="findClaim(s)"
        />

        <select class="my-2 h-9 w-44 mr-4 bg-slate-50" @change="findClaim(s)" v-model="s.master">
            <option value="">Инженер</option>
            <template v-for="m in selectMasters" :key="m">
                <option v-if="s.master == m.uid" selected :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
                <option v-else :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
            </template>
        </select>

        <button class="v-btn bg-gray-500 hover:bg-gray-400 mr-4" v-if="s.clear">
            <span class="v-btn_text" @click="Clear">Сброс</span>
        </button>
    </div>

    <select v-model="itemsInPage" style="padding: 7px;">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
    <Paginator :count="pageCount" v-model="page"/>

    <div class="max-w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl">
        <div class="grid grid-cols-10 h-10 v-bbgray px-4 uppercase items-center">
            <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">№</div>
            <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-3">Клиент</div>
            <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">СОЗДАНА</div>
            <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">ВЫПОЛНЕН</div>
            <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">Заказ</div>
            <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">Диагностика</div>
            <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">Решение</div>
            <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">Гос Номер</div>
        </div>

        <div
            v-for="item in PaginatedClaimList"
            :key="item"
            :class="
                'grid grid-cols-10 gap-2 px-4 py-2 items-center v-bbgray cursor-pointer transition duration-300 hover:shadow-v1 odd:bg-white bg-teal-light' +
                item.status
            "
            @click="showClaim(item)"
        >
            <div class="flex">
                <div class="w-5 h-5 mr-2"><LineIco :status="parseInt(item.status)" /></div>
                #{{ item.iid }}
            </div>

            <div class="flex items-center col-span-3">
                <span
                    class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-1"
                    :class="'!bg-status-' + item.status"
                >
                    {{ item.cname?.slice(0, 1) }}
                </span>
                <div class="flex flex-col justify-center overflow-hidden pr-4">
                    <span class="text-[10px] text-gray-400 leading-none uppercase mb-1">заказ {{ item.order_iid }}</span>
                    <span class="text-base font-semibold break-all max-h-10 leading-5">{{ item.cname?.slice(0, 20) }}</span>
                </div>
            </div>

            <div>{{ moment(item.create_at).format("DD.MM.YYYY") }}</div>

            <div>
                <span v-if="item.order_date">{{ moment(item.order_date).format("DD.MM.YYYY") }}</span>
            </div>

            <div>
                <a v-if="item.order_master_id" class="flex items-center">
                    <div v-if="item.opicture" class="min-w-[1.5rem] w-6 h-w-6 rounded-full mr-3 overflow-hidden bg-status-1">
                        <img :src="'https://api.tahocrm.ru/uploads/users/' + item.opicture" alt="" />
                    </div>
                    <span v-else class="avatardel">{{ item.oname.slice(0, 1) }}</span>
                    {{ item.oname }}
                </a>
                <b v-else class="text-rose-500">Нет мастера!</b>
            </div>

            <div>
                <a v-if="item.diagnos_master_id" class="flex items-center">
                    <div v-if="item.dpicture" class="min-w-[1.5rem] w-6 h-w-6 rounded-full mr-3 overflow-hidden bg-status-1">
                        <img :src="'https://api.tahocrm.ru/uploads/users/' + item.dpicture" alt="" />
                    </div>
                    <span v-else class="avatardel">{{ item.dname.slice(0, 1) }}</span>
                    {{ item.dname }}
                </a>
                <b v-else class="text-rose-500">Нет мастера!</b>
            </div>

            <div>
                <a v-if="item.solution_master_id" class="flex items-center">
                    <div v-if="item.spicture" class="min-w-[1.5rem] w-6 h-w-6 rounded-full mr-3 overflow-hidden bg-status-1">
                        <img :src="'https://api.tahocrm.ru/uploads/users/' + item.spicture" alt="" />
                    </div>
                    <span v-else class="avatardel">{{ item.sname.slice(0, 1) }}</span>
                    {{ item.sname }}
                </a>
                <b v-else class="text-rose-500">Нет мастера!</b>
            </div>

            <div class="min-w-[90px] v-gray font-latoblod-serif">
                <span v-if="item.num" class="block text-center text-slate-600 w-full min-w-full rounded-sm v-tsnum px-2 py-1 text-xs uppercase">
                    {{ item.num }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import LineIco from "../components/ui/LineIco.vue";
import Paginator from "@/components/ui/Paginator";

export default {
    name: "ClaimList",

    components: { LineIco, Paginator },

    data() {
        return {
            moment: moment,
            page: 0,
            itemsInPage: 10,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "ClaimList", "ClaimCounter", "selectMasters"]),
        pageCount(){
            return Math.ceil((this.ClaimList?.length ?? 0) / this.itemsInPage);
        },
        PaginatedClaimList(){
            return this.ClaimList.slice(this.page * this.itemsInPage, (this.page + 1) * this.itemsInPage);
        },
    },

    methods: {
        ...mapActions(["getClaims", "setClaim", "findClaim", "find", "clearSettings"]),

        filterStatus(status) {
            this.s.status = status;
            this.findClaim(this.s);
        },

        filterPayStatus() {
            this.s.pay_status = 1;
            this.findClaim(this.s);
        },

        filterClaim() {
            this.s.status = "";
            this.s.claim = 1;
            this.findClaim(this.s);
        },

        filterDoc() {
            this.s.status = "";
            this.s.doc = 1;
            this.findClaim(this.s);
        },

        filterPay(pay) {
            this.s.pay = pay;
            this.findClaim(this.s);
        },

        filterCategory(category) {
            this.s.category = category;
            this.findClaim(this.s);
        },

        Clear() {
            this.clearSettings();
            this.findClaim(this.s);
        },

        showClaim(claim) {
            this.setClaim(claim);
        },
    },

    mounted() {
        this.getClaims();
    },
};
</script>
