import axios from "axios";
const API = "https://api.tahocrm.ru/";
class AuthService {
    login(user) {
        return axios
            .post(API + "signin", {
                username: user.username,
                password: user.password,
            })
            .then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(user) {
        return axios.post(API + "signup", {
            username: user.username,
            email: user.email,
            password: user.password,
        });
    }

    isLogged() {
        return !!localStorage.getItem("AUTH");
        // дописать функцию проверки AUTH в базе
    }
}
export default new AuthService();
