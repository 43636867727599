import API from "../../services/api";
import axios from "axios";
import router from "@/router/router";

export default {
    state: {
        menu: [],
        profile: [],
        role: "",
        myRoles: [],
        backM: 0,
    },

    mutations: {
        menu: (state, menu) => {
            state.menu = menu;
        },
        profile: (state, profile) => {
            state.profile = profile;
        },
        messages: (state, messages) => {
            state.messages = messages;
        },
        role: (state, role) => {
            state.role = role;
        },
        myRoles: (state, myRoles) => {
            state.myRoles = myRoles;
        },
        achive: (state, achive) => {
            state.achive = achive;
        },
        raiting: (state, raiting) => {
            state.raiting = raiting;
        },
        cats: (state, cats) => {
            state.cats = cats;
        },
        backM: (state, data) => {
            state.backM = data;
        },
        botHash: (state, data) => {
            state.botHash = data;
        },
    },

    actions: {
        Login(ctx, data) {
            console.log("Login", API);
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("password", data.password);
            // axios.post("https://api.tahocrm.ru/profile/login", formData).then((res) => {
            axios.post(API.URL+"profile/login", formData).then((res) => {

                console.log(res.data);
                ctx.commit("setAlert", res.data.alert);
                if (res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("profile", res.data.profile);
                    ctx.commit("role", res.data.role);
                    ctx.commit("myRoles", res.data.myRoles);
                    ctx.commit("menu", res.data.menu);
                    ctx.dispatch("loadSettings");
                    ctx.dispatch("getSelects");
                    router.push("/profile");
                }
            });
        },

        Logout(ctx) {
            localStorage.removeItem("AUTH");
            localStorage.removeItem("profile");
            ctx.commit("profile", null);
            ctx.commit("role", null);
            ctx.commit("myRoles", null);
            ctx.commit("menu", null);
            router.push({ name: "Login" });
        },

        getProfile(ctx) {
            // console.log("getProfile", performance.now());
            ctx.dispatch("loadSettings");
            return API.GET("profile/profile").then((res) => {
                console.log("getProfile", res.data);
                if (res.data.profile) {
                    ctx.commit("profile", res.data.profile);
                    ctx.commit("role", res.data.role);
                    ctx.commit("myRoles", res.data.myRoles);
                    ctx.commit("menu", res.data.menu);
                    ctx.commit("messages", res.data.messages);
                    ctx.commit("achive", res.data.achive);
                    ctx.commit("raiting", res.data.raiting);
                    ctx.commit("cats", res.data.cats);
                    ctx.commit("botHash", res.data.botHash);
                    ctx.dispatch("getSelects");

                    // вернуться в кабинет менеджера
                    let AUTHM = localStorage.getItem("AUTHM");
                    if (AUTHM && AUTHM.length > 0) {
                        ctx.commit("backM", 1);
                    }
                } else {
                    ctx.commit("profile", null);
                    router.push("/login");
                }
            });
        },

        // loadProfile(ctx) {
        //     console.log("loadProfile");
        //     let profile = localStorage.getItem("profile");
        //     if (profile) {
        //         ctx.commit("profile", JSON.parse(profile));
        //     } else {
        //         ctx.dispatch("getProfile");
        //     }
        //     let role = localStorage.getItem("role");
        //     if (role) {
        //         ctx.commit("role", .parse(role));
        //     } else {
        //         ctx.dispatch("getProfile");
        //     }
        // },

        changeRole(ctx, role) {
            ctx.commit("role", role);
            let formData = new FormData();
            formData.append("role", role);
            API.POST("profile/role", formData).then((res) => {
                ctx.commit("role", res.data.role);
                ctx.commit("menu", res.data.menu);
            });
        },

        nextRole(ctx) {
            API.GET("profile/next-role").then((res) => {
                ctx.commit("role", res.data.role);
                ctx.commit("menu", res.data.menu);
                if (router.currentRoute.value.path == "/order") {
                    ctx.dispatch("getOrders");
                }
            });
        },

        toggleDisallow(ctx, mid) {
            let formData = new FormData();
            formData.append("mid", mid);
            API.POST("profile/toggle-disallow", formData).then((res) => {
                ctx.commit("messages", res.data.messages);
            });
        },

        editProfile() { },
    },

    getters: {
        menu(state) {
            return state.menu;
        },
        profile(state) {
            return state.profile;
        },
        messages(state) {
            return state.messages;
        },
        role(state) {
            return state.role;
        },
        myRoles(state) {
            return state.myRoles;
        },
        achive(state) {
            return state.achive;
        },
        raiting(state) {
            return state.raiting;
        },
        cats(state) {
            return state.cats;
        },
        backM(state) {
            return state.backM;
        },
        botHash(state) {
            return state.botHash;
        },
    },
};
