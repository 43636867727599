import API from "../../services/api";

export default {
    state: {
        salaries: [],
        filteredSalaries: [],
        bonus: [],
        salaryOrders: null,
    },

    mutations: {
        setSalaries: (state, salaries) => {
            state.salaries = salaries;
            state.filteredSalaries = salaries;
        },
        setBonus: (state, salaries) => {
            state.bonus = salaries;
        },
        filteredSalaries: (state, salaries) => {
            state.filteredSalaries = salaries;
        },
        setSalaryOrders: (state, orders) => {
            state.salaryOrders = orders;
        },
        setSalaryBonuses: (state, orders)=>{
            state.salaryBonuses = orders;
        },
    },

    actions: {
        async getSalaries(ctx, {month, master}={}) {
            ctx.dispatch("setLoader", true);
            let url = [
                "/salary/salaries",
                [
                    month?`month=${month}`:'',
                    master?`master=${master}`:'',
                ]
                    .filter(Boolean)
                    .join('&'),
            ]
                .filter(Boolean)
                .join('?');
            let res = await API.GET(url);
            localStorage.setItem("salaries", JSON.stringify(res?.data?.salaries));
            ctx.commit("setSalaries", res.data.salaries);
            ctx.dispatch("setLoader", false);

        },

        fillSalaries(ctx) {
            ctx.commit("filteredSalaries", ctx.state.salaries);
        },

        getSalaryOrders(ctx, data) {
            if(data==null){
                ctx.dispatch("setSalaryOrders", null);
                return;
            }
            const t0 = performance.now();

            ctx.dispatch("setLoader", true);

            API.GET("salary/orders", data).then((res) => {
                ctx.dispatch("setSalaryOrders", res.data.orders);
                ctx.dispatch("setLoader", false);
                console.log(performance.now() - t0, "ms");
            });
        },

        setSalaryOrders(ctx, orders) {
            if(orders == null){
                return ctx.commit("setSalaryOrders", null);
            }
            orders.forEach((order) => {
                order.total = 0;
                if (order.salary) {
                    order.salary.forEach((elem) => {
                        if (elem.type == 1 && parseInt(elem.sum)) {
                            order.total += parseInt(elem.sum);
                        }
                    });
                }
            });
            ctx.commit("setSalaryOrders", orders);
        },
        setSalaryBonuses(ctx, orders){
            ctx.commit("setSalaryBonuses", orders);
        },
        getSalaryBonuses(ctx, data) {
            if(data==null){
                ctx.dispatch("setSalaryBonuses", null);
                return;
            }
            API.GET("salary/bonuses", data).then((res) => {
                ctx.commit("setSalaryBonuses", res.data.bonuses);
            });
        },


        getSalaryBonus(ctx) {
            API.GET("salary/bonus").then((res) => {
                ctx.commit("setBonus", res.data.bonus);
            });
        },

        reSalary() {
            API.GET("salary/re").then(() => {
                location.reload();
            });
        },

        getData(ctx) {
            API.GET("salary/data").then((res) => {
                localStorage.setItem("data", JSON.stringify(res.data.data));
                ctx.commit("setData", res.data.data);
                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findData", ctx.rootState.s);
                });
            });
        },

        findSalary(ctx, s) {
            let list = ctx.state.salaries;

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => String(item.uid) == String(s.master));
            }

            if (list.length != ctx.state.salaries.length) {
                s.clear = true;
            }

            ctx.state.filteredSalaries = list;
            ctx.dispatch("setSettings", s);
        },

        findMaster(ctx, s) {
            let list = ctx.state.masters;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => item.name.toLowerCase().indexOf(s.findme) !== -1);
            }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category_id) == String(s.category));
            }

            if (list.length != ctx.state.masters.length) {
                s.clear = true;
            }

            ctx.state.filteredMasters = list;
            ctx.dispatch("setSettings", s);
        },
    },

    getters: {
        SalaryList(state) {
            return state.filteredSalaries;
        },

        BonusList(state) {
            return state.bonus;
        },

        salaryOrders(state) {
            return state.salaryOrders;
        },
        salaryBonuses(state) {
            return state.salaryBonuses;
        },
    },
};
