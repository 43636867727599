<template>
    <div class="bg-white rounded-md">
        <div class="p-4 relative v-bbgray">
            <h2 class="text-center font-bold text-lg uppercase">Заказы</h2>
        </div>
        <div class="mx-4 h-[500px] w-full max-w-full" ref="newChartOrder"></div>
    </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { mapGetters } from "vuex";

export default {
    name: "StatisticOrders",

    computed: {
        ...mapGetters(["statisticShow", "statOrdersGraf"]),
    },

    watch: {
        statOrdersGraf(newValue) {
            if (newValue) {
                this.root.dispose();
                this.showGraf(newValue);
            }
        },
    },

    methods: {
        showGraf(chartData) {
            let root = am5.Root.new(this.$refs.newChartOrder);

            root.setThemes([am5themes_Animated.new(root)]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    pinchZoomX: true,
                })
            );

            let cursor = chart.set(
                "cursor",
                am5xy.XYCursor.new(root, {
                    behavior: "none",
                })
            );
            cursor.lineY.set("visible", false);

            let xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    groupData: false,
                    baseInterval: {
                        timeUnit: "week",
                        count: 1,
                    },
                    renderer: am5xy.AxisRendererX.new(root, {}),
                    tooltip: am5.Tooltip.new(root, {}),
                })
            );

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    // forceHidden: true,
                    renderer: am5xy.AxisRendererY.new(root, {}),
                })
            );

            let series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    name: "Заказы",
                    minBulletDistance: 10,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    valueXField: "date",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}",
                        strokeWidth: 4,
                    }),
                })
            );

            series.strokes.template.setAll({
                strokeWidth: 2,
            });

            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["date"],
            });

            if (chartData) {
                series.data.setAll(chartData);
            }

            series.bullets.push(function () {
                var circle = am5.Circle.new(root, {
                    radius: 4,
                    fill: root.interfaceColors.get("background"),
                    stroke: series.get("fill"),
                    strokeWidth: 2,
                });

                return am5.Bullet.new(root, {
                    sprite: circle,
                });
            });

            this.root = root;
        },
    },
    mounted() {
        this.showGraf(this.statOrdersGraf);
        window.DEBUG_STATISTIC_ORDERS = this;
    },

    beforeUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    },
};
</script>

<style scoped></style>
