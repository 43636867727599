<template>
  <div
    v-if="showTables"
    class="flex flex-col justify-between mt-5 mb-12 border-br-8"
  >
    <div class="plan-table-wrap w-full rounded-[8px] bg-white pb-10">
      <div class="plan-table-title flex justify-between border-tr-8 pl-16 pr-8">
        <h2>
          {{ localPlanMRT.profile.name }}
          {{ localPlanMRT.profile.secondname }}
        </h2>

        <div class="flex items-center">
          <label for="days_plan">дней</label>
          <input
            class="bg-green-100 days-input py-1 px-2 w-12 mr-8 ml-4 text-center"
            type="text"
            id="days_plan"
            v-model="localPlanMRT.days_plan"
          />

          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '01'"
            class="font-bold text-center"
          >
            Январь {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '02'"
            class="font-bold text-center"
          >
            Февраль {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '03'"
            class="font-bold text-center"
          >
            Март {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '04'"
            class="font-bold text-center"
          >
            Апрель {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '05'"
            class="font-bold text-center"
          >
            Май {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '06'"
            class="font-bold text-center"
          >
            Июнь {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '07'"
            class="font-bold text-center"
          >
            Июль {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '08'"
            class="font-bold text-center"
          >
            Август {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '09'"
            class="font-bold text-center"
          >
            Сентябрь {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '10'"
            class="font-bold text-center"
          >
            Октябрь {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '11'"
            class="font-bold text-center"
          >
            Ноябрь {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
          <h2
            v-if="localPlanMRT.date_plan.substr(5, 2) == '12'"
            class="font-bold text-center"
          >
            Декабрь {{ localPlanMRT.date_plan.substr(0, 4) }}
          </h2>
        </div>
      </div>
      <!-- ВЕРХНЯЯ ТАБЛИЦА -->
      <div class="wrap-pt-grid title-padding-pt border-tr-8 ml-8 mr-8">
        <p class="bg-green-200">Плановые<br />Показатели</p>
        <p class="bg-green-200">Дневной<br />план</p>
        <p class="bg-green-200">Общий<br />План</p>
        <p
          @click="changeFlag(0)"
          :class="{
            'bg-green-200': localPlanMRT.flags[0] == 1,
            'bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="cursor-pointer"
        >
          Тахографы
        </p>
        <p
          @click="changeFlag(1)"
          :class="{
            'bg-green-200': localPlanMRT.flags[1] == 1,
            'bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="cursor-pointer"
        >
          Мониторинг
        </p>
        <p
          @click="changeFlag(2)"
          :class="{
            'bg-green-200': localPlanMRT.flags[2] == 1,
            'bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="cursor-pointer"
        >
          Карты
        </p>
        <p
          @click="changeFlag(3)"
          :class="{
            'bg-green-200': localPlanMRT.flags[3] == 1,
            'bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="cursor-pointer"
        >
          Пропуска
        </p>
        <p
          @click="changeFlag(4)"
          :class="{
            'bg-green-200': localPlanMRT.flags[4] == 1,
            'bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="cursor-pointer"
        >
          Обучение
        </p>
        <p
          @click="changeFlag(5)"
          :class="{
            'bg-green-200': localPlanMRT.flags[5] == 1,
            'bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="cursor-pointer"
        >
          Водители
        </p>
      </div>
      <!-- Маржа, руб -->
      <div class="wrap-pt-grid data-padding-pt bg-white border-br-8 ml-8 mr-8">
        <p>{{ titles_top[0] }}</p>
        <input
          readonly
          :value="day_plan_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="month_plan_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.taho_plan"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          class="bg-green-100 px-2"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
        />
        <input
          v-model="localPlanMRT.monitor_plan"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.card_plan"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.propusk_plan"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.teach_plan"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.driver_plan"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!-- Маржа, руб -->

      <!-- % MR -->
      <div class="wrap-pt-grid data-padding-pt bg-white border-br-8 ml-8 mr-8">
        <p>{{ titles_top[1] }}</p>
        <p></p>
        <input
          readonly
          :value="month_margin_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.taho_margin"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.monitor_margin"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.card_margin"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.propusk_margin"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.teach_margin"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.driver_margin"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!-- % MR -->

      <!-- Доход, руб -->
      <div class="wrap-pt-grid data-padding-pt bg-white border-br-8 ml-8 mr-8">
        <p>{{ titles_top[2] }}</p>
        <input
          readonly
          :value="day_income_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="month_income_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.taho_plan) * 100) /
                Number(localPlanMRT.taho_margin)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.monitor_plan) * 100) /
                Number(localPlanMRT.monitor_margin)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.card_plan) * 100) /
                Number(localPlanMRT.card_margin)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.propusk_plan) * 100) /
                Number(localPlanMRT.propusk_margin)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.teach_plan) * 100) /
                Number(localPlanMRT.teach_margin)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.driver_plan) * 100) /
                Number(localPlanMRT.driver_margin)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-gray-200 px-2"
        />
      </div>
      <!-- Доход, руб -->

      <!-- Средний чек, руб -->
      <div class="wrap-pt-grid data-padding-pt bg-white border-br-8 ml-8 mr-8">
        <p>{{ titles_top[3] }}</p>
        <p></p>
        <input
          readonly
          :value="month_mean_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.taho_mean"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.monitor_mean"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.card_mean"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.propusk_mean"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.teach_mean"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.driver_mean"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!-- Средний чек, руб -->

      <!-- ВЕРХНЯЯ ТАБЛИЦА -->
    </div>

    <div class="plan-table-wrap w-full rounded-[8px] bg-white pb-16">
      <div
        class="wrap-pt-grid data-second-color title-padding-pt border-tr-8 ml-8 mr-8"
      >
        <p class="bg-green-200">Плановые<br />Показатели</p>
        <p class="bg-green-200">Дневной<br />план</p>
        <p class="bg-green-200">Общий<br />План</p>
        <p
          @click="changeFlag(0)"
          :class="{
            'bg-green-200': localPlanMRT.flags[0] == 1,
            'bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="cursor-pointer"
        >
          Тахографы
        </p>
        <p
          @click="changeFlag(1)"
          :class="{
            'bg-green-200': localPlanMRT.flags[1] == 1,
            'bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="cursor-pointer"
        >
          Мониторинг
        </p>
        <p
          @click="changeFlag(2)"
          :class="{
            'bg-green-200': localPlanMRT.flags[2] == 1,
            'bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="cursor-pointer"
        >
          Карты
        </p>
        <p
          @click="changeFlag(3)"
          :class="{
            'bg-green-200': localPlanMRT.flags[3] == 1,
            'bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="cursor-pointer"
        >
          Пропуска
        </p>
        <p
          @click="changeFlag(4)"
          :class="{
            'bg-green-200': localPlanMRT.flags[4] == 1,
            'bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="cursor-pointer"
        >
          Обучение
        </p>
        <p
          @click="changeFlag(5)"
          :class="{
            'bg-green-200': localPlanMRT.flags[5] == 1,
            'bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="cursor-pointer"
        >
          Водители
        </p>
      </div>

      <!-- оплаченных счетов -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[0] }}</p>
        <input
          readonly
          :value="day_invoice_done_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="month_invoice_done_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />

        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.taho_plan) * 100) /
                Number(localPlanMRT.taho_margin) /
                Number(localPlanMRT.taho_mean)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.monitor_plan) * 100) /
                Number(localPlanMRT.monitor_margin) /
                Number(localPlanMRT.monitor_mean)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.card_plan) * 100) /
                Number(localPlanMRT.card_margin) /
                Number(localPlanMRT.card_mean)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.propusk_plan) * 100) /
                Number(localPlanMRT.propusk_margin) /
                Number(localPlanMRT.propusk_mean)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.teach_plan) * 100) /
                Number(localPlanMRT.teach_margin) /
                Number(localPlanMRT.teach_mean)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (Number(localPlanMRT.driver_plan) * 100) /
                Number(localPlanMRT.driver_margin) /
                Number(localPlanMRT.driver_mean)
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-gray-200 px-2"
        />
      </div>
      <!-- оплаченных счетов  -->

      <!-- % отказов выставленных  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[1] }}</p>
        <p></p>
        <input
          readonly
          :value="fail_month_invoice_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.fail_taho_invoice"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_monitor_invoice"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_card_invoice"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_propusk_invoice"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_teach_invoice"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_driver_invoice"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!--% отказов выставленных -->

      <!-- выставленных счетов  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[2] }}</p>
        <input
          readonly
          :value="day_invoice_all_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="month_invoice_all_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((Number(localPlanMRT.taho_plan) * 100) /
                Number(localPlanMRT.taho_margin) /
                Number(localPlanMRT.taho_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_invoice))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((Number(localPlanMRT.monitor_plan) * 100) /
                Number(localPlanMRT.monitor_margin) /
                Number(localPlanMRT.monitor_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_invoice))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((Number(localPlanMRT.card_plan) * 100) /
                Number(localPlanMRT.card_margin) /
                Number(localPlanMRT.card_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_card_invoice))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((Number(localPlanMRT.propusk_plan) * 100) /
                Number(localPlanMRT.propusk_margin) /
                Number(localPlanMRT.propusk_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_invoice))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((Number(localPlanMRT.teach_plan) * 100) /
                Number(localPlanMRT.teach_margin) /
                Number(localPlanMRT.teach_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_invoice))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((Number(localPlanMRT.driver_plan) * 100) /
                Number(localPlanMRT.driver_margin) /
                Number(localPlanMRT.driver_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_invoice))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-gray-200 px-2"
        />
      </div>
      <!--выставленных счетов -->

      <!-- % отказов заявок  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[3] }}</p>
        <p></p>
        <input
          readonly
          :value="fail_month_bid_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.fail_taho_bid"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_monitor_bid"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_card_bid"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_propusk_bid"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_teach_bid"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_driver_bid"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!-- % отказов заявок  -->

      <!-- сделанных заявок  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[4] }}</p>
        <input
          readonly
          :value="day_bid_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="month_bid_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((Number(localPlanMRT.taho_plan) * 100) /
                Number(localPlanMRT.taho_margin) /
                Number(localPlanMRT.taho_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_bid))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((Number(localPlanMRT.monitor_plan) * 100) /
                Number(localPlanMRT.monitor_margin) /
                Number(localPlanMRT.monitor_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_bid))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((Number(localPlanMRT.card_plan) * 100) /
                Number(localPlanMRT.card_margin) /
                Number(localPlanMRT.card_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_card_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_card_bid))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((Number(localPlanMRT.propusk_plan) * 100) /
                Number(localPlanMRT.propusk_margin) /
                Number(localPlanMRT.propusk_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_bid))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((Number(localPlanMRT.teach_plan) * 100) /
                Number(localPlanMRT.teach_margin) /
                Number(localPlanMRT.teach_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_bid))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((Number(localPlanMRT.driver_plan) * 100) /
                Number(localPlanMRT.driver_margin) /
                Number(localPlanMRT.driver_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_bid))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-gray-200 px-2"
        />
      </div>
      <!-- сделанных заявок -->

      <!--% отказа ЛИДов  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[5] }}</p>
        <p></p>
        <input
          readonly
          :value="fail_month_lead_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.fail_taho_lead"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_monitor_lead"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_card_lead"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_propusk_lead"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_teach_lead"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_driver_lead"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!--% отказа ЛИДов  -->

      <!--сделано ЛИДов  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[6] }}</p>
        <input
          readonly
          :value="day_lead_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="month_lead_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((Number(localPlanMRT.taho_plan) * 100) /
                Number(localPlanMRT.taho_margin) /
                Number(localPlanMRT.taho_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_lead))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((Number(localPlanMRT.monitor_plan) * 100) /
                Number(localPlanMRT.monitor_margin) /
                Number(localPlanMRT.monitor_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_lead))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((Number(localPlanMRT.card_plan) * 100) /
                Number(localPlanMRT.card_margin) /
                Number(localPlanMRT.card_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_card_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_card_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_card_lead))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((Number(localPlanMRT.propusk_plan) * 100) /
                Number(localPlanMRT.propusk_margin) /
                Number(localPlanMRT.propusk_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_lead))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((Number(localPlanMRT.teach_plan) * 100) /
                Number(localPlanMRT.teach_margin) /
                Number(localPlanMRT.teach_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_lead))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((Number(localPlanMRT.driver_plan) * 100) /
                Number(localPlanMRT.driver_margin) /
                Number(localPlanMRT.driver_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_lead))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-gray-200 px-2"
        />
      </div>
      <!--сделано ЛИДов  -->

      <!--% исходящих касаний  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[7] }}</p>
        <p></p>
        <input
          readonly
          :value="month_perc_out_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.taho_perc_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.monitor_perc_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.card_perc_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.propusk_perc_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.teach_perc_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.driver_perc_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!-- % исходящих касаний -->

      <!-- % отказа исходящих касаний -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[8] }}</p>
        <p></p>
        <input
          readonly
          :value="fail_month_touch_out_special"
          oninput="this.value =
        Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.fail_taho_touch_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_monitor_touch_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_card_touch_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_propusk_touch_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_teach_touch_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_driver_touch_out"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!-- % отказа исходящих касаний  -->

      <!-- исходящих касаний -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[9] }}</p>
        <input
          readonly
          :value="day_touch_out_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="month_touch_out_out_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.taho_plan) * 100) /
                Number(localPlanMRT.taho_margin) /
                Number(localPlanMRT.taho_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_lead))) *
                Number(localPlanMRT.taho_perc_out)) /
                (100 - Number(localPlanMRT.fail_taho_touch_out))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.monitor_plan) * 100) /
                Number(localPlanMRT.monitor_margin) /
                Number(localPlanMRT.monitor_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_lead))) *
                Number(localPlanMRT.monitor_perc_out)) /
                (100 - Number(localPlanMRT.fail_monitor_touch_out))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.card_plan) * 100) /
                Number(localPlanMRT.card_margin) /
                Number(localPlanMRT.card_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_card_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_card_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_card_lead))) *
                Number(localPlanMRT.card_perc_out)) /
                (100 - Number(localPlanMRT.fail_card_touch_out))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.propusk_plan) * 100) /
                Number(localPlanMRT.propusk_margin) /
                Number(localPlanMRT.propusk_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_lead))) *
                Number(localPlanMRT.propusk_perc_out)) /
                (100 - Number(localPlanMRT.fail_propusk_touch_out))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.teach_plan) * 100) /
                Number(localPlanMRT.teach_margin) /
                Number(localPlanMRT.teach_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_lead))) *
                Number(localPlanMRT.teach_perc_out)) /
                (100 - Number(localPlanMRT.fail_teach_touch_out))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.driver_plan) * 100) /
                Number(localPlanMRT.driver_margin) /
                Number(localPlanMRT.driver_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_lead))) *
                Number(localPlanMRT.driver_perc_out)) /
                (100 - Number(localPlanMRT.fail_driver_touch_out))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-gray-200 px-2"
        />
      </div>
      <!-- исходящих касаний -->

      <!--% входящих касаний  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[10] }}</p>
        <p></p>
        <input
          readonly
          :value="month_perc_in_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.taho_perc_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.monitor_perc_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.card_perc_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.propusk_perc_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.teach_perc_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.driver_perc_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!--% входящих касаний  -->

      <!-- % отказа входящих касаний  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[11] }}</p>
        <p></p>
        <input
          readonly
          :value="fail_month_touch_in_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.fail_taho_touch_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_monitor_touch_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_card_touch_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_propusk_touch_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_teach_touch_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.fail_driver_touch_in"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!-- % отказа входящих касаний  -->

      <!-- входящих касаний -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[12] }}</p>
        <input
          readonly
          :value="day_touch_in_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="month_touch_in_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.taho_plan) * 100) /
                Number(localPlanMRT.taho_margin) /
                Number(localPlanMRT.taho_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_taho_lead))) *
                Number(localPlanMRT.taho_perc_in)) /
                (100 - Number(localPlanMRT.fail_taho_touch_in))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.monitor_plan) * 100) /
                Number(localPlanMRT.monitor_margin) /
                Number(localPlanMRT.monitor_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_monitor_lead))) *
                Number(localPlanMRT.monitor_perc_in)) /
                (100 - Number(localPlanMRT.fail_monitor_touch_in))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.card_plan) * 100) /
                Number(localPlanMRT.card_margin) /
                Number(localPlanMRT.card_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_card_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_card_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_card_lead))) *
                Number(localPlanMRT.card_perc_in)) /
                (100 - Number(localPlanMRT.fail_card_touch_in))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.propusk_plan) * 100) /
                Number(localPlanMRT.propusk_margin) /
                Number(localPlanMRT.propusk_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_propusk_lead))) *
                Number(localPlanMRT.propusk_perc_in)) /
                (100 - Number(localPlanMRT.fail_propusk_touch_in))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.teach_plan) * 100) /
                Number(localPlanMRT.teach_margin) /
                Number(localPlanMRT.teach_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_teach_lead))) *
                Number(localPlanMRT.teach_perc_in)) /
                (100 - Number(localPlanMRT.fail_teach_touch_in))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-gray-200 px-2"
        />
        <input
          readonly
          :value="
            Math.round(
              (((((((((Number(localPlanMRT.driver_plan) * 100) /
                Number(localPlanMRT.driver_margin) /
                Number(localPlanMRT.driver_mean)) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_invoice))) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_bid))) *
                100) /
                (100 - Number(localPlanMRT.fail_driver_lead))) *
                Number(localPlanMRT.driver_perc_in)) /
                (100 - Number(localPlanMRT.fail_driver_touch_in))
            )
          "
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-gray-200 px-2"
        />
      </div>
      <!--входящих касаний  -->

      <!-- % новых клиентов  -->
      <div
        class="wrap-pt-grid data2-second-color data2-padding-pt bg-white border-br-8 ml-8 mr-8"
      >
        <p>{{ title_bottom[13] }}</p>
        <p></p>
        <input
          readonly
          :value="month_new_special"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          class="px-2"
        />
        <input
          v-model="localPlanMRT.taho_new"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[0] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.monitor_new"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[1] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.card_new"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[2] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.propusk_new"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[3] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.teach_new"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[4] == 0,
          }"
          class="bg-green-100 px-2"
        />
        <input
          v-model="localPlanMRT.driver_new"
          oninput="this.value = Number(this.value.replace(/[^0-9]/g, ''))"
          type="text"
          :class="{
            '!bg-rose-200': localPlanMRT.flags[5] == 0,
          }"
          class="bg-green-100 px-2"
        />
      </div>
      <!-- % новых клиентов -->
    </div>

    <div class="flex justify-center items-center bg-white pb-10">
      <button
        @click="saveReportMRT()"
        v-if="date_prop && localPlanMRT.approved != 1"
        class="button-save-table bg-green-500"
      >
        Сохранить
      </button>
      <button
        v-if="date_prop"
        @click="closeMRT()"
        class="button-abort-table mx-12 bg-white"
      >
        Назад
      </button>
      <button
        @click="approveThisReport()"
        v-if="date_prop && localPlanMRT.approved != 1"
        class="button-accept-table bg-blue-300"
      >
        Утвердить
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReportPlanManagerTable",
  props: {
    manager_id_prop: Int16Array,
    date_prop: String,
  },
  data() {
    return {
      dateFrom: null,
      localPlanMRT: {},
      text_manager: "",
      showTables: false,
      days_plan: 22,

      titles_top: ["Маржа, руб", "% MR", "Доход, руб", "Средний чек, руб"],
      title_bottom: [
        "оплаченных счетов",
        "% отказов выставленных",
        "выставленных счетов",
        "% отказов заявок",
        "сделанных заявок",
        "% отказа ЛИДов",
        "сделано ЛИДов",
        "% исходящих касаний",
        "% отказа исходящих касаний",
        "исходящих касаний",
        "% входящих касаний",
        "% отказа входящих касаний",
        "входящих касаний",
        "% новых клиентов",
      ],
    };
  },
  computed: {
    ...mapGetters(["s", "profile", "selectManagers", "planMRT"]),

    // Маржа, Руб
    month_plan_special() {
      let MPSum = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.taho_plan);
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.monitor_plan);
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.card_plan);
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.propusk_plan);
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.teach_plan);
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.driver_plan);
        }
      }
      console.log("taho_plan", this.localPlanMRT.taho_plan);
      console.log("MPSum", MPSum);
      return MPSum;
    },

    day_plan_special() {
      return Math.round(this.month_plan_special / this.localPlanMRT.days_plan);
    },
    // Маржа, Руб

    // % MR
    month_margin_special() {
      let MMSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.taho_margin);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.monitor_margin);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.card_margin);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.propusk_margin);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.teach_margin);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.driver_margin);
          counter++;
        }
      }

      return Math.round(MMSum / counter);
    },
    // % MR

    // % Доход, руб
    month_income_special() {
      let MMSum = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MMSum +=
            (Number(this.localPlanMRT.taho_plan) * 100) /
            Number(this.localPlanMRT.taho_margin);
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MMSum +=
            (Number(this.localPlanMRT.monitor_plan) * 100) /
            Number(this.localPlanMRT.monitor_margin);
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MMSum +=
            (Number(this.localPlanMRT.card_plan) * 100) /
            Number(this.localPlanMRT.card_margin);
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MMSum +=
            (Number(this.localPlanMRT.propusk_plan) * 100) /
            Number(this.localPlanMRT.propusk_margin);
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MMSum +=
            (Number(this.localPlanMRT.teach_plan) * 100) /
            Number(this.localPlanMRT.teach_margin);
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MMSum +=
            (Number(this.localPlanMRT.driver_plan) * 100) /
            Number(this.localPlanMRT.driver_margin);
        }
      }

      return Math.round(MMSum);
    },

    day_income_special() {
      return Math.round(
        this.month_income_special / this.localPlanMRT.days_plan
      );
    },
    // % Доход, руб

    // Средний Чек, Руб
    month_mean_special() {
      let MMSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.taho_mean);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.monitor_mean);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.card_mean);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.propusk_mean);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.teach_mean);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MMSum += Number(this.localPlanMRT.driver_mean);
          counter++;
        }
      }

      return Math.round(MMSum / counter);
    },
    // Средний Чек, Руб

    // оплаченных счетов
    month_invoice_done_special() {
      let MISum = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (Number(this.localPlanMRT.taho_plan) * 100) /
            Number(this.localPlanMRT.taho_margin) /
            Number(this.localPlanMRT.taho_mean);
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (Number(this.localPlanMRT.monitor_plan) * 100) /
            Number(this.localPlanMRT.monitor_margin) /
            Number(this.localPlanMRT.monitor_mean);
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (Number(this.localPlanMRT.card_plan) * 100) /
            Number(this.localPlanMRT.card_margin) /
            Number(this.localPlanMRT.card_mean);
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (Number(this.localPlanMRT.propusk_plan) * 100) /
            Number(this.localPlanMRT.propusk_margin) /
            Number(this.localPlanMRT.propusk_mean);
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (Number(this.localPlanMRT.teach_plan) * 100) /
            Number(this.localPlanMRT.teach_margin) /
            Number(this.localPlanMRT.teach_mean);
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (Number(this.localPlanMRT.driver_plan) * 100) /
            Number(this.localPlanMRT.driver_margin) /
            Number(this.localPlanMRT.driver_mean);
        }
      }
      console.log("MISum", MISum);
      return Math.round(MISum);
    },
    day_invoice_done_special() {
      return Math.round(
        this.month_invoice_done_special / this.localPlanMRT.days_plan
      );
    },
    // оплаченных счетов

    // % отказов выставленных
    fail_month_invoice_special() {
      let FMSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_taho_invoice);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_monitor_invoice);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_card_invoice);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_propusk_invoice);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_teach_invoice);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_driver_invoice);
          counter++;
        }
      }

      return Math.round(FMSum / counter);
    },
    // % отказов выставленных

    // выставленных счетов
    month_invoice_all_special() {
      let MISum = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (((Number(this.localPlanMRT.taho_plan) * 100) /
              Number(this.localPlanMRT.taho_margin) /
              Number(this.localPlanMRT.taho_mean)) *
              100) /
            (100 - Number(this.localPlanMRT.fail_taho_invoice));
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (((Number(this.localPlanMRT.monitor_plan) * 100) /
              Number(this.localPlanMRT.monitor_margin) /
              Number(this.localPlanMRT.monitor_mean)) *
              100) /
            (100 - Number(this.localPlanMRT.fail_monitor_invoice));
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (((Number(this.localPlanMRT.card_plan) * 100) /
              Number(this.localPlanMRT.card_margin) /
              Number(this.localPlanMRT.card_mean)) *
              100) /
            (100 - Number(this.localPlanMRT.fail_card_invoice));
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (((Number(this.localPlanMRT.propusk_plan) * 100) /
              Number(this.localPlanMRT.propusk_margin) /
              Number(this.localPlanMRT.propusk_mean)) *
              100) /
            (100 - Number(this.localPlanMRT.fail_propusk_invoice));
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (((Number(this.localPlanMRT.teach_plan) * 100) /
              Number(this.localPlanMRT.teach_margin) /
              Number(this.localPlanMRT.teach_mean)) *
              100) /
            (100 - Number(this.localPlanMRT.fail_teach_invoice));
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MISum +=
            (((Number(this.localPlanMRT.driver_plan) * 100) /
              Number(this.localPlanMRT.driver_margin) /
              Number(this.localPlanMRT.driver_mean)) *
              100) /
            (100 - Number(this.localPlanMRT.fail_driver_invoice));
        }
      }
      return Math.round(MISum);
    },

    day_invoice_all_special() {
      return Math.round(
        this.month_invoice_all_special / Number(this.localPlanMRT.days_plan)
      );
    },
    // % отказов заявок
    fail_month_bid_special() {
      let FMSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_taho_bid);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_monitor_bid);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_card_bid);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_propusk_bid);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_teach_bid);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_driver_bid);
          counter++;
        }
      }

      return Math.round(FMSum / counter);
    },
    // % отказов заявок

    // сделанных заявок
    month_bid_special() {
      let MBSum = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MBSum +=
            (((((Number(this.localPlanMRT.taho_plan) * 100) /
              Number(this.localPlanMRT.taho_margin) /
              Number(this.localPlanMRT.taho_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_taho_invoice))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_taho_bid));
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MBSum +=
            (((((Number(this.localPlanMRT.monitor_plan) * 100) /
              Number(this.localPlanMRT.monitor_margin) /
              Number(this.localPlanMRT.monitor_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_monitor_invoice))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_monitor_bid));
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MBSum +=
            (((((Number(this.localPlanMRT.card_plan) * 100) /
              Number(this.localPlanMRT.card_margin) /
              Number(this.localPlanMRT.card_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_card_invoice))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_card_bid));
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MBSum +=
            (((((Number(this.localPlanMRT.propusk_plan) * 100) /
              Number(this.localPlanMRT.propusk_margin) /
              Number(this.localPlanMRT.propusk_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_propusk_invoice))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_propusk_bid));
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MBSum +=
            (((((Number(this.localPlanMRT.teach_plan) * 100) /
              Number(this.localPlanMRT.teach_margin) /
              Number(this.localPlanMRT.teach_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_teach_invoice))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_teach_bid));
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MBSum +=
            (((((Number(this.localPlanMRT.driver_plan) * 100) /
              Number(this.localPlanMRT.driver_margin) /
              Number(this.localPlanMRT.driver_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_driver_invoice))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_driver_bid));
        }
      }
      return Math.round(MBSum);
    },

    day_bid_special() {
      return Math.round(
        this.month_bid_special / Number(this.localPlanMRT.days_plan)
      );
    },

    // сделанных заявок

    // % отказа ЛИДов
    fail_month_lead_special() {
      let FMSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_taho_lead);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_monitor_lead);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_card_lead);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_propusk_lead);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_teach_lead);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_driver_lead);
          counter++;
        }
      }

      return Math.round(FMSum / counter);
    },
    // % отказа ЛИДов

    // сделано ЛИДов
    month_lead_special() {
      let MLSum = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((Number(this.localPlanMRT.taho_plan) * 100) /
              Number(this.localPlanMRT.taho_margin) /
              Number(this.localPlanMRT.taho_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_taho_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_taho_bid))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_taho_lead));
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((Number(this.localPlanMRT.monitor_plan) * 100) /
              Number(this.localPlanMRT.monitor_margin) /
              Number(this.localPlanMRT.monitor_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_monitor_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_monitor_bid))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_monitor_lead));
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((Number(this.localPlanMRT.card_plan) * 100) /
              Number(this.localPlanMRT.card_margin) /
              Number(this.localPlanMRT.card_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_card_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_card_bid))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_card_lead));
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((Number(this.localPlanMRT.propusk_plan) * 100) /
              Number(this.localPlanMRT.propusk_margin) /
              Number(this.localPlanMRT.propusk_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_propusk_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_propusk_bid))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_propusk_lead));
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((Number(this.localPlanMRT.teach_plan) * 100) /
              Number(this.localPlanMRT.teach_margin) /
              Number(this.localPlanMRT.teach_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_teach_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_teach_bid))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_teach_lead));
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((Number(this.localPlanMRT.driver_plan) * 100) /
              Number(this.localPlanMRT.driver_margin) /
              Number(this.localPlanMRT.driver_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_driver_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_driver_bid))) *
              100) /
            (100 - Number(this.localPlanMRT.fail_driver_lead));
        }
      }
      return Math.round(MLSum);
    },

    day_lead_special() {
      return Math.round(
        this.month_lead_special / Number(this.localPlanMRT.days_plan)
      );
    },
    // сделано ЛИДов

    // % исходящих касаний
    month_perc_out_special() {
      let MPSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.taho_perc_out);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.monitor_perc_out);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.card_perc_out);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.propusk_perc_out);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.teach_perc_out);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.driver_perc_out);
          counter++;
        }
      }

      return Math.round(MPSum / counter);
    },
    // % исходящих касаний

    // % отказа исходящих касаний
    fail_month_touch_out_special() {
      let FMSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_taho_touch_out);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_monitor_touch_out);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_card_touch_out);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_propusk_touch_out);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_teach_touch_out);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          FMSum += Number(this.localPlanMRT.fail_driver_touch_out);
          counter++;
        }
      }

      return Math.round(FMSum / counter);
    },
    // % отказа исходящих касаний

    // исходящих касаний
    month_touch_out_out_special() {
      let MLSum = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.taho_plan) * 100) /
              Number(this.localPlanMRT.taho_margin) /
              Number(this.localPlanMRT.taho_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_taho_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_taho_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_taho_lead))) *
              Number(this.localPlanMRT.taho_perc_out)) /
            (100 - Number(this.localPlanMRT.fail_taho_touch_out));
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.monitor_plan) * 100) /
              Number(this.localPlanMRT.monitor_margin) /
              Number(this.localPlanMRT.monitor_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_monitor_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_monitor_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_monitor_lead))) *
              Number(this.localPlanMRT.monitor_perc_out)) /
            (100 - Number(this.localPlanMRT.fail_monitor_touch_out));
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.card_plan) * 100) /
              Number(this.localPlanMRT.card_margin) /
              Number(this.localPlanMRT.card_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_card_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_card_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_card_lead))) *
              Number(this.localPlanMRT.card_perc_out)) /
            (100 - Number(this.localPlanMRT.fail_card_touch_out));
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.propusk_plan) * 100) /
              Number(this.localPlanMRT.propusk_margin) /
              Number(this.localPlanMRT.propusk_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_propusk_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_propusk_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_propusk_lead))) *
              Number(this.localPlanMRT.propusk_perc_out)) /
            (100 - Number(this.localPlanMRT.fail_propusk_touch_out));
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.teach_plan) * 100) /
              Number(this.localPlanMRT.teach_margin) /
              Number(this.localPlanMRT.teach_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_teach_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_teach_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_teach_lead))) *
              Number(this.localPlanMRT.teach_perc_out)) /
            (100 - Number(this.localPlanMRT.fail_teach_touch_out));
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.driver_plan) * 100) /
              Number(this.localPlanMRT.driver_margin) /
              Number(this.localPlanMRT.driver_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_driver_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_driver_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_driver_lead))) *
              Number(this.localPlanMRT.driver_perc_out)) /
            (100 - Number(this.localPlanMRT.fail_driver_touch_out));
        }
      }
      return Math.round(MLSum);
    },

    day_touch_out_special() {
      return Math.round(
        this.month_touch_out_out_special / Number(this.localPlanMRT.days_plan)
      );
    },
    // исходящих касаний

    // % входящих касаний
    month_perc_in_special() {
      let MPSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.taho_perc_in);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.monitor_perc_in);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.card_perc_in);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.propusk_perc_in);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.teach_perc_in);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.driver_perc_in);
          counter++;
        }
      }

      return Math.round(MPSum / counter);
    },
    // % входящих касаний

    // % отказа входящих касаний
    fail_month_touch_in_special() {
      let MPSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.fail_taho_touch_in);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.fail_monitor_touch_in);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.fail_card_touch_in);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.fail_propusk_touch_in);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.fail_teach_touch_in);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.fail_driver_touch_in);
          counter++;
        }
      }

      return Math.round(MPSum / counter);
    },
    // % отказа входящих касаний

    // входящих касаний
    month_touch_in_special() {
      let MLSum = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.taho_plan) * 100) /
              Number(this.localPlanMRT.taho_margin) /
              Number(this.localPlanMRT.taho_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_taho_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_taho_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_taho_lead))) *
              Number(this.localPlanMRT.taho_perc_in)) /
            (100 - Number(this.localPlanMRT.fail_taho_touch_in));
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.monitor_plan) * 100) /
              Number(this.localPlanMRT.monitor_margin) /
              Number(this.localPlanMRT.monitor_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_monitor_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_monitor_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_monitor_lead))) *
              Number(this.localPlanMRT.monitor_perc_in)) /
            (100 - Number(this.localPlanMRT.fail_monitor_touch_in));
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.card_plan) * 100) /
              Number(this.localPlanMRT.card_margin) /
              Number(this.localPlanMRT.card_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_card_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_card_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_card_lead))) *
              Number(this.localPlanMRT.card_perc_in)) /
            (100 - Number(this.localPlanMRT.fail_card_touch_in));
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.propusk_plan) * 100) /
              Number(this.localPlanMRT.propusk_margin) /
              Number(this.localPlanMRT.propusk_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_propusk_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_propusk_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_propusk_lead))) *
              Number(this.localPlanMRT.propusk_perc_in)) /
            (100 - Number(this.localPlanMRT.fail_propusk_touch_in));
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.teach_plan) * 100) /
              Number(this.localPlanMRT.teach_margin) /
              Number(this.localPlanMRT.teach_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_teach_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_teach_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_teach_lead))) *
              Number(this.localPlanMRT.teach_perc_in)) /
            (100 - Number(this.localPlanMRT.fail_teach_touch_in));
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MLSum +=
            (((((((((Number(this.localPlanMRT.driver_plan) * 100) /
              Number(this.localPlanMRT.driver_margin) /
              Number(this.localPlanMRT.driver_mean)) *
              100) /
              (100 - Number(this.localPlanMRT.fail_driver_invoice))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_driver_bid))) *
              100) /
              (100 - Number(this.localPlanMRT.fail_driver_lead))) *
              Number(this.localPlanMRT.driver_perc_in)) /
            (100 - Number(this.localPlanMRT.fail_driver_touch_in));
        }
      }
      return Math.round(MLSum);
    },

    day_touch_in_special() {
      return Math.round(
        this.month_touch_in_special / Number(this.localPlanMRT.days_plan)
      );
    },
    // входящих касаний

    // % новых клиентов
    month_new_special() {
      let MPSum = 0;
      let counter = 0;
      for (let i = 0; i < this.localPlanMRT.flags.length; i++) {
        if (i == 0 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.taho_new);
          counter++;
        }
        if (i == 1 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.monitor_new);
          counter++;
        }
        if (i == 2 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.card_new);
          counter++;
        }
        if (i == 3 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.propusk_new);
          counter++;
        }
        if (i == 4 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.teach_new);
          counter++;
        }
        if (i == 5 && this.localPlanMRT.flags[i] == 1) {
          MPSum += Number(this.localPlanMRT.driver_new);
          counter++;
        }
      }

      return Math.round(MPSum / counter);
    },
    // % новых клиентов
  },
  methods: {
    ...mapActions(["getManagerPlanReportTable", "setPlanMRT", "approveReport"]),
    addSpace(strNumber) {
      return String(strNumber).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },
    closeMRT() {
      this.$emit("closePopup");
    },
    async getThisManagerPlan() {
      await this.getManagerPlanReportTable({
        date: this.date_prop,
        manager_id: this.manager_id_prop,
      });
      this.localPlanMRT = Object.assign({}, this.planMRT);
      this.showTables = true;
    },

    changeFlag(whichFlag) {
      if (this.localPlanMRT.flags[whichFlag] == 0) {
        this.localPlanMRT.flags[whichFlag] = 1;
      } else {
        this.localPlanMRT.flags[whichFlag] = 0;
      }
    },

    saveReportMRT() {
      // Маржа, Руб
      this.localPlanMRT.day_plan = this.day_plan_special;
      this.localPlanMRT.month_plan = this.month_plan_special;
      // Маржа, Руб

      // % MR
      this.localPlanMRT.month_margin = this.month_margin_special;
      // % MR

      // Доход, Руб
      this.localPlanMRT.day_income = this.day_income_special;

      this.localPlanMRT.month_income = this.month_income_special;

      this.localPlanMRT.taho_income = Math.round(
        (Number(this.localPlanMRT.taho_plan) * 100) /
          Number(this.localPlanMRT.taho_margin)
      );
      this.localPlanMRT.monitor_income = Math.round(
        (Number(this.localPlanMRT.monitor_plan) * 100) /
          Number(this.localPlanMRT.monitor_margin)
      );
      this.localPlanMRT.card_income = Math.round(
        (Number(this.localPlanMRT.card_plan) * 100) /
          Number(this.localPlanMRT.card_margin)
      );
      this.localPlanMRT.propusk_income = Math.round(
        (Number(this.localPlanMRT.propusk_plan) * 100) /
          Number(this.localPlanMRT.propusk_margin)
      );
      this.localPlanMRT.teach_income = Math.round(
        (Number(this.localPlanMRT.teach_plan) * 100) /
          Number(this.localPlanMRT.teach_margin)
      );
      this.localPlanMRT.driver_income = Math.round(
        (Number(this.localPlanMRT.driver_plan) * 100) /
          Number(this.localPlanMRT.driver_margin)
      );
      // Доход, Руб

      // Средний Чек, Руб
      this.localPlanMRT.month_mean = this.month_mean_special;
      // Средний Чек, Руб

      // оплаченных счетов
      this.localPlanMRT.day_invoice_done = this.day_invoice_done_special;

      this.localPlanMRT.month_invoice_done = this.month_invoice_done_special;

      this.localPlanMRT.taho_invoice_done = Math.round(
        (Number(this.localPlanMRT.taho_plan) * 100) /
          Number(this.localPlanMRT.taho_margin) /
          Number(this.localPlanMRT.taho_mean)
      );
      this.localPlanMRT.monitor_invoice_done = Math.round(
        (Number(this.localPlanMRT.monitor_plan) * 100) /
          Number(this.localPlanMRT.monitor_margin) /
          Number(this.localPlanMRT.monitor_mean)
      );
      this.localPlanMRT.card_invoice_done = Math.round(
        (Number(this.localPlanMRT.card_plan) * 100) /
          Number(this.localPlanMRT.card_margin) /
          Number(this.localPlanMRT.card_mean)
      );
      this.localPlanMRT.propusk_invoice_done = Math.round(
        (Number(this.localPlanMRT.propusk_plan) * 100) /
          Number(this.localPlanMRT.propusk_margin) /
          Number(this.localPlanMRT.propusk_mean)
      );
      this.localPlanMRT.teach_invoice_done = Math.round(
        (Number(this.localPlanMRT.teach_plan) * 100) /
          Number(this.localPlanMRT.teach_margin) /
          Number(this.localPlanMRT.teach_mean)
      );
      this.localPlanMRT.driver_invoice_done = Math.round(
        (Number(this.localPlanMRT.driver_plan) * 100) /
          Number(this.localPlanMRT.driver_margin) /
          Number(this.localPlanMRT.driver_mean)
      );
      // оплаченных счетов

      // % отказов выставленных
      this.localPlanMRT.fail_month_invoice = this.fail_month_invoice_special;
      // % отказов выставленных

      // выставленных счетов
      this.localPlanMRT.day_invoice_all = this.day_invoice_all_special;
      this.localPlanMRT.month_invoice_all = this.month_invoice_all_special;

      this.localPlanMRT.taho_invoice_all = Math.round(
        (((Number(this.localPlanMRT.taho_plan) * 100) /
          Number(this.localPlanMRT.taho_margin) /
          Number(this.localPlanMRT.taho_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_invoice))
      );
      this.localPlanMRT.monitor_invoice_all = Math.round(
        (((Number(this.localPlanMRT.monitor_plan) * 100) /
          Number(this.localPlanMRT.monitor_margin) /
          Number(this.localPlanMRT.monitor_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_invoice))
      );
      this.localPlanMRT.card_invoice_all = Math.round(
        (((Number(this.localPlanMRT.card_plan) * 100) /
          Number(this.localPlanMRT.card_margin) /
          Number(this.localPlanMRT.card_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_invoice))
      );
      this.localPlanMRT.propusk_invoice_all = Math.round(
        (((Number(this.localPlanMRT.propusk_plan) * 100) /
          Number(this.localPlanMRT.propusk_margin) /
          Number(this.localPlanMRT.propusk_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_invoice))
      );
      this.localPlanMRT.teach_invoice_all = Math.round(
        (((Number(this.localPlanMRT.teach_plan) * 100) /
          Number(this.localPlanMRT.teach_margin) /
          Number(this.localPlanMRT.teach_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_invoice))
      );
      this.localPlanMRT.driver_invoice_all = Math.round(
        (((Number(this.localPlanMRT.driver_plan) * 100) /
          Number(this.localPlanMRT.driver_margin) /
          Number(this.localPlanMRT.driver_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_invoice))
      );
      // выставленных счетов

      // % отказов заявок
      this.localPlanMRT.fail_month_bid = this.fail_month_bid_special;
      // % отказов заявок

      // сделанных заявок
      this.localPlanMRT.day_bid = this.day_bid_special;
      this.localPlanMRT.month_bid = this.month_bid_special;

      this.localPlanMRT.taho_bid = Math.round(
        (((((Number(this.localPlanMRT.taho_plan) * 100) /
          Number(this.localPlanMRT.taho_margin) /
          Number(this.localPlanMRT.taho_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_bid))
      );
      this.localPlanMRT.monitor_bid = Math.round(
        (((((Number(this.localPlanMRT.monitor_plan) * 100) /
          Number(this.localPlanMRT.monitor_margin) /
          Number(this.localPlanMRT.monitor_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_bid))
      );
      this.localPlanMRT.card_bid = Math.round(
        (((((Number(this.localPlanMRT.card_plan) * 100) /
          Number(this.localPlanMRT.card_margin) /
          Number(this.localPlanMRT.card_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_bid))
      );
      this.localPlanMRT.propusk_bid = Math.round(
        (((((Number(this.localPlanMRT.propusk_plan) * 100) /
          Number(this.localPlanMRT.propusk_margin) /
          Number(this.localPlanMRT.propusk_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_bid))
      );
      this.localPlanMRT.teach_bid = Math.round(
        (((((Number(this.localPlanMRT.teach_plan) * 100) /
          Number(this.localPlanMRT.teach_margin) /
          Number(this.localPlanMRT.teach_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_bid))
      );
      this.localPlanMRT.driver_bid = Math.round(
        (((((Number(this.localPlanMRT.teach_plan) * 100) /
          Number(this.localPlanMRT.teach_margin) /
          Number(this.localPlanMRT.teach_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_bid))
      );
      // сделанных заявок

      // % отказа ЛИДов
      this.localPlanMRT.fail_month_lead = this.fail_month_lead_special;
      // % отказа ЛИДов

      // сделано ЛИДов
      this.localPlanMRT.day_lead = this.day_lead_special;

      this.localPlanMRT.month_lead = this.month_lead_special;

      this.localPlanMRT.taho_lead = Math.round(
        (((((((Number(this.localPlanMRT.taho_plan) * 100) /
          Number(this.localPlanMRT.taho_margin) /
          Number(this.localPlanMRT.taho_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_lead))
      );

      this.localPlanMRT.monitor_lead = Math.round(
        (((((((Number(this.localPlanMRT.monitor_plan) * 100) /
          Number(this.localPlanMRT.monitor_margin) /
          Number(this.localPlanMRT.monitor_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_lead))
      );
      this.localPlanMRT.card_lead = Math.round(
        (((((((Number(this.localPlanMRT.card_plan) * 100) /
          Number(this.localPlanMRT.card_margin) /
          Number(this.localPlanMRT.card_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_lead))
      );
      this.localPlanMRT.propusk_lead = Math.round(
        (((((((Number(this.localPlanMRT.propusk_plan) * 100) /
          Number(this.localPlanMRT.propusk_margin) /
          Number(this.localPlanMRT.propusk_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_lead))
      );
      this.localPlanMRT.teach_lead = Math.round(
        (((((((Number(this.localPlanMRT.teach_plan) * 100) /
          Number(this.localPlanMRT.teach_margin) /
          Number(this.localPlanMRT.teach_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_lead))
      );
      this.localPlanMRT.driver_lead = Math.round(
        (((((((Number(this.localPlanMRT.driver_plan) * 100) /
          Number(this.localPlanMRT.driver_margin) /
          Number(this.localPlanMRT.driver_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_lead))
      );
      // сделано ЛИДов

      // % исходящих касаний
      this.localPlanMRT.month_perc_out = this.month_perc_out_special;
      // % исходящих касаний

      // % отказа исходящих касаний
      this.localPlanMRT.fail_month_touch_out =
        this.fail_month_touch_out_special;
      // % отказа исходящих касаний

      // исходящих касаний
      this.localPlanMRT.day_touch_out = this.day_touch_out_special;

      this.localPlanMRT.month_touch_out = this.month_touch_out_out_specia;

      this.localPlanMRT.taho_touch_out = Math.round(
        (((((((((Number(this.localPlanMRT.taho_plan) * 100) /
          Number(this.localPlanMRT.taho_margin) /
          Number(this.localPlanMRT.taho_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_lead))) *
          Number(this.localPlanMRT.taho_perc_out)) /
          (100 - Number(this.localPlanMRT.fail_taho_touch_out))
      );
      this.localPlanMRT.monitor_touch_out = Math.round(
        (((((((((Number(this.localPlanMRT.monitor_plan) * 100) /
          Number(this.localPlanMRT.monitor_margin) /
          Number(this.localPlanMRT.monitor_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_lead))) *
          Number(this.localPlanMRT.monitor_perc_out)) /
          (100 - Number(this.localPlanMRT.fail_monitor_touch_out))
      );
      this.localPlanMRT.card_touch_out = Math.round(
        (((((((((Number(this.localPlanMRT.card_plan) * 100) /
          Number(this.localPlanMRT.card_margin) /
          Number(this.localPlanMRT.card_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_lead))) *
          Number(this.localPlanMRT.card_perc_out)) /
          (100 - Number(this.localPlanMRT.fail_card_touch_out))
      );
      this.localPlanMRT.propusk_touch_out = Math.round(
        (((((((((Number(this.localPlanMRT.propusk_plan) * 100) /
          Number(this.localPlanMRT.propusk_margin) /
          Number(this.localPlanMRT.propusk_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_lead))) *
          Number(this.localPlanMRT.propusk_perc_out)) /
          (100 - Number(this.localPlanMRT.fail_propusk_touch_out))
      );
      this.localPlanMRT.teach_touch_out = Math.round(
        (((((((((Number(this.localPlanMRT.teach_plan) * 100) /
          Number(this.localPlanMRT.teach_margin) /
          Number(this.localPlanMRT.teach_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_lead))) *
          Number(this.localPlanMRT.teach_perc_out)) /
          (100 - Number(this.localPlanMRT.fail_teach_touch_out))
      );
      this.localPlanMRT.driver_touch_out = Math.round(
        (((((((((Number(this.localPlanMRT.driver_plan) * 100) /
          Number(this.localPlanMRT.driver_margin) /
          Number(this.localPlanMRT.driver_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_lead))) *
          Number(this.localPlanMRT.driver_perc_out)) /
          (100 - Number(this.localPlanMRT.fail_driver_touch_out))
      );
      // исходящих касаний

      // % входящих касаний
      this.localPlanMRT.month_perc_in = this.month_perc_in_special;
      // % входящих касаний

      // % отказа входящих касаний
      this.localPlanMRT.fail_month_touch_in = this.fail_month_touch_in_special;
      // % отказа входящих касаний

      // входящих касаний
      this.localPlanMRT.day_touch_in = this.day_touch_in_special;
      this.localPlanMRT.month_touch_in = this.month_touch_in_special;

      this.localPlanMRT.taho_touch_in = Math.round(
        (((((((((Number(this.localPlanMRT.taho_plan) * 100) /
          Number(this.localPlanMRT.taho_margin) /
          Number(this.localPlanMRT.taho_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_taho_lead))) *
          Number(this.localPlanMRT.taho_perc_in)) /
          (100 - Number(this.localPlanMRT.fail_taho_touch_in))
      );
      this.localPlanMRT.monitor_touch_in = Math.round(
        (((((((((Number(this.localPlanMRT.monitor_plan) * 100) /
          Number(this.localPlanMRT.monitor_margin) /
          Number(this.localPlanMRT.monitor_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_monitor_lead))) *
          Number(this.localPlanMRT.monitor_perc_in)) /
          (100 - Number(this.localPlanMRT.fail_monitor_touch_in))
      );
      this.localPlanMRT.card_touch_in = Math.round(
        (((((((((Number(this.localPlanMRT.card_plan) * 100) /
          Number(this.localPlanMRT.card_margin) /
          Number(this.localPlanMRT.card_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_card_lead))) *
          Number(this.localPlanMRT.card_perc_in)) /
          (100 - Number(this.localPlanMRT.fail_card_touch_in))
      );
      this.localPlanMRT.propusk_touch_in = Math.round(
        (((((((((Number(this.localPlanMRT.propusk_plan) * 100) /
          Number(this.localPlanMRT.propusk_margin) /
          Number(this.localPlanMRT.propusk_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_propusk_lead))) *
          Number(this.localPlanMRT.propusk_perc_in)) /
          (100 - Number(this.localPlanMRT.fail_propusk_touch_in))
      );
      this.localPlanMRT.teach_touch_in = Math.round(
        (((((((((Number(this.localPlanMRT.teach_plan) * 100) /
          Number(this.localPlanMRT.teach_margin) /
          Number(this.localPlanMRT.teach_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_teach_lead))) *
          Number(this.localPlanMRT.teach_perc_in)) /
          (100 - Number(this.localPlanMRT.fail_teach_touch_in))
      );
      this.localPlanMRT.driver_touch_in = Math.round(
        (((((((((Number(this.localPlanMRT.driver_plan) * 100) /
          Number(this.localPlanMRT.driver_margin) /
          Number(this.localPlanMRT.driver_mean)) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_invoice))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_bid))) *
          100) /
          (100 - Number(this.localPlanMRT.fail_driver_lead))) *
          Number(this.localPlanMRT.driver_perc_in)) /
          (100 - Number(this.localPlanMRT.fail_driver_touch_in))
      );
      // входящих касаний

      // % новых клиентов
      this.localPlanMRT.month_new = this.month_new_special;
      // % новых клиентов

      this.setPlanMRT(this.localPlanMRT);
    },

    approveThisReport() {
      if (
        confirm(
          "После утверждения плана его редактирование будет запрещено. Подтвердите корректность введенных данных"
        )
      ) {
        this.approveReport({
          date: this.localPlanMRT.date_plan,
          manager_id: this.localPlanMRT.manager_id,
        });
      }
    },
  },
  async mounted() {
    // var currentYear = new Date().getFullYear();
    // var lastMonth = new Date().getMonth();
    // var currentMonth = lastMonth + 1;

    // if (currentMonth < 10) {
    //   this.dateFrom = "" + currentYear + "-0" + lastMonth;
    // } else {
    //   this.dateFrom = "" + currentYear + "-" + lastMonth;
    // }
    // if (currentMonth < 10) {
    //   this.dateTo = "" + currentYear + "-0" + currentMonth;
    // } else {
    //   this.dateTo = "" + currentYear + "-" + currentMonth;
    // }
    this.getThisManagerPlan();
  },
};
</script>

<style scoped>
.button-save-table {
  border: 2px solid rgb(34 197 94);
  border-radius: 3px;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 12px 88px;
}
.button-abort-table {
  border-radius: 3px;
  border: 2px solid rgb(34 197 94);
  color: #000;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 12px 88px;
}
.button-accept-table {
  border-radius: 3px;
  color: #fff;
  border: 2px solid #93c5fd;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 12px 88px;
}
.plan-table-wrap > div:last-child {
  border-bottom: 1px solid rgb(136, 136, 136);
}
.wrap-pt-grid {
  display: grid;
  grid-template-columns: 20% repeat(8, 10%);
  border-bottom: 1px solid rgb(232, 236, 236);
}
.wrap-plant-grid {
  display: grid;
  grid-template-columns: 435px repeat(3, 1fr);
}
.plan-table-title {
  padding-top: 25px;
  padding-bottom: 25px;
  color: rgb(38, 45, 41);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.border-br-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.border-tr-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.wrap-pt-grid > * {
  color: rgb(38, 45, 41);
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;

  border-right: 1px solid rgb(232, 236, 236);
}
.wrap-pt-grid > *:last-child {
  border-right: none;
}
.wrap-pt-grid > *:nth-child(2) {
  border-left: 1px solid rgb(136, 136, 136);
}
.wrap-pt-grid > *:nth-child(3) {
  border-right: 1px solid rgb(136, 136, 136);
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
}
.data-second-color > *:nth-child(2) {
  border-left: 1px solid rgb(136, 136, 136);
  border-right: 1px solid rgb(232, 236, 236);
}
.data-second-color > *:nth-child(4) {
  border-left: 1px solid rgb(232, 236, 236);
}

.data2-second-color > *:nth-child(2) {
  border-right: 1px solid rgb(232, 236, 236);
}
.data2-second-color > *:nth-child(4) {
  border-left: 1px solid rgb(232, 236, 236);
}

.title-padding-pt > * {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-padding-pt > *,
.data2-padding-pt > * {
  align-items: center;
  padding: 20px 0;
}
.images-row > img {
  height: 13px;
  width: auto;
}
.user-row > img {
  height: 20px;
  width: auto;
  margin-top: 3px;
}
.top-th {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  border-right: 1px solid rgb(232, 236, 236);
  border-bottom: 1px solid rgb(232, 236, 236);
  padding: 10px 0;
}
.bottom-th,
.row-pt-data {
  display: flex;
  align-items: center;
}
.bottom-th > p {
  padding: 5px 0;
}
.bottom-th > *,
.row-pt-data > * {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  border-right: 1px solid rgb(232, 236, 236);
  width: 33.3%;
}
.title-col {
  border-right: 1px solid rgb(136, 136, 136);
  border-bottom: 1px solid rgb(232, 236, 236);

  display: flex;
  align-items: center;
}
.title-col > *:first-child {
  width: 68px;
}
.title-col > *:nth-child(2) {
  width: 185px;
}
.title-col > *:last-child {
  width: 125px;
}
.title-col > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.money-row > p {
  color: rgb(38, 45, 41);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: capitalize;
}
.row-pt-data > span {
  padding-top: 20px;
  padding-bottom: 20px;
}
.row-pt-data > input {
  padding-top: 20px;
  padding-bottom: 20px;
}
.data-padding-pt > input:nth-child(2),
.data-padding-pt > input:nth-child(3) {
  background: #e5e7eb;
}
.data-padding-pt > input:first-child,
.data2-padding-pt > input:first-child {
  text-align: left;
  padding-left: 25px;
}
.data2-padding-pt > input:nth-child(2),
.data2-padding-pt > input:nth-child(3) {
  background: #e5e7eb;
}

.row-pt-data > input {
  text-align: center;
}
</style>
