<template>
  <div class="flex max-xl:flex-col">
    <div class="w-[400px] my-4 max-xl:mx-auto max-xl:max-w-full">
      <div class="bg-white rounded-[4px]">
        <div class="p-5 v-bbgray flex items-center justify-center">
          <div
            v-if="worker.active == 0"
            title="Сотрудник уволен!"
            class="w-5 h-5 mr-4"
          >
            <svg class="w-full h-full fill-status-11">
              <use xlink:href="@/assets/i.svg#status-11"></use>
            </svg>
          </div>
          <h2 class="uppercase text-center text-xl font-latobold">
            {{ worker.name }} {{ worker.secondname }}
          </h2>
        </div>
        <div class="p-5 v-bbgray">
          <div
            class="flex text-base text-zinc-800 font-latobold flex-col items-center"
          >
            <div
              class="w-48 h-48 my-5 mx-auto bg-green-500 overflow-hidden relative"
            >
              <span class="avatar w-full h-full">
                <img
                  v-if="worker.picture"
                  class="w-full h-full object-cover"
                  :src="
                    'https://api.tahocrm.ru/uploads/users/mini/' +
                    worker.picture
                  "
                />
              </span>
            </div>
          </div>
        </div>
        <div class="p-5 v-bbgray">
          <div class="flex my-1 items-center">
            <svg class="w-5 h-5 mr-4">
              <use xlink:href="@/assets/i.svg#ico-world-line"></use>
            </svg>
            <div class="flex">
              <span class="font-latobold mr-2">Логин:</span>
              <span>{{ worker.username }}</span>
            </div>
          </div>

          <div class="flex my-1 items-center">
            <svg class="w-5 h-5 mr-4">
              <use xlink:href="@/assets/i.svg#ico-question-line"></use>
            </svg>
            <div v-if="role == 'Studio' || role == 'Hosd'" class="flex">
              <span class="font-latobold mr-2">Пароль:</span>
              <span>{{ worker.pass }}</span>
            </div>
            <div v-else class="font-latobold">Пароль: зашифрован</div>
          </div>

          <div class="flex my-1 items-center">
            <svg class="w-5 h-5 mr-4">
              <use xlink:href="@/assets/i.svg#ico-question-line"></use>
            </svg>
            <div class="flex">
              <span class="font-latobold mr-2">Активность:</span>
              <span v-if="worker.active == 10">Активен</span>
              <span v-if="worker.active == 0">Неактивен</span>
            </div>
          </div>
        </div>

        <div class="p-5 v-bbgray">
          <div class="flex items-center">
            <svg class="w-5 h-5 mr-4">
              <use xlink:href="@/assets/i.svg#ico-mail"></use>
            </svg>
            <div class="flex items-center">
              <span class="font-latobold mr-2 w-16">E-mail:</span>
              <!-- <span>{{ worker.email }}</span> -->
              <label>
                <input
                  type="text"
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  name="sum"
                  v-model="worker.email"
                  @change="changethisWorker()"
                />
              </label>
            </div>
          </div>
          <div class="flex mt-3 items-center">
            <svg class="w-5 h-5 mr-4">
              <use xlink:href="@/assets/i.svg#ico-phone"></use>
            </svg>
            <div class="flex items-center">
              <span class="font-latobold mr-2 w-16">Телефон:</span>
              <label>
                <input
                  type="text"
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  name="sum"
                  v-model="worker.phone"
                  @change="changethisWorker()"
                />
              </label>
            </div>
          </div>
          <div v-if="worker.fb" class="flex my-1 items-center">
            <svg class="w-5 h-5 mr-4">
              <use xlink:href="@/assets/i.svg#ico-avatar"></use>
            </svg>
            <div class="flex">
              <span class="font-latobold mr-2">FB:</span>
              <span>{{ worker.fb }}</span>
            </div>
          </div>
          <div v-if="worker.tl" class="flex my-1 items-center">
            <svg class="w-5 h-5 mr-4">
              <use xlink:href="@/assets/i.svg#ico-avatar"></use>
            </svg>
            <div class="flex">
              <span class="font-latobold mr-2">TL:</span>
              <span>{{ worker.tl }}</span>
            </div>
          </div>
        </div>

        <div class="p-4 v-bbgray">
          <h3 class="ml-4 mb-4 uppercase text-base font-latobold">Роли</h3>
          <template v-for="(rl, key) in r" :key="rl">
            <div
              class="v-btn bg-gray-500 hover:bg-gray-400 w-full flex justify-center items-center my-2"
              :class="{
                '!bg-green-500 hover:!bg-green-400': workerRoles.includes(key),
              }"
              @click="onOffRole(key)"
            >
              <p
                class="flex cursor-pointer group text-green-500 hover:text-green-400 transition-colors duration-150"
              >
                <span class="v-btn_text">{{ rl }}</span>
              </p>
            </div>
          </template>
        </div>

        <div class="p-4 v-bbgray flex justify-center">
          <template v-if="workerRoles.includes('Master')">
            <a class="v-btn v-btn--sky v-btn--sm mx-2" @click="workerCal">
              <svg class="w-5 h-5 fill-white mr-2">
                <use xlink:href="@/assets/i.svg#nav-calendar"></use>
              </svg>
              <span class="v-btn_text">Смены</span>
            </a>
          </template>

          <template
            v-if="role == 'Studio' || (role == 'Hosd' && worker.active != 0)"
          >
            <a
              class="v-btn v-btn--rose v-btn--sm mx-2"
              @click="delWorker(worker)"
            >
              <svg class="w-5 h-5 fill-white mr-2">
                <use xlink:href="@/assets/i.svg#ico-delete"></use>
              </svg>
              <span class="v-btn_text">Уволить</span>
            </a>
          </template>

          <template
            v-if="role == 'Studio' || (role == 'Hosd' && worker.active == 0)"
          >
            <a
              class="v-btn v-btn--green v-btn--sm mx-2"
              @click="delWorker(worker)"
            >
              <span class="v-btn_text">Восстановить</span>
            </a>
          </template>
        </div>
      </div>
    </div>

    <div class="flex flex-col ml-4 mr-12 flex-1 mb-4">
      <div class="p-5 bg-white rounded-[4px] mt-4 flex flex-wrap">
        <div
          class="my-4 relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
        >
          <div
            class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
          >
            <svg
              class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
            >
              <use xlink:href="@/assets/i.svg#ico-rating"></use>
            </svg>
            <h2
              class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
              v-if="worker.raiting"
            >
              {{ worker.raiting }}
            </h2>
            <svg
              class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
              :class="[
                { '!fill-status-5': worker.raiting > 9 },
                { '!fill-status-10': worker.raiting > 7 && worker.raiting < 9 },
                { '!fill-status-7': worker.raiting < 7 },
              ]"
            >
              <use xlink:href="@/assets/i.svg#bg-romb"></use>
            </svg>
            <!-- НУЖНО МЕНЯТЬ ЦВЕТ РОМБА в зависимости от цифры-->
            <!-- if (number > 9) { 'green'; }-->
            <!-- if (7 < number && number < 9) { 'orang'; } -->
            <!-- if (number < 7) { 'red'; }  -->
          </div>
          <span
            class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
            >Рейтинг</span
          >
        </div>

        <div
          class="my-4 relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
        >
          <div
            class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
          >
            <svg
              class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
            >
              <use xlink:href="@/assets/i.svg#ico-time"></use>
            </svg>
            <h2
              class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
              v-if="worker.a1"
            >
              {{ worker.a1 }}
            </h2>
            <svg
              class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
              :class="[
                { '!fill-status-5': worker.a1 > 9 },
                { '!fill-status-10': worker.a1 > 7 && worker.a1 < 9 },
                { '!fill-status-7': worker.a1 < 7 },
              ]"
            >
              <use xlink:href="@/assets/i.svg#bg-romb"></use>
            </svg>
            <!-- НУЖНО МЕНЯТЬ ЦВЕТ РОМБА в зависимости от цифры-->
          </div>
          <span
            class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
            >ДИСЦИПЛИНА</span
          >
        </div>

        <div
          class="my-4 relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
        >
          <div
            class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
          >
            <svg
              class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
            >
              <use xlink:href="@/assets/i.svg#nav-masters"></use>
            </svg>
            <h2
              class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
              v-if="worker.a2"
            >
              {{ worker.a2 }}
            </h2>
            <svg
              class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
              :class="[
                { '!fill-status-5': worker.a2 > 9 },
                { '!fill-status-10': worker.a2 > 7 && worker.a2 < 9 },
                { '!fill-status-7': worker.a2 < 7 },
              ]"
            >
              <use xlink:href="@/assets/i.svg#bg-romb"></use>
            </svg>
            <!-- НУЖНО МЕНЯТЬ ЦВЕТ РОМБА в зависимости от цифры-->
          </div>
          <span
            class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
            >КАЧЕСТВО</span
          >
        </div>

        <div
          class="my-4 relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
        >
          <div
            class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
          >
            <svg
              class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
            >
              <use xlink:href="@/assets/i.svg#warranty"></use>
            </svg>
            <h2
              class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
              v-if="worker.a3"
            >
              {{ worker.a3 }}
            </h2>
            <svg
              class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
              :class="[
                { '!fill-status-5': worker.a3 > 9 },
                { '!fill-status-10': worker.a3 > 7 && worker.a3 < 9 },
                { '!fill-status-7': worker.a3 < 7 },
              ]"
            >
              <use xlink:href="@/assets/i.svg#bg-romb"></use>
            </svg>
            <!-- НУЖНО МЕНЯТЬ ЦВЕТ РОМБА в зависимости от цифры-->
          </div>
          <span
            class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
            >НАДЁЖНОСТЬ</span
          >
        </div>
      </div>
      <div class="bg-white mt-4 rounded-[4px]">
        <template v-if="workerRoles.includes('Manager', 'Master')">
          <div>
            <h2
              class="py-4 text-center text-lg uppercase font-latobold v-bbgray"
            >
              Доступные категории услуг
            </h2>
            <div class="p-5">
              <div
                v-for="cat in workerCats"
                :key="cat"
                @click="ChangeCat(cat.id)"
                class="cursor-pointer"
              >
                <div class="flex items-center my-1">
                  <span
                    v-if="cat.masterPrice?.id"
                    class="w-6 h-6 bg-status-5 flex justify-center rounded-full items-center overflow-hidden mr-2"
                  >
                    <svg class="w-4 h-4 fill-white">
                      <use xlink:href="@/assets/i.svg#ico-ok-line"></use>
                    </svg>
                  </span>
                  <span
                    v-else
                    class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
                  >
                    <svg class="w-4 h-4 fill-white">
                      <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                    </svg>
                  </span>
                  <span class="font-latobold">{{ cat.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WorkerView",

  data() {
    return {
      mnths: [
        "",
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
      r: {
        Manager: "Менеджер",
        Logist: "Логист",
        Studio: "Директор",
        Master: "Инженер",
        Check: "Контроль качества",
        Finance: "Финансист",
        Assistant: "Асистент",
        Buh: "Бухгалтер",
        Outsourcer: "Подрядчик",
        Hosd: "Руководитель ОП",
      },
      cats: [],
      master: {},
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "roleNames",
      "worker",
      "workerCats",
    ]),

    workerRoles() {
      if (this.worker.roles) {
        return this.worker.roles.map((e) => e.item_name);
      }
      return [];
    },
  },

  methods: {
    ...mapActions([
      "getWorker",
      "delWorker",
      "onOffRole",
      "ChangeCat",
      "setCalType",
      "getCal",
      "changeWorker",
    ]),

    workerCal() {
      this.setCalType(5);
      this.getCal();
      this.$router.push("/calendar");
    },
    changethisWorker() {
      this.changeWorker({
        uid: this.worker.uid,
        email: this.worker.email,
        phone: this.worker.phone,
      });
    },
  },

  mounted() {
    this.getWorker();
  },
};
</script>
