<template>
    <section>
        <div class="goodone">
            <div class="flex-wrap-shit my-5">
                <div class="first-flex-wrap">
                    <div class="length-of-voronka">
                        <div class="days-gone">
                            {{ showNum(stages?.funnel) }} <span>дн</span>
                        </div>
                        <div class="label-days">
                            <span>длинна воронки</span>
                        </div>
                        <div class="plan">
                            ПЛАН: {{ showNum(planes?.funnel) }} <span>дн</span>
                        </div>
                        <div class="absolute-number"
                        :class="showSign(stages?.funnel - planes?.funnel)">
                            {{ showNum(stages?.funnel - planes?.funnel) }} <span>дн</span>
                        </div>
                    </div>
                    <div class="opened-orders">
                        <p>открытые заказы</p>
                        <!-- <ListRomb
                            :ico="rombStyles.opened.ico"
                            :num="stages.all_open_orders_count"
                            :back="rombStyles.opened.back"
                            @click="currentRomb = 'opened'; $L('opened')"
                        ></ListRomb> -->
                        <ListRomb
                        :ico="rombStyles.opened.ico"
                        :num="stages.all_open_orders_count"
                        :back="rombStyles.opened.back"
                    ></ListRomb>
                    </div>
                </div>
                <div class="second-flex-wrap">
                    <div
                        class="flex"
                    >
                        <DiffRomb
                                v-for="romb in rombs"
                                :key="romb"
                                :label="rombStyles[romb].label"
                                :ico="rombStyles[romb].ico"
                                :back="rombStyles[romb].back"
                                :stage="stages[romb]"
                                :plane="planes[romb]"
                            >
                        </DiffRomb>
                    </div>
                    <p class="mt-auto">длина этапа по закрытым заказам, дн</p>
                </div>
                <!-- <div class="third-flex-wrap">
                    <div
                        class="flex"
                    >
                        <ListRomb
                            :ico="rombStyles.checked.ico"
                            :num="grouped?.checked?.length"
                            :label="rombStyles.checked.label"
                            :back="rombStyles.checked.back"
                            @click="currentRomb = 'checked'; $L('checked')"
                        ></ListRomb>
                        <ListRomb
                            :ico="rombStyles.canseled.ico"
                            :num="grouped?.canseled?.length"
                            :label="rombStyles.canseled.label"
                            :back="rombStyles.canseled.back"
                            @click="currentRomb = 'canseled'; $L('canseled')"
                        ></ListRomb>
                    </div>
                    <p>заказы за период</p>
                </div> -->
            </div>

            <div class="flex-wrap-shit my-5 pb-5">
                <div
                    class="wrap-table-1"
                >
                    <p style="width: 135px;">{{rombStyles.excess.label}}</p>
                    <!-- <ListRomb
                        :ico="rombStyles.excess.ico"
                        :num="stages?.all_penalty_count"
                        :back="rombStyles.excess.back"
                        @click="currentRomb = 'excess'; $L('excess')"
                    ></ListRomb> -->
                    <ListRomb
                        :ico="rombStyles.excess.ico"
                        :num="stages?.all_penalty_count"
                        :back="rombStyles.excess.back"
                    ></ListRomb>
                </div>
                <div class="wrap-table-22">
                    <div>
                        <p>отложенные заказы</p>
                        <div class="flex">
                            <ListRomb
                                v-for="romb in postponedRombs.slice(0, 1)"
                                :key="romb"
                                :ico="rombStyles[romb].ico"
                                :num="stages.canceled_all_ids?.length"
                                :label="rombStyles[romb].label"
                                :back="rombStyles[romb].back"
                                @click="currentRomb = romb; chosenAll = true; $L(romb)"
                            ></ListRomb>
                        </div>
                    </div>
                    <div>
                        <p>отложенные заказы по статусам</p>
                        <div class="flex">
                            <ListRomb
                                v-for="romb in postponedRombs.slice(1, 5)"
                                :key="romb"
                                :ico="rombStyles[romb].ico"
                                :num="grouped[romb]?.length"
                                :label="rombStyles[romb].label"
                                :back="rombStyles[romb].back"
                                @click="currentRomb = romb; $L(romb)"
                            ></ListRomb>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                v-if="currentRomb && !chosenAll"
                @close="currentRomb = null; chosenAll = false;"
            >
                <LoadedOrderList
                    :ids="grouped[currentRomb]"
                />
            </Modal>
            <Modal
                v-if="currentRomb && chosenAll"
                @close="currentRomb = null; chosenAll = false;"
            >
                <LoadedOrderList
                    :ids="stages.canceled_all_ids"
                />
            </Modal>
        </div>
    </section>
</template>
<script>
import LoadedOrderList from '@/components/report/LoadedOrderList.vue';
import DiffRomb from '@/components/report/DiffRomb.vue';
import ListRomb from '@/components/report/ListRomb.vue';
import Modal from '@/components/report/Modal.vue';
export default {
    name: "ReportClosedorders",
    components: {DiffRomb, ListRomb,LoadedOrderList, Modal},
    props: {
        stages: Object,
        planes: Object,
        grouped: Object,
    },
    data(){
        return {
            chosenAll: false,
            rombs: [
                'mintrans',
                'postponed',
                'appointed',
                'working',
                'completed',
            ],
            postponedRombs: [
                'postponedList',
                'empty',
                'urgently',
                'manager',
                'client',
            ],
            rombStyles: {
                mintrans: {
                    ico: "ico-edit",
                    back: "fill-status-12",
                    label: "Минтранс",
                },
                postponed: {
                    ico: "status-10",
                    back: "fill-status-10",
                    label: "Отложены",
                },
                appointed: {
                    ico: "status-3",
                    back: "fill-status-3",
                    label: "Назначены",
                },
                working: {
                    ico: "nav-masters",
                    back: "fill-status-1",
                    label: "В работе",
                },
                completed: {
                    ico: "status-5",
                    back: "fill-status-5",
                    label: "Выполнено",
                },

                excess: {
                    ico: "status-11",
                    back: "fill-dark-red",
                    label: "Цикл превышен",
                },
                checked: {
                    ico: "ico-ok",
                    back: "fill-status-6",
                    label: "Проверены ТО",
                },
                canseled: {
                    ico: "ico-no",
                    back: "fill-status-7",
                    label: "Отменено",
                },
                opened: {
                    ico: "status-11",
                    back: "fill-dark-blue",
                    label: "Открытые заказы",
                },
                postponedList: {
                    ico: "status-10",
                    back: "fill-status-10",
                    label: "20% от поступивших",
                },
                empty: {
                    ico: "status-10",
                    back: "fill-dark-orange",
                    label: "нет",
                },
                urgently: {
                    ico: "status-10",
                    back: "fill-dark-orange",
                    label: "Срочно",
                },
                manager: {
                    ico: "status-10",
                    back: "fill-dark-orange",
                    label: "Менеджер",
                },
                client: {
                    ico: "status-10",
                    back: "fill-dark-orange",
                    label: "Клиент",
                },
            },
            currentRomb: null,
            $L(...a){console.log(...a)},
        }
    },
    computed: {
    },
    methods: {
        showNum(n){
            return Math.abs(+n || 0).toLocaleString(Intl.NumberFormat, { 
                maximumFractionDigits: 1,
             });
        },
        showSign(n){
            return (Math.abs( +n || 0) < 0.05)
                ? 'null-diff'
                : +n > 0
                ? 'positive-diff'
                : 'negative-diff';
        }
    },
    mounted(){
        window.DEBUG_REPORT_CLOSE = this;
        
        var title_delayed_orders = '';
        if (this.stages.canceled_all_ids == 0 || !this.stages.canceled_all_ids) {
            title_delayed_orders = '0% ОТ ПОСТУПИВШИХ';
        }
        else {
            title_delayed_orders = '' + Math.round(this.stages.canceled_all_ids?.length / this.stages.all_open_orders_count * 100) + '% ОТ ПОСТУПИВШИХ';
        }
        this.rombStyles.postponedList.label = title_delayed_orders;
    },
}
</script>
<style>
    .flex-wrap-shit {
        display: flex;
        justify-content: space-between;
    }
    .first-flex-wrap {
        min-width: 292px;
    }
    .second-flex-wrap {
        width: 100%;
        margin-left: 15px;
    }
    .third-flex-wrap {
        min-width: 344px;
    }
    .first-flex-wrap > div,
    .second-flex-wrap,
    .third-flex-wrap {
        border-radius: 5px;
        background: #F8FAFC;
        box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
    }
    .first-flex-wrap > div{
        padding: 15px 25px 10px 25px;
    }

    .length-of-voronka, .otklonenie-of-period {
        position: relative;
    }
    .opened-orders {
        margin-top: 15px;
    }
    .opened-orders p {
        color: #262D29;
        font-size: 13.5px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        margin-bottom: 15px;
    }
    .absolute-number {
        position: absolute;
        top: 15px;
        right: 9px;
        font-size: 24px; 
        font-weight: 700; 
    }
    .otklonenie-of-period .title, .label-days {
        color: #262D29;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .days-gone, .days-change {
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 11px;
    }
    .days-gone span, .days-change span {
        font-size: 20px; 
    }
    .days-gone {
        color: #8E8E8E;
    }

    .label-days{
        margin-bottom: 20px;
    }
    .plan {
        color: #ABABAB;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
    .plan span {
        font-size: 9px; 
    }

    .second-flex-wrap, .third-flex-wrap {
        min-height: 100%;
    }

    .second-flex-wrap > p, .third-flex-wrap > p {
        color: #262D29;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
    .second-flex-wrap, .third-flex-wrap {
        padding: 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .wrap-table-1 {
        border-radius: 5px;
        background: #F8FAFC;
        box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
        padding: 15px 79px 40px 79px;
    }
    .wrap-table-1 p, .wrap-table-22 p {
        color: #262D29;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    .wrap-table-22 {
        display: flex;
        justify-content: space-between;
        margin-left: 15px;
        width: 100%;
        border-radius: 5px;
        background: #F8FAFC;
        box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.03);
        padding: 15px 25px 55px 25px;
    }              
    .wrap-table-22 > div:first-child {
       width: 20%; 
    }
    .wrap-table-22 > div:last-child {
        width: 80%;
    }
    .fill-dark-blue {
        fill: #455386;
    }
    .fill-dark-red {
        fill: #974562;
    }
    .fill-dark-orange {
        fill: #FA8223;
    }

    .null-diff {color: rgb(131, 131, 19);}
    .positive-diff {color: #FA344C; }
    .negative-diff {color: #22C55E;}
    .positive-diff::before {content: ">"; padding-right: 5px;}
    .negative-diff::before {content: "<"; padding-right: 5px;}

</style>