import API from "../../services/api";
import moment from "moment";
import router from "@/router/router";
export default {
    state: {
        leads: [],
        filteredLeads: {},
        countLeads: {},
        countTouches: {},
        filteredTouches: {},

        invoice: {},
        invoices: [],
        countInvoices: {},
        filteredInvoices: {},

        lead: {},
        touch: {},
        touches: [],
        leadSource: [],
        garland: {},
        
        bidClients: {},
        bidContacts: {},
        bids: [],
        bid: {},
   
        countBids: {},
        filteredBids: {},
        firms: [],
        prices_bid: [],
        invoiceOrders: [],

        leadPaginator: {
            page: 0,
            itemsInPage: 10
        },
        bidPaginator: {
            page: 0,
            itemsInPage: 10
        },
        touchPaginator: {
            page: 0,
            itemsInPage: 10
        },
        invoicePaginator: {
            page: 0,
            itemsInPage: 10
        },
    },

    mutations: {
        setLeads: (state, leads) => {
            state.leads = leads;
            state.filteredLeads = leads;
            state.countLeads = leads;
        },
        setTouches: (state, touches) => {
            state.touches = touches;

            state.filteredTouches = touches;
            state.countTouches = touches;
        },
        setInvoices: (state, invoices) => {
            state.invoices = invoices;

            state.filteredInvoices = invoices;
            state.countInvoices = invoices;
        },

        setInvoiceOrders: (state, invoiceOrders) => {
            state.invoiceOrders = invoiceOrders;
        },

        setBids: (state, bids) => {
            state.bids = bids;
            state.filteredBids = bids;
            state.countBids = bids;
        },
        setPrices: (state, prices_bid) => {
            state.prices_bid = prices_bid;
        },
        filteredLeads: (state, leads) => {
            state.filteredLeads = leads;
        },
        filteredTouches: (state, touches) => {
            state.filteredTouches = touches;
        },
        filteredBids: (state, bids) => {
            state.filteredBids = bids;
        },
        setFirms: (state, data) => {
            state.firms = data;
        },
        setLead: (state, data) => {
            state.lead = data;
        },
        setTouch: (state, data) => {
            state.touch = data;
        },
        setBid: (state, data) => {
            state.bid = data;
        },      
        setInvoice(state , invoice) {
            state.invoice = invoice;
        },  
        setGarland: (state, data) => {
            state.garland = data;
        },
        setLeadClients: (state, data) => {
            state.leadClients = data;
        },
        setTouchClients: (state, data) => {
            state.touchClients = data;
        },
        setBidClients: (state, data) => {
            state.bidClients = data;
        },

        setLeadCompanies: (state, data) => {
            state.leadCompanies = data;
        },
        setLeadContacts: (state, data) => {
            state.leadContacts = data;
        },
        setTouchCompanies: (state, data) => {
            state.touchCompanies = data;
        },
        setTouchContacts: (state, data) => {
            state.touchContacts = data;
        },
        setBidCompanies: (state, data) => {
            state.bidCompanies = data;
        },
        setBidContacts: (state, data) => {
            state.bidContacts = data;
        },
        setLeadSource: (state, data) => {
            state.leadSource = data;
        }, 
    },

    actions: {
        setLead(ctx, lead) {
            console.log("setLead info", lead);
            ctx.commit("setLead", lead);
            localStorage.setItem("lead", JSON.stringify(lead));
        },
        setTouch(ctx, touch) {
            console.log("setTouch");
            ctx.commit("setTouch", touch);
            localStorage.setItem("touch", JSON.stringify(touch));
        },
        setBid(ctx, bid) {
            console.log("setBid info", bid);
            ctx.commit("setBid", bid);
            localStorage.setItem("bid", JSON.stringify(bid));
        },
        setInvoice(ctx, invoice) {
            console.log("setInvoice info", invoice);
            ctx.commit("setInvoice", invoice);
            localStorage.setItem("invoice", JSON.stringify(invoice));
        },
        
        setPrices(ctx, prices_bid) {
            // console.log("setBid info", prices);
            ctx.commit("setPrices", prices_bid);
            // localStorage.setItem("prices_bid", JSON.stringify(prices_bid));
        },
        setGarland(ctx, garland) {
            console.log("setGarland", garland);
            ctx.commit("setGarland", garland);
            localStorage.setItem("garland", JSON.stringify(garland));
        },
        getLeads(ctx) {
            localStorage.removeItem('leads');
            ctx.dispatch("setLoader", true);

            ctx.dispatch("loadLeads").then(() => {
                ctx.dispatch("statusLead", ctx.rootState.s);
            });

            return API.GET("lead/leads").then((res) => {
                console.log("getLeads", res.data);
                // localStorage.setItem("leads", JSON.stringify(res.data.leads));
                ctx.commit("setLeads", res.data.leads);

                ctx.dispatch("statusLead", ctx.rootState.s).then(() => {
                    ctx.dispatch("setLoader", false);
                });
            });
        },

        getTouches(ctx) {
            ctx.dispatch("setLoader", true);

            ctx.dispatch("loadTouches").then(() => {
                ctx.dispatch("statusTouch", ctx.rootState.s);
            });

            return API.GET("touch/touches").then((res) => {
                console.log("getTouches", res.data);
                // localStorage.setItem("touches", JSON.stringify(res.data.touches));
                ctx.commit("setTouches", res.data.touches);

                ctx.dispatch("statusTouch", ctx.rootState.s).then(() => {
                    ctx.dispatch("setLoader", false);
                });
            });
        },

        getInvoices(ctx) {
            ctx.dispatch("setLoader", true);

            ctx.dispatch("loadInvoices").then(() => {
                ctx.dispatch("statusInvoice", ctx.rootState.s);
            });

            return API.GET("invoice/invoices").then((res) => {
                console.log("getInvoices", res.data);
                // localStorage.setItem("invoices", JSON.stringify(res.data.invoices));
                ctx.commit("setInvoices", res.data.invoices);

                ctx.dispatch("statusInvoice", ctx.rootState.s).then(() => {
                    ctx.dispatch("setLoader", false);
                });
            });
        },

        getInvoiceOrders(ctx) {
            let invoice = localStorage.getItem("invoice");
                invoice = JSON.parse(invoice);
            return API.GET("invoice/invoice-orders", { id: invoice.id }).then((res) => {
                console.log("getInvoices", res.data);
                // localStorage.setItem("invoiceOrders", JSON.stringify(res.data.invoiceOrders));
                ctx.commit("setInvoiceOrders", res.data.invoiceOrders);
            });
        },
        

        getFirms(ctx) {
            return API.GET("bid/list-firms").then((res) => {
                console.log("getFirms", res.data);
                localStorage.setItem("firms", JSON.stringify(res.data.firms));
                ctx.commit("setFirms", res.data.firms);
                // ctx.dispatch("statusBid", ctx.rootState.s).then(() => {
                //     ctx.dispatch("setLoader", false);
                // });
            });
        },

        getBids(ctx) {
            ctx.dispatch("setLoader", true);
            ctx.dispatch("loadBids").then(() => {
                ctx.dispatch("statusBid", ctx.rootState.s);
            });

            return API.GET("bid/bids").then((res) => {
                console.log("getBids", res.data);
                // localStorage.setItem("bids", JSON.stringify(res.data.bids));
                ctx.commit("setBids", res.data.bids);

                ctx.dispatch("statusBid", ctx.rootState.s).then(() => {
                    ctx.dispatch("setLoader", false);
                });
            });
        },
        
        setCountBidPrice(ctx, item) {
            let bid = ctx.state.bid;
            bid.price.push({ loader: 1 });
            ctx.dispatch("setBid", bid);

            let formData = new FormData();
            formData.append("bid_id", ctx.state.bid.id);
            formData.append("bid_price_id", item.price_id);
            formData.append("price_sum", item.sum);
            formData.append("items", item.items);
            API.POST("bid/set-sum", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        newBidPrice(ctx, item) {
            let bid = ctx.state.bid;
            bid.price.push({ loader: 1 });
            ctx.dispatch("setBid", bid);

            let formData = new FormData();
            formData.append("bid_id", ctx.state.bid.id);
            formData.append("bid_price_id", item.price_id);
            formData.append("price_sum", item.sum);
            API.POST("bid/set-sum", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setBidPrice(ctx, item) {
            let bid = ctx.state.bid;
            bid.price.push({ loader: 1 });
            ctx.dispatch("setBid", bid);

            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("price_id", item.id);
            formData.append("price_sum", item.sum);
            API.POST("bid/add-price", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delBidPrice(ctx, p) {
            console.log("delBidPrice: ", p);
            ctx.state.bid.price = ctx.state.bid.price.filter((item) => item != p);
            ctx.dispatch("setBid", ctx.state.bid);

            let formData = new FormData();
            formData.append("bid_id", ctx.state.bid.id);
            formData.append("bid_price_id", p.id);
            API.POST("bid/del-price", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // setBidSum(ctx, sum) {
        //     console.log("setBidSum: ", sum);
        //     ctx.state.bid.sum = sum;
        //     ctx.dispatch("setBid", ctx.state.bid);

        //     let formData = new FormData();
        //     formData.append("bid_id", ctx.state.bid.id);
        //     formData.append("sum", sum);
        //     API.POST("bid/set-sum", formData).then((res) => {
        //         res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
        //         res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
        //     });
        // },


        async RemoveSelectedInvoices (ctx, info) {
            let fd = new FormData();
            info.checked.forEach((x) => fd.append("ids[]", x));
            
            let res = await API.POST("invoice/del", fd);

            if (res.status == 200) {
                if(res.data.alert.gtfo) {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.gtfo) : null;
                }
                else {
                    
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.invoices) : null;
                      }, 500);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.orders) : null;
                      }, 1000);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.tasks) : null;
                      }, 1500);
                      
                      ctx.dispatch("getInvoices");

                }
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }   
        },

        async RemoveSelectedLeads (ctx, info) {
            let fd = new FormData();
            info.checked.forEach((x) => fd.append("ids[]", x));
            
            let res = await API.POST("lead/del", fd);

            if (res.status == 200) {
                if(res.data.alert.gtfo) {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.gtfo) : null;
                }
                else {
                    setTimeout(() => {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.leads) : null;
                      }, 500);
                      setTimeout(() => {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.bids) : null;
                      }, 1000);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.invoices) : null;
                      }, 1500);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.orders) : null;
                      }, 2000);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.tasks) : null;
                      }, 2500);
                      
                      ctx.dispatch("getLeads");

                }
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }             
        },
        async RemoveSelectedTouches (ctx, info) {
            let fd = new FormData();
            info.checked.forEach((x) => fd.append("ids[]", x));
            
            let res = await API.POST("touch/del", fd);

            if (res.status == 200) {

                if(res.data.alert.gtfo) {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.gtfo) : null;
                }
                else {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.touches) : null;
                    setTimeout(() => {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.leads) : null;
                      }, 500);
                      setTimeout(() => {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.bids) : null;
                      }, 1000);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.invoices) : null;
                      }, 1500);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.orders) : null;
                      }, 2000);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.tasks) : null;
                      }, 2500);
                      
                    ctx.dispatch("getTouches");
                }
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }             
        },
        async RemoveSelectedBids (ctx, info) {
            let fd = new FormData();
            info.checked.forEach((x) => fd.append("ids[]", x));
            
            let res = await API.POST("bid/del", fd);

            if (res.status == 200) {
                if(res.data.alert.gtfo) {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.gtfo) : null;
                }
                else { 
                      setTimeout(() => {
                    res.data.alert ? ctx.commit("setAlert", res.data.alert.bids) : null;
                      }, 1000);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.invoices) : null;
                      }, 1500);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.orders) : null;
                      }, 2000);
                      setTimeout(() => {
                        res.data.alert ? ctx.commit("setAlert", res.data.alert.tasks) : null;
                      }, 2500);     
                      ctx.dispatch("getBids");
                }
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }             
        },

        // RemoveSelectedLeads() {
        //     let fd = new FormData();
        //     this.checked.forEach((x) => fd.append("ids[]", x));
        //     API.POST("lead/del", fd).then((data) => {
        //       this.getLeads();
        //       return data;
        //     });
        // },

        // RemoveSelectedTouches() {
        //     let fd = new FormData();
        //     this.checked.forEach((x) => fd.append("ids[]", x));
        //     API.POST("touch/del", fd).then((data) => {
        //       this.getTouches();
    
        //     });
        // },

        // RemoveSelectedBids() {
        //     let fd = new FormData();
        //     this.checked.forEach((x) => fd.append("ids[]", x));
        //     API.POST("bid/del", fd).then((data) => {
        //       this.getBids();
        //     });
        // },

        

        loadLeads(ctx) {
            let leads = JSON.parse(localStorage.getItem("leads"));
            if (leads?.length > 0) {
                ctx.commit("setLeads", leads);
            }
        },

        loadBids(ctx) {
            let bids = JSON.parse(localStorage.getItem("bids"));
            if (bids?.length > 0) {
                ctx.commit("setBids", bids);
            }
        },

        loadTouches(ctx) {
            let touches = JSON.parse(localStorage.getItem("touches"));
            if (touches?.length > 0) {
                ctx.commit("setTouches", touches);
            }
        },     
        loadInvoices(ctx) {
            let invoices = JSON.parse(localStorage.getItem("invoices"));
            if (invoices?.length > 0) {
                ctx.commit("setInvoices", invoices);
            }
        },   

        

        fillLeads(ctx) {
            ctx.commit("filteredLeads", ctx.state.leads);
        },
        fillBids(ctx) {
            ctx.commit("filteredBids", ctx.state.bids);
        },
        fillTouches(ctx) {
            ctx.commit("filteredTouches", ctx.state.touches);
        },

        getLead(ctx) {
            localStorage.removeItem('leads');
            
            let lead = localStorage.getItem("lead");
            if (lead) {
                lead = JSON.parse(lead);
                return API.GET("lead/one", { id:lead.id }).then((res) => {
                    ctx.dispatch("setLead", res.data.lead);
                    if (res.data.lead.client_id) {
                        ctx.dispatch("getClientCompaniesContacts", res.data.lead.client_id);
                    }
                });
            } else {
                router.push("/lead");
            }
        },

        getBid(ctx) {
            let bid = localStorage.getItem("bid");
            if (bid) {
                bid = JSON.parse(bid);
                return API.GET("bid/one", { id: bid.id }).then((res) => {
                    ctx.dispatch("setBid", res.data.bid);
                    if (res.data.bid.client_id) {
                        ctx.dispatch("getBidContacts", res.data.bid.client_id);
                    }
                });
            } else {
                router.push("/bid");
            }
        },

        getTouch(ctx) {
            let touch = localStorage.getItem("touch");
            if (touch) {
                touch = JSON.parse(touch);
                return API.GET("touch/one", { id: touch.id }).then((res) => {
                    ctx.dispatch("setTouch", res.data.touch);
                    if (res.data.touch.client_id) {
                        ctx.dispatch("getTouchContacts", res.data.touch.client_id);
                    }
                });
            } else {
                router.push("/touch/touches");
            }
        }, 
        async getInvoice(ctx) {
            let invoice = localStorage.getItem("invoice");
            if (invoice) {
                invoice = JSON.parse(invoice);
                console.log('get invoice invoice', invoice);
                return API.GET("invoice/one", { id: invoice.id }).then((res) => {
                    ctx.dispatch("setInvoice", res.data.invoice);
                });
            } else {
                router.push("/invoice");
            }

        }, 

        saveDateInvoice(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("pay_wait", moment(data.pay_wait).format("YYYY-MM-DD HH:mm:ss"));
            API.POST("invoice/change-pay-wait", formData).then((res) => {
                ctx.dispatch("setInvoice", res.data.invoice);
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                // if (res.data.invoice.client_id) {
                //     ctx.dispatch("getInvoiceContacts", res.data.invoice.client_id);
                // }
            });
        },

        createInvoiceFromInvoice(ctx) {
            // console.log('API', );
            // let formData = new FormData();
            // formData.append("invoice_id", ctx.state.invoice.id);
            // API.POST("invoice/create-pdf", formData).then((res) => {
            //     res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            window.open(API.URL+'invoice/create-pdf?invoice_id='+ctx.state.invoice.id, '_blank');
            // });
        },
        
        setInvoiceCRMNumber(ctx, invoice) {
            let formData = new FormData();
            formData.append("id", invoice.id);
            formData.append("number_crm", invoice.number_crm);
            API.POST("invoice/change-number-crm", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        setInvoiceCode(ctx, invoice) {
            let formData = new FormData();
            formData.append("id", invoice.id);
            formData.append("code", invoice.code);
            API.POST("invoice/change-code", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        setInvoiceCodebill(ctx, invoice) {
            let formData = new FormData();
            formData.append("id", invoice.id);
            formData.append("codebill", invoice.codebill);
            API.POST("invoice/change-codebill", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        checkInvoicePay(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.invoice_id);
            API.POST("invoice/check-pay", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setInvoiceField(ctx, {invoice, field, value}){
            let formData = new FormData();
            formData.append("id", invoice.id);
            formData.append("field", field);
            formData.append("value", value);
            API.POST("invoice/set-field", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },


        UploadInvoice(ctx, data) {
            ctx.state.loadForm = false;
            ctx.commit("setResult", null);
            // console.log(data);
            let formData = new FormData();
            for (var i = 0; i < data.e.target.files.length; i++) {
                let file = data.e.target.files[i];

                let ext = "не определилось";
                let parts = file.name.split(".");
                if (parts.length > 1) {
                    ext = parts.pop();
                }
                if (!["jpeg", "jpg", "png", "bmp", "pdf"].includes(ext)) {
                    ctx.commit("setResult", "Неправильный формат. Загрузите .jpg .png .pdf");
                    ctx.state.loadForm = true;
                    return;
                }

                formData.append("picture[" + i + "]", file);
            }

            formData.append("objectName", 'garant');
            formData.append('garant', data.id);
            formData.append("status", data.status);

            return API.POST("ajax/img", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: function (progressEvent) {
                    ctx.state.progressBar = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                }.bind(ctx),
            })
                .then((response) => {
                    console.log(response);

                    console.log("data.item ", data.item);
                    if (data.item == "profile") {
                        console.log("picture ", response.data.picture[0].img);
                        console.log("profile ", ctx.rootState.profile.profile);
                        ctx.rootState.profile.profile.picture = response.data.picture[0].img;
                        ctx.commit("profile", ctx.rootState.profile.profile);
                        return;
                    }

                    if (!data.item.images) {
                        data.item.images = [];
                    }
                    response.data.picture.forEach((elem) => {
                        data.item.images.push(elem);
                    });

                    ctx.state.loadForm = true;
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    throw error;
                });
        },

        setInvoicePay(ctx, pay_status) {
            ctx.state.invoice.pay_status = pay_status;

            let formData = new FormData();
            formData.append("id", ctx.state.invoice.id);
            formData.append("pay", pay_status);
            API.POST("invoice/set-pay", formData).then((res) => {
                res.data.invoice ? ctx.dispatch("setInvoice", res.data.invoice) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        AcceptPay(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.invoice.id);
            API.POST("invoice/accept-pay", formData).then((res) => {
                res.data.invoice ? ctx.dispatch("setInvoice", res.data.invoice) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        DeclinePay(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.invoice.id);
            API.POST("invoice/decline-pay", formData).then((res) => {
                res.data.invoice ? ctx.dispatch("setInvoice", res.data.invoice) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        getGarladnsTouch(ctx) {
            let touch = localStorage.getItem("touch");
            if (touch) {
                touch = JSON.parse(touch);
                console.log('touch', touch);
                return API.GET("garlands/touch", { id: touch.id }).then((res) => {
                    ctx.dispatch("setGarland", res.data.garland);
                });
            } else {
                router.push("/touch/touches");
            }
        },

        getGarladnsLead(ctx) {
            let lead = localStorage.getItem("lead");
            if (lead) {
                lead = JSON.parse(lead);
                return API.GET("garlands/lead", { id: lead.id }).then((res) => {
                    ctx.dispatch("setGarland", res.data.garland);
                });
            } else {
                router.push("/lead");
            }
        },

        getGarladnsBid(ctx) {
            let bid = localStorage.getItem("bid");
            if (bid) {
                bid = JSON.parse(bid);
                return API.GET("garlands/bid", { id: bid.id }).then((res) => {
                    ctx.dispatch("setGarland", res.data.garland);
                });
            } else {
                router.push("/bid");
            }
        },
        getGarladnsInvoice(ctx) {
            let invoice = localStorage.getItem("invoice");
            if (invoice) {
                invoice = JSON.parse(invoice);
                return API.GET("garlands/invoice", { id: invoice.id }).then((res) => {
                    ctx.dispatch("setGarland", res.data.garland);
                });
            } else {
                router.push("/invoice");
            }
        },
 
 
        // РЕСУРСЫ: адреса форм регистрации лида и прикрепленные менеджеры
        getLeadSource(ctx) {
            return API.GET("lead/source").then((res) => {
                console.log("getLeadSource", res.data);
                ctx.commit("setLeadSource", res.data.list);
            });
        },

        addLeadSource(ctx, data) {
            let formData = new FormData();
            formData.append("host", data.host);
            formData.append("url", data.url);
            formData.append("manager_id", data.manager_id);

            return API.POST("lead/add-source", formData).then((res) => {
                res.data.list ? ctx.commit("setLeadSource", res.data.list) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delLeadSource(ctx, id) {
            let formData = new FormData();
            formData.append("source_id", id);

            return API.POST("lead/del-source", formData).then((res) => {
                res.data.list ? ctx.commit("setLeadSource", res.data.list) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setLeadSourceManager(ctx, data) {
            let formData = new FormData();
            formData.append("source_id", data.source_id);
            formData.append("manager_id", data.manager_id);
            return API.POST("lead/source-manager", formData).then((res) => {
                res.data.list ? ctx.commit("setLeadSource", res.data.list) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delLeadSourceManager(ctx, id) {
            let formData = new FormData();
            formData.append("source_id", id);
            return API.POST("lead/del-source-manager", formData).then((res) => {
                res.data.list ? ctx.commit("setLeadSource", res.data.list) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // КОМПАНИИ И КОНТАКТЫ КЛИЕНТА
        getClientCompaniesContacts(ctx, client_id) {
            let formData = new FormData();
            formData.append("client_id", client_id);
            return API.POST("order/get-contacts", formData).then((res) => {
                ctx.commit("setLeadCompanies", res.data.companies);
                ctx.commit("setLeadContacts", res.data.contacts);
            });
        },
        
        setFormForBid(ctx, firm_id) {
            let formData = new FormData();
            formData.append("bid_id", ctx.state.bid.id);
            formData.append("firm_id", firm_id);

            return API.POST("bid/set-firm", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delBidFirm (ctx) {
            let formData = new FormData();
            formData.append("bid_id", ctx.state.bid.id);

            return API.POST("bid/del-firm", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        startLead(ctx) {
            let formData = new FormData();
            formData.append("lead_id", ctx.state.lead.id );
            return API.POST("lead/start-lead", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });            
        },
        startBid(ctx) {
            let formData = new FormData();
            formData.append("bid_id", ctx.state.bid.id );
            return API.POST("bid/start-bid", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });            
        },

        findLead(ctx, s) {
            if (!ctx.state.leads) {
                return null;
            }
            let list = ctx.state.leads;
            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let num = null;
                    let client = null;
                    // iid = item.iid.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (iid || num || client) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.manager != "" && s.manager != undefined) {
                list = list.filter((item) => String(item.manager_id) == String(s.manager));
            }

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => String(item.master_id) == String(s.master));
            }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category) == String(s.category));
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.dates && item.dates[0]?.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.dates && item.dates[0]?.date <= s.end);
            }

            if (list.length != ctx.state.leads?.length) {
                s.clear = true;
            }

            ctx.state.filteredLeads = list;
            ctx.state.countLeads = list;
            ctx.dispatch("setSettings", s);
        },

        findBid(ctx, s) {
            if (!ctx.state.bids) {
                return null;
            }
            let list = ctx.state.bids;
            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let num = null;
                    let client = null;
                    iid = item.id.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (iid || num || client) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.manager != "" && s.manager != undefined) {
                list = list.filter((item) => String(item.manager_id) == String(s.manager));
            }

            if (s.creator != "" && s.creator != undefined) {
                list = list.filter((item) => String(item.who) == String(s.creator));
            }
            if (s.parent_id != "" && s.parent_id != undefined) {
                list = list.filter((item) => String(item.parent_id) == String(s.parent_id));
            }
            if (s.creator != "" && s.creator != undefined) {
                list = list.filter((item) => String(item.who) == String(s.creator));
            }

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => String(item.master_id) == String(s.master));
            }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category) == String(s.category));
            }
            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.created_at.slice(0, 10) >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.created_at.slice(0, 10) <= s.end);
            }

            if (list.length != ctx.state.leads?.length) {
                s.clear = true;
            }

            ctx.state.filteredBids = list;
            ctx.state.countBids = list;
            ctx.dispatch("setSettings", s);
        },

        findInvoice(ctx, s) {
            if (!ctx.state.invoices) {
                return null;
            }
            let list = ctx.state.invoices;
            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let num = null;
                    let client = null;
                    let invoice_number = null;
                    let codebill = null;
                    // iid = item.iid.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.invoice_number) {
                        invoice_number = item.invoice_number.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.codebill) {
                        codebill = item.codebill.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (iid || num || client || invoice_number || codebill) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.manager != "" && s.manager != undefined) {
                list = list.filter((item) => String(item.manager_id) == String(s.manager));
            }
            if (s.parent_id != "" && s.parent_id != undefined) {
                list = list.filter((item) => String(item.parent_id) == String(s.parent_id));
            }

            if (s.creator != "" && s.creator != undefined) {
                list = list.filter((item) => String(item.who) == String(s.creator));
            }

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => String(item.master_id) == String(s.master));
            }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category) == String(s.category));
            }
            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.created_at.slice(0, 10) >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.created_at.slice(0, 10) <= s.end);
            }

            if (s.startPW != "" && s.startPW != undefined) {
                list = list.filter((item) => item.pay_wait != null);
                list = list.filter((item) => item.pay_wait.slice(0, 10) >= s.startPW);
            }
            if (s.endPW != "" && s.endPW != undefined) {
                list = list.filter((item) => item.pay_wait != null);
                list = list.filter((item) => item.pay_wait.slice(0, 10) <= s.endPW);
            }

            if (s.startFP != "" && s.startFP != undefined) {
                list = list.filter((item) => item.pay_date != null);
                list = list.filter((item) => item.pay_date.slice(0, 10) >= s.startFP);
            }
            if (s.endFP != "" && s.endFP != undefined) {
                list = list.filter((item) => item.pay_date != null);
                list = list.filter((item) => item.pay_date.slice(0, 10) <= s.endFP);
            }

            if (list.length != ctx.state.leads?.length) {
                s.clear = true;
            }

            ctx.state.filteredInvoices = list;
            ctx.state.countInvoices = list;
            ctx.dispatch("setSettings", s);
        },
        
        findLead1(ctx, s) {
            if (!ctx.state.leads) {
                return null;
            }
            let list = ctx.state.leads;
            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let id = null;
                    let num = null;
                    let client = null;
                    id = item.id.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (id || num || client) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.manager != "" && s.manager != undefined) {
                list = list.filter((item) => String(item.manager_id) == String(s.manager));
            }
            if (s.creator != "" && s.creator != undefined) {
                list = list.filter((item) => String(item.who) == String(s.creator));
            }

            // if (s.master != "" && s.master != undefined) {
            //     list = list.filter((item) => String(item.master_id) == String(s.master));
            // }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category) == String(s.category));
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.create_at.slice(0, 10) >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.create_at.slice(0, 10) <= s.end);
            }

            if (list.length != ctx.state.leads?.length) {
                s.clear = true;
            }
            ctx.state.filteredLeads = Object.assign([], list);
            ctx.state.countLeads = Object.assign([], list);

            ctx.dispatch("setSettings", s);
        },

        findTouch(ctx, s) {
            if (!ctx.state.touches) {
                return null;
            }
            let list = ctx.state.touches;
            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let id = null;
                    let num = null;
                    let client = null;
                    id = item.id.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (id || num || client) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.manager != "" && s.manager != undefined) {
                list = list.filter((item) => String(item.manager_id) == String(s.manager));
            }

            if (s.creator != "" && s.creator != undefined) {
                list = list.filter((item) => String(item.who) == String(s.creator));
            }

            if (s.del != "" && s.del != undefined) {
                list = list.filter((item) => String(item.del) == String(s.del));
            }

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => String(item.master_id) == String(s.master));
            }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category) == String(s.category));
            }

            if (s.business_proc != "" && s.business_proc != undefined) {
                list = list.filter((item) => String(item.business_proc) == String(s.business_proc));
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.created_at.slice(0, 10) >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.created_at.slice(0, 10) <= s.end);
            }



            if (list.length != ctx.state.touches?.length) {
                s.clear = true;
            }

            ctx.state.filteredTouches = list;
            ctx.state.countTouches = list;

            ctx.dispatch("setSettings", s);
        },

        statusLead(ctx, s) {
            ctx.dispatch("findLead1", s);
            let list = ctx.state.filteredLeads;
            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => String(item.status) == String(s.status));
            }

            if (list.length != ctx.state.leads?.length) {
                s.clear = true;
            }

            ctx.state.filteredLeads = list;
            console.log('list AFTER', list);
        },

        statusTouch(ctx, s) {
            ctx.dispatch("findTouch", s);
            let list = ctx.state.filteredTouches;

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => String(item.status) == String(s.status));
            }

            if (list.length != ctx.state.touches?.length) {
                s.clear = true;
            }

            ctx.state.filteredTouches = list;
        },
        statusInvoice(ctx, s) {
            ctx.dispatch("findInvoice", s);
            let list = ctx.state.filteredInvoices;

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => String(item.status) == String(s.status));
            }

            if (s.violate != "" && s.violate != undefined) {
                list = list.filter((item) => String(item.violate) == String(s.violate));
            }

            if (list.length != ctx.state.invoices?.length) {
                s.clear = true;
            }

            ctx.state.filteredInvoices = list;
        },

        

        statusBid(ctx, s) {
            ctx.dispatch("findBid", s);
            let list = ctx.state.filteredBids;

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => String(item.status) == String(s.status));
            }

            if (list.length != ctx.state.bids?.length) {
                s.clear = true;
            }

            ctx.state.filteredBids = list;
        },

        setTouchFromT(ctx, formData = null) {
            API.POST("task/create-touch", formData).then((res) => {
                res.data.touch ? ctx.dispatch("setTouch", res.data.touch) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                router.push("/touch/edit");
            });
        },

        addTouch(ctx, formData = null) {
            // console.log("addOrder");
            API.POST("touch/create", formData).then((res) => {
                res.data.touch ? ctx.dispatch("setTouch", res.data.touch) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                router.push("/touch/edit");
            });
        },

        addTouchContact(ctx, formData = null) {
            // console.log("addOrder");
            API.POST("touch/create-is-contact", formData).then((res) => {
                res.data.touch ? ctx.dispatch("setTouch", res.data.touch) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                router.push("/touch/edit");
            });
        },

        addLead(ctx, formData = null) {
            console.log("addLead");
            return API.POST("lead/add-lead", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                router.push("/lead/edit");
            });
        },
        
        showLead(ctx, lead) {
            ctx.dispatch("setLead", lead);
            router.push("/lead/edit");
        },
        showBid(ctx, bid) {
            ctx.dispatch("setBid", bid);
            router.push("/bid/edit");
        },        
        showInvoice(ctx, invoice) {
            ctx.dispatch("setInvoice", invoice);
            router.push("/invoice/edit");
        },    
        showTouch(ctx, touch) {
            ctx.dispatch("setTouch", touch);
            router.push("/touch/edit");
        },

        goToTouchFromLead(ctx, touch_id) {
            return API.GET("touch/one", { id: touch_id }).then((res) => {
                ctx.dispatch("setTouch", res.data.touch);
                router.push("/touch/edit");
            });
        },
        goToLeadFromTouch(ctx, lead_id) {
            return API.GET("lead/one", { id: lead_id }).then((res) => {
                ctx.dispatch("setLead", res.data.lead);
                router.push("/lead/edit");
            });
        },
        goToBidNow(ctx, bid_id) {
            return API.GET("bid/one", { id: bid_id }).then((res) => {
                ctx.dispatch("setBid", res.data.bid);
                router.push("/bid/edit");
            });
        },
        goToInvoiceNow(ctx, invoice_id) {
            return API.GET("invoice/one", { id: invoice_id }).then((res) => {
                ctx.dispatch("setInvoice", res.data.invoice);
                router.push("/invoice/edit");
            });
        },
      

        async showLeadById(ctx, id) {
            let lead = { id: id };
            localStorage.setItem("lead", JSON.stringify(lead));
            await ctx.commit("setLead", lead);
            await router.push("/lead/edit");
        },
        async showTouchById(ctx, id) {
            let touch = { id: id };
            localStorage.setItem("touch", JSON.stringify(touch));

            await ctx.commit("setTouch", touch);
            await router.push("/touch/edit");
        },
        async showBidById(ctx, id) {
            let bid = { id: id };
            localStorage.setItem("bid", JSON.stringify(bid));

            await ctx.commit("setBid", bid);
            await router.push("/bid/edit");
        },

        // НАПРАВЛЕНИЕ
        kindOfLead(ctx, item) {
            console.log(item);
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("name", item.name);
            formData.append("val", item.val);
            return API.POST("lead/kind", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // КЛИЕНТ
        findLeadClient(ctx, find) {
            console.log("findClient", find);
            return API.GET("lead/client-list", { id: ctx.state.lead.id, find: find }).then((res) => {
                ctx.commit("setLeadClients", res.data);
            });
        },

        setLeadClient(ctx, client_id) {
            ctx.state.lead.client_id = client_id;
            ctx.state.lead.client = { loader: 1 };
            ctx.dispatch("setLead", ctx.state.lead);
            ctx.commit("setLeadClients", null);

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("client_id", client_id);
            return API.POST("lead/change-client", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                ctx.commit("setLeadContacts", res.data.contacts);
            });
        },

        async setTouchClient(ctx, client_id) {
            ctx.state.touch.client_id = client_id;
            ctx.state.touch.client = { loader: 1 };
            ctx.dispatch("setTouch", ctx.state.touch);
            ctx.commit("setTouchClients", null);

            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("client_id", client_id);
            const res = await API.POST("touch/change-client", formData);
            res.data.touch ? ctx.dispatch("setTouch", res.data.touch) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            ctx.commit("setTouchContacts", res.data.contacts);
        },


        async setBidClient(ctx, client_id) {
            ctx.state.bid.client_id = client_id;
            ctx.state.bid.client = { loader: 1 };
            ctx.dispatch("setBid", ctx.state.bid);
            ctx.commit("setBidClients", null);

            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("client_id", client_id);
            const res = await API.POST("bid/change-client", formData);
            res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            ctx.commit("setBidContacts", res.data.contacts);
        },


        delLeadClient(ctx) {
            ctx.state.lead.client_id = null;
            ctx.state.lead.contact_id = null;
            ctx.commit("setLeadContacts", null);
            ctx.dispatch("setLead", ctx.state.lead);

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("client_id", 0);
            return API.POST("lead/change-client", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        async delTouchClient(ctx) {
            ctx.state.touch.client_id = null;
            ctx.state.touch.contact_id = null;
            ctx.commit("setTouchContacts", null);
            ctx.dispatch("setTouch", ctx.state.touch);

            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("client_id", 0);
            const res = await API.POST("touch/change-client", formData);
            res.data.lead ? ctx.dispatch("setTouch", res.data.touch) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
        },

        delBidClient(ctx) {
            ctx.state.bid.client_id = null;
            ctx.state.bid.contact_id = null;
            ctx.commit("setLeadContacts", null);
            ctx.dispatch("setBid", ctx.state.bid);

            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("client_id", 0);
            return API.POST("bid/change-client", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setLead", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        addLeadClient(ctx, text) {
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("text", text);
            return API.POST("lead/add-client", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.client ? ctx.dispatch("setClient", res.data.client) : null;
                res.data.contact ? ctx.dispatch("setContact", res.data.contact) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                ctx.rootState.s.popup = "contactedit";
                ctx.commit("settings", ctx.rootState.s);
            });
        },

        addTouchClient(ctx, text) {
            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("text", text);
            return API.POST("touch/add-client", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.touch) : null;
                res.data.client ? ctx.dispatch("setClient", res.data.client) : null;
                res.data.contact ? ctx.dispatch("setContact", res.data.contact) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                ctx.rootState.s.popup = "contactedit";
                ctx.commit("settings", ctx.rootState.s);
            });
        },

        addBidClient(ctx, text) {
            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("text", text);
            return API.POST("bid/add-client", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.client ? ctx.dispatch("setClient", res.data.client) : null;
                res.data.contact ? ctx.dispatch("setContact", res.data.contact) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                ctx.rootState.s.popup = "contactedit";
                ctx.commit("settings", ctx.rootState.s);
            });
        },

        // КОМПАНИЯ
        setLeadCompany(ctx, item) {
            ctx.state.lead.company_id = item.id;
            ctx.state.lead.company = { loader: 1 };
            ctx.dispatch("setLead", ctx.state.lead);

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("client_id", ctx.state.lead.client_id);
            formData.append("company_id", item.id);
            this.find = "";
            return API.POST("lead/change-company", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delLeadCompany(ctx) {
            ctx.state.lead.company_id = null;
            ctx.state.lead.company = null;
            ctx.dispatch("setLead", ctx.state.lead);

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("company_id", 0);
            return API.POST("lead/change-company", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setBidCompany(ctx, item) {
            ctx.state.bid.company_id = item.id;
            ctx.state.bid.company = { loader: 1 };
            ctx.dispatch("setBid", ctx.state.bid);

            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("company_id", item.id);
            this.find = "";
            return API.POST("bid/change-company", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delBidCompany(ctx) {
            ctx.state.bid.company_id = null;
            ctx.state.bid.company = null;
            ctx.dispatch("setBid", ctx.state.bid);

            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("company_id", 0);
            return API.POST("bid/change-company", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // КОНТАКТ
        getLeadContacts(ctx, client_id) {
            let formData = new FormData();
            formData.append("client_id", client_id);
            return API.POST("lead/get-contacts", formData).then((res) => {
                ctx.commit("setLeadCompanies", res.data.companies);
                ctx.commit("setLeadContacts", res.data.contacts);
            });
        },

        getBidContacts(ctx, client_id) {
            let formData = new FormData();
            formData.append("client_id", client_id);
            return API.POST("bid/get-contacts", formData).then((res) => {
                ctx.commit("setBidCompanies", res.data.companies);
                ctx.commit("setBidContacts", res.data.contacts);
            });
        },

        getTouchContacts(ctx, client_id) {
            let formData = new FormData();
            formData.append("client_id", client_id);
            return API.POST("touch/get-contacts", formData).then((res) => {
                ctx.commit("setTouchCompanies", res.data.companies);
                ctx.commit("setTouchContacts", res.data.contacts);
            });
        },

        getInvoiceContacts(ctx, client_id) {
            let formData = new FormData();
            formData.append("client_id", client_id);
            return API.POST("invoice/get-contacts", formData).then((res) => {
                ctx.commit("setInvoiceCompanies", res.data.companies);
                ctx.commit("setInvoiceContacts", res.data.contacts);
            });
        },
        
        setLeadContact(ctx, item) {
            ctx.state.lead.contact_id = item.id;
            ctx.state.lead.contact = { loader: 1 };
            ctx.dispatch("setLead", ctx.state.lead);

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("client_id", ctx.state.lead.client_id);
            formData.append("contact_id", item.id);
            this.find = "";
            return API.POST("lead/change-contact", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setTouchContact(ctx, item) {
            ctx.state.touch.contact_id = item.id;
            ctx.state.touch.contact = { loader: 1 };
            ctx.dispatch("setLead", ctx.state.touch);

            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            // formData.append("client_id", ctx.state.touch.client_id);
            formData.append("contact_id", item.id);
            this.find = "";
            return API.POST("touch/change-contact", formData).then((res) => {
                res.data.touch ? ctx.dispatch("setTouch", res.data.touch) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setBidContact(ctx, item) {
            ctx.state.bid.contact_id = item.id;
            ctx.state.bid.contact = { loader: 1 };
            ctx.dispatch("setBid", ctx.state.bid);

            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            // formData.append("client_id", ctx.state.bid.client_id);
            formData.append("contact_id", item.id);
            return API.POST("bid/change-contact", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delLeadContact(ctx) {
            ctx.state.lead.contact_id = null;
            ctx.state.lead.contact = null;
            ctx.dispatch("setLead", ctx.state.lead);

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("contact_id", 0);
            return API.POST("lead/change-contact", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delTouchContact(ctx) {
            ctx.state.touch.contact_id = null;
            ctx.state.touch.contact = null;
            ctx.dispatch("setTouch", ctx.state.touch);

            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("contact_id", 0);
            return API.POST("touch/change-contact", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setTouch", res.data.touch) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delBidContact(ctx) {
            ctx.state.bid.contact_id = null;
            ctx.state.bid.contact = null;
            ctx.dispatch("setBid", ctx.state.bid);

            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("contact_id", 0);
            return API.POST("bid/change-contact", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        //bid/list-price

        setClientBusiness(ctx, data) {
            if (data.business != 5 || data.business_dop?.length > 0) {
                let formData = new FormData();
                formData.append("id", ctx.state.lead.id);
                formData.append("client_id", ctx.state.lead.client_id);
                formData.append("business", data.business);
                formData.append("business_dop", data.business_dop);
                return API.POST("lead/client-business", formData).then((res) => {
                    res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                    res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                });
            }
        },

        setClientTs(ctx, ts) {
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("client_id", ctx.state.lead.client_id);
            formData.append("ts", ts);
            return API.POST("lead/client-ts", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setLeadSum(ctx, sum) {
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("sum", sum);
            return API.POST("lead/set-sum", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setLeadDirection(ctx, direction) {
            console.log(direction);
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("direction", direction);
            return API.POST("lead/direction", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        async setTouchDirection(ctx, direction) {
            console.log(direction);
            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("direction", direction);
            const res = await API.POST("touch/direction", formData);
            res.data.touch ? ctx.dispatch("setTouch", res.data.touch) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
        },

        async setBidDirection(ctx, direction) {
            console.log(direction);
            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("direction", direction);
            const res = await API.POST("bid/direction", formData);
            res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
        },

        setLeadType(ctx, type) {
            console.log(type);
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("type", type);
            return API.POST("lead/type", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        async setTouchType(ctx, type) {
            console.log(type);
            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("type", type);
            const res = await API.POST("touch/type", formData);
            res.data.touch ? ctx.dispatch("setTouch", res.data.touch) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
        },

        setBidType(ctx, type) {
            console.log(type);
            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("type", type);
            return API.POST("bid/type", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setLeadChannel(ctx, channel) {
            console.log(channel);
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("channel", channel);
            return API.POST("lead/channel", formData).then((res) => {
                console.log("channel", res.data);
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        async setTouchChannel(ctx, channel) {
            console.log(channel);
            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("channel", channel);
            const res = await API.POST("touch/channel", formData);
            console.log("channel", res.data);
            res.data.touch ? ctx.dispatch("setTouch", res.data.touch) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
        },

        setBidChannel(ctx, channel) {
            console.log(channel);
            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("channel", channel);
            return API.POST("bid/channel", formData).then((res) => {
                console.log("channel", res.data);
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        async changeBP(ctx, biz_id) {
            console.log(biz_id);
            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("business", parseInt(biz_id));
            const res = await API.POST("touch/business", formData);
            console.log("channel", res.data);
            res.data.touch ? ctx.dispatch("setTouch", res.data.touch) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
        },

        setLeadDetail(ctx, detail) {
            console.log(detail);
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("detail", detail);
            return API.POST("lead/detail", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setBidDetail(ctx, detail) {
            console.log(detail);
            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("detail", detail);
            return API.POST("bid/detail", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        createBidFromLead(ctx, parent_id) {
            console.log(parent_id);
            let formData = new FormData();
            formData.append("lead_id", ctx.state.lead.id);
            formData.append("parent_id", parent_id);
            return API.POST("lead/create-bid", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                router.push("/bid/edit");
            });
        },

        setLeadManager(ctx, item) {
            console.log("setLeadManager", item);

            ctx.state.lead.manager_id = item.uid;
            ctx.state.lead.uname = item.name;
            ctx.state.lead.usecondname = item.secondname;
            ctx.state.lead.upicture = item.picture;

            ctx.dispatch("setLead", ctx.state.lead);

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("manager_id", item.uid);
            return API.POST("lead/change-manager", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delLeadManager(ctx) {
            ctx.state.lead.manager_id = null;
            ctx.state.lead.manager = null;

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("manager_id", 0);
            return API.POST("lead/change-manager", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setBidManager(ctx, item) {
            console.log("setLeadManager", item);

            ctx.state.lead.manager_id = item.uid;
            ctx.state.lead.uname = item.name;
            ctx.state.lead.usecondname = item.secondname;
            ctx.state.lead.upicture = item.picture;

            ctx.dispatch("setBid", ctx.state.bid);

            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("manager_id", item.uid);
            return API.POST("bid/change-manager", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delBidManager(ctx) {
            ctx.state.lead.manager_id = null;
            ctx.state.lead.manager = null;

            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("manager_id", 0);
            return API.POST("bid/change-manager", formData).then((res) => {
                res.data.bid ? ctx.dispatch("setBid", res.data.bid) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        saveLead(ctx, lead) {
            console.log("saveLeadCode: ", lead.code);
            let formData = new FormData();
            formData.append("id", lead.id);
            formData.append("code", lead.code);
            return API.POST("lead/save-lead", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        sendCancel(ctx, data) {
            ctx.state.lead.status = 11;
            ctx.state.lead.timer = null;
            ctx.dispatch("setLead", ctx.state.lead);

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("cancel_id", data.cancel_id);
            formData.append("comment", data.comment);
            return API.POST("lead/cancel", formData).then((res) => {
                ctx.dispatch("setLead", res.data.lead);
            });
        },

        sendTouchCancel(ctx, data) {
            ctx.state.lead.status = 11;
            ctx.state.lead.timer = null;
            ctx.dispatch("setTouch", ctx.state.touch);

            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("cancel_id", data.cancel_id);
            formData.append("comment", data.comment);
            
            API.POST("touch/cancel", formData).then((res) => {
                ctx.dispatch("setTouch", res.data.touch);
            });            

            setTimeout(() => {
                router.push("/touch/touches");
            }, "1000");
        },

        sendBidCancel(ctx, data) {
            ctx.state.bid.status = 3;
            ctx.state.bid.timer = null;
            ctx.dispatch("setBid", ctx.state.bid);

            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("cancel_id", data.cancel_id);
            formData.append("comment", data.comment);
            return API.POST("bid/cancel", formData).then((res) => {
                ctx.dispatch("setBid", res.data.bid);
            });
        },

        sendInvoiceCancel(ctx, data) {
            ctx.state.invoice.status = 4;
            ctx.dispatch("setInvoice", ctx.state.invoice);

            let formData = new FormData();
            formData.append("id", ctx.state.invoice.id);
            formData.append("cancel_id", data.cancel_id);
            formData.append("comment", data.comment);
            return API.POST("invoice/cancel", formData).then((res) => {
                ctx.dispatch("setInvoice", res.data.bid);
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;

            });
        },

        setStatus(ctx, status) {
            if ([6, 8].includes(status)) {
                ctx.state.lead.timer = null;
            }
            ctx.state.lead.status = status;
            ctx.dispatch("setLead", ctx.state.lead);

            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("status", status);
            API.POST("lead/set-status", formData).then((res) => {
                ctx.state.lead.timer = res.data.timer;
                ctx.dispatch("setLead", ctx.state.lead);
            });
        },

        pushTaskinLead(ctx, task) {
            ctx.state.lead.tasks.push(task);
            ctx.dispatch("setLead", ctx.state.lead);
        },

        delLead(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            router.push("/lead");
            return API.POST("lead/del-lead", formData);
        },
        delTouch(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            router.push("/touch/touches");
            return API.POST("touch/del-touch", formData);
        },
        delBid(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            router.push("/bid");
            return API.POST("bid/del-touch", formData);
        },

        createLeadFromTouch(ctx, formData = null) {
            API.POST("touch/create-lead", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                router.push("/lead/edit");
            });
        },
        

        createInvoiceFromBid(ctx, formData = null) {
            API.POST("bid/create-invoice", formData).then((res) => {
                res.data.invoice ? ctx.dispatch("setInvoice", res.data.invoice) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                router.push("/invoice/edit");
            });
        },
        
        setEvent1S(ctx, event_one_s){
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("event_one_s", event_one_s);
            return API.POST("lead/set-event-one-s", formData);
        },

        setLeadChannelDetailType(ctx, type){
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("channel_detail_type", type);
            return API.POST("lead/set-channel-detail-type", formData);
        },
        setLeadChannelDetailValue(ctx, value){
            // console.log("Вызов", value ,  new Error);
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("channel_detail_value", value);
            return API.POST("lead/set-channel-detail-value", formData);
        },
        setLeadCommunicationType(ctx, type){
            let formData = new FormData();
            formData.append("id", ctx.state.lead.id);
            formData.append("communication_type", type);
            return API.POST("lead/set-communication-type", formData);
        },

        setTouchChannelDetailType(ctx, type){
            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("channel_detail_type", type);
            return API.POST("touch/set-channel-detail-type", formData);
        },
        setTouchChannelDetailValue(ctx, value){
            // console.log("Вызов", value ,  new Error);
            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("channel_detail_value", value);
            return API.POST("touch/set-channel-detail-value", formData);
        },
        setTouchCommunicationType(ctx, type){
            let formData = new FormData();
            formData.append("id", ctx.state.touch.id);
            formData.append("communication_type", type);
            return API.POST("touch/set-communication-type", formData);
        },

        setBidChannelDetailType(ctx, type){
            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("channel_detail_type", type);
            return API.POST("bid/set-channel-detail-type", formData);
        },
        setBidChannelDetailValue(ctx, value){
            // console.log("Вызов", value ,  new Error);
            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("channel_detail_value", value);
            return API.POST("bid/set-channel-detail-value", formData);
        },
        setBidCommunicationType(ctx, type){
            let formData = new FormData();
            formData.append("id", ctx.state.bid.id);
            formData.append("communication_type", type);
            return API.POST("bid/set-communication-type", formData);
        },
        
        getBidPrices(ctx) {
            let bid = localStorage.getItem("bid");
            if (bid) {
                bid = JSON.parse(bid);
                return API.GET("bid/list-price", { id: bid.id }).then((res) => {
                    res.data.prices_bid ? ctx.dispatch("setPrices", res.data.prices_bid) : null;
                });
            }
        }
    },

    getters: {
        LeadList(state) {
            return state.filteredLeads;
        },
        BidList(state) {
            return state.filteredBids;
        },
        InvoiceList(state) {
            return state.filteredInvoices;
        },
        TouchesList(state) {
            return state.filteredTouches;
        },

        LeadCounter(state) {
            let count = [];
            count[1] = 0;
            count[2] = 0;
            count[4] = 0;
            count[6] = 0;
            count[8] = 0;
            count[11] = 0;
            if (state.countLeads?.length > 0) {
      
                state.countLeads?.forEach((elem) => {
                    if (elem.status == 1) {
                        count[1]++;
                    }
                    if (elem.status == 2) {
                        count[2]++;
                    }
                    if (elem.status == 4) {
                        count[4]++;
                    }
                    if (elem.status == 6) {
                        count[6]++;
                    }
                    if (elem.status == 8) {
                        count[8]++;
                    }
                    if (elem.status == 11) {
                        count[11]++;
                    }
                });
            }
            return count;
        },

        BidCounter(state) {
            let count = [];
            count[1] = 0;
            count[2] = 0;
            count[3] = 0;
            count[4] = 0;
            count[6] = 0;
            if (state.countBids?.length > 0) {
             
                state.countBids?.forEach((elem) => {
                    if (elem.status == 1) {
                        count[1]++;
                    }
                    if (elem.status == 2) {
                        count[2]++;
                    }
                    if (elem.status == 3) {
                        count[3]++;
                    }
                    if (elem.status == 4) {
                        count[4]++;
                    }
                    if (elem.status == 6) {
                        count[6]++;
                    }
                });
            }
            return count;
        },

        InvoiceCounter(state) {
            let count = [];
            count[1] = 0;
            count[2] = 0;
            count[3] = 0;
            count[4] = 0;
            count[6] = 0;
            if (state.countInvoices?.length > 0) {
                state.countInvoices?.forEach((elem) => {
                    if (elem.status == 1) {
                        count[1]++;
                    }
                    if (elem.status == 2) {
                        count[2]++;
                    }
                    if (elem.status == 3) {
                        count[3]++;
                    }
                    if (elem.status == 4) {
                        count[4]++;
                    }
                    if (elem.violate == 2) {
                        count[6]++;
                    }
                });
            }
            return count;
        },

        TouchesCounter(state) {
            let count = [];
            count[0] = 0;
            // count[3] = 0;
            count[1] = 0;
            count[2] = 0;
            count[3] = 0;
            if (state.countTouches?.length > 0) {
   
                // count[11] = 0;
                state.countTouches?.forEach((elem) => {
                    if (elem.status == 1) {
                        count[0]++;
                    }
                    if (elem.status == 2) {
                        count[1]++;
                    }
                    if (elem.status == 3) {
                        count[2]++;
                    }
                    if (elem.del == 1) {
                        count[3]++;
                    }
                    // if (elem.status == 11) {
                    //     count[11]++;
                    // }
                });
            }
            return count;
        },


        lead(state) {
            return state.lead;
        },

        touch(state) {
            return state.touch;
        },
        bid(state) {
            return state.bid;
        },
        invoice(state) {
            return state.invoice;
        },
        prices_bid(state) {
            return state.prices_bid;
        },
        garland(state) {
            console.log('getGarland', state.garland);
            return state.garland;
        },
        selectLeadClients(state) {
            return state.leadClients;
        },

        selectLeadCompanies(state) {
            return state.leadCompanies;
        },

        selectLeadContacts(state) {
            return state.leadContacts;
        },

        selectTouchClients(state) {
            return state.touchClients;
        },
        selectTouchCompanies(state) {
            return state.touchCompanies;
        },
        selectTouchContacts(state) {
            return state.touchContacts;
        },
        selectBidContacts(state) {
            return state.bidContacts;
        },
        selectBidClients(state) {
            return state.bidClients;
        },
        selectBidCompanies(state) {
            return state.bidCompanies;
        },

        selectInvoiceContacts(state) {
            return state.invoiceContacts;
        },
        selectInvoiceClients(state) {
            return state.invoiceClients;
        },
        selectInvoiceCompanies(state) {
            return state.invoiceCompanies;
        },
        firms(state) {
            return state.firms;
        },
        leadSource(state) {
            return state.leadSource;
        },
        invoiceOrders(state) {
            return state.invoiceOrders;
        },

        leadPaginator(state){
            return state.leadPaginator;
        },
        bidPaginator(state){
            return state.bidPaginator;
        },
        touchPaginator(state){
            return state.touchPaginator;
        },
        invoicePaginator(state){
            return state.invoicePaginator;
        },
    },
};
