<template>
  <div
    v-if="s.popup == 'view'"
    id="popnew"
    class="w-2/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <CompanyView @close="close" />
  </div>
  <div
    v-if="s.popup == 'edit'"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <CompanyEdit @close="close" />
  </div>
  <div
    v-if="s.popup"
    @click="close"
    id="overlay"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
  ></div>

  <template v-if="ClientCompanies">
    <div class="bg-white rounded-lg overflow-hidden">
      <div class="p-5 flex justify-between items-center v-bbgray">
        <h3 class="font-latobold text-base">
          Компании {{ ClientCompanies.length }}
        </h3>
        <template
          v-if="
            profile.uid == client.manager_id ||
            ['Assistant', 'Studio', 'Hosd'].includes(role)
          "
        >
          <a
            class="v-btn bg-green-500 hover:bg-green-400"
            @click="addCompany(), (s.popup = 'edit')"
          >
            <svg class="fill-white w-4 h-4 mr-3 rotate-45">
              <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
            <span class="v-btn_text">Создать</span>
          </a>
        </template>
      </div>
      <div class="grid grid-cols-2 px-5 py-2 v-bbgray">
        <p class="text-gray-400 uppercase text-[11px]">КОМПАНИЯ</p>
        <p class="text-gray-400 uppercase text-[11px]">ИНН</p>
      </div>

      <div
        v-for="item in ClientCompanies"
        :key="item"
        :id="item.iid"
        class="grid grid-cols-2 px-4 py-2 relative group items-center v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white"
      >
        <div @click="showCompany(item), (this.s.popup = 'view')">
          <div class="flex items-center group cursor-pointer">
            <div
              class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-lg text-white"
            >
              {{ item.name?.slice(0, 1) }}
            </div>
            <span
              class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="cell name">{{ item.inn }}</div>
        <template
          v-if="
            profile.uid == client.manager_id ||
            ['Assistant', 'Studio', 'Hosd'].includes(role)
          "
        >
          <div
            class="flex absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150"
          >
            <a
              class="cursor-pointer"
              @click="editCompany(item), (this.s.popup = 'edit')"
            >
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-green-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-edit"></use>
              </svg>
            </a>
            <a class="cursor-pointer ml-4" @click="delCompany(item)">
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </a>
          </div>
        </template>
      </div>
    </div>
  </template>

  <div v-else class="loader opacity1 mt30">
    <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CompanyView from "../components/client/CompanyView";
import CompanyEdit from "../components/client/CompanyEdit";

export default {
  name: "ClientCompany",

  components: { CompanyView, CompanyEdit },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "ClientCompanies",
    ]),
  },

  methods: {
    ...mapActions([
      "Tab",
      "addCompany",
      "showCompany",
      "editCompany",
      "delCompany",
      "getClientTabsCompany",
      "getClient",
    ]),

    close() {
      this.s.popup = false;
      this.company = null;
      localStorage.removeItem("company");
    },
  },

  async mounted() {
    window.DEBUG_CLIENT_TAB_COMPANY = this;
    //await this.getClient();
    await this.getClientTabsCompany();
  },
};
</script>
