<template>
  <div class="pb-2 w-full flex flex-wrap items-center">
    <div class="flex-2_0 mr-15px w-[200px] my-4">
      <input
        type="text"
        class="h-9 w-full px-3 py-2 text-sm bg-slate-50"
        placeholder="Сайт"
        v-model="host"
      />
    </div>
    <div class="flex-2_0 mr-15px w-[200px] my-4">
      <input
        type="text"
        class="h-9 w-full px-3 py-2 text-sm bg-slate-50"
        placeholder="Полный URL"
        v-model="url"
      />
    </div>

    <template
      v-if="['Manager', 'Logist', 'Studio', 'Hosd', 'Check'].includes(role)"
    >
      <select class="my-2 h-9 w-40 mr-4 bg-slate-50" v-model="manager_id">
        <option value="0">Менеджер</option>
        <template v-for="m in selectManagers" :key="m">
          <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
        </template>
      </select>
    </template>

    <div class="flex py-4">
      <button class="v-btn bg-green-500 hover:bg-green-400">
        <span
          class="v-btn_text flex items-center"
          @click="
            addLeadSource({ host: host, url: url, manager_id: manager_id })
          "
        >
          <svg
            class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
          >
            <use xlink:href="@/assets/i.svg#ico-no"></use>
          </svg>
          Создать
        </span>
      </button>
    </div>
  </div>

  <div
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <div class="grid grid-cols-12 h-10 v-bbgray px-4 uppercase items-center">
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-3"
      >
        САЙТ
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-5"
      >
        URL
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-3"
      >
        МЕНЕДЖЕР
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
      >
        УДАЛИТЬ
      </div>
    </div>
    <template v-for="item in leadSource" :key="item">
      <a
        class="grid grid-cols-12 px-4 py-2 gap-2 v-bbgray status-1 bg-teal-light odd:bg-white items-center hover:shadow-v1"
      >
        <div class="text-black flex w-full h-full items-center col-span-3">
          <span
            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
            :class="'bg-status-' + item.status"
          >
            {{ item.host?.slice(0, 1).toUpperCase() }}
          </span>
          <div class="flex flex-col justify-center overflow-hidden pr-4">
            <span
              class="text-base font-semibold break-all max-h-10 leading-5"
              >{{ item.host }}</span
            >
          </div>
        </div>

        <div
          class="flex flex-col text-black text-xs tex-left max-h-16 col-span-5"
        >
          <span>{{ item.url }}</span>
        </div>

        <template v-if="['Studio', 'Hosd'].includes(role)">
          <div
            v-if="item.mname"
            class="font-latoblod-serif font-[16px] text-v-gray relative flex justify-start items-center col-span-3"
          >
            <div
              class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
              :class="'bg-status-' + item.status"
            >
              <IcoPic :pic="item.mpicture" :name="item.mname" />
            </div>
            <div class="flex flex-col justify-center overflow-hidden">
              <span class="text-[10px] text-gray-400 leading-none uppercase"
                >Менеджер</span
              >
              <span class="text-black overflow-hidden">{{ item.mname }}</span>
            </div>
            <div
              class="group cursor-pointer ml-4"
              @click="delLeadSourceManager(item.id)"
            >
              <svg
                class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </div>
          </div>
          <template v-else>
            <select
              class="my-2 h-9 w-40 mr-4 bg-slate-50"
              @change="
                setLeadSourceManager({
                  source_id: item.id,
                  manager_id: manager_id,
                })
              "
              v-model="manager_id"
            >
              <option value="">Менеджер</option>
              <template v-for="m in selectManagers" :key="m">
                <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
              </template>
            </select>
          </template>
        </template>

        <template v-if="['Studio', 'Hosd'].includes(role)">
          <div class="group cursor-pointer" @click="delLeadSource(item.id)">
            <svg
              class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
            >
              <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
          </div>
        </template>
      </a>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LeadSource",

  data() {
    return {
      host: "",
      url: "",
      manager_id: 0,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "leadSource",
      "selectManagers",
    ]),
  },

  methods: {
    ...mapActions([
      "getLeadSource",
      "addLeadSource",
      "delLeadSource",
      "setLeadSourceManager",
      "delLeadSourceManager",
    ]),
  },

  mounted() {
    this.getLeadSource();
  },
};
</script>
