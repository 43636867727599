<template>
  <div
    id="popup"
    class="w-[49%] ml-auto relative opacity-100 bg-white rounded-lg overflow-y-auto max-h-full mb-8 max-l:mx-auto max-l:w-full max-l:max-w-[450px]"
  >
    <a
      class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
      @click="close"
    >
      <span class="text-white mr-2">Закрыть</span>
      <svg class="fill-white w-4 h-4">
        <use xlink:href="@/assets/i.svg#ico-no"></use>
      </svg>
    </a>

    <div>
      <div class="p-5 v-bbgray">
        <h2 class="flex flex-col" v-if="calendarDay.day">
          <span class="text-gray-400 uppercase text-[11px] font-latobold">
            {{ selectWeekDay[parseInt(moment(calendarDay.day).isoWeekday())] }}
          </span>
          <span class="text-black text-xl font-latobold">
            {{ moment(calendarDay.day).format("DD") }}
            {{ selectMonthMin[parseInt(moment(calendarDay.day).format("MM"))] }}
          </span>
        </h2>
      </div>

      <div>
        <template v-if="[1, 3].includes(s.calendar)">
          <template v-if="role == 'Master'">
            <div
              class="grid grid-cols-calendar__popup-head px-4 py-2 text-gray-400 text-xs v-bbgray"
            >
              <div class="">Время</div>
              <div class="">Заказы</div>
            </div>
            <div
              v-for="row in calendarDay"
              :key="row"
              class="h-9 grid grid-cols-calendar__popup-head v-bbgray hover:shadow-v2 transition-all duration-150"
            >
              <div
                class="bg-sky-50 h-full flex items-center justify-center text-[11px]"
              >
                {{ row.start?.slice(0, 5) }}
              </div>
              <div class="flex ml-4 w-max items-center">
                <IcoPicSmall :pic="row.wpicture" :name="row.wname" />

                <a
                  class="cursor-pointer mr-2 text-[12px] text-gray-500 hover:text-green-500 transition-colors duration-150"
                  @click="editOrderId(row.id)"
                >
                  {{ row.iid }}
                </a>
                <div class="c-dark mr5">{{ row.client }}</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="grid grid-cols-calendar__popup-head px-4 py-2 text-gray-400 text-xs v-bbgray"
            >
              <div class="">Время</div>
              <div class="">Заказы</div>
              <div class="text-right">Свободны</div>
            </div>
            <div
              v-for="row in calendarDay.cal_time"
              :key="row"
              class="h-9 grid grid-cols-calendar__popup-head v-bbgray hover:shadow-v2 transition-all duration-150"
            >
              <div
                class="bg-sky-50 h-full flex items-center justify-center text-[11px]"
              >
                {{ row.time.slice(0, 5) }}
              </div>

              <div class="flex ml-4 w-max items-center">
                <div
                  v-for="item in row.order"
                  :key="item"
                  class="flex items-center mx-2 bg-slate-100 rounded-xl py-1"
                >
                  <IcoPicSmall
                    :pic="item.profile.picture"
                    :name="item.profile.name"
                  />

                  <a
                    class="cursor-pointer mr-2 text-[12px] text-gray-500 hover:text-green-500 transition-colors duration-150"
                    @click="editOrderId(item.order.id)"
                  >
                    {{ item.order.iid }}
                  </a>
                  <div v-if="role == 'Master'" class="mr-2">
                    {{ item.order.client.name }}
                  </div>
                </div>
              </div>
              <div
                v-if="['Manager', 'Logist', 'Studio', 'Hosd'].includes(role)"
                class="flex ml-6 w-max items-center justify-self-end mr-6"
              >
                <template v-if="role == 'Logist'">
                  <a
                    v-for="item in row.empty"
                    :key="item"
                    class="rounded-full bg-slate-100 p-2"
                  >
                    <IcoPicSmall
                      :pic="item.profile.picture"
                      :name="item.profile.name"
                    />
                  </a>

                  <a
                    v-for="item in row.lock"
                    :key="item"
                    class="rounded-full bg-slate-100 p-2"
                  >
                    <svg class="f-red">
                      <use xlink:href="@/assets/i.svg#ico-closed"></use>
                    </svg>
                    <IcoPicSmall
                      :pic="item.profile.picture"
                      :name="item.profile.name"
                    />
                  </a>
                </template>
                <template v-if="['Manager', 'Studio', 'Hosd'].includes(role)">
                  <a
                    v-for="item in row.empty"
                    :key="item"
                    class="w-6 h-6 rounded-full bg-slate-100 p-[2px] cursor-pointer"
                    @click="jsaddorder"
                  >
                    <IcoPicSmall
                      :pic="item.profile.picture"
                      :name="item.profile.name"
                    />
                  </a>

                  <a
                    v-for="item in row.lock"
                    :key="item"
                    class="w-6 h-6 rounded-full bg-slate-100 border-[1px] border-rose-500 p-[2px] relative"
                  >
                    <svg class="fill-rose-400 w-3 h-3 absolute -right-1 -top-1">
                      <use xlink:href="@/assets/i.svg#ico-closed"></use>
                    </svg>

                    <IcoPicSmall
                      :pic="item.profile.picture"
                      :name="item.profile.name"
                    />
                  </a>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>

      <template v-if="s.calendar == 2">
        <div
          v-for="row in calendarDay.cal_time"
          :key="row"
          class="h-9 grid grid-cols-calendar__popup-head v-bbgray hover:shadow-v2 transition-all duration-150"
        >
          <div
            class="bg-sky-50 h-full flex items-center justify-center text-[11px]"
          >
            {{ row.time.slice(0, 5) }}
          </div>

          <div class="flex ml-4 w-max items-center mr-6">
            <a
              v-for="item in row.done"
              :key="item"
              class="w-5 h-5 rounded-full bg-slate-100 p-[2px] cursor-pointer"
            >
              <IcoPicSmall :pic="item.picture" :name="item.name" />
            </a>
          </div>
          <div class="flex ml-4 w-max items-center justify-self-end mr-6">
            <a
              v-for="item in row.open"
              :key="item"
              class="w-5 h-5 rounded-full bg-slate-100 p-[2px] cursor-pointer"
            >
              <IcoPicSmall :pic="item.picture" :name="item.name" />
            </a>
          </div>
        </div>
      </template>

      <template v-if="s.calendar == 4">
        <div
          v-for="row in calendarDay.cal_time"
          :key="row"
          class="h-9 grid grid-cols-calendar__popup-head v-bbgray hover:shadow-v2 transition-all duration-150 items-center"
        >
          <div
            class="bg-sky-50 h-full flex items-center justify-center text-[11px]"
          >
            {{ row.time.slice(0, 5) }}
          </div>

          <a
            v-for="item in row.order"
            :key="item"
            class="rounded-full p-[2px] cursor-pointer flex items-center mx-4 w-full"
          >
            <div
              class="min-w-[1.5rem] bg-gray-300 rounded-full flex justify-center items-center"
            >
              <IcoPicSmall pic="" :name="item.client" />
            </div>
            <a class="text-[11px] ml-2">{{ item.iid }}</a>
            <div class="mx-4 w-max font-latobold">{{ item.client }}</div>
            <div class="ml-1">{{ item.sum }}</div>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import IcoPicSmall from "../components/ui/IcoPicSmall.vue";

export default {
  name: "CalendarDay",

  components: { IcoPicSmall },

  data() {
    return {
      moment: moment,
    };
  },
  computed: {
    ...mapGetters([
      "s",
      "role",
      "calendarDay",
      "selectMonthMin",
      "selectWeekDay",
    ]),
  },
  methods: {
    ...mapActions(["delCalDay", "editOrderId"]),

    close() {
      console.log("close");
      this.delCalDay();
      this.$emit("closePopup");
    },
  },

  mounted() {},
};
</script>
