import API from "../../services/api";
// import router from "@/router/router";

export default {
    state: {
        tasks: [],
        filteredTasks: [],
        countTasks: [],
        tasksTypes: [],
        tasksWorkers: [],
        task: {},
    },

    mutations: {
        setTasks(state, tasks) {
            state.tasks = tasks;
            state.filteredTasks = tasks;
            state.countTasks = tasks;
            // state.tasks = tasks.filter((item) => parseInt(item.status) != 8);
        },

        setMarketings(state, tasks) {
            state.marketings = tasks;
            state.filteredMarketings = tasks;
            state.countMarketings = tasks;
            // state.tasks = tasks.filter((item) => parseInt(item.status) != 8);
        },

        filteredTasks(state, tasks) {
            state.filteredTasks = tasks;
        },

        setTasksTypes(state, data) {
            state.tasksTypes = data;
        },

        setTasksWorkers(state, data) {
            state.tasksWorkers = data;
        },

        setTask(state, task) {
            state.task = task;
        },
    },

    actions: {
        getTasks(ctx) {
            ctx.dispatch("setLoader", true);
            API.GET("task/tasks").then((res) => {
                ctx.commit("setTasks", res.data.tasks);
                ctx.commit("setTasksTypes", res.data.types);
                localStorage.setItem("taskTypes", JSON.stringify(res.data.types));
                ctx.commit("setTasksWorkers", res.data.workers);
                localStorage.setItem("taskWorkers", JSON.stringify(res.data.workers));
                let s = ctx.rootState.s;
                // s.status = 2;
                ctx.dispatch("findTask", s).then(() => {
                    ctx.dispatch("setLoader", false);
                });
            });
        },

        getMarketings(ctx) {
            const t0 = performance.now();
            ctx.dispatch("setLoader", true);
            API.GET("task/marketing").then((res) => {
                ctx.commit("setMarketings", res.data.tasks);
                localStorage.setItem("marketings", JSON.stringify(res.data.tasks));
                ctx.commit("setTasksTypes", res.data.types);
                localStorage.setItem("taskTypes", JSON.stringify(res.data.types));
                ctx.commit("setTasksWorkers", res.data.workers);
                localStorage.setItem("taskWorkers", JSON.stringify(res.data.workers));

                ctx.dispatch("findMarketing", ctx.rootState.s).then(() => {
                    ctx.dispatch("setLoader", false);
                    console.log(performance.now() - t0, "ms");
                });
            });
        },

        findTask(ctx, s) {
            let list = ctx.state.tasks;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let taskname = null;
                    let client = null;
                    let creator = null;
                    iid = item.iid.toString().indexOf(s.findme) !== -1;
                    if (item.taskname) {
                        taskname = item.taskname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.wname) {
                        creator = item.wname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (iid || taskname || client || creator) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.creator != "" && s.creator != undefined && s.creator != null) {
                list = list.filter((item) => {
                    if (String(item.who) == String(s.creator)) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.worker != "" && s.worker != undefined) {
                list = list.filter((item) => {
                    if (String(item.uid) == String(s.worker)) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.dep != "" && s.dep != undefined) {
                list = list.filter((item) => {
                    if (String(item.dep) == String(s.dep)) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.type != "" && s.type != undefined) {
                list = list.filter((item) => {
                    if (String(item.type) == String(s.type)) {
                        return true;
                    }
                    return false;
                });
            }            

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => {
                    if (item.date && item.date >= s.start) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.category != "" && s.category != undefined) {
                let from = null;
                let to = null;
                if (s.category == 1) {
                    from = 0;
                    to = 2;
                }
                if (s.category == 10) {
                    from = 1;
                    to = 11;
                }
                if (s.category == 30) {
                    from = 10;
                    to = 31;
                }
                if (s.category == 50) {
                    from = 30;
                    to = 51;
                }
                if (s.category == 99) {
                    from = 50;
                    to = 100000;
                }

                if (s.category == "zero") {
                    list = list.filter((item) => parseInt(item.ts) < 1 || item.ts == null);
                } else {
                    list = list.filter((item) => parseInt(item.ts) > from && parseInt(item.ts) < to);
                }
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => {
                    if (item.date && item.date <= s.end) {
                        return true;
                    }
                    return false;
                });
            }

            if (list.length != ctx.state.tasks.length) {
                s.clear = true;
            }

            ctx.state.filteredTasks = list;
            ctx.state.countTasks = list;
            ctx.dispatch("setSettings", s);
        },

        statusTask(ctx, s) {
            ctx.dispatch("findTask", s);
            let list = ctx.state.filteredTasks;

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => {
                    if (String(item.status) == String(s.status)) {
                        return true;
                    }
                    return false;
                });
            }

            ctx.state.filteredTasks = list;
        },

        findMarketing(ctx, s) {
            console.log(s);
            let list = ctx.state.marketings;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let taskname = null;
                    let client = null;
                    iid = item.iid.toString().indexOf(s.findme) !== -1;
                    if (item.taskname) {
                        taskname = item.taskname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (iid || taskname || client) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.worker != "" && s.worker != undefined) {
                list = list.filter((item) => parseInt(item.uid) == parseInt(s.worker));
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => {
                    if (item.date && item.date >= s.start) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => {
                    if (item.date && item.date <= s.end) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.category != "" && s.category != undefined) {
                let from = null;
                let to = null;
                if (s.category == 1) {
                    from = 0;
                    to = 2;
                }
                if (s.category == 10) {
                    from = 1;
                    to = 11;
                }
                if (s.category == 30) {
                    from = 10;
                    to = 31;
                }
                if (s.category == 50) {
                    from = 30;
                    to = 51;
                }
                if (s.category == 99) {
                    from = 50;
                    to = 100000;
                }

                if (s.category == "zero") {
                    list = list.filter((item) => parseInt(item.ts) < 1 || item.ts == null);
                } else {
                    list = list.filter((item) => parseInt(item.ts) > from && parseInt(item.ts) < to);
                }
            }

            if (list.length != ctx.state.marketings.length) {
                s.clear = true;
            }

            ctx.state.filteredMarketings = list;
            ctx.state.countMarketings = list;
            ctx.dispatch("setSettings", s);
        },

        statusMarketing(ctx, s) {
            ctx.dispatch("findMarketing", s);

            let list = ctx.state.filteredMarketings;

            if ([2, 6].includes(s.status)) {
                list = list.filter((item) => {
                    if (parseInt(item.status) == parseInt(s.status)) {
                        return true;
                    }
                    return false;
                });
            }
            if (s.status == "vio") {
                list = list.filter((item) => parseInt(item.violate) > 0);
            }

            if (list.length != ctx.state.marketings.length) {
                s.clear = true;
            }

            ctx.state.filteredMarketings = list;
        },

        getTaskTypesWorkers(ctx) {
            if (ctx.state.tasksTypes?.length > 0 && ctx.state.tasksWorkers?.length > 0) {
                return;
            }
            API.GET("task/types-workers").then((res) => {
                ctx.commit("setTasksTypes", res.data.types);
                ctx.commit("setTasksWorkers", res.data.workers);
            });
        },

        setTask(ctx, task) {
            ctx.commit("setTask", task);
            localStorage.setItem("task", JSON.stringify(task));
        },

        showTaskReport(ctx, task) {
            ctx.rootState.s.popup = "task";
            ctx.commit("settings", ctx.rootState.s);
            ctx.dispatch("getTaskTypesWorkers");

            API.GET("task/one", { id: task.id }).then((res) => {
                ctx.dispatch("setTask", res.data.task);
            });
        },

        async RemoveSelectedTasks (ctx, info) {
            let fd = new FormData();
            info.checked.forEach((x) => fd.append("ids[]", x));
            
            let res = await API.POST("task/del", fd);

            if (res.status == 200) {
                res.data.alert ? ctx.commit("setAlert", res.data.alert.success) : null;                
                ctx.dispatch("getTasks");
            }
            else {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                throw new Error(res.status);
            }             
        },

        showTask(ctx, task) {
            ctx.dispatch("setTask", task);
            ctx.rootState.s.popup = "task";
            ctx.commit("settings", ctx.rootState.s);
            ctx.dispatch("getTaskTypesWorkers");

            API.GET("task/one", { id: task.id }).then((res) => {
                ctx.dispatch("setTask", res.data.task);
            });
        },

        addTask(ctx, formData) {
            console.log("formData", formData);
            API.GET("task/add-task", formData).then((res) => {
                ctx.dispatch("setTask", res.data.task);
                ctx.rootState.s.popup = "task";
                ctx.commit("settings", ctx.rootState.s);

                if (res.data.task.client_id && ctx.rootState.client) {
                    ctx.dispatch("pushTaskinClient", res.data.task);
                }
                if (res.data.task.lead_id && ctx.rootState.lead) {
                    ctx.dispatch("pushTaskinLead", res.data.task);
                }
                if (res.data.task.order_id && ctx.rootState.order) {
                    ctx.dispatch("pushTaskinOrder", res.data.task);
                }
                if (ctx.state.tasks) {
                    ctx.state.tasks.unshift(res.data.task);
                }
            });
        },

        setTaskDep(ctx, id) {
            ctx.state.task.department = id;
            if (id == 9) {
                ctx.state.task.uid = 1;
                // ctx.state.task.type = 1;
            } else {
                ctx.state.task.uid = null;
            }
        },

        setTaskType(ctx, task) {
            ctx.dispatch("setTask", task);

            let formData = new FormData();
            formData.append("id", task.id);
            formData.append("department", parseInt(task.department));
            formData.append("type", parseInt(task.type));
            if (parseInt(task.uid) > 0) formData.append("uid", parseInt(task.uid));
            API.POST("task/set-type", formData).then((res) => {
                ctx.dispatch("setTask", res.data.task);
                ctx.dispatch("updateTaskList", res.data.task);
            });
        },

        delTaskType(ctx, task) {
            ctx.state.task.department = null;
            ctx.state.task.type = null;
            ctx.state.task.uid = null;
            ctx.dispatch("setTask", task);

            let formData = new FormData();
            formData.append("id", task.id);
            API.POST("task/del-type", formData).then((res) => {
                ctx.dispatch("setTask", res.data.task);
                ctx.dispatch("updateTaskList", res.data.task);
            });
        },

        setTaskUser(ctx, task) {
            ctx.dispatch("setTask", task);

            let formData = new FormData();
            formData.append("id", task.id);
            formData.append("uid", task.uid);
            API.POST("task/set-user", formData).then((res) => {
                ctx.dispatch("setTask", res.data.task);
                ctx.dispatch("updateTaskList", res.data.task);
            });
        },

        delTaskUser(ctx, task) {
            ctx.state.task.uid = null;
            ctx.dispatch("setTask", task);

            let formData = new FormData();
            formData.append("id", task.id);
            API.POST("task/del-user", formData).then((res) => {
                ctx.dispatch("setTask", res.data.task);
                ctx.dispatch("updateTaskList", res.data.task);
            });
        },

        updateTaskList(ctx, task) {
            if (ctx.state.tasks && task) {
                ctx.state.tasks = ctx.state.tasks.filter((t) => t.id !== task.id);
                ctx.state.tasks.unshift(task);
                ctx.commit("setTasks", ctx.state.tasks);
                localStorage.setItem("tasks", JSON.stringify(ctx.state.tasks));
            }
        },

        setTaskStatus(ctx, status) {
            ctx.state.task.status = status;
            ctx.dispatch("setTask", ctx.state.task);

            let formData = new FormData();
            formData.append("id", ctx.state.task.id);
            formData.append("status", status);
            API.POST("task/set-status", formData).then((res) => {
                ctx.dispatch("setTask", res.data.task);
                ctx.dispatch("updateTaskList", res.data.task);
            });
        },

        setTaskDate(ctx, value){
            ctx.state.task.date = value;
            ctx.dispatch("setTask", ctx.state.task);

            let formData = new FormData();
            formData.append("id", ctx.state.task.id);
            formData.append("date", value);
            API.POST("task/set-date", formData).then((res) => {
                ctx.dispatch("setTask", res.data.task);
                ctx.dispatch("updateTaskList", res.data.task);
            });
        },

        setTaskMinutes(ctx, value){
            ctx.state.task.minutes = value;
            ctx.dispatch("setTask", ctx.state.task);

            let formData = new FormData();
            formData.append("id", ctx.state.task.id);
            formData.append("minute", value);
            API.POST("task/set-minute", formData).then((res) => {
                ctx.dispatch("setTask", res.data.task);
                ctx.dispatch("updateTaskList", res.data.task);
            });
        },

        delTask(ctx, task) {
            ctx.state.tasks = ctx.state.tasks.filter((t) => t.iid !== task.iid);
            ctx.state.filteredTasks = ctx.state.filteredTasks.filter((t) => t !== task);

            if (ctx.rootState.order.tasks) {
                ctx.rootState.order.tasks = ctx.rootState.order.tasks.filter((t) => t !== task);
                ctx.dispatch("setOrder", ctx.rootState.order);
            }
            ctx.rootState.s.popup = null;
            ctx.commit("setTasks", ctx.state.tasks);
            ctx.commit("filteredTasks", ctx.state.filteredTasks);
            ctx.commit("settings", ctx.rootState.s);


            let formData = new FormData();
            formData.append("iid", task.iid);
            API.POST("task/del-task", formData);
        },
    },

    getters: {
        TaskList(state) {
            return state.filteredTasks;
        },

        MarketingList(state) {
            return state.filteredMarketings;
        },

        TaskCounter(state) {
            let count = [];
            if (state.countTasks && state.countTasks.length > 0) {
                count[1] = 0;
                count[2] = 0;
                count[4] = 0;
                count[5] = 0;
                count[6] = 0;
                count[7] = 0;
                count[19] = 0;
                count["vio"] = 0;
                state.countTasks.forEach((elem) => {
                    if (elem.status == 1) {
                        count[1]++;
                    }
                    if (elem.status == 2) {
                        count[2]++;
                    }
                    if (elem.status == 4) {
                        count[4]++;
                    }
                    if (elem.status == 5) {
                        count[5]++;
                    }
                    if (elem.status == 6) {
                        count[6]++;
                    }
                    if (elem.status == 7) {
                        count[7]++;
                    }
                    if (elem.status == 19) {
                        count[19]++;
                    }
                    if (elem.violate > 0) {
                        count["vio"]++;
                    }
                });
            }
            return count;
        },

        MarketingCounter(state) {
            let count = [];
            if (state.countMarketings && state.countMarketings.length > 0) {
                count[2] = 0;
                count[6] = 0;
                count["vio"] = 0;
                state.countMarketings.forEach((elem) => {
                    if (elem.status == 2) {
                        count[2]++;
                    }
                    if (elem.status == 6) {
                        count[6]++;
                    }
                    if (elem.violate > 0) {
                        count["vio"]++;
                    }
                });
            }
            return count;
        },

        tasksTypes(state) {
            return state.tasksTypes;
        },

        tasksWorkers(state) {
            return state.tasksWorkers;
        },

        task(state) {
            return state.task;
        },
    },
};
