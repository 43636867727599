import axios from "axios";
import authHeader from "./header";

const API = "https://api.tahocrm.ru/";
const IMG = "https://api.tahocrm.ru/uploads/";

// const API = "https://apidev.tahocrm.ru/";
// const IMG = "https://apidev.tahocrm.ru/uploads/";

// const API = "http://192.168.50.32:8000/";
// const IMG = "http://localhost:8099/";

//const API = "http://192.168.50.50:8000/";
//const IMG = "http://192.168.50.50:8008/";

//const API = "http://109.194.2.11:8000/";
//const IMG = "http://109.194.2.11:8008/";

//const API = "http://192.168.88.250:8000/";
//const IMG = "http://192.168.88.250:8008/";

class UserService {
    GET(url, data) {
        return axios.get(API + url, { params: data, headers: authHeader() });
    }
    POST(url, formData) {
        return axios.post(API + url, formData, { headers: authHeader() });
    }
}
UserService.prototype.URL = API;
UserService.prototype.IMG = IMG;
export default new UserService();
