<template>
    <div
    class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
    :click="click"
    >
        <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
            <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto transition-all duration-300">
                <use :xlink:href="require('@/assets/i.svg') + '#' + ico"></use>
            </svg>
            <h2 class="text-2xl z-2 relative mt-1 text-white transition-all duration-300">
                {{ num }}
            </h2>
            <svg
                class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 transition-all duration-300"
                :class="back"
            >
                <use xlink:href="@/assets/i.svg#bg-romb"></use>
            </svg>
        </div>
        <span
            class="duration-300 w-full  opacity-100 text-status-20 label-romb"
        >
            {{ label }}
        </span>
    </div>
</template>
<script>
export default {
    name: "ReportListRomb",
    props: {
        ico: String,
        back: String,
        num: Number,
        label: String,
        click: Function,
    }
}
</script>

<style scoped>
    .label-romb {
        color: #262D29;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
</style>